<ng-template #defaultButtons>
  <ng-template ngFor let-buttonItem [ngForOf]="data">
    <button *ngIf="!buttonItem.hide"
            [type]="buttonItem.btnType ? buttonItem.btnType : 'button'"
            [ngClass]="generateButtonClasses(buttonItem)"
            [disabled]="buttonItem.loading ? true : buttonItem.disabled !== undefined ? buttonItem.disabled : disabled"
            (click)="buttonClick.emit(buttonItem)">
      <span *ngIf="buttonItem.icon"
            [ngClass]="[buttonItem.icon]"></span>
      {{ buttonItem.text }}
    </button>
  </ng-template>

</ng-template>
<ng-container *ngIf="dataTemplate && data">
  <ng-template [ngTemplateOutlet]="dataTemplate"></ng-template>
  <ng-template [ngTemplateOutlet]="defaultButtons"></ng-template>
</ng-container>
<ng-container *ngIf="dataTemplate && !data || !dataTemplate && data"
              [ngTemplateOutlet]="dataTemplate ? dataTemplate : defaultButtons">
</ng-container>
