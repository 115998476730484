<app-header></app-header>
<main class="mbs-container">
  <div class="mbs-container-inner">
    <div class="mbs-container-content">
      <div class="mbs-page">
        <div class="mbs-page_content">
          <router-outlet></router-outlet>
        </div>
        <mbs-toasts data-position="bottom-right"></mbs-toasts>
        <mbs-alert-container></mbs-alert-container>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</main>
