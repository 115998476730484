import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackupHistoryItem, hasAdminRole, HistoryPeriods, HistoryUnits, PieDataItem, ServiceType } from '@app/common/models';
import { ODataService, ODataServiceFactory } from '@app/common/odata';
import { AuthService, BaseOdataService } from '@app/common/services';
import { forkJoin, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class HistoryService extends BaseOdataService<any> {
  private odataMethods: ODataService<any>;

  constructor(odataFactory: ODataServiceFactory, private http: HttpClient, private authService: AuthService) {
    super(odataFactory, 'BackupUsers');
    this.odataMethods = odataFactory.CreateService('');
  }

  usageHistory(period: HistoryPeriods, units: HistoryUnits): Observable<BackupHistoryItem[]> {
    return forkJoin([this.authService.getAuthUser(), this.authService.getRoles()]).pipe(
      switchMap(([user, roles]) => {
        const isAdmin: boolean = hasAdminRole(roles);
        const id: string = isAdmin ? user.DomainId : user.Id;
        const serviceName: string = isAdmin ? 'Domains' : 'BackupUsers';
        const newOData: ODataService<BackupHistoryItem> = this.odataFactory.CreateService<BackupHistoryItem>(
          `/${serviceName}(${id})/UsageHistory`
        );

        return newOData
          .Query()
          .CustomQueryOptions([
            { key: 'period', value: period },
            { key: 'units', value: units }
          ])
          .Exec();
      })
    );
  }

  userUsageHistory(id: string, period: HistoryPeriods, units: HistoryUnits): Observable<BackupHistoryItem[]> {
    const newOData = this.odataFactory.CreateService<BackupHistoryItem>(`/BackupUsers(${id})/UsageHistory`);
    return newOData
      .Query()
      .CustomQueryOptions([
        { key: 'period', value: period },
        { key: 'units', value: units }
      ])
      .Exec();
  }

  userUsageDetails(id: string, type: ServiceType): Observable<PieDataItem[]> {
    const newOData = this.odataFactory.CreateService<PieDataItem>(`/BackupUsers(${id})/UsageDetails`);
    const url = newOData
      .Query()
      .CustomQueryOptions([{ key: 'service', value: type }])
      .GetUrl();
    return this.http.get<{ Items: PieDataItem[]; Service: ServiceType }>(url).pipe(map((x) => x.Items));
  }

  domainUsageDetails(id: string, type: ServiceType): Observable<PieDataItem[]> {
    const newOData = this.odataFactory.CreateService<PieDataItem>(`/Domains(${id})/UsageDetails`);
    const url = newOData
      .Query()
      .CustomQueryOptions([{ key: 'service', value: type }])
      .GetUrl();
    return this.http.get<{ Items: PieDataItem[]; Service: ServiceType }>(url).pipe(map((x) => x.Items));
  }

  domainUsageHistory(id: string, period: HistoryPeriods, units: HistoryUnits): Observable<BackupHistoryItem[]> {
    const newOData = this.odataFactory.CreateService<BackupHistoryItem>(`/Domains(${id})/UsageHistory`);
    return newOData
      .Query()
      .CustomQueryOptions([
        { key: 'period', value: period },
        { key: 'units', value: units }
      ])
      .Exec();
  }
}
