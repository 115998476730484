import { isArray, isEqual } from 'lodash';
import { Observable, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, startWith } from 'rxjs/operators';

export function getLoadingState(state: Observable<boolean>[] | Observable<boolean>): Observable<boolean> {
  if (!state) return of(false);

  if (isArray(state)) {
    const startState: boolean[] = new Array(state.length).fill(true);

    return combineLatest(state).pipe(
      startWith(startState),
      distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
      map((loads: boolean[]) => loads.some(Boolean)),
      shareReplay(1)
    );
  } else {
    return state.pipe(startWith(true), distinctUntilChanged(), shareReplay(1));
  }
}
