import { Pipe, PipeTransform } from '@angular/core';
import { I18NextPipe } from 'angular-i18next';

@Pipe({
  name: 'selectGridAllButtonText',
  standalone: true,
  pure: true
})
export class SelectGridAllButtonTextPipe implements PipeTransform {
  constructor(private i18nPipe: I18NextPipe) {}

  transform(total: number, oneEntityKey: string, manyEntitiesKey: string): string {
    const selectAll = this.i18nPipe.transform('paging.selectAll');
    const entities = this.i18nPipe.transform(total === 1 ? oneEntityKey : manyEntitiesKey);

    return `${selectAll} ${entities}: ${total}`;
  }
}
