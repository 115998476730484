import { InjectionToken } from '@angular/core';

export function loadConfigurations(url) {
  return new Promise(function (resolve, reject) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    xhr.onload = function () {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response);
      } else {
        reject(new Error(`Load configuration error: ${xhr.statusText}`));
      }
    };
    xhr.onerror = function () {
      reject(new Error(`Load configuration error: ${xhr.statusText}`));
    };
    xhr.send();
  });
}

export const MBS_APP_CONFIG = new InjectionToken<string>('MbsAppConfig');
