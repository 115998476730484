import { Attribute, Component, ContentChild, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import { isNil } from 'lodash';
import { MbsSize } from '../utils';
import { ButtonDropdownDirective } from './button-dropdown.directive';

export type ButtonSize = MbsSize.inherit | MbsSize.xxs | MbsSize.xs | MbsSize.sm | MbsSize.md | MbsSize.lg;
export type ButtonType =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'light'
  | 'dark'
  | 'link'
  | 'brand'
  | 'muted'
  | 'outline-primary'
  | 'outline-secondary'
  | 'outline-success'
  | 'outline-danger'
  | 'outline-warning'
  | 'outline-info'
  | 'outline-light'
  | 'outline-dark'
  | 'outline-brand';

@Component({
  selector: 'mbs-button, [mbs-button]',
  templateUrl: 'button.component.html',
  host: {
    '[class.-isCtrl]': 'isCtrl',
    '[class.-disabled]': 'disabledOrLoading',
    '[class.-isBlock]': 'block'
  }
})
export class ButtonComponent {
  public iconClasses: string;
  public readonly MbsSize = MbsSize;

  /**
   * Button type. By default is `button`
   */
  @Input() buttonType: 'button' | 'reset' | 'submit' = 'button';
  /**
   * If `true` it will use `ctrl` class instead of `btn`
   */
  @Input() isCtrl = false;

  /**
   * If `true` it will use `dashed` class instead of `btn`
   */
  @Input() isDashed = false;

  /**
   * Implements one of bootstrap's themes to button <br>
   * Possible values `primary | secondary | success | danger | warning | info | light | dark | link`
   * <br><br>
   * Defaults: <br>
   * If `isCtrl` is `true` - `primary` <br>
   * Else - `secondary`
   */
  @Input() type: ButtonType;
  /**
   * Implements one of button size<br />
   * Possible values for btn: `'xxs' | 'xs' | 'sm' | 'md' | 'lg'`<br />
   * Possible values for ctrl: `'inherit' | 'xxs' | 'xs' | 'sm' | 'md' | 'lg'`
   */
  @Input() size: ButtonSize;
  /**
   * If `true` button will have `display: block` style
   */
  @Input() block = false;
  /**
   * For now correctly works only when `isCtrl` is `false` <br>
   * If `true` replaces button's content with an animated loader and disables the button.
   */
  @Input() loading = false;
  @Input() disabled = false;
  /**
   * CSS class(es) for an icon. For now icon always placed on the left of button content
   */
  @Input() icon: string;

  @Input() active: boolean;

  @Input() id: string;

  @Input() textOverflow: boolean;

  @Input() customParentClasses = '';
  @Input() customClasses = '';
  /**
   * The preferred placement of the dropdown.
   * Accepts an array of strings or a string with space separated possible values.
   */
  @Input() dropdownPlacement: PlacementArray = 'bottom-left bottom-right top-left top-right';
  @Input() tooltipData: string[]; // Works only as array. Do not try to use simple string
  @Input() tooltipContainer = 'body';
  @Input() tooltipPlacement: PlacementArray = ['left', 'auto'];
  @Input() tooltipTriggers = 'click:blur';
  @Input() tooltipAlways = false;
  @Input() tooltipClass: string;
  @Input() hideDropdownIcon = false;

  // Set button's width equal to it's height
  @Input() square = false;

  // Button's border radius will be set to 50%
  @Input() rounded = false;

  @Input() align: 'left';

  @Output() click = new EventEmitter();

  @ContentChild(ButtonDropdownDirective, { static: true, read: ButtonDropdownDirective })
  dropdownMenuItemsTemplate: ButtonDropdownDirective;

  private get realType(): string {
    if (this.type) {
      return this.type;
    }

    return this.isCtrl ? 'primary' : 'secondary';
  }

  public get prefix(): string {
    if (this.isCtrl) return 'ctrl';
    if (this.isDashed) return 'dashed';

    return 'btn';
  }

  public get btnOverflowClass(): string {
    return isNil(this.textOverflow) ? '' : this.textOverflow ? 'text-overflow' : 'text-no-overflow';
  }

  public get btnTypeClass(): string {
    return `${this.prefix}-${this.realType}`;
  }

  public get btnSizeClass(): string {
    return this.size ? `${this.prefix}-${this.size}` : '';
  }

  public get btnBlockClass(): string {
    return this.block ? 'btn-block' : '';
  }

  public get btnLoadingClass(): string {
    return this.loading ? '-loading' : '';
  }

  public get btnActiveClass(): string {
    return this.active ? 'active' : '';
  }

  get disabledOrLoading(): boolean {
    return this.disabled || this.loading;
  }

  constructor(@Attribute('disabled') disabled) {
    this.disabled = !isNil(disabled) && disabled !== false;
  }

  ngOnInit(): void {
    this.iconClasses = `${this.isCtrl ? 'ctrl-ico' : 'btn-ico'} ${this.icon}`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled) {
      const disabled = changes.disabled.currentValue as boolean;

      this.disabled = !isNil(disabled) && disabled !== false;
    }

    if (changes.icon) {
      this.iconClasses = `${this.isCtrl ? 'ctrl-ico' : 'btn-ico'} ${this.icon}`;
    }
  }

  handleClick(event: Event & { pointerType: string; target: EventTarget & { blur: () => void } }): void {
    event.stopPropagation();

    if (event.pointerType === 'mouse') {
      event?.target?.blur();
    }

    if (this.buttonType !== 'submit') {
      event.preventDefault();
    }

    this.click.emit(event);
  }
}
