import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ButtonModule } from '../../button/button.module';
import { DatepickerModule } from '../datepicker/datepicker.module';
import { InputBaseModule } from '../input-base/input-base.module';
import { InputRangeModule } from '../input-range/input-range.module';
import { InputModule } from '../input/input.module';
import { DatepickerRangeComponent } from './datepicker-range.component';

@NgModule({
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    InputBaseModule,
    ButtonModule,
    InputModule,
    InputRangeModule,
    DatepickerModule,
    NgbDropdownModule
  ],
  declarations: [DatepickerRangeComponent],
  exports: [DatepickerRangeComponent]
})
export class DatepickerRangeModule {}
