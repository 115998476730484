import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { FormError } from '@app/common';
import { BackupStatisticGrid, DeleteUserBackupOutput } from '@app/common/components/delete-user-backup';
import { DeleteBackup, ServiceType } from '@app/common/models';
import { AuthService, UserOdataService } from '@app/common/services';
import { isHomeUser } from '@app/common/utils';
import { I18_NAMESPACE_MODULE } from '@app/i18';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MbsPopupType, ModalComponent } from 'mbs-ui-kit';
import { BehaviorSubject, forkJoin, Observable, switchMap } from 'rxjs';
import { finalize, map, startWith } from 'rxjs/operators';

@UntilDestroy()
@Component({
  templateUrl: './delete-backup-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteBackupModalComponent {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  #payloadForDeleteBackup: DeleteBackup = {} as DeleteBackup;

  public readonly loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public readonly disabled$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  public readonly services$: BehaviorSubject<ServiceType[]> = new BehaviorSubject([]);
  public readonly moduleAccount = I18_NAMESPACE_MODULE.account;

  public readonly MbsPopupType = MbsPopupType;
  public backupStatistics$: Observable<BackupStatisticGrid[]>;

  public deleteBackupFormError: FormError = { message: '' };

  get userId(): string {
    return this.authService.id;
  }

  constructor(private authService: AuthService, public userService: UserOdataService) {
    this.backupStatistics$ = forkJoin([
      this.authService.getAuthUser().pipe(switchMap((user) => this.userService.getBackupStatistic([this.userId], !isHomeUser(user)))),
      this.authService.getRoles()
    ]).pipe(
      map(([statistic, roles]) => this.userService.mapBackupStatisticToGridList(statistic, roles)),
      startWith([]),
      finalize(() => this.loading$.next(false))
    );
  }

  invalidForms(state: boolean): void {
    this.disabled$.next(!state);
  }

  deleteBackupChange(value: DeleteUserBackupOutput): void {
    this.#payloadForDeleteBackup = {
      UserIds: [this.userId],
      Email: value.deleteServices.includes(ServiceType.Mail),
      Drive: value.deleteServices.includes(ServiceType.Drive),
      Contact: value.deleteServices.includes(ServiceType.Contacts),
      Calendar: value.deleteServices.includes(ServiceType.Calendar),
      TeamDrive: value.deleteServices.includes(ServiceType.TeamDrives),
      SharePoint: value.deleteServices.includes(ServiceType.SharePoint),
      Password: value.password
    };

    this.services$.next(value.deleteServices);
  }

  handleDelete(): void {
    this.baseModal.save(this.#payloadForDeleteBackup);
  }

  handleClose(): void {
    this.baseModal.close();
  }
}
