import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { AuthService, UserOdataService } from '@app/common/services';
import { I18_NAMESPACE_MODULE } from '@app/i18';
import { UntilDestroy } from '@ngneat/until-destroy';
import { get } from 'lodash';
import { MbsPopupType, ModalComponent } from 'mbs-ui-kit';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  templateUrl: './delete-alternate-account-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteAlternateAccountModalComponent implements OnInit {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  public deleteLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public readonly moduleAccount = I18_NAMESPACE_MODULE.account;
  public readonly MbsPopupType = MbsPopupType;

  public alternateAccount$: Observable<string>;

  constructor(private authService: AuthService, private userService: UserOdataService) {}

  ngOnInit(): void {
    this.alternateAccount$ = of(get(this.baseModal, 'data.alternateAccount', ''));
  }

  handleDelete(): void {
    this.deleteLoading$.next(true);
    this.authService
      .getAuthUser()
      .pipe(
        switchMap((user) => this.userService.deleteAlternateEmail(user.Id)),
        finalize(() => this.deleteLoading$.next(false))
      )
      .subscribe({
        next: () => {
          this.baseModal.save(true);
        }
      });
  }

  handleClose(): void {
    this.baseModal.close();
  }
}
