import { Directive } from '@angular/core';

@Directive({
  exportAs: 'sortArrow',
  selector: '[sortArrow]',
  host: {
    '[class.ico]': 'true'
  }
})
export class TableSortArrowDirective {}
