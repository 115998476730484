import { EventEmitter } from '@angular/core';
import { PersistentStateServiceBase } from './persistent-state-base.service';
import { SharedPersistentStateEnum } from './shared-persistent-state-enum';

export type ViewMode = SharedPersistentStateEnum.table | SharedPersistentStateEnum.grid;
export class SharedPersistentState {
  viewTableGridMode: ViewMode = SharedPersistentStateEnum.table;
}
export class SharedPersistentStateService extends PersistentStateServiceBase<SharedPersistentState> {
  private eventEmitter: EventEmitter<string>;
  constructor(namespace: string) {
    super(SharedPersistentState, new EventEmitter<string>());
    this.eventEmitter = this.getEmitterInstance();
    this.eventEmitter.emit(namespace);
    this.listenStorageChange();
  }
}
