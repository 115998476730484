import { MbsNgClass } from '../../form/input-base/input-label/input-label.model';
import { isNil } from 'lodash';

/**
 * This function is useful when it's need to get CSS classes from @Input and concat it with some inner classes for further usage in template;
 * @param {MbsNgClass} classes
 * @param {string} defaultClass
 * @param {boolean} hasLabel
 * @return {string}
 */
export function convertCssClassesToString(classes: MbsNgClass, defaultClass = '', hasLabel = true): string {
  if (isNil(classes) || !hasLabel) {
    return defaultClass;
  }
  switch (true) {
    case typeof classes === 'string': {
      // linter do not understand that classes is 100% string, so toString is required but useless
      return defaultClass.toString() + ' ' + classes.toString();
    }
    case Array.isArray(classes): {
      // It is 100% Array, but linter don't understand =(
      return [defaultClass].concat(classes as Array<string>).join(' ');
    }
    case typeof classes === 'object': {
      // Set or Object
      if (Object.prototype.hasOwnProperty.call(classes, 'add')) {
        // same stuff as previous but with Set
        return [defaultClass].concat(Array.from(classes as Set<string>)).join(' ');
      } else {
        // classes is object
        const activeClasses = Object.entries(classes)
          .filter(([key, val]) => Boolean(val))
          .map(([key, val]) => key);
        return [defaultClass].concat(activeClasses).join(' ');
      }
    }
  }
  return '';
}
