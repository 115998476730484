import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AutoActivationMode, NewGroupSettings } from '@app/common/models';
import { AuthService, AzureGroupsService } from '@app/common/services';
import { OrganizationalUnitsService } from '@app/common/services/organizational-units.service';
import { I18_NAMESPACE_MODULE } from '@app/i18';
import { UntilDestroy } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { ModalComponent, ToastService } from 'mbs-ui-kit';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

@UntilDestroy()
@Component({
  templateUrl: './new-group-settings-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewGroupSettingsModalComponent implements OnInit {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  readonly #toastTitleSuccess = this.i18nPipe.transform('toast.success.title', { format: 'title' });
  readonly #toastTitleError = this.i18nPipe.transform('toast.error.title', { format: 'title' });

  public readonly loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public readonly AutoActivationMode = AutoActivationMode;
  public readonly moduleUsers = I18_NAMESPACE_MODULE.users;
  public readonly moduleButton = I18_NAMESPACE_MODULE.button;
  public readonly saveLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public formGroup: FormGroup;

  get baseModalData(): NewGroupSettings {
    return this.baseModal.data as NewGroupSettings;
  }

  get autoActivationModeCtrl(): FormControl<AutoActivationMode> {
    return <FormControl>this.formGroup.controls.autoActivationMode;
  }

  get isGoogle(): boolean {
    return this.authService.isGoogle;
  }

  constructor(
    private authService: AuthService,
    private i18nPipe: I18NextPipe,
    private fb: FormBuilder,
    private azureGroupsService: AzureGroupsService,
    private orgUnitsService: OrganizationalUnitsService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.formGroup = this.fb.group({
      autoActivationMode: [this.baseModalData.AutoActivationMode]
    });
  }

  private groupService(): OrganizationalUnitsService | AzureGroupsService {
    return this.isGoogle ? this.orgUnitsService : this.azureGroupsService;
  }

  handleSave(): void {
    const payload: NewGroupSettings = {
      AutoActivationMode: this.autoActivationModeCtrl.value
    };

    this.saveLoading$.next(true);
    this.groupService()
      .updateNewGroupSettings(payload)
      .pipe(finalize(() => this.saveLoading$.next(false)))
      .subscribe({
        next: () => {
          this.toastService.success(
            this.i18nPipe.transform(this.moduleUsers + ':toast.body.autoActivationSettings', { format: 'capitalize' }),
            this.#toastTitleSuccess
          );
          this.baseModal.save(true);
        },
        error: (res: HttpErrorResponse) => {
          this.toastService.error(
            (res.status !== 500 && res.error?.value) || (this.moduleUsers + ':toast.body.somethingGetWrong', { format: 'capitalize' }),
            this.#toastTitleError
          );
        }
      });
  }

  handleCancel(): void {
    this.baseModal.close();
  }
}
