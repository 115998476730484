import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoaderModule } from '../loader/loader.module';
import { TabsetContentDirective } from './directives/tabset-content.directive';
import { TabsetGroupTriggerDirective } from './directives/tabset-group-trigger.directive';
import { TabsetGroupDirective } from './directives/tabset-group.directive';
import { TabsetItemDirective } from './directives/tabset-item.directive';
import { TabsetLinkDirective } from './directives/tabset-link.directive';
import { TabsetDirective } from './directives/tabset.directive';
import { TabsetDestroyerDirective, TabsetItemValidDirective, TabsetValidatedDirective } from './tabs.service';
import { TabsetContentOutlet } from './tabset-content-outlet.component';

const declarations = [
  TabsetGroupTriggerDirective,
  TabsetItemDirective,
  TabsetLinkDirective,
  TabsetContentDirective,
  TabsetContentOutlet,
  TabsetGroupDirective,
  TabsetValidatedDirective,
  TabsetItemValidDirective,
  TabsetDestroyerDirective,
  TabsetDirective
];

@NgModule({
  imports: [CommonModule, LoaderModule],
  exports: declarations,
  declarations: declarations
})
export class TabsetModule {
  constructor() {}
}
