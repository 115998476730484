import { COLORS, getRandomHEX } from '@app/common/utils';

export function getColors(requiredLength: number): string[] {
  if (COLORS.length >= requiredLength) return COLORS;

  const missingColors: string[] = [];
  const countOfMissing: number = requiredLength - COLORS.length;

  for (let i = 0; i < countOfMissing; i++) {
    missingColors.push(getRandomHEX());
  }

  return COLORS.concat(missingColors);
}
