import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TileComponent } from './tile.component';

@NgModule({
  imports: [CommonModule, NgbTooltipModule],
  declarations: [TileComponent],
  exports: [TileComponent]
})
export class TileModule {
  constructor() {}
}
