import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from '../button/button.module';
import { InputModule } from '../form/input/input.module';
import { StepComponent } from './step/step.component';
import { StepsComponent } from './steps.component';

@NgModule({
  declarations: [StepsComponent, StepComponent],
  exports: [StepsComponent, StepComponent],
  imports: [CommonModule, ButtonModule, InputModule, FormsModule]
})
export class StepsModule {}
