import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '../button/button.module';
import { InputModule } from '../form/input/input.module';
import { ListEditorComponent } from './list-editor.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, InputModule, ButtonModule],
  declarations: [ListEditorComponent],
  exports: [ListEditorComponent]
})
export class ListEditorModule {}
