import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { ConfigurationService } from '@app/common/services/configuration.service';
import { getSentryEnvironment } from '@app/common/utils';
import * as Sentry from '@sentry/browser';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor(private config: ConfigurationService) {}

  public handleError(error): void {
    const extractedError = this.extractError(error) || 'Handled unknown error';

    if (this.config.get('production')) {
      if (this.isErrorSkipped(error)) return;

      Sentry.setTag('environment', getSentryEnvironment());

      const eventId = Sentry.captureException(extractedError);
      console.error('Unexpected error. Event Id: ' + eventId, extractedError);
    } else {
      // When in development mode, log the error to console for immediate feedback.
      console.error(extractedError);
    }
  }

  private isErrorSkipped(error) {
    return error?.status === HttpStatusCode.Unauthorized;
  }

  private extractError(error) {
    // Try to unwrap zone.js error.
    // https://github.com/angular/angular/blob/master/packages/core/@mbs-ui/util/errors.ts
    if (error && error.ngOriginalError) {
      error = error.ngOriginalError;
    }

    // We can handle messages and Error objects directly.
    if (typeof error === 'string' || error instanceof Error) {
      return error;
    }

    // If it's http module error, extract as much information from it as we can.
    if (error instanceof HttpErrorResponse) {
      // The `error` property of http exception can be either an `Error` object, which we can use directly...
      if (error.error instanceof Error) {
        return error.error;
      }

      // ... or an`ErrorEvent`, which can provide us with the message but no stack...
      if (error.error instanceof ErrorEvent) {
        return error.error.message;
      }

      if (typeof error.error === 'string' && error.status === HttpStatusCode.Unauthorized) {
        return `Server returned code 401 - Unauthorized`;
      }

      // ...or the request body itself, which we can use as a message instead.
      if (typeof error.error === 'string') {
        return `Server returned code ${error.status} with body "${error.error}"`;
      }

      // If we don't have any detailed information, fallback to the request message itself.
      return error.message;
    }

    // Skip if there's no error, and let user decide what to do with it.
    return null;
  }
}
