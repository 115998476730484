import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ServiceTypeStr } from '@app/common';
import { AuthUser, RetentionPolicyOdata } from '@app/common/models';
import { AuthService } from '@app/common/services';
import { isHomeUser } from '@app/common/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { isNil } from 'lodash';
import { FormsUtil, GuidEmpty, MbsPopupType, MbsValidators, ModalComponent } from 'mbs-ui-kit';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

@UntilDestroy()
@Component({
  templateUrl: './edit-create-retention-policy-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditCreateRetentionPolicyModalComponent implements OnInit {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  public readonly MbsPopupType = MbsPopupType;
  public readonly ServiceTypeStr = ServiceTypeStr;
  public readonly prefix = Math.random().toString(36).substring(2) + '_';
  public retentionPolicyForm: UntypedFormGroup = this.fb.group({
    Id: GuidEmpty,
    ReadOnly: [{ value: false, disabled: true }],
    ServiceType: [ServiceTypeStr.Mail, [Validators.required]],
    Name: ['', [this.customNameValidator.bind(this)]],
    KeepCntUnits: ['1'],
    KeepUnit: ['Days'],
    KeepRevisionsCnt: [{ value: '1', disabled: true }],
    DelayPurgeCntUnits: ['1'],
    DelayPurgeUnit: ['Days'],
    ByModifyOrBackupDate: [true],
    EnableKeepCntUnits: [true],
    EnableKeepRevisionsCnt: [false],
    AlwaysKeepLastRevision: [false],
    HasLegalHold: [false]
  });

  public timePeriods: string[] = [
    this.i18nPipe.transform('retentionPolicy.modal.select-periods.days', { format: 'title' }),
    this.i18nPipe.transform('retentionPolicy.modal.select-periods.weeks', { format: 'title' }),
    this.i18nPipe.transform('retentionPolicy.modal.select-periods.months', { format: 'title' }),
    this.i18nPipe.transform('retentionPolicy.modal.select-periods.years', { format: 'title' })
  ];
  public availableRetentionPolicies: { value: string; label: string }[];
  public title = this.i18nPipe.transform('retentionPolicy.modal.title.create', { format: 'title' });
  public okButtonText = this.i18nPipe.transform('retentionPolicy.modal.button.create', { format: 'title' });
  public cancelButtonText = this.i18nPipe.transform('retentionPolicy.modal.button.cancel', { format: 'title' });
  public okButtonDisabled = true;
  public policy: RetentionPolicyOdata;

  private user$: Observable<AuthUser>;
  private eventRetentionPolicyForm$: Observable<any>;

  get enableKeepCntUnitsControl(): FormControl<boolean> {
    return <FormControl>this.retentionPolicyForm.controls['EnableKeepCntUnits'];
  }

  get keepRevisionsCntControl(): FormControl<{ value: string; disabled: boolean }> {
    return <FormControl>this.retentionPolicyForm.controls['KeepRevisionsCnt'];
  }

  get delayPurgeCntUnitsControl(): FormControl<string> {
    return <FormControl>this.retentionPolicyForm.controls['KeepCntUnits'];
  }

  get serviceTypeControl(): FormControl<ServiceTypeStr> {
    return <FormControl>this.retentionPolicyForm.controls['ServiceType'];
  }

  get isOffice(): boolean {
    return this.authService?.isOffice;
  }

  get isGoogle(): boolean {
    return this.authService?.isGoogle;
  }

  get isImmutability(): boolean {
    return this.authService?.isImmutability;
  }

  constructor(public i18nPipe: I18NextPipe, private fb: FormBuilder, private authService: AuthService) {}

  ngOnInit(): void {
    this.policy = this.baseModal.data as RetentionPolicyOdata;

    if (this.policy) {
      this.retentionPolicyForm.patchValue(this.policy, { emitEvent: false });
      this.title = this.i18nPipe.transform('retentionPolicy.modal.title.edit', { format: 'title' });
      this.okButtonText = this.i18nPipe.transform('retentionPolicy.modal.button.save', { format: 'title' });
    }

    this.initStreams();
    this.setValidators();

    if (this.needTriggerValidationKeepRevisionsCntControl(this.enableKeepCntUnitsControl.value)) {
      FormsUtil.triggerValidation(this.keepRevisionsCntControl);
    }

    if (this.needTriggerValidationDelayPurgeCntUnitsControl(this.enableKeepCntUnitsControl.value)) {
      FormsUtil.triggerValidation(this.delayPurgeCntUnitsControl);
    }

    if (this.serviceTypeControl.value !== ServiceTypeStr.Mail && this.serviceTypeControl.value !== ServiceTypeStr.Teams) {
      this.toggleEnableKeepRevisions();
      this.okButtonDisabled = true;
    }
  }

  private setValidators(): void {
    this.delayPurgeCntUnitsControl.setValidators(this.delayPurgeCntUnitsValidator.bind(this));
    this.keepRevisionsCntControl.setValidators(this.keepRevisionsCntValidator.bind(this));
  }

  private keepRevisionsCntValidator(control: FormControl, minCount = 1): ValidationErrors | null {
    if (!control) return null;

    if (!this.enableKeepCntUnitsControl.value && (isNil(control.value) || control.value < minCount)) {
      return { min: { message: MbsValidators.validatorMessages.min({ min: minCount, actual: control.value || 0 }) } };
    }

    return null;
  }

  private delayPurgeCntUnitsValidator(control: FormControl, minCount = 1): ValidationErrors | null {
    if (!control) return null;

    if (this.enableKeepCntUnitsControl.value && (isNil(control.value) || control.value < minCount)) {
      return { min: { message: MbsValidators.validatorMessages.min({ min: minCount, actual: control.value || 0 }) } };
    }

    return null;
  }

  private needTriggerValidationKeepRevisionsCntControl(isEnableKeepRevisionsCntControl: boolean): boolean {
    return !isEnableKeepRevisionsCntControl && !this.keepRevisionsCntControl.touched;
  }

  private needTriggerValidationDelayPurgeCntUnitsControl(isEnableDelayPurgeCntUnitsControl: boolean): boolean {
    return isEnableDelayPurgeCntUnitsControl && !this.delayPurgeCntUnitsControl.touched;
  }

  private customNameValidator(control: AbstractControl): ValidationErrors | null {
    const errors = Validators.compose([Validators.required, Validators.maxLength(50)])(control);

    if (!errors) return null;

    if (errors.maxlength) {
      return { maxlength: { message: MbsValidators.validatorMessages.maxlength(errors.maxlength) } };
    }

    if (errors.required) {
      return { required: { message: 'The field is required' } };
    }

    return null;
  }

  initStreams(): void {
    this.user$ = this.authService.getAuthUser().pipe(filter((user) => user != null));
    this.eventRetentionPolicyForm$ = this.retentionPolicyForm.valueChanges;

    this.eventRetentionPolicyForm$.pipe(untilDestroyed(this)).subscribe({
      next: () => (this.okButtonDisabled = this.retentionPolicyForm.invalid)
    });

    this.enableKeepCntUnitsControl.valueChanges.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe({
      next: (value: boolean) => {
        if (this.needTriggerValidationKeepRevisionsCntControl(value)) {
          FormsUtil.triggerValidation(this.keepRevisionsCntControl);
        }

        if (this.needTriggerValidationDelayPurgeCntUnitsControl(value)) {
          FormsUtil.triggerValidation(this.delayPurgeCntUnitsControl);
        }
      }
    });

    this.serviceTypeControl.valueChanges.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe({
      next: (serviceType) => {
        if (serviceType == ServiceTypeStr.Mail || serviceType == ServiceTypeStr.Teams) {
          this.enableKeepCntUnitsControl.patchValue(true);
        }

        this.toggleEnableKeepRevisions();
      }
    });

    this.user$.pipe(untilDestroyed(this)).subscribe({
      next: (user) => {
        const policies = [
          { value: 'Mail', label: this.i18nPipe.transform('retentionPolicy.modal.select-for-service.mail', { format: 'title' }) },
          { value: 'Drive', label: this.i18nPipe.transform('retentionPolicy.modal.select-for-service.drive', { format: 'title' }) },
          {
            value: 'Contacts',
            label: this.i18nPipe.transform('retentionPolicy.modal.select-for-service.contacts', { format: 'title' })
          },
          {
            value: 'Calendar',
            label: this.i18nPipe.transform('retentionPolicy.modal.select-for-service.calendar', { format: 'title' })
          }
        ];

        if (!isHomeUser(user) && this.isGoogle) {
          policies.push({
            value: 'TeamDrives',
            label: this.i18nPipe.transform('retentionPolicy.modal.select-for-service.sharedDrives', { format: 'title' })
          });
        }
        if (!isHomeUser(user) && this.isOffice) {
          policies.push({
            value: 'SharePoint',
            label: this.i18nPipe.transform('retentionPolicy.modal.select-for-service.sharePoint', { format: 'title' })
          });
          policies.push({
            value: 'Teams',
            label: this.i18nPipe.transform('retentionPolicy.modal.select-for-service.teams', { format: 'title' })
          });
        }

        this.availableRetentionPolicies = policies;
      }
    });
  }

  handleSave(): void {
    const policy = this.retentionPolicyForm.value;

    if (policy.ServiceType == ServiceTypeStr.Mail || policy.ServiceType == ServiceTypeStr.Teams) {
      policy.EnableKeepCntUnits = true;
    }

    this.baseModal.save(policy);
  }

  handleClose(): void {
    this.baseModal.close();
  }

  toggleEnableKeepRevisions(): void {
    const controls = this.retentionPolicyForm.controls;
    const EnableKeepCntUnits = this.retentionPolicyForm.value.EnableKeepCntUnits;

    if (!EnableKeepCntUnits) {
      controls.KeepCntUnits.disable();
      controls.KeepUnit.disable();
      controls.ByModifyOrBackupDate.disable();
      controls.AlwaysKeepLastRevision.disable();
      controls.KeepRevisionsCnt.enable();
    } else {
      controls.KeepRevisionsCnt.disable();
      controls.KeepCntUnits.enable();
      controls.KeepUnit.enable();
      controls.ByModifyOrBackupDate.enable();
      controls.AlwaysKeepLastRevision.enable();
    }

    controls.EnableKeepRevisionsCnt.setValue(!EnableKeepCntUnits);
  }
}
