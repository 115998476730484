import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbTimeAdapter, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { IMaskModule } from 'angular-imask';
import { InputBaseModule } from '../input-base/input-base.module';
import { NgbTimeStringAdapterFormat12 } from './ngb-time-adapters/ngb-time-string-adapter-format-12';
import { TimepickerComponent } from './timepicker.component';

@NgModule({
  imports: [CommonModule, FormsModule, NgbTimepickerModule, InputBaseModule, IMaskModule, NgbDropdownModule],
  declarations: [TimepickerComponent],
  exports: [TimepickerComponent],
  providers: [{ provide: NgbTimeAdapter, useClass: NgbTimeStringAdapterFormat12 }]
})
export class TimepickerModule {
  constructor() {
    // empty
  }
}
