import { Injectable } from '@angular/core';
import { DataChangeWatcherService } from 'mbs-ui-kit';
import { Observable } from 'rxjs';

@Injectable()
export class DataChangeWatcherGuard {
  constructor(private dcw: DataChangeWatcherService) {}

  canDeactivate(): Observable<boolean> {
    return this.dcw.detectChanges();
  }
}
