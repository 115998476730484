import { Directive, TemplateRef } from '@angular/core';

/**
 * Tile template for table-grid in grid mode
 */
@Directive({
  selector: '[mbsGridTile]'
})
export class GridTileDirective {
  constructor(public template: TemplateRef<any>) {}
}
