import { Component, Input } from '@angular/core';
import { BootstrapTheme } from '../utils';

/**
 * Spinner animation for some works
 */
@Component({
  selector: 'app-loader, mbs-loader',
  template: `
    <div class="mbs-loader" [ngClass]="bgClass">
      <div class="mbs-loader_spinner" [ngClass]="[spinnerSize, spinnerColor]"></div>
    </div>
  `
})
export class LoaderComponent {
  /**
   * Spinner size.
   * * 'xsmall' - 35px * 35px
   * * 'small' - 75px * 75px
   * * 'default' - 150px * 150px
   * @param {'small' | 'default' | 'xsmall'} value
   */
  @Input() set size(value: 'small' | 'default' | 'xsmall') {
    switch (value) {
      case 'small':
        this.spinnerSize = 'mbs-loader_spinner-small';
        break;
      case 'xsmall':
        this.spinnerSize = 'mbs-loader_spinner-xsmall';
        break;
      case 'default':
      default:
        break;
    }
  }

  @Input() theme: BootstrapTheme;
  @Input() type: BootstrapTheme;

  get bgClass(): string {
    return this.type ? `bg-transparent-${this.type}` : '';
  }

  get spinnerColor(): string {
    return this.theme ? `mbs-loader_spinner-${this.theme}` : '';
  }

  spinnerSize = '';
}
