import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import { ButtonSize } from '../button';
import { MbsSize, MbsType } from '../utils';

@Component({
  selector: 'mbs-tag',
  templateUrl: 'tag.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': 'hostClasses',
    '(click)': 'handleClick()'
  }
})
export class TagComponent implements OnInit, OnChanges {
  /**
   * Tag id. Required.
   */
  @Input() id: string;

  @Input() disabled: boolean;

  /**
   * If `true` - component emits `mbsClick` event on click. Should not be used with `closable`.
   */
  @Input() clickable = false;

  /**
   * If `true` - shows close icon. Component emits `mbsClose` event on click at the close button inside tag. Should not be used with `clickable`.
   */
  @Input() closable: boolean;

  /**
   * Color theme for close icon.
   */
  @Input() closeIconType: MbsType;

  /**
   * Highlights tag via `-active` css-class.
   */
  @Input() active: boolean;

  /**
   * Custom css classes.
   */
  @Input('class') customClass: string;

  /**
   *  Tag size. Default or `MbsSize.sm`
   */
  @Input() size: MbsSize.sm | '' = '';

  /**
   * Tooltip for text-ellipsis
   */
  @Input() tooltip: string | TemplateRef<any>;

  /**
   * Border will be transparent if true
   */
  @Input() noBorder = false;

  /**
   * Emits on click if `clickable` is `true`
   */
  @Output() mbsClick = new EventEmitter<string>();

  /**
   * Emits on click at close icon;
   */
  @Output() mbsClose = new EventEmitter<string>();

  public readonly mbsType = MbsType;
  public readonly mbsSize = MbsSize;

  public closableSize: ButtonSize = MbsSize.xs;
  public hostClasses = '';
  public closeIcon = '';

  private closeIconClass = 'ico ico-failed mr-0 ctrl-ico '; // space in the end is required

  ngOnInit() {
    this.updateHostClasses();
    this.updateClosableSize();
    this.updateCloseIconClasses();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.active ||
      changes.type ||
      changes.customClass ||
      changes.clickable ||
      changes.size ||
      changes.closable ||
      changes.noBorder
    ) {
      this.updateHostClasses();
    }

    if (changes.size) {
      this.updateClosableSize();
    }

    if (changes.active || changes.closable) {
      this.updateCloseIconClasses();
    }
  }

  private updateCloseIconClasses() {
    if (this.closable) {
      this.closeIcon = this.closeIconClass.concat(this.active && !this.disabled ? 'text-white' : '');
    }
  }

  private updateClosableSize(): void {
    this.closableSize = this.size ? MbsSize.xxs : MbsSize.xs;
  }

  private updateHostClasses(): void {
    const temp = ['mbs-tag', '-primary', 'mw-100'];

    if (this.active) {
      temp.push('-active');
    }

    if (this.customClass) {
      temp.push(this.customClass);
    }

    if (this.clickable) {
      temp.push('-clickable');
    }

    if (this.closable) {
      temp.push('-closable');
    }

    if (this.disabled) {
      temp.push('-disabled');
    }

    if (this.size) {
      temp.push(`-${this.size}`);
    }

    if (this.noBorder) {
      temp.push('-no-border');
    }

    this.hostClasses = temp.join(' ');
  }

  public handleClick() {
    if (this.clickable) {
      this.mbsClick.emit(this.id);
    }
  }
}
