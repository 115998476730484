export interface PlanInfoOdata {
  '@odata.context': string;
  ActivatedPlanId: string;
  AverageDaysInMonth: number;
  CloudBerryDiscount: number;
  CloudBerryDiscountState: CloudBerryDiscountState;
  CloudBerryDiscountUrl: string;
  DaysToEndPlan: number;
  ExpireDate: string;
  IsCanceled: boolean;
  Name: string;
  StorageSize: boolean;
  SubscriptionId: string;
  SubscriptionUrl: string;
  TariffType: TariffType;
  UserLimit: number;
}

export enum CloudBerryDiscountState {
  notFound = 'NotFound',
  approved = 'Approved',
  notApproved = 'NotApproved',
  pending = 'Pending'
}

export enum TariffType {
  trial = 'Trial',
  perUser = 'PerUser',
  perData = 'PerData',
  perDomain = 'PerDomain',
  perOnOrHaveDataUsers = 'PerOnOrHaveDataUsers',
  perHaveDataUsers = 'PerHaveDataUsers',
  perActualData = 'PerActualData'
}
