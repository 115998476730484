export enum DriveBaseTagsEnum {
  Folder,
  File,
  Name
}

export const DriveBaseTagsMap: Map<DriveBaseTagsEnum, { tag: string; prop: string }> = new Map([
  [DriveBaseTagsEnum.Folder, { tag: 'Folder', prop: 'Type' }],
  [DriveBaseTagsEnum.File, { tag: 'File', prop: 'Type' }],
  [DriveBaseTagsEnum.Name, { tag: 'Name', prop: 'Name' }]
]);
