export enum CalendarServiceTag {
  Event = 1,
  Descr
}

export const CalendarServiceTagsMap: Map<CalendarServiceTag, { tag: string; prop: string }> = new Map([
  [
    CalendarServiceTag.Event,
    {
      tag: 'Event Name',
      prop: 'Name'
    }
  ],
  [
    CalendarServiceTag.Descr,
    {
      tag: 'Description',
      prop: 'Descr'
    }
  ]
]);
