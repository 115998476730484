<mbs-modal [loading]="loading$ | async"
           [title]="moduleAccount + ':modal.title.confirmApply' | i18next: { format: 'title' }">

  <ng-container modal-body
                [formGroup]="formGroup">

    <mbs-alert [type]="MbsPopupType.info"
               [icon]="true">
      {{ moduleAccount + ':modal.alertAddPasswordProtection' | i18next: { format: 'capitalize' } }}
    </mbs-alert>

    <mbs-form-group>
      <mbs-input [type]="passwordType$ | async"
                 [appendButtons]="[
                    {
                      id: 'append-password',
                      type: 'secondary',
                      icon: getAppendButtonsIcon(passwordType$ | async),
                      btnType: 'button',
                      loading: false,
                      disabled: false
                    }
                 ]"
                 (buttonClick)="handleChangePasswordType($event)"
                 formControlName="password"
                 [label]="moduleAccount + ':modal.label.password' | i18next: { format: 'title' }"
                 mbsAutoFocus
                 autocomplete="off"></mbs-input>
    </mbs-form-group>

    <mbs-form-group class="mb-0">
      <mbs-input [type]="confirmPasswordType$ | async"
                 [appendButtons]="[
                    {
                      id: 'append-confirm-password',
                      type: 'secondary',
                      icon: getAppendButtonsIcon(confirmPasswordType$ | async),
                      btnType: 'button',
                      loading: false,
                      disabled: false
                    }
                 ]"
                 (buttonClick)="handleChangePasswordType($event)"
                 formControlName="confirmPassword"
                 [label]="moduleAccount + ':modal.label.confirmPassword' | i18next: { format: 'title' }"
                 autocomplete="off"></mbs-input>
    </mbs-form-group>

  </ng-container>

  <ng-container modalFooter>

    <mbs-button (click)="handleApply()"
                [disabled]="!formGroup.valid"
                type="primary">
      {{ moduleAccount + ':modal.button.apply' | i18next: { format: 'title' } }}
    </mbs-button>

    <mbs-button (click)="handleClose()">
      {{ moduleAccount + ':modal.button.cancel' | i18next: { format: 'title' } }}
    </mbs-button>

  </ng-container>

</mbs-modal>

