import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { BrowserStorage, RoutePath } from '@app/common';
import { AuthService, DomainService, LocalStorageService } from '@app/common/services';
import { AuthCallbackComponent } from '@app/components/login/auth-callback/auth-callback.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SidepanelService } from 'mbs-ui-kit';
import { filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private sidepanelService: SidepanelService,
    private authService: AuthService,
    private domainService: DomainService,
    private storageService: LocalStorageService
  ) {}

  ngOnDestroy(): void {
    this.storageService.removeAllListeners();
  }

  ngOnInit(): void {
    this.storageService.listenStorageChange();

    this.storageService.change
      .pipe(
        filter(() => this.document.hidden),
        untilDestroyed(this)
      )
      .subscribe((event) => {
        if (event.key !== BrowserStorage.Prefix + BrowserStorage.Token) return;

        if (event.newValue) {
          this.authService.loggedIn$.next(true);
          void this.router.navigate([this.storageService.get<string>(BrowserStorage.AuthPrefix) + RoutePath.Dashboard]);
        } else {
          this.authService.logout();
        }
      });

    this.authService.loggedIn$
      .asObservable()
      .pipe(
        filter((state) => state === false),
        untilDestroyed(this)
      )
      .subscribe({
        next: () => {
          this.domainService.resetCache();
        }
      });

    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationStart),
        filter((e: NavigationStart) => this.isUrlChanged(e)),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.sidepanelService.remove('my_account');
      });

    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        switchMap((e) => this.sidepanelService.closeAll('my_account').pipe(map(() => e))), // Close all sidepanels after change user in user-select
        tap(() => this.sidepanelService.closeAll()), // Close all sidepanels after change user in user-select
        map(() => this.getRouteWithData(this.activatedRoute.firstChild)),
        mergeMap((route: ActivatedRoute) => route.data)
      )
      .subscribe({
        next: (data: { title: string }) => {
          this.titleService.setTitle(this.updateTitle(data.title));
        }
      });
  }

  private isUrlChanged(eventParams: NavigationStart): boolean {
    const currentUrl = this.getUrlWithoutParams(this.router.url) ?? '';
    const nextUrl = this.getUrlWithoutParams(eventParams?.url) ?? '';

    return currentUrl !== nextUrl;
  }

  private getUrlWithoutParams(url: string) {
    if (!url) return url;

    const urlTree = this.router.parseUrl(url);

    urlTree.queryParams = {};
    urlTree.fragment = null;

    return urlTree.toString().replace(this.authService.prefix, '');
  }

  getRouteWithData(route: ActivatedRoute): ActivatedRoute {
    const routeDataKeys = () => Object.keys(child.snapshot.data);
    const routeFirstChildDataKeys = () => Object.keys(child.snapshot.firstChild.data);
    // `route` or `route.firstChild` depends on route architecture in route.module.
    // After redesign authorization, it is important that we need to skip the /office/ or /gapps/ route level
    let child = route.firstChild;

    while (child) {
      if (child.firstChild && (routeDataKeys().length === 0 || (routeDataKeys().length > 0 && routeFirstChildDataKeys().length > 0))) {
        child = child.firstChild;
        route = child;
      } else if (!child.firstChild && child.component === AuthCallbackComponent) {
        route = route.firstChild;
        child = null;
      } else {
        child = null;
      }
    }

    return route;
  }

  updateTitle(pageName = 'Apps'): string {
    if (this.authService.isOffice) return `${pageName} - Microsoft 365 Business backup`;
    if (this.authService.isGoogle) return `${pageName} - Google Workspace backup`;

    return pageName;
  }
}
