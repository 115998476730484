import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[mbsAutoFocus]'
})
export class AutoFocusDirective implements AfterViewInit {
  constructor(private elRef: ElementRef<HTMLElement>) {}

  ngAfterViewInit(): void {
    const input = this.elRef.nativeElement.querySelector('input');

    if (input) {
      // prevent ExpressionChangedAfterItHasBeenCheckedError
      setTimeout(() => input.focus());
    }
  }
}
