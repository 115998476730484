import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { APPEND_BUTTON_PASSWORD_STATE, ChangePassword } from '@app/common';
import { UserOdataService } from '@app/common/services';
import { getAppendButtonsIcon, getAppendButtonsState, hasAppendButtonsPasswordType, isConfirmPasswordValidator } from '@app/common/utils';
import { camelCase } from 'lodash';
import { InputButton, ModalComponent } from 'mbs-ui-kit';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  templateUrl: './change-password-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangePasswordModalComponent implements OnInit {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  private userId = '';

  public readonly getAppendButtonsIcon = getAppendButtonsIcon;

  public changePasswordForm = new UntypedFormGroup(
    {
      Password: new FormControl('', [Validators.required]),
      NewPassword: new FormControl('', [Validators.required, Validators.minLength(7), Validators.maxLength(20)]),
      ConfirmPassword: new FormControl('', Validators.required)
    },
    isConfirmPasswordValidator.bind({}, { password: 'NewPassword', confirmPassword: 'ConfirmPassword' })
  );

  public resetLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public oldPasswordType$: BehaviorSubject<string> = new BehaviorSubject(APPEND_BUTTON_PASSWORD_STATE.hidden.type);
  public newPasswordType$: BehaviorSubject<string> = new BehaviorSubject(APPEND_BUTTON_PASSWORD_STATE.hidden.type);
  public confirmPasswordType$: BehaviorSubject<string> = new BehaviorSubject(APPEND_BUTTON_PASSWORD_STATE.hidden.type);

  constructor(public userService: UserOdataService) {}

  ngOnInit(): void {
    this.changePasswordForm.reset();
    this.userId = this.baseModal.data as string;
  }

  changeButtonClick(): void {
    const data = this.changePasswordForm.value as ChangePassword;

    this.resetLoading$.next(true);
    this.userService
      .editAlternatePassword(this.userId, data.Password, data.NewPassword, data.ConfirmPassword)
      .pipe(finalize(() => this.resetLoading$.next(false)))
      .subscribe({
        next: () => this.baseModal.save(),
        error: (e: HttpErrorResponse) => {
          this.handleFormError(e, this.changePasswordForm);
        }
      });
  }

  cancelButtonClick(): void {
    this.baseModal.close();
  }

  private handleFormError(response: HttpErrorResponse, form: UntypedFormGroup): void {
    if (response.status === 400) {
      // response.error.error.details.forEach((input) => {
      //   const p = form.get(input.target);
      //   p.setValue('');
      //   p.setErrors({ [`${input.target}Incorrect`]: { message: input.message } });
      // });
      form.get('Password').setValue('');
      form.get('Password').setErrors({ [`Password Incorrect`]: { message: (response.error as { value: string }).value } });
    }
  }

  handleChangePasswordType(event: InputButton): void {
    if (!event) return;

    const id = camelCase(event.id.replace('append', ''));
    const subjectById: BehaviorSubject<string> = this[id + 'Type$'];
    const isPasswordType: boolean = hasAppendButtonsPasswordType(subjectById.getValue());

    subjectById.next(APPEND_BUTTON_PASSWORD_STATE[getAppendButtonsState(isPasswordType)].type);
  }
}
