import { Pipe, PipeTransform } from '@angular/core';
import { isArray } from 'lodash';
import { naturalSort } from 'mbs-ui-kit';

type SortOrderByType = 'string' | 'number' | 'Date';

@Pipe({
  name: 'sortOrderBy',
  pure: true
})
export class SortOrderByPipe<T> implements PipeTransform {
  transform(array: T[], field: string, type: SortOrderByType = 'string'): T[] {
    if (!isArray(array)) throw new Error('Not an array passed for sorting');

    switch (type) {
      case 'string':
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        return array.sort((a: T, b: T) => naturalSort(a[field].toLowerCase(), b[field].toLowerCase()));
      case 'number':
        return array.sort((a: T, b: T) => naturalSort(a[field], b[field]));
      case 'Date':
        return array.sort((a: T, b: T) => new Date(a[field]).getTime() - new Date(b[field]).getTime());
      default:
        throw new Error('Unknown sorting type');
    }
  }
}
