export enum ExportResultsTagsEnum {
  userName = 'User Name',
  fileName = 'File Name',
  serviceType = 'Service Type',
  dateFrom = 'Date From',
  dateTo = 'Date To',
  sizeMore = 'Size More',
  sizeLess = 'Size Less'
}

export const ExportResultsTagsMap: Map<ExportResultsTagsEnum, { tag: string; prop: string }> = new Map([
  [
    ExportResultsTagsEnum.userName,
    {
      tag: 'User Name',
      prop: 'UserName'
    }
  ],
  [
    ExportResultsTagsEnum.fileName,
    {
      tag: 'File Name',
      prop: 'Name'
    }
  ],
  [
    ExportResultsTagsEnum.serviceType,
    {
      tag: 'Service Type',
      prop: 'ServiceType'
    }
  ],
  [
    ExportResultsTagsEnum.dateFrom,
    {
      tag: 'Date From',
      prop: 'CreateDate'
    }
  ],
  [
    ExportResultsTagsEnum.dateTo,
    {
      tag: 'To Date',
      prop: 'CreateDate'
    }
  ],
  [
    ExportResultsTagsEnum.sizeMore,
    {
      tag: 'Size More Than',
      prop: 'Size'
    }
  ],
  [
    ExportResultsTagsEnum.sizeLess,
    {
      tag: 'Size Less Than',
      prop: 'Size'
    }
  ]
]);
