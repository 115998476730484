import { TemplateRef, Type } from '@angular/core';
import { MbsPopupType, MbsSize } from '../utils';

type Content = string | TemplateRef<any> | Type<any>;
type CloseCallback = (close: () => void) => void;

export enum AlertType {
  success = 'success',
  error = 'danger',
  info = 'info',
  warning = 'warning',
  primary = 'primary'
}

export default class Alert {
  /**
   * Hide `Alert` after the preset time
   */
  public autoClose: number;

  /**
   * Alert `type` by color theme
   */
  public type: MbsPopupType;

  /**
   * Alert `size` <br />
   * Possible values: `MbsSize.sm` | `MbsSize.lg` <br />
   */
  public size?: MbsSize.sm | MbsSize.lg;

  /**
   * Unique Alert `id`
   */
  public id: string;

  /**
   *  If true prevents the alert from being closed after one route change, this is handy for displaying messages after a redirect such as a successful registration message.
   */
  public keepAfterRouteChange = false;

  /**
   * String (supports raw HTML) or TemplateRef for Alert's content
   */
  public content?: Content;

  /**
   * If need, you can get context in `TemplateRef`. <br />
   * Also used for closing current `Alert` in `AlertContainerComponent`
   */
  public context?: Alert;

  /**
   * Icon class for Alert's icon. <br />
   * If set `boolean`: will be used default icon by Alert `type` <br />
   * If set `string` (with custom icon class): will be used custom icon class
   */
  public icon: string | boolean;

  /**
   * @ignore
   * @private
   */
  private myCloseCallback: CloseCallback;

  get closeCallback(): CloseCallback {
    return this.myCloseCallback;
  }

  constructor(init?: Partial<Alert>) {
    Object.assign(this, init);
  }

  /**
   * Called before closing `Alert`
   * @param {CloseCallback} closeCallback
   */
  beforeCloseCallback(closeCallback: CloseCallback): void {
    this.myCloseCallback = closeCallback;
  }
}
