import { Injectable, NgZone } from '@angular/core';
import { BrowserStorage } from '@app/common';
import { AuthUser } from '@app/common/models';
import { LocalStorageService } from '@app/common/services';
import { ConfigurationService } from '@app/common/services/configuration.service';
import { environment } from '@env/environment';
import posthog from 'posthog-js';

@Injectable({
  providedIn: 'root'
})
export class PosthogService {
  constructor(private ngZone: NgZone, private storageService: LocalStorageService, private config: ConfigurationService) {}

  public initialize(user: AuthUser) {
    if (this.needDeclinePostHog() || !this.storageService.get<string>(BrowserStorage.Token)) return;

    this.ngZone.runOutsideAngular(() => {
      posthog.init(environment.posthog.prod.key, {
        disable_session_recording: true,
        api_host: environment.posthog.prod.serverUrl,
        loaded: () => {
          posthog.identify(user.Id, {
            Email: user.Email,
            DomainId: user.DomainId,
            DomainName: user.DomainName,
            Id: user.Id,
            Name: user.Name,
            Provider: user.Provider,
            RestoreEnable: user.RestoreEnable,
            SignInEnable: user.SignInEnable
          });
        }
      });
    });
  }

  public needDeclinePostHog(): boolean {
    return !this.isAcceptedHost(location.host);
  }

  public isAcceptedHost(host: string): boolean {
    const acceptedHosts: string[] = this.config.get('posthog')?.acceptedHosts || [];
    const declinedHosts: string[] = this.config.get('posthog')?.declinedHosts || [];

    if (declinedHosts.some((mask) => this.isMatchWithMask(host, mask))) {
      return false;
    }

    if (acceptedHosts.some((mask) => this.isMatchWithMask(host, mask))) {
      return true;
    }

    return false;
  }

  private isMatchWithMask(value: string, mask: string): boolean {
    const lValue = value.toLowerCase();
    const lMask = mask.toLowerCase();

    if (lMask.startsWith('*')) {
      return lValue.endsWith(lMask.slice(1));
    }

    if (lMask.endsWith('*')) {
      return lValue.startsWith(lMask.slice(0, -1));
    }

    return lValue === lMask;
  }
}
