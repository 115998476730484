export enum ContactsServiceTag {
  FirstName = 1,
  LastName,
  PrimaryEmail,
  PhoneNumber
}

export const ContactsServiceTagsMap: Map<ContactsServiceTag, { tag: string; prop: string }> = new Map([
  [
    ContactsServiceTag.FirstName,
    {
      tag: 'First Name',
      prop: 'FirstName'
    }
  ],
  [
    ContactsServiceTag.LastName,
    {
      tag: 'Last Name',
      prop: 'LastName'
    }
  ],
  [
    ContactsServiceTag.PrimaryEmail,
    {
      tag: 'Primary Email',
      prop: 'PrimaryEmail'
    }
  ],
  [
    ContactsServiceTag.PhoneNumber,
    {
      tag: 'Phone Number',
      prop: 'PhoneNumber'
    }
  ]
]);
