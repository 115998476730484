import { Directive, Input, OnInit, TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';
import { ModelTemplate, SmartSearchState } from './models';

@Directive({
  selector: '[smartSearchKeyword]',
  exportAs: 'smartSearchKeyword'
})
export class SmartSearchKeywordDirective implements OnInit {
  @Input() tag: string;
  @Input() filterByProp?: string;
  @Input() items: (state: SmartSearchState) => Observable<any>;
  @Input() itemFormatter: (item: any) => string;
  @Input() isMultiple?: boolean;
  @Input() isSequenceMultiple?: boolean;
  @Input() addGroupBrackets?: boolean;
  @Input() enableCacheStorage?: boolean;

  constructor(public template: TemplateRef<any>) {}

  ngOnInit(): void {
    if (!this.tag) {
      throw new Error('Tag is empty!');
    }

    if (!this.items) {
      throw new Error('Items is empty!');
    }
  }

  toModelTemplate(): ModelTemplate<any> {
    return {
      tag: this.tag,
      filterByProp: this.filterByProp,
      items: this.items,
      template: this.template,
      itemFormatter: this.itemFormatter,
      isMultiple: this.isMultiple,
      isSequenceMultiple: this.isSequenceMultiple,
      addGroupBrackets: this.addGroupBrackets,
      enableCacheStorage: this.enableCacheStorage
    };
  }
}
