import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { InputBaseModule } from '../input-base/input-base.module';
import { DropFileComponent } from './drop-file.component';
import { DropFileDirective } from './drop-file.directive';
import { DropLabelTextDirective } from './drop-label-text.directive';

@NgModule({
  imports: [ReactiveFormsModule, FormsModule, CommonModule, NgbTooltipModule, InputBaseModule],
  declarations: [DropFileComponent, DropFileDirective, DropLabelTextDirective],
  exports: [DropFileComponent, DropLabelTextDirective]
})
export class DropFileModule {}
