import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgOptionTemplateDirective, NgSelectComponent } from '@ng-select/ng-select';
import { ButtonSize } from '../button';

export const ADVANCED_SELECTBOX_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line no-use-before-define
  useExisting: forwardRef(() => AdvancedSelectComponent),
  multi: true
};

/**
 * Select with `next` and `prev` buttons. Based on <a href="https://github.com/ng-select/ng-select" target="_blank">ng-select</a>
 */
@Component({
  selector: 'app-advanced-select, mbs-advanced-select',
  templateUrl: './advanced-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ADVANCED_SELECTBOX_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class AdvancedSelectComponent<T> implements AfterViewInit {
  @ViewChild(NgSelectComponent, { static: true }) ngSelect: NgSelectComponent;

  @Input() public set items(value: T[]) {
    this.myItems = value;
    this.currentIndex = this.getCurrentIndex();
  }
  get items(): T[] {
    return this.myItems;
  }
  @Input() public bindValue: string;
  @Input() public bindLabel: string;
  @Input() public reverse = false;
  @Input() public disabled = false;
  @Input() public set buttonSize(value: ButtonSize) {
    this.buttonSizeClass = value ? 'btn-' + value : '';
  }
  @Input() public appendTo = 'body';
  @Input() public loading = false;

  @ContentChild(NgOptionTemplateDirective, { read: TemplateRef, static: true }) optionTemplate: TemplateRef<any>;

  private onTouchedCallback: () => void;
  private onChangeCallback: (_: any) => void;

  private val: T;
  private myItems: T[] = [];

  public buttonSizeClass: string;
  public currentIndex: number;

  public set value(value: T) {
    if (this.bindValue && value) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this.val = value[this.bindValue];
    } else {
      this.val = value;
    }
  }

  public get value(): T {
    return this.val;
  }

  public get isLeftButtonDisabled(): boolean {
    return this.reverse ? this.currentIndex === this.items.length - 1 : this.currentIndex === 0;
  }

  public get isRightButtonDisabled(): boolean {
    return this.reverse ? this.currentIndex === 0 : this.currentIndex === this.items.length - 1;
  }

  @Output() change = new EventEmitter();

  ngAfterViewInit(): void {
    this.ngSelect.optionTemplate = this.optionTemplate;
    this.ngSelect.ngAfterViewInit();
  }

  onChange(event: T): void {
    this.value = event;
    this.currentIndex = this.getCurrentIndex();
    this.change.emit(this.value);

    if (this.onChangeCallback) {
      this.onChangeCallback(this.value);
    }
  }

  private onNext(): void {
    if (this.currentIndex < this.items.length - 1) {
      this.onChange(this.items[this.currentIndex + 1]);
    }
  }

  private onPrev(): void {
    if (this.currentIndex > 0) {
      this.onChange(this.items[this.currentIndex - 1]);
    }
  }

  handleLeftBtnClick(): void {
    this.reverse ? this.onNext() : this.onPrev();
  }

  handleRightBtnClick(): void {
    this.reverse ? this.onPrev() : this.onNext();
  }

  private getCurrentIndex() {
    return this.items.findIndex((value) => (this.bindValue ? value[this.bindValue] : value) === this.value);
  }

  writeValue(value: T): void {
    this.val = value;
    this.currentIndex = this.getCurrentIndex();
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedCallback = fn;
  }
}
