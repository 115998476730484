import { RoutePath } from '@app/common';
import { PageData } from '@app/common/models';
import { ServiceType } from '@app/common/models/user.model';

export interface ServiceTypeUiInfo {
  serviceType: ServiceType;
  displayName: string;
  iconCssClass: string;
  url: (id?: string) => string;
}

const serviceUiInfo: Map<ServiceType, ServiceTypeUiInfo> = new Map<ServiceType, ServiceTypeUiInfo>([
  [ServiceType.All, { serviceType: ServiceType.All, displayName: 'All', iconCssClass: '', url: () => '' }],
  [ServiceType.Users, { serviceType: ServiceType.Users, displayName: 'Users', iconCssClass: 'fa fa-users', url: () => RoutePath.Users }],
  [
    ServiceType.History,
    {
      serviceType: ServiceType.History,
      displayName: 'History',
      iconCssClass: 'fa fa-history',
      url: (id) => addIdToUrl(RoutePath.History, id)
    }
  ],
  [
    ServiceType.Payments,
    { serviceType: ServiceType.Payments, displayName: 'Payments', iconCssClass: 'fa fa-money', url: () => RoutePath.Payments }
  ],
  [
    ServiceType.Mail,
    { serviceType: ServiceType.Mail, displayName: 'Mail', iconCssClass: 'fa fa-envelope', url: (id) => addIdToUrl(RoutePath.Mail, id) }
  ],
  [
    ServiceType.Drive,
    { serviceType: ServiceType.Drive, displayName: 'Drive', iconCssClass: 'ico ico-Drive', url: (id) => addIdToUrl(RoutePath.Drive, id) }
  ],
  [
    ServiceType.Contacts,
    {
      serviceType: ServiceType.Contacts,
      displayName: 'Contacts',
      iconCssClass: 'fa fa-address-book-o',
      url: (id) => addIdToUrl(RoutePath.Contacts, id)
    }
  ],
  [
    ServiceType.Calendar,
    {
      serviceType: ServiceType.Calendar,
      displayName: 'Calendar',
      iconCssClass: 'ico ico-Calendar',
      url: (id) => addIdToUrl(RoutePath.Calendar, id)
    }
  ],
  [
    ServiceType.TeamDrives,
    {
      serviceType: ServiceType.TeamDrives,
      displayName: 'Shared Drives',
      iconCssClass: 'ico ico-GoogleDrive',
      url: () => RoutePath.SharedDrives
    }
  ],
  [
    ServiceType.SharePoint,
    {
      serviceType: ServiceType.SharePoint,
      displayName: 'SharePoint',
      iconCssClass: 'ico ico-SquareS',
      url: () => RoutePath.Sharepoint
    }
  ],
  [
    ServiceType.Teams,
    { serviceType: ServiceType.Teams, displayName: 'Teams', iconCssClass: 'ico ico-SquareT', url: () => RoutePath.Teams }
  ],
  [
    ServiceType.Export,
    { serviceType: ServiceType.Export, displayName: 'Export Results', iconCssClass: 'fa fa-download', url: () => RoutePath.ExportResults }
  ]
]);

/*
 * Partial data depending on what came to `pageInfo` in order to set it into routing module
 * We get for example:
 * if string: `{ title: string }`
 * if enum ServiceType: `{ title: string, serviceType: ServiceType }`
 * @param pageInfo
 */
export function partialRoutingDataByTitle(pageInfo: string | ServiceType): PageData {
  const props = {} as PageData;
  props.title = getPageTitle(pageInfo);

  if (typeof pageInfo !== 'string') {
    props.serviceType = pageInfo;
  }

  return props;
}

/*
 * @param pageInfo Page title. ServiceType of page name
 */
export function getPageTitle(pageInfo: string | ServiceType): string {
  return typeof pageInfo === 'string' ? pageInfo : getServiceUiInfo(pageInfo).displayName;
}

export function getServiceUiInfo(serviceType: ServiceType): ServiceTypeUiInfo {
  return serviceUiInfo.get(serviceType);
}

function addIdToUrl(url: string, id: string): string {
  return id ? `${url}/${id}` : url;
}
