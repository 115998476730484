import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BOOLEAN_OPERATOR, COMPARISON_OPERATOR, MailFolderType, SINGLE_QUOTES_REGEX } from '@app/common';
import { Mail, MailAttachment, MailAttachmentConfigOData, MailFolder, RestoreMailFolder, RestoreToAnotherUser } from '@app/common/models';
import { ODataPagedResult, ODataService, ODataServiceFactory } from '@app/common/odata';
import { OdataObject, SharedOdataService } from '@app/common/services';
import { MailTagsEnum, MailTagsMap, sizeFilterEnum } from '@app/common/services/smart-search';
import { filterByWords, getOdataTop } from '@app/common/utils';
import {
  FilterOptions,
  containsWrapper,
  getFilterByContains,
  getFilterByDateRange,
  getFilterByEq,
  getFilterBySize
} from '@app/common/utils/functions/search';
import { I18NextPipe } from 'angular-i18next';
import { IKeyValue } from 'linq-collections';
import { isNil } from 'lodash';
import { SmartSearchModel, SmartSearchModelField } from 'mbs-ui-kit';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MailOdataService extends SharedOdataService<Mail> {
  #groupId: string;
  #userId: string;
  #customOptions: IKeyValue<string, any> | IKeyValue<string, any>[];

  readonly #from = MailTagsMap.get(MailTagsEnum.from);
  readonly #to = MailTagsMap.get(MailTagsEnum.to);
  readonly #sizeMore = MailTagsMap.get(MailTagsEnum.sizeMore);
  readonly #sizeLess = MailTagsMap.get(MailTagsEnum.sizeLess);
  readonly #subject = MailTagsMap.get(MailTagsEnum.subject);
  readonly #withAttachment = MailTagsMap.get(MailTagsEnum.withAttachment);
  readonly #backupArchive = MailTagsMap.get(MailTagsEnum.backupArchive);
  readonly #dateTo = MailTagsMap.get(MailTagsEnum.dateTo);
  readonly #dateFrom = MailTagsMap.get(MailTagsEnum.dateFrom);

  private odataMethods: ODataService<any>;
  private mailMessagesService: ODataService<any>;

  set groupId(id: string) {
    this.#groupId = id;
  }

  get groupId(): string {
    return this.#groupId;
  }

  set userId(id: string) {
    this.#userId = id;
  }

  get userId(): string {
    return this.#userId;
  }

  constructor(private i18nPipe: I18NextPipe, odataFactory: ODataServiceFactory, private http: HttpClient) {
    super(odataFactory, 'MailFolders');
    this.odataMethods = odataFactory.CreateService('');
    this.mailMessagesService = odataFactory.CreateService('MailMessages');
  }

  getMailsByGroupId(): Observable<ODataPagedResult<Mail>> {
    const newOdata = this.odataFactory.CreateService<Mail>(`/MailFolders(${this.groupId})/MailMessages`);

    this.#customOptions = [{ key: 'UserId', value: this.userId }];

    return newOdata
      .Query()
      .CustomQueryOptions(this.#customOptions)
      .OrderBy(this.orderBy)
      .Filter(this.filter)
      .Top(this.pageSize)
      .Skip(this.pageSize * (this.page - 1))
      .ExecWithCount();
  }

  getMailFolders(userId: string, options?: { inbox?: boolean; search?: string; skip?: number }): Observable<ODataPagedResult<MailFolder>> {
    const { inbox, search, skip } = options || {};
    const filter = inbox
      ? `Type ${COMPARISON_OPERATOR.eq} '${MailFolderType.Inbox}'`
      : `Type ${COMPARISON_OPERATOR.ne} '${MailFolderType.Inbox}'`;

    return this.odataFactory
      .CreateService<MailFolder>(`/BackupUsers(${userId})/MailFolders`)
      .Query()
      .OrderBy(inbox ? null : 'Name asc')
      .Top(getOdataTop(search || inbox))
      .Skip(skip)
      .Filter(search ? containsWrapper('Name', search) : filter)
      .ExecWithCount();
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  updateFilter(searchObj: SmartSearchModel): void {
    if (this.groupId == null) return;

    const filter: string[] = [];
    this.#customOptions = null;

    if (searchObj[this.#from.tag]) {
      const options: FilterOptions = {
        model: searchObj[this.#from.tag] as SmartSearchModelField[],
        prop: this.#from.prop
      };

      filter.push(getFilterByContains(options));
    }

    if (searchObj[this.#to.tag]) {
      const options: FilterOptions = {
        model: searchObj[this.#to.tag] as SmartSearchModelField[],
        prop: this.#to.prop
      };

      filter.push(getFilterByContains(options));
    }

    if (searchObj[this.#subject.tag]) {
      const options: FilterOptions = {
        model: searchObj[this.#subject.tag] as SmartSearchModelField[],
        prop: this.#subject.prop
      };

      filter.push(getFilterByContains(options));
    }

    if (searchObj[this.#withAttachment.tag]) {
      const options: FilterOptions = {
        model: searchObj[this.#withAttachment.tag] as SmartSearchModelField[],
        prop: this.#withAttachment.prop
      };

      filter.push(getFilterByEq(options, false));
    }

    if (searchObj[this.#backupArchive.tag]) {
      const options: FilterOptions = {
        model: searchObj[this.#backupArchive.tag] as SmartSearchModelField[],
        prop: this.#backupArchive.prop
      };

      filter.push(getFilterByEq(options, false));
    }

    if (searchObj[this.#sizeMore.tag]) {
      const options: FilterOptions & { filter: sizeFilterEnum } = {
        model: searchObj[this.#sizeMore.tag] as SmartSearchModelField[],
        prop: this.#sizeMore.prop,
        filter: sizeFilterEnum.more
      };

      filter.push(getFilterBySize(options));
    }

    if (searchObj[this.#sizeLess.tag]) {
      const options: FilterOptions & { filter: sizeFilterEnum } = {
        model: searchObj[this.#sizeLess.tag] as SmartSearchModelField[],
        prop: this.#sizeLess.prop,
        filter: sizeFilterEnum.less
      };

      filter.push(getFilterBySize(options));
    }

    if (searchObj[this.#dateFrom.tag]) {
      const options: FilterOptions = {
        model: searchObj[this.#dateFrom.tag] as SmartSearchModelField[],
        prop: this.#dateFrom.prop
      };
      const value = getFilterByDateRange(options, 'from');

      value && filter.push(value);
    }

    if (searchObj[this.#dateTo.tag]) {
      const options: FilterOptions = {
        model: searchObj[this.#dateTo.tag] as SmartSearchModelField[],
        prop: this.#dateTo.prop
      };
      const value = getFilterByDateRange(options, 'to');

      value && filter.push(value);
    }

    if (searchObj.words?.filter(Boolean)) {
      const term = filterByWords(searchObj).replace(SINGLE_QUOTES_REGEX, '');
      const keys = [this.#subject.prop, this.#from.prop, this.#to.prop];
      const values = keys.map((k) => `(${containsWrapper(k, term)})`);

      filter.push('('.concat(values.join(` ${BOOLEAN_OPERATOR.or} `), ')'));
    }

    this.filter = filter.length > 0 ? filter.join(' and ') : '';
  }

  getMailBodyUrl(id: string): Observable<string> {
    const url = this.mailMessagesService.Get(id).GetUrl() + '/MsgBody';

    return this.http.get(url, { responseType: 'blob' }).pipe(
      map((response) => {
        // change blob content type
        response = response.slice(0, response.size, 'text/html');
        return URL.createObjectURL(response);
      })
    );
  }

  getMailAttachment(mailId: string): Observable<MailAttachment[]> {
    return this.mailMessagesService.ItemProperty<OdataObject<MailAttachmentConfigOData[]>>(mailId, 'GetAttachments').pipe(
      map((result) => result.value),
      map((result) => result.map((a) => new MailAttachment(a.Name, a.MsgId, isNil(a.Num) ? null : `?num=${a.Num}`)))
    );
  }

  restoreAllMailbox(userId: string, restoreTo: RestoreToAnotherUser = null): Observable<any> {
    return this.odataMethods.CustomCollectionAction('RestoreWholeMail', { UserId: userId, AnotherUser: restoreTo });
  }

  restoreFolder(options: { folderId: string; restoreTo?: RestoreToAnotherUser; subFolders?: boolean }): Observable<any> {
    const { folderId, restoreTo, subFolders } = options || {};
    const postData: RestoreMailFolder = {
      FolderId: folderId,
      AnotherUser: restoreTo || null
    };

    if (!isNil(subFolders)) {
      postData.IncludeSubfolders = subFolders;
    }

    return this.odataMethods.CustomCollectionAction('RestoreMailFolder', postData);
  }

  restoreMessages(messageIds: string[], restoreTo: RestoreToAnotherUser = null): Observable<any> {
    return this.odataMethods.CustomCollectionAction('RestoreMailMsgs', { MsgsIds: messageIds, AnotherUser: restoreTo });
  }

  /*
   * Get top 20 emails in 'From' section that match `containsValue`
   * @param {string} containsValue search string
   */
  getEmailsInFromSection(containsValue: string): Observable<Mail[]> {
    return this.searchItems(MailTagsEnum.from, containsValue);
  }

  /*
   * Get top 20 emails in 'To' section that match `containsValue`
   * @param {string} containsValue search string
   */
  getEmailsInToSection(containsValue: string): Observable<Mail[]> {
    return this.searchItems(MailTagsEnum.to, containsValue);
  }

  /*
   * Get top 20 subjects that match `containsValue`
   * @param {string} containsValue search string
   */
  getListOfSubjects(containsValue: string): Observable<Mail[]> {
    return this.searchItems(MailTagsEnum.subject, containsValue);
  }

  private searchItems(searchFields: MailTagsEnum.from | MailTagsEnum.to | MailTagsEnum.subject, containsValue: string): Observable<Mail[]> {
    this.#customOptions = [{ key: 'UserId', value: this.userId }];

    return this.odataFactory
      .CreateService<Mail>(`/MailFolders(${this.groupId})/MailMessages`)
      .Query()
      .CustomQueryOptions(this.#customOptions)
      .Top(100)
      .Select(searchFields)
      .Filter(containsWrapper(searchFields, containsValue))
      .Exec();
  }

  deleteFolder(folderId: string, password: string): Observable<any> {
    const options = {
      FolderId: folderId,
      Password: password
    };

    return this.odataMethods.CustomCollectionAction('DeleteMailFolder', options);
  }

  deleteMessages(messageIds: string[], password: string): Observable<any> {
    const options = {
      MsgsIds: messageIds,
      Password: password
    };

    return this.odataMethods.CustomCollectionAction('DeleteMailMsgs', options);
  }
}
