<mbs-modal [loading]="loading$ | async"
           [title]="moduleAccount + ':modal.title.deleteBackup' | i18next: { format: 'title' }">

  <ng-container modal-body>
    <app-delete-user-backup [hidden]="loading$ | async"
                            [statistics]="backupStatistics$ | async"
                            [formError]="deleteBackupFormError"
                            (deleteBackupChange)="deleteBackupChange($event)"
                            (deleteBackupFormValid)="invalidForms($event)">
    </app-delete-user-backup>
  </ng-container>

  <ng-container modalFooter>

    <mbs-button (click)="handleDelete()"
                [disabled]="(disabled$ | async) || (services$ | async)?.length === 0"
                type="danger">
      {{ moduleAccount + ':modal.button.delete' | i18next: { format: 'title' } }}
    </mbs-button>

    <mbs-button (click)="handleClose()">
      {{ moduleAccount + ':modal.button.cancel' | i18next: { format: 'title' } }}
    </mbs-button>

  </ng-container>

</mbs-modal>
