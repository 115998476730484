<cdk-virtual-scroll-viewport #viewport
                             *ngIf="virtualScroll"
                             [itemSize]="virtualItemSize"
                             [ngClass]="classesForRoot"
                             [ngStyle]="{ height: getVirtualScrollViewportSize() }"
                             class="mbs-tree -virtual-scroll"
                             [class.-file]="mode === 'file'">

  <ng-container *cdkVirtualFor="let item of visibleFlatData; index as index; trackBy: virtualTrackBy">

    <ng-container *ngTemplateOutlet="nodeTemplate; context: { $implicit: item }"></ng-container>

    <div *ngIf="item | treeLoadMore: index: visibleFlatData as loadMoreItem"
         [ngClass]="'-level-' + (loadMoreItem?.level + 1)"
         class="mbs-tree-item">
      <ng-container *ngTemplateOutlet="loadMoreTemplate ? loadMoreTemplate : loadMoreButton; context: { $implicit: loadMoreItem }"></ng-container>
    </div>

  </ng-container>

</cdk-virtual-scroll-viewport>

<div *ngIf="!virtualScroll"
     #mbsTree
     [ngClass]="rootClasses"
     [ngStyle]="{ 'max-height': maxHeight, 'min-height': minHeight, 'height': height }"
     class="mbs-tree"
     [class.-file]="mode === 'file'">

  <ng-container *ngFor="let item of flatData; index as index">

    <ng-container *ngIf="needToRender(item)">
      <ng-container *ngTemplateOutlet="nodeTemplate; context: { $implicit: item }"></ng-container>
    </ng-container>

    <div *ngIf="needToRender(item) && item | treeLoadMore: index: flatData as loadMoreItem"
         [ngClass]="'-level-' + (loadMoreItem.level + 1)"
         class="mbs-tree-item">
      <ng-container *ngTemplateOutlet="loadMoreTemplate ? loadMoreTemplate : loadMoreButton; context: { $implicit: loadMoreItem }"></ng-container>
    </div>
  </ng-container>

</div>

<ng-template #loadMoreButton
             let-item>

  <mbs-button (click)="handleClickLoadMore(item.node?.parent, $event)"
              [disabled]="(disableIfLoadingSubtree && loadingSubtree) || isDisabled(item)"
              [isCtrl]="true"
              [loading]="item.node.loadingChildren || loadingSubtree"
              [icon]="'ico ico-Refresh'"
              type="primary">
    <span class="nowrap">
      {{ item?.node?.parent?.loadMoreButtonCaption || 'Load more...' }}
    </span>
  </mbs-button>

</ng-template>

<ng-template #textLayout
             let-item>

  <span *ngIf="item.node.icon"
        [ngClass]="item.node.icon"
        class="mbs-tree-item_icon"></span>

  <mbs-button (click)="handleClickItem(item, $event, false)"
              [isCtrl]="true"
              class="mbs-tree-item_text"
              type="primary"
              [disabled]="isDisabled(item)"
              [ngClass]="hideTreeIcon ? '-hideArrow' : ''">
    <ng-container *ngTemplateOutlet="template ? template : defaultLabelTemplate; context: { $implicit: item.node }"></ng-container>
  </mbs-button>

  <div *ngIf="appendTemplate"
       class="mbs-tree-item_append">
    <ng-container *ngTemplateOutlet="appendTemplate; context: { $implicit: item.node }"></ng-container>
  </div>

</ng-template>

<ng-template #checkboxLayout
             let-item>

  <mbs-checkbox (change)="handleCheck($event, item)"
                [(ngModel)]="item.node.checked"
                [disabled]="isDisabled(item)"
                [id]="item.node.id"
                [indeterminate]="item.node.indeterminate"
                [labelContext]="item.node"
                [label]="template || item.node.label"
                [ngClass]="hideTreeIcon ? '-hideArrow' : ''"
                [value]="item.node.value"
                class="mbs-tree-item_checkbox"
                [class.-checked]="mode === 'file' && item.node.checked"
                [class.-indeterminate]="mode === 'file' && item.node.indeterminate"></mbs-checkbox>

  <div *ngIf="appendTemplate"
       class="mbs-tree-item_append">
    <ng-container *ngTemplateOutlet="appendTemplate; context: { $implicit: item.node }"></ng-container>
  </div>

</ng-template>

<ng-template #defaultLabelTemplate
             let-node>
  {{ node.label }}
</ng-template>

<ng-template #nodeTemplate
             let-item>

  <div class="mbs-tree-item"
       [ngClass]="hashTreeClasses[item?.node?.id]?.levelClass"
       [class.d-none]="itemsCanBeHidden && !item.node.shown">

    <div [ngClass]="[
           hashTreeClasses[item?.node?.id]?.otherClasses,
           selectable && (item?.node?.selected || item?.node?.id === selectedItemId) && !hashTreeClasses[item?.node?.id]?.isDisabled ? selectClass : ''
         ]"
         class="mbs-tree-item_label">

      <mbs-button (click)="handleClickItem(item, $event, true)"
                  *ngIf="!hideTreeIcon && (item.node.children?.length > 0 || (lazy && !item.node.gotChildren))"
                  [disabled]="(disableIfLoadingSubtree && loadingSubtree) || isDisabled(item)"
                  [customClasses]="!item.node.expanded ? '' : '-expanded'"
                  [isCtrl]="true"
                  [loading]="item.node.loadingChildren"
                  class="mbs-tree-item_arrow"
                  type="dark"></mbs-button>

      <ng-container *ngTemplateOutlet=" mode === 'text' || item.node.hideCheckbox ? textLayout : checkboxLayout; context: { $implicit: item }"></ng-container>

    </div>

  </div>

</ng-template>
