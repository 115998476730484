import { TemplateRef } from '@angular/core';
import { ButtonType } from '../button';
import { MbsPopupType, TextBreak } from '../utils';

export type Button = {
  isCtrl?: boolean;
  type?: ButtonType;
  label?: string;
  clickHandler: (data?: any) => void;
};

export interface DelayConfig {
  [MbsPopupType.info]: number;
  [MbsPopupType.success]: number;
  [MbsPopupType.warning]: number;
  [MbsPopupType.danger]: number;
}

export default class Toast {
  /**
   * Button settings for insert to footer of toast
   */
  buttons?: Button[] = [];

  /**
   * Color theme
   */
  type?: MbsPopupType = MbsPopupType.danger;

  /**
   * String or TemplateRef for Toast's header template;
   */
  header?: string | TemplateRef<any>;

  /**
   * String (supports raw HTML) or TemplateRef for Toast's body template
   */
  content?: string | TemplateRef<any> = 'Sorry, something went wrong';

  /**
   * HTML or TemplateRef for Toast's footer template;
   */
  footer?: string | TemplateRef<any>;

  /**
   * Toast id
   */
  id?: string;

  /**
   * Custom class(es) for Toast
   */
  classname?: string;

  /**
   * Delay (in milliseconds) before Toast disappears
   */
  delay?: number;

  /**
   * Icon class for Toast's header. Visible only if `header` is set <br />
   * If set `boolean`: will be used default icon by toast type <br />
   * If set `string` (with custom icon class): will be used custom icon class
   */
  icon?: string | boolean;

  /**
   * Need to add `text-break` CSS-class to Toast:
   * <br>
   *
   * Possible values: `TextBreak.header | TextBreak.content | TextBreak.both | TextBreak.none`
   */
  textBreak?: TextBreak.header | TextBreak.content | TextBreak.both | TextBreak.none = TextBreak.none;

  /**
   * Keep state toast after you route to change. <br />
   * Toast will be removed if flag is false
   */
  keepAfterRouteChange?: boolean;

  /**
   * Show close button in Toast's header. Will be shown if header is set.
   * Now close button is always shown !
   */
  showClose? = true;

  preventClose? = false;

  constructor(init?: Partial<Toast>) {
    Object.assign(this, init);
  }
}
