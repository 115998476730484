<nav class="mbs-breadcrumbs"
     aria-label="breadcrumb">

  <ng-container *ngFor="let item of list; let index = index; let last = last">

    <ng-container *ngIf="last; else notLast">
      <span class="mbs-breadcrumbs_item -active"
            aria-current="page">
        {{ itemDisplayValue(item) }}
      </span>
    </ng-container>

    <ng-template #notLast>

      <span class="mbs-breadcrumbs_item">
        <mbs-button class="mbs-breadcrumbs_item-btn"
                    [isCtrl]="true"
                    [size]="MbsSize.sm"
                    (click)="handleItemClick(item, index)">
          {{ itemDisplayValue(item) }}
        </mbs-button>
      </span>

      <span class="mbs-breadcrumbs_item mbs-breadcrumbs_item-divider">
        <span *ngIf="delimiterIcon; else defaultDivider"
              [ngClass]="delimiterIcon"></span>

        <ng-template #defaultDivider>/</ng-template>
      </span>

    </ng-template>

  </ng-container>

</nav>
