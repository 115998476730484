import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Injectable,
  Injector,
  Renderer2,
  RendererFactory2,
  Type,
  ViewContainerRef
} from '@angular/core';
import { get, isNil } from 'lodash';
import { Observable, of, queueScheduler, scheduled } from 'rxjs';
import { map, tap, zipAll } from 'rxjs/operators';
import { ModalService } from '../modal/modal.service';
import { Sidepanel } from './Sidepanel';
import { SidepanelCrudBase } from './SidepanelCrudBase';

export type SidepanelInfoEvent = {
  name: string;
  id: string;
};

type SidepanelInstance = {
  panel: ComponentRef<Sidepanel>;
  destroy: () => void;
};

export interface SidepanelConfig {
  isCreate?: boolean;
}

@Injectable({ providedIn: 'root' })
export class SidepanelService {
  /**
   * All created sidepanels
   *
   * Instance - destroy function
   */
  private panels: SidepanelInstance[] = [];
  private renderer: Renderer2;

  public onOpen = new EventEmitter<SidepanelInfoEvent>(true);
  public onClose = new EventEmitter<SidepanelInfoEvent>(true);

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    rendererFactory: RendererFactory2,
    private modalService: ModalService
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  /**
   * Registration sidepanel into service.
   * @param {Type<T>} type sidepanel type
   * @param {ViewContainerRef} vc default
   * @param {Injector} injector default
   *
   * @return {T}
   */
  add<T extends Sidepanel>(type: Type<T>, vc?: ViewContainerRef, injector?: Injector): T {
    const panel = this.panels.find(p => p.panel.instance instanceof type);
    if (panel) {
      return panel.panel.instance as T;
    }

    let componentRef: ComponentRef<T>;

    const factory = this.componentFactoryResolver.resolveComponentFactory(type);

    if (vc) {
      componentRef = vc.createComponent(factory, vc.length, injector || vc.injector);
      const index = vc.indexOf(componentRef.hostView);
      this.panels.push({
        panel: componentRef,
        destroy: () => {
          vc.remove(index);
        }
      });
    } else {
      componentRef = factory.create(injector || this.injector);
      const appRootNode: HTMLElement = this.appRef.components[0].location.nativeElement as HTMLElement;
      // Attach incoming component to the appRef so that it's inside the ng component tree
      this.appRef.attachView(componentRef.hostView);
      // Get DOM element from incoming component
      const contentElem: HTMLElement = componentRef.location.nativeElement as HTMLElement;
      // Append DOM element to the body
      this.renderer.appendChild(appRootNode, contentElem);

      componentRef.changeDetectorRef.detectChanges();
      // add panel to array of active panels
      this.panels.push({
        panel: componentRef,
        destroy: () => {
          this.renderer.removeChild(appRootNode, contentElem);
          componentRef.destroy();

          // remove panel from array of active panels
          this.panels = this.panels.filter(p => p.panel != componentRef);
        }
      });
    }

    componentRef.instance.genericPanel.panelClosed.subscribe(() =>
      this.onClose.emit({ id: componentRef.instance.genericPanel.id, name: componentRef.componentType.name })
    );

    return componentRef.instance;
  }

  addAndOpenByType<T extends Sidepanel>(type: Type<T>, vc?: ViewContainerRef, injector?: Injector): Observable<boolean> {
    this.add(type, vc, injector);
    return this.openByType(type);
  }

  /**
   * Check exists sidepanel
   * @param {Type<T>} type sidepanel type
   * @return {boolean}
   */
  contains<T extends Sidepanel>(type: Type<T>): boolean {
    return this.panels.some(p => p.panel.instance instanceof type);
  }

  /**
   * Try get exists sidepanel
   * @param {Type<T>}type sidepanel type
   * @return {T}
   */
  get<T extends Sidepanel>(type: Type<T>): T {
    const componentRef = this.panels.find(p => p.panel.instance instanceof type);
    return componentRef ? (componentRef.panel.instance as T) : undefined;
  }

  /**
   * Remove sidepanel from virtual and real DOM by type
   * @param {Type<T>} type sidepanel type
   * @return {boolean}
   */
  removeByType<T extends Sidepanel>(type: Type<T>): boolean {
    const componentRef = this.panels.find(x => x.panel.instance instanceof type);
    return this.removeComponent(componentRef);
  }

  /**
   * Remove sidepanel from virtual and real DOM by specified id
   * @param {string} id sidepanel identification
   * @return {boolean}
   */
  remove(id: string): boolean {
    const panel = this.panels.find(x => x.panel.instance.genericPanel.id === id);
    return this.removeComponent(panel);
  }

  /**
   * Remove all sidepanels from virtual and real DOM
   * @return {boolean}
   */
  removeAll(): boolean {
    let allRemoved = true;
    this.panels.forEach(panel => {
      allRemoved = allRemoved && this.removeComponent(panel);
    });
    return allRemoved;
  }

  /**
   * Remove component from virtual and real DOM
   * @param {SidepanelInstance} sidepanel component reference
   * @return {SidepanelInstance}
   */
  private removeComponent(sidepanel: SidepanelInstance): boolean {
    if (sidepanel) {
      // remove panel from array of active panels
      this.panels = this.panels.filter(p => p != sidepanel);

      sidepanel.destroy();
      return true;
    }
    return false;
  }

  /**
   * Open sidepanel by specified id
   * @param {string} id sidepanel identification
   * @return {Observable<boolean>}
   */
  open(id: string): Observable<boolean> {
    // open panel specified by id
    const panel = this.panels.find(x => x.panel.instance.genericPanel.id === id);
    return this.openSidepanel(panel);
  }

  /**
   * Open sidepanel by type
   * @param {Type<T>} type sidepanel type
   * @return {Observable<boolean>}
   */
  openByType<T extends Sidepanel>(type: Type<T>): Observable<boolean> {
    const panel = this.panels.find(x => x.panel.instance instanceof type);
    return this.openSidepanel(panel);
  }

  /**
   * Toggle loading specified sidepanel by `Type`
   * @param {Type<T>} type sidepanel type
   * @param {SidepanelConfig} config
   * @return {Observable<boolean>}
   */
  toggleLoadingDataByType<T extends SidepanelCrudBase<any>>(type: Type<T>, config?: SidepanelConfig): Observable<boolean> {
    const sidepanel = this.panels.find(x => x.panel.instance instanceof type);
    if (sidepanel.panel.instance instanceof SidepanelCrudBase) {
      return this.toggleLoadingData(sidepanel.panel.instance, config);
    } else {
      return of(false);
    }
  }

  /**
   * Open sidepanel
   * @param {SidepanelInstance} sidepanel component reference
   * @return {Observable<boolean>}
   */
  private openSidepanel(sidepanel: SidepanelInstance): Observable<boolean> {
    if (sidepanel) {
      return this.closeAll(sidepanel.panel.instance.genericPanel.id).pipe(
        tap(result => {
          if (result) {
            const panelInfo = { name: sidepanel.panel.componentType.name, id: sidepanel.panel.instance.genericPanel.id };
            this.onOpen.emit(panelInfo);
            sidepanel.panel.instance.genericPanel.open();
          }
        })
      );
    }
    return of(false);
  }

  /**
   * Toggle loading data sidepanelCrudBase
   * @param {SidepanelCrudBase<any>} sidepanel component reference
   * @param {SidepanelConfig} config
   * @return {Observable<boolean>}
   */
  private toggleLoadingData(sidepanel: SidepanelCrudBase<any>, config?: SidepanelConfig): Observable<boolean> {
    if (sidepanel) {
      if (!isNil(config)) {
        sidepanel.genericPanel.isCreate = config.isCreate;
      }
      sidepanel.loadingData = !sidepanel.loadingData;
      return of(true);
    }
    return of(false);
  }

  /**
   * Close sidepanel by specified id
   * @param {string} id sidepanel identification
   * @return {Observable<boolean>}
   */
  close(id: string): Observable<boolean> {
    const sidepanel = this.panels.find(x => x.panel.instance.genericPanel.id === id);
    return this.closeSidepanel(sidepanel.panel);
  }

  /**
   * Close sidepanel by type
   * @param {Type<T>} type sidepanel type
   * @return {Observable<boolean>}
   */
  closeByType<T extends Sidepanel>(type: Type<T>): Observable<boolean> {
    const sidepanel = this.panels.find(x => x.panel.instance instanceof type);
    return this.closeSidepanel(sidepanel.panel);
  }

  /**
   * Close sidepanel
   * @param {ComponentRef<Sidepanel>} componentRef component reference
   * @return {Observable<boolean>}
   */
  private closeSidepanel(componentRef: ComponentRef<Sidepanel>): Observable<boolean> {
    if (componentRef) {
      return componentRef.instance.handleClose().pipe(
        tap(result => {
          const panelInfo = { name: componentRef.componentType.name, id: componentRef.instance.genericPanel.id };
          return result && this.onClose.emit(panelInfo);
        })
      );
    }

    return of(this.hasOpenModals());
  }

  /**
   * Close all open sidepanels
   * @param {string} id exclude sidepanel id
   * @return {Observable<boolean>}
   */
  closeAll(id?: string): Observable<boolean> {
    const closeablePanels = this.panels
      .filter(p => p.panel.instance.genericPanel.show && p.panel.instance.genericPanel.id !== id)
      .map(p =>
        p.panel.instance
          .handleClose()
          .pipe(tap(result => result && this.onClose.emit({ id: p.panel.instance.genericPanel.id, name: p.panel.componentType.name })))
      );
    return closeablePanels.length > 0
      ? scheduled(closeablePanels, queueScheduler).pipe(
          zipAll(),
          map(closeResults => closeResults.every(r => r))
        )
      : of(true);
  }

  /**
   * Detects at least one of the modals is opened
   * @return {boolean}
   */
  private hasOpenModals(): boolean {
    return this.modalService.hasOpenModals();
  }

  /**
   * Toggle specified sidepanel by `ID`
   * @param {string} id
   * @param {any} state
   */
  toggle(id: string, state): void {
    if (state) {
      this.open(id).subscribe();
    } else {
      this.close(id).subscribe();
    }
  }

  /**
   * For calc table padding and start animation
   * @param {Sidepanel} panel
   * @return {number}
   */
  getPanelOffsetWidthByType(panel: Sidepanel): number {
    return get(panel, 'genericPanel.sidePanel.nativeElement.offsetWidth', 0);
  }
}
