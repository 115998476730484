import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BackupSetting, ServiceType } from '@app/common/models';
import { AuthService } from '@app/common/services';
import { getServiceUiInfo, ServiceTypeUiInfo } from '@app/common/utils';
import { I18_NAMESPACE_MODULE } from '@app/i18';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MbsPopupType, MbsSize, SharedPersistentStateEnum, TableHeader } from 'mbs-ui-kit';
import { BackupConfigSettings } from './backup-config-control.model';

@UntilDestroy()
@Component({
  selector: 'app-backup-config-control',
  templateUrl: './backup-config-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BackupConfigControlComponent {
  #disabledServices: ServiceType[] = [];

  public disabledServicesWarningText: string;
  public servicesInfo: { info: ServiceTypeUiInfo; value: BackupSetting; disabled: boolean }[] = [];
  public headers: TableHeader[];

  public readonly viewMode: SharedPersistentStateEnum = SharedPersistentStateEnum.table;
  public readonly moduleUsers = I18_NAMESPACE_MODULE.users;
  public readonly MbsSize = MbsSize;
  public readonly MbsPopupType = MbsPopupType;

  public formGroup: FormGroup;

  private readonly configureServices: ServiceType[] = [ServiceType.Mail, ServiceType.Drive, ServiceType.Contacts, ServiceType.Calendar];

  @Input() backupEnabled: boolean;
  @Input() IsPublicFolder: boolean;

  @Input() set disabledServices(services: ServiceType[]) {
    this.#disabledServices = services.filter((s) => this.configureServices.includes(s));

    if (this.#disabledServices.length === 0) return;

    this.#disabledServices.forEach((serviceType) => {
      const control = this.formGroup.controls[serviceType];

      if (control) {
        control.disable({ emitEvent: false });
      }
    });

    const warningTextArray = [];
    const isOneServiceDisabled = this.#disabledServices.length === 1;

    if (this.#disabledServices.length === this.configureServices.length) {
      warningTextArray.push('The backup services are not enabled');
    } else {
      const servicesString = this.#disabledServices
        .map((s) => getServiceUiInfo(s).displayName)
        .join(', ')
        .replace(/,(?=[^,]*$)/, ' and');

      warningTextArray.push(
        `The ${servicesString} backup ${isOneServiceDisabled ? 'service' : 'services'} ${isOneServiceDisabled ? 'is' : 'are'} not enabled`
      );
    }

    warningTextArray.push(`Please enable ${isOneServiceDisabled ? 'it' : 'them'} on the Dashboard page`);

    this.disabledServicesWarningText = warningTextArray.join('. ');
  }

  get disabledServices(): ServiceType[] {
    return this.#disabledServices;
  }

  @Input() set settings(settings: BackupConfigSettings) {
    if (settings == null) return;

    const formInitValue = { backupEnabled: settings.backupEnabled };

    settings.services.forEach((service) => {
      formInitValue[service.serviceType] = service.enabled;
    });

    this.formGroup.patchValue(formInitValue);
  }

  @Input() userIdForLink: string;
  @Output() mbsChange = new EventEmitter<BackupConfigSettings>();

  get prefix(): string {
    return this.authService?.prefix;
  }

  constructor(private authService: AuthService, private fb: FormBuilder) {
    this.headers = this.getTableHeaders();

    this.formInit();
    this.initStreams();
  }

  initStreams(): void {
    this.formGroup.valueChanges.pipe(untilDestroyed(this)).subscribe({
      next: (settings) => {
        const backupEnabled = settings.backupEnabled;

        this.servicesInfo.forEach((i) => {
          i.disabled =
            this.disabledServices.includes(i.info.serviceType) ||
            !backupEnabled ||
            (this.IsPublicFolder && i.info.serviceType === ServiceType.Drive);

          const control = this.formGroup.controls[i.value.serviceType];

          if (i.disabled) {
            control.disable({ emitEvent: false });
          } else {
            control.enable({ emitEvent: false });
          }
        });

        const services = Object.keys(settings).filter((s) => s != 'backupEnabled');
        const emitValue: BackupConfigSettings = {
          backupEnabled,
          services: services.map((s) => ({ serviceType: parseInt(s), enabled: settings[s] as boolean }))
        };

        this.mbsChange.emit(emitValue);
      }
    });
  }

  private formInit(): void {
    this.formGroup = this.fb.group({
      backupEnabled: [false]
    });

    this.configureServices.forEach((serviceType) => {
      this.formGroup.addControl(serviceType.toString(), this.fb.control({ value: false, disabled: false }));

      this.servicesInfo.push({
        info: getServiceUiInfo(serviceType),
        value: { serviceType, enabled: false },
        disabled: this.disabledServices.includes(serviceType)
      });
    });
  }

  private getTableHeaders(): TableHeader[] {
    return [
      {
        name: '',
        gridColMin: '24rem',
        gridColSize: '24rem',
        headerName: 'service'
      },
      {
        name: '',
        gridColMin: '7rem',
        gridColSize: '7rem',
        headerName: 'enabled'
      }
    ];
  }
}
