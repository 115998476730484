import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BOOLEAN_OPERATOR, SINGLE_QUOTES_REGEX, TaskAction } from '@app/common';
import {
  AuthUser,
  DomainCancelAllPayload,
  hasAdminRole,
  OperationTypeByAPIEnum,
  Role,
  ServiceTypeByAPIEnum,
  TaskIds,
  TaskIdsResponse,
  TaskManagerOdata,
  UserCancelAllPayload
} from '@app/common/models';
import { AuthService, SharedOdataService } from '@app/common/services';
import { TaskManagerTagsEnum, TaskManagerTagsMap } from '@app/common/services/smart-search';
import { filterByWords } from '@app/common/utils';
import {
  containsWrapper,
  FilterOptions,
  getFilterByContains,
  getFilterByEq,
  getFilterByEqFromEnum
} from '@app/common/utils/functions/search';
import { ODataPagedResult, ODataServiceFactory } from '@app/common/odata';
import { isArray, isNil } from 'lodash';
import { SmartSearchModel, SmartSearchModelField } from 'mbs-ui-kit';
import { forkJoin, Observable, throwError } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TaskManagerService extends SharedOdataService<TaskManagerOdata> {
  readonly #userName = TaskManagerTagsMap.get(TaskManagerTagsEnum.userName);
  readonly #serviceType = TaskManagerTagsMap.get(TaskManagerTagsEnum.serviceType);
  readonly #task = TaskManagerTagsMap.get(TaskManagerTagsEnum.task);
  readonly #status = TaskManagerTagsMap.get(TaskManagerTagsEnum.status);

  private user$: Observable<AuthUser>;
  private roles$: Observable<Role[]>;

  constructor(odataFactory: ODataServiceFactory, private http: HttpClient, private authService: AuthService) {
    super(odataFactory, 'Tasks');

    this.user$ = authService.getAuthUser();
    this.roles$ = authService.getRoles();
  }

  fetchAction(id: string | string[] | undefined, action: TaskAction): Observable<void> {
    switch (action) {
      case TaskAction.Resume:
      case TaskAction.Restart: {
        const odataService = this.odataFactory.CreateService<TaskManagerOdata>(`Tasks(${id})/Restart`);

        return odataService.Post<void>(null).Exec();
      }
      case TaskAction.Pause: {
        const odataService = this.odataFactory.CreateService<TaskManagerOdata>(`Tasks(${id})/Pause`);

        return odataService.Post<void>(null).Exec();
      }
      case TaskAction.Cancel: {
        const odataService = this.odataFactory.CreateService<TaskManagerOdata>(`Tasks(${id})/Cancel`);

        return odataService.Post<void>(null).Exec();
      }
      case TaskAction.CancelAll: {
        return this.cancelAll() as unknown as Observable<void>;
      }
      case TaskAction.CancelSelected: {
        const ids = isArray(id) ? id : [id];
        const payload: TaskIds = { TaskIds: ids };

        return this.cancelSelected(payload) as unknown as Observable<void>;
      }
      default:
        return throwError(() => 'Unknown action');
    }
  }

  getTasks(): Observable<ODataPagedResult<TaskManagerOdata>> {
    return forkJoin([this.user$, this.roles$]).pipe(
      filter(([user, roles]) => !isNil(user) && !isNil(roles)),
      switchMap(([user, roles]) => (hasAdminRole(roles) ? this.domainTasks(user.DomainId) : this.userTasks(user.Id)))
    );
  }

  private userTasks(id: string): Observable<ODataPagedResult<TaskManagerOdata>> {
    const odataService = this.odataFactory.CreateService<TaskManagerOdata>(`/BackupUsers(${id})/Tasks`);

    return this.fetchData<TaskManagerOdata>(odataService);
  }

  private domainTasks(id: string): Observable<ODataPagedResult<TaskManagerOdata>> {
    const odataService = this.odataFactory.CreateService<TaskManagerOdata>(`/Domains(${id})/Tasks`);

    return this.fetchData<TaskManagerOdata>(odataService);
  }

  updateFilter(searchObj: SmartSearchModel): void {
    const filter: string[] = [];

    if (searchObj[this.#userName.tag]) {
      const options: FilterOptions = {
        model: searchObj[this.#userName.tag] as SmartSearchModelField[],
        prop: this.#userName.prop
      };

      filter.push(getFilterByContains(options));
    }

    if (searchObj[this.#task.tag]) {
      const options: FilterOptions = {
        model: searchObj[this.#task.tag] as SmartSearchModelField[],
        prop: this.#task.prop,
        enumItems: OperationTypeByAPIEnum
      };

      filter.push(getFilterByEqFromEnum(options));
    }

    if (searchObj[this.#serviceType.tag]) {
      const options: FilterOptions = {
        model: searchObj[this.#serviceType.tag] as SmartSearchModelField[],
        prop: this.#serviceType.prop,
        enumItems: ServiceTypeByAPIEnum
      };

      filter.push(getFilterByEqFromEnum(options));
    }

    if (searchObj[this.#status.tag]) {
      const options: FilterOptions = {
        model: searchObj[this.#status.tag] as SmartSearchModelField[],
        prop: this.#status.prop
      };

      filter.push(getFilterByEq(options));
    }

    if (searchObj.words?.filter(Boolean)) {
      const term = filterByWords(searchObj).replace(SINGLE_QUOTES_REGEX, '');

      filter.push(containsWrapper(this.#userName.prop, term));
    }

    this.filter = filter.length > 0 ? filter.join(` ${BOOLEAN_OPERATOR.and} `) : '';
  }

  cancelSelected(payload: TaskIds): Observable<TaskIdsResponse[]> {
    const odataService = this.odataFactory.CreateService<TaskIdsResponse[]>(`Tasks/CancelSelected`);

    return odataService.Post<TaskIds>(payload).Exec() as unknown as Observable<TaskIdsResponse[]>;
  }

  cancelAll(): Observable<TaskIdsResponse[]> {
    return forkJoin([this.user$, this.roles$]).pipe(
      filter(([user, roles]) => !isNil(user) && !isNil(roles)),
      switchMap(([user, roles]) => {
        return hasAdminRole(roles) ? this.domainCancelAll(user.DomainId) : this.userCancelAll(user.Id);
      })
    );
  }

  private domainCancelAll(id: string): Observable<TaskIdsResponse[]> {
    const odataService = this.odataFactory.CreateService<TaskIdsResponse[]>(`Tasks/CancelAll`);

    return odataService.Post<DomainCancelAllPayload>({ DomainId: id }).Exec() as unknown as Observable<TaskIdsResponse[]>;
  }

  private userCancelAll(id: string): Observable<TaskIdsResponse[]> {
    const odataService = this.odataFactory.CreateService<TaskIdsResponse[]>(`Tasks/CancelAll`);

    return odataService.Post<UserCancelAllPayload>({ UserId: id }).Exec() as unknown as Observable<TaskIdsResponse[]>;
  }
}
