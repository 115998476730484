import { Pipe, PipeTransform } from '@angular/core';
import { ChannelItem, TeamFile, TeamItem, TeamItemType } from '@app/common/models';

@Pipe({
  name: 'foldersIcon',
  pure: true
})
export class FolderIconPipe implements PipeTransform {
  transform(item: TeamFile | TeamItem | ChannelItem, host?: string): string {
    const icoClass = ['fa'];

    if (host) {
      icoClass.push(host);
    }

    icoClass.push(this.imageSrcForDriveItem(item));

    return icoClass.join(' ');
  }

  private imageSrcForDriveItem(item: TeamItem): string {
    switch (item.ItemType) {
      case TeamItemType.Team:
        return `fa-users text-primary`;
      case TeamItemType.Channel:
        return `fa-commenting text-primary`;
      case TeamItemType.PostsRoot:
        return `fa-comments text-primary`;
      case TeamItemType.FilesRoot:
        return `fa-file text-primary`;
      default:
        return '';
    }
  }
}
