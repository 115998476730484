export const STAGE_HOSTS = [
  'apps-rc.mspbackups.com',
  'apps-preprod.mspbackups.com',
  'scloud.resale-stage.mspbackups.com',
  'apps-stage.mspbackups.com',
  'apps-stage2.mspbackups.com'
]

export const PROD_HOSTS = [
  '*apps.mspbackups.com',
  'apps.msp360.com'
]

export const LOCAL_HOSTS = ['localhost']
