<!-- Icon -->
<div class="alert-icon_wrapper"
     *ngIf="icon">
  <span [ngClass]="getAlertIcon"></span>
</div>

<!-- Content -->
<div class="alert-content">
  <ng-content *ngIf="!content"></ng-content>
  <ng-container *ngIf="content">
    <ng-container *ngTemplateOutlet="isTemplate(content) ? templateRef : innerHTMLTemplate;
          context: { $implicit: content }">
    </ng-container>
  </ng-container>
</div>

<!-- Button close -->
<div class="alert-close_wrapper"
     *ngIf="closable">
  <mbs-button [isCtrl]="true"
              icon="ico ico-Close"
              type="dark"
              [size]="size === MbsSize.lg ? MbsSize.xs : MbsSize.xxs"
              (click)="closeAlert(context)"
              class="alert-close">
  </mbs-button>
</div>

<!-- Templates -->
<ng-template #innerHTMLTemplate
             let-content>
  <div [innerHTML]="content | safe: 'html'"></div>
</ng-template>

<ng-template #templateRef
             let-content>
  <ng-container *ngTemplateOutlet="content; context: { $implicit: context }"></ng-container>
</ng-template>
