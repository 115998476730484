/** @format */

import { Component, Input } from '@angular/core';
import { TabsetDirective } from './directives/tabset.directive';

@Component({
  selector: '[mbsTabsetOutlet]',
  host: { '[class.mbs-tabset_content]': 'true' },
  styleUrls: ['./tabset-content-outlet.component.scss'],
  // prettier-ignore
  template: `
    <ng-content select="[prepend]"></ng-content>
    <ng-template ngFor
                let-item
                [ngForOf]="nav.items">
      <div class="mbs-tab-pane"
           *ngIf="item.isPanelInDom()"
           [class.active]="item.isActive"
           [class.d-none]="!item.isActive"
           [class.h-100]="item.fullHeight"
           [class.loading]="item.isLoading">
        <mbs-loader *ngIf="item.isLoading"></mbs-loader>
        <div [hidden]="item.isLoading"
             [class.h-100]="item.fullHeight">
          <ng-template [ngTemplateOutlet]="item.contentTemplate?.templateRef"
                      [ngTemplateOutletContext]="{ $implicit: item?.active }"></ng-template>
        </div>
      </div>
    </ng-template>
    <ng-content select="[append]"></ng-content>
  `
})
export class TabsetContentOutlet {
  /**
   * A role to set on the nav pane
   */
  @Input() paneRole;

  /**
   * Reference to the `mbsTabset` instance
   */
  @Input('mbsTabsetOutlet') nav: TabsetDirective;
}
