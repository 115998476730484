<div class="mbs-header mbs-main-menu">

  <nav class="navbar navbar-light navbar-expand-apps-lg apps-navbar">

    <div class="row no-gutters navbar-inner align-items-center">

      <div class="col-nav-apps-auto">

        <span class="font-weight-bold text-nowrap">

          <ng-container *ngIf="isGoogle; else officeTRef">

            <ng-container *ngIf="showShortSystemLogo; else defaultGoogleWorkspaceTRef">
              {{ 'header.googleWorkspaceShort' | i18next: { format: 'title' } }}
            </ng-container>

            <ng-template #defaultGoogleWorkspaceTRef>
              {{ 'header.googleWorkspace' | i18next: { format: 'title' } }}
            </ng-template>

          </ng-container>

          <ng-template #officeTRef>
            <ng-container *ngIf="showShortSystemLogo; else defaultMicrosoft365TRef">
              {{ 'header.microsoft365Short' | i18next: { format: 'title' } }}
            </ng-container>

            <ng-template #defaultMicrosoft365TRef>
              {{ 'header.microsoft365' | i18next: { format: 'title' } }}
            </ng-template>

          </ng-template>

        </span>

      </div>

      <div class="header-divider"></div>

      <div class="d-flex col-nav-apps-auto order-nav-apps-1">
        <a class="navbar-brand apps-navbar_brand"
           [routerLink]="[prefix + RoutePath.Dashboard]"
           routerLinkActive="active">
          <span class="ico ico-Home"></span>
        </a>
      </div>

      <div [ngbCollapse]="isNavbarCollapsed"
           class="collapse navbar-collapse col-nav-apps-lg-auto order-nav-apps-3 order-nav-apps-lg-2"
           id="navbarSupportedContent">

        <ul class="navbar-nav mr-auto">

          <li *ngFor="let item of items"
              class="nav-item"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: !(item.needChangeDetectionHack && userId) }">

            <a class="nav-link"
               [routerLink]="[prefix + item.link]">
              <span class="nav-link_ico"
                    [class]="item.icon"></span>
              {{ item.displayName }}
            </a>

          </li>

          <li ngbDropdown
              display="dynamic"
              class="nav-item"
              placement="bottom-right bottom-left"
              routerLinkActive="active">

            <ng-container *ngIf="getRouterUrl | activeRoute as activeRoute; else navItemTemplateRef">

              <button class="nav-link -sublink"
                      id="dropdownBasic2"
                      ngbDropdownToggle>

                <span class="d-inline-flex align-items-center flex-row">

                  <span class="nav-link_ico"
                        [ngClass]="activeRoute.iconClass"></span>

                  <span class="d-block mx-1">
                    <span class="nav-link_parent">
                      {{ 'header.reporting' | i18next: { format: 'title' } }}
                    </span>
                    <span class="d-block">
                      {{ activeRoute.name }}
                    </span>
                  </span>

                </span>

              </button>

            </ng-container>

            <ng-template #navItemTemplateRef>
              <button class="nav-link"
                      id="dropdownBasic1"
                      ngbDropdownToggle>
                <span class="fa fa-bar-chart nav-link_ico"></span>
                {{ 'header.reporting' | i18next: { format: 'title' } }}
              </button>
            </ng-template>

            <ul ngbDropdownMenu
                aria-labelledby="dropdownBasic1"
                placement="bottom-left bottom-right"
                class="nav-submenu -arrow">

              <li *ngIf="!isDomainUser"
                  class="nav-submenu-item"
                  routerLinkActive="active"
                  ngbDropdownItem>

                <a [routerLink]="[prefix + RoutePath.BackupHistory]"
                   class="nav-submenu-link">
                  <span class="nav-link_ico fa fa-edit"></span>
                  {{ 'header.dropdown.backupHistory' | i18next: { format: 'title' } }}
                </a>

              </li>

              <li class="nav-submenu-item dropdown-item"
                  routerLinkActive="active"
                  ngbDropdownItem>

                <a [routerLink]="[prefix + RoutePath.History]"
                   class="nav-submenu-link text-muted">
                  <span class="nav-link_ico ico ico-OpenHistory"></span>
                  {{ 'header.dropdown.history' | i18next: { format: 'title' } }}
                </a>

              </li>

              <li *ngIf="isAdmin || isHomeUser"
                  class="nav-submenu-item dropdown-item"
                  routerLinkActive="active"
                  ngbDropdownItem>

                <a [routerLink]="[prefix + RoutePath.Reports]"
                   class="nav-submenu-link text-muted">
                  <span class="nav-link_ico ico ico-Excel"></span>
                  {{ 'header.dropdown.reports' | i18next: { format: 'title' } }}
                </a>

              </li>

              <li *ngIf="isAdmin && !isHomeUser"
                  class="nav-submenu-item dropdown-item"
                  routerLinkActive="active"
                  ngbDropdownItem>

                <a [routerLink]="[prefix + RoutePath.AuditLog]"
                   class="nav-submenu-link text-muted">
                  <span class="nav-link_ico fa fa-book"></span>
                  {{ 'header.dropdown.auditLog' | i18next: { format: 'title' } }}
                </a>

              </li>

              <li *ngIf="!(isProviderSignIn$ | async)"
                  class="nav-submenu-item dropdown-item"
                  routerLinkActive="active"
                  ngbDropdownItem>

                <a [routerLink]="[prefix + RoutePath.ExportResults]"
                   class="nav-submenu-link text-muted">
                  <span class="nav-link_ico fa fa-download"></span>
                  {{ 'header.dropdown.exportResults' | i18next: { format: 'title' } }}
                </a>

              </li>

              <li *ngIf="isAdmin || isHomeUser"
                  class="nav-submenu-item dropdown-item"
                  routerLinkActive="active"
                  ngbDropdownItem>

                <a [routerLink]="[prefix + RoutePath.SkipItems]"
                   class="nav-submenu-link text-muted">
                  <span class="nav-link_ico fa fa-list-alt"></span>
                  {{ 'header.dropdown.skipItems' | i18next: { format: 'title' } }}
                </a>

              </li>

            </ul>

          </li>

        </ul>

      </div>

      <div class="d-flex ml-auto col-nav-apps-auto order-nav-apps-2 order-nav-apps-lg-3 justify-content-end">

        <div class="row no-gutters align-items-center justify-content-end">

          <div *ngIf="isImmutability"
               [ngbTooltip]="'header.tooltip.immutable' | i18next: { format: 'capitalize' }"
               container="body"
               placement="bottom-left auto"
               class="header-badge"
               [class]="emergencySignIn ? 'mr-1 mr-sm-2' : showNavText ? 'mr-2 mr-sm-4' : 'mr-1 mr-sm-2'">
            <span class="fa fa-lock text-lg"></span>
            <ng-container *ngIf="showNavText">
              {{ 'header.immutable' | i18next: { format: 'title' } }}
            </ng-container>
          </div>

          <div *ngIf="emergencySignIn"
               [ngbTooltip]="'header.tooltip.signedInWith' | i18next: { format: 'capitalize' }"
               container="body"
               placement="bottom-left auto"
               class="header-badge mr-2 mr-sm-4">
            <span class="fa fa-key text-base"></span>
          </div>

          <a routerLinkActive="active"
             [routerLink]="[prefix + RoutePath.TaskManager]"
             class="btn btn-light mr-1 mr-sm-2">
            <span class="fa fa-tasks text-lg lh-base"></span>
            <ng-container *ngIf="showNavText">
              {{ 'header.tasks' | i18next: { format: 'title' } }}
            </ng-container>
          </a>

          <div ngbDropdown
               display="dynamic"
               placement="bottom-right"
               class="mr-1 mr-sm-2"
               #accountDropdown>

            <button id="userMenuButton"
                    class="btn btn-light"
                    [class.position-relative]="isProviderSignIn$ | async"
                    [title]="'header.account' | i18next: { format: 'title' }"
                    ngbDropdownToggle>
              <span class="fa fa-user-o text-success text-lg"></span>
              <span *ngIf="isProviderSignIn$ | async"
                    class="fa fa-gear text-success text-xxs provider-icon"></span>
            </button>

            <ul ngbDropdownMenu
                aria-labelledby="userMenuButton"
                class="nav-submenu -arrow">

              <li class="nav-submenu-item mbs-navbar_userinfo disabled"
                  disabled="true">

                <div class="mbs-navbar_userinfo-item text-muted">
                  <mbs-text-ellipsis>
                    {{ authUser?.Name }}
                    <ng-template [hide]="!(isProviderSignIn$ | async)"
                                 mbsTextEllipsisAppend>
                        <span class="text-success ml-1">
                          {{ 'header.dropdown.provider' | i18next: { format: 'title' } }}
                        </span>
                    </ng-template>
                  </mbs-text-ellipsis>
                </div>

                <div class="mbs-navbar_userinfo-item text-muted">
                  <mbs-text-ellipsis>
                    {{ authUser?.Email }}
                  </mbs-text-ellipsis>
                </div>

                <div *ngIf="(isProviderSignIn$ | async) && authUser?.DomainName"
                     class="mbs-navbar_userinfo-item text-muted">
                  <span class="text-muted text-xs">{{ 'header.dropdown.domainName' | i18next: { format: 'title' } }}</span>
                  <mbs-text-ellipsis>
                    {{ authUser?.DomainName }}
                  </mbs-text-ellipsis>
                </div>

              </li>

              <li class="dropdown-divider"></li>

              <li *ngIf="!(isProviderSignIn$ | async)"
                  class="nav-submenu-item dropdown-item cursor-pointer"
                  ngbDropdownItem>

                <a class="nav-submenu-link text-muted"
                   (click)="handleOpenMyAccountSidepanel()">
                  <span class="nav-link_ico fa fa-user-o"></span>
                  {{ 'header.dropdown.myAccount' | i18next: { format: 'title' } }}
                </a>

              </li>

              <li *ngIf="isAdmin || isHomeUser"
                  class="nav-submenu-item dropdown-item"
                  routerLinkActive="active"
                  ngbDropdownItem>

                <a class="nav-submenu-link text-muted"
                   [routerLink]="[prefix + RoutePath.RetentionPolicy]">
                  <span class="nav-link_ico ico ico-Basket"></span>
                  {{ 'header.dropdown.retentionPolicies' | i18next: { format: 'title' } }}
                </a>

              </li>

              <li class="nav-submenu-item dropdown-item"
                  routerLinkActive="active"
                  ngbDropdownItem>

                <a class="nav-submenu-link text-muted"
                   [routerLink]="[prefix + RoutePath.TaskManager]">
                  <span class="nav-link_ico fa fa-tasks"></span>
                  {{ 'header.dropdown.taskManager' | i18next: { format: 'title' } }}
                </a>

              </li>

              <li class="dropdown-divider"></li>

              <li *ngIf="isBusinessOffice"
                  class="nav-submenu-item dropdown-item"
                  ngbDropdownItem>

                <button class="nav-submenu-link text-muted"
                        (click)="handleSignAsAdmin()">
                  <span class="nav-link_ico fa fa-black-tie"></span>
                  {{ 'header.dropdown.signIn' | i18next: { format: 'title' } }}
                </button>

              </li>

              <li class="nav-submenu-item dropdown-item"
                  ngbDropdownItem>

                <button class="nav-submenu-link text-muted"
                        (click)="handleLogout()">
                  <span class="fa fa-sign-out nav-link_ico"></span>
                  {{ 'header.dropdown.signOut' | i18next: { format: 'title' } }}
                </button>

              </li>

            </ul>

          </div>

          <ng-container *ngIf="productMode === ProductMode.mbs">
            <a class="btn btn-light"
               [class.mr-3]="showMargin"
               routerLinkActive="active"
               [routerLink]="[prefix + RoutePath.Help, RoutePath.GoogleOffice365]">
              <span class="nav-link_ico fa fa-question-circle-o text-base mr-0"></span>
              {{ 'header.help' | i18next: { format: 'title' } }}
            </a>
          </ng-container>

          <ng-container *ngIf="productMode === ProductMode.resale">
            <div ngbDropdown
                 display="dynamic"
                 class="nav-item"
                 placement="bottom-right">

              <button [class.mr-3]="showMargin"
                      id="helpMenuButton"
                      class="btn btn-light"
                      ngbDropdownToggle>
                <span class="fa fa-question-circle-o text-base"></span>
                {{ 'header.help' | i18next: { format: 'title' } }}
              </button>

              <ul ngbDropdownMenu
                  placement="bottom-right"
                  aria-labelledby="helpMenuButton"
                  class="nav-submenu -arrow">

                <li class="nav-submenu-item dropdown-item"
                    ngbDropdownItem>

                  <a class="nav-submenu-link text-muted"
                     target="_blank"
                     href="https://help.msp360.com/cloudberry-backup-for-ms-office-365">
                    <span class="nav-link_ico fa fa-question-circle-o text-base"></span>
                    {{ 'header.help' | i18next: { format: 'title' } }}
                  </a>

                </li>

                <li class="nav-submenu-item dropdown-item"
                    ngbDropdownItem>

                  <a href="https://support.msp360.com/cases/new"
                     target="_blank"
                     class="nav-submenu-link text-muted">
                    <span class="nav-link_ico fa fa-life-ring"></span>
                    {{ 'header.dropdown.support' | i18next: { format: 'title' } }}
                  </a>

                </li>

              </ul>

            </div>

          </ng-container>

          <button class="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  (click)="isNavbarCollapsed = !isNavbarCollapsed">
            <span class="navbar-toggler-icon"></span>
          </button>

        </div>

      </div>

    </div>

  </nav>

</div>
