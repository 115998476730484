import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { APPEND_BUTTON_PASSWORD_STATE, ChangeAlternateEmail } from '@app/common';
import { UserOdataService } from '@app/common/services';
import { getAppendButtonsIcon, getAppendButtonsState, hasAppendButtonsPasswordType } from '@app/common/utils';
import { ModalComponent } from 'mbs-ui-kit';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  templateUrl: './change-email-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeEmailModalComponent implements OnInit {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  private userId = '';

  public readonly getAppendButtonsIcon = getAppendButtonsIcon;

  public passwordType$: BehaviorSubject<string> = new BehaviorSubject(APPEND_BUTTON_PASSWORD_STATE.hidden.type);
  public changeLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public changeEmailForm = new UntypedFormGroup({
    Email: new FormControl('', [Validators.required, Validators.email]),
    Password: new FormControl('', [Validators.required, Validators.maxLength(20)])
  });

  constructor(public userService: UserOdataService) {}

  ngOnInit(): void {
    this.changeEmailForm.reset();
    this.userId = this.baseModal.data as string;
  }

  changeButtonClick(): void {
    const data = this.changeEmailForm.value as ChangeAlternateEmail;

    this.changeLoading$.next(true);
    this.userService
      .editAlternateEmail(this.userId, data.Email, data.Password)
      .pipe(finalize(() => this.changeLoading$.next(false)))
      .subscribe({
        next: () => this.baseModal.save(),
        error: (e: HttpErrorResponse) => {
          this.handleFormError(e, this.changeEmailForm);
        }
      });
  }

  cancelButtonClick(): void {
    this.baseModal.close();
  }

  private handleFormError(response: HttpErrorResponse, form: UntypedFormGroup): void {
    if (response.status === 400) {
      // response.error.error.details.forEach((input) => {
      //   const p = form.get(input.target);
      //   p.setValue('');
      //   p.setErrors({ [`${input.target}Incorrect`]: { message: input.message } });
      // });
      form.get('Password').setValue('');
      form.get('Password').setErrors({ [`Password Incorrect`]: { message: (response.error as { value: string }).value } });
    }
  }

  handleChangePasswordType(): void {
    const isPasswordType: boolean = hasAppendButtonsPasswordType(this.passwordType$.value);

    this.passwordType$.next(APPEND_BUTTON_PASSWORD_STATE[getAppendButtonsState(isPasswordType)].type);
  }
}
