<div class="mbs-invoice-plan mb-3">

  <div class="mbs-invoice-plan_ico mbs-invoice-plan_ico-secondary">
    <span class="fa fa-list-alt text-muted"></span>
  </div>

  <div *ngIf="planInfo"
       class="mbs-invoice-plan_component">

    <div class="mbs-invoice-plan_current">
      {{ planInfo ? planInfo.Name : (modulePayments + ':title.noSubscription' | i18next: { format: 'title' }) }}
    </div>

    <ng-container *ngIf="!hasUnsubscribedPlan; else elsePlanBlock">

      <div class="d-flex">

        <span class="mbs-invoice-plan_data">

          <ng-container *ngIf="planInfo.IsCanceled; else notCanceled">
            {{ unExpiredPlan ? 'Expires' : 'Expired' }}
          </ng-container>:

          <span [ngClass]="{ 'text-warning font-weight-semibold': !unExpiredPlan }">
            {{ planInfo.ExpireDate | date: DateFormat.mediumDate }}
          </span>

          <ng-template #notCanceled>
            {{ unExpiredPlan ? (planInfo.TariffType === TariffType.trial ? 'Expires' : 'Next payment') : 'Expired' }}
          </ng-template>

        </span>

      </div>

    </ng-container>

    <ng-template #elsePlanBlock>

      <div class="d-flex">

        <span class="mbs-invoice-plan_data">

          <ng-container *ngIf="unExpiredPlan; else expired">
            {{ modulePayments + ':modal.title.unsubscribed.next' | i18next: { format: 'capitalize' } }}.
            {{ modulePayments + ':modal.title.unsubscribed.valid' | i18next: { format: 'capitalize' } }}
          </ng-container>

          <ng-template #expired>
            Expired:
          </ng-template>

          <span class="text-warning font-weight-semibold">
            {{ planInfo.ExpireDate | date: DateFormat.mediumDate }}
          </span>

        </span>

      </div>

    </ng-template>

  </div>

</div>
