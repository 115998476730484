import { DNS } from '@app/common';

export const environment = {
  production: true,
  authUrl: null,
  proxyUrl: null,
  localePath: './assets/locales/{{lng}}/{{ns}}.json',
  posthog: {
    prod: {
      key: 'phc_ryMka3Ji8Hf5SIedewlqATKInfZy2eAo0KTpNpMRSbO',
      serverUrl: 'https://posthog.mon.mspbackups.com'
    },
    stage: {
      key: 'phc_W87dzexm7McSv2ms3rFkxJ1T8v5zAhTzBfH8DxIYcgo',
      serverUrl: 'https://posthog.stage.mspbackups.com'
    }
  },
  sentry: {
    dsn: DNS
  },
  spriteBase: './assets/svg/sprites/sprite.svg#',
  spriteMimeCategory: './assets/svg/sprites/mime-category-sprite.svg#',
  spriteMimeCategorySiteBlocks: './assets/svg/sprites/mime-category-site-blocks-sprite.svg#'
};
