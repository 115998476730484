import { FlatTreeElement } from '../models/flatTreeElement.model';
import { TreeElement } from '../models/treeElement.model';

// Function that maps a nested node to a flat node
export function nodeTransformer(node: TreeElement, level: number): FlatTreeElement {
  return {
    expandable: node.children && node.children.length > 0,
    level,
    node
  };
}
