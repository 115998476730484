import { ContentChildren, Directive, Inject, Input, Optional, QueryList } from '@angular/core';
import { TabsetItemDirectiveToken, TabsetItemDirectiveType } from '../tokens/tabset-item.directive.token';
import { TabsetDirectiveToken, TabsetDirectiveType } from '../tokens/tabset.directive.token';
import { TabsetLinkDirective } from './tabset-link.directive';

@Directive({
  selector: '[mbsTabsetGroup]',
  exportAs: 'mbsTabsetGroup',
  host: {
    '[class.mbs-tabset_group]': 'true',
    '[class.-active]': 'isActive',
    '[class.d-none]': '!isActive',
    '[class.-disabled]': 'isDisabled'
  }
})
export class TabsetGroupDirective {
  @Input('mbsTabsetGroup') id: string;

  get isActive(): boolean {
    return this.items.toArray().some(item => item.isActive);
  }

  set isActive(bool) {
    if (bool) {
      const firstActive = this.items.toArray().find(item => {
        return !item.isDisabled;
      });
      if (firstActive) {
        this.parentTabset.select(firstActive.id);
      } else {
        this.parentTabset.select(this.items.first.id);
      }
    }
  }

  public get isDisabled(): boolean {
    return this.items ? this.items.first.parentTab.isDisabled : false;
  }

  @ContentChildren(TabsetItemDirectiveToken, { descendants: true }) private items: QueryList<TabsetItemDirectiveType>;
  @ContentChildren(TabsetLinkDirective, { descendants: true }) private links: QueryList<TabsetLinkDirective>;

  constructor(@Optional() @Inject(TabsetDirectiveToken) private parentTabset: TabsetDirectiveType) {}
}
