import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ODataError, RoutePath } from '@app/common';
import { getErrorText, isNoLicensesAvailable } from '@app/common/utils';
import { AuthState } from '@app/components/login/enums';
import { ToastService } from 'mbs-ui-kit';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export const passwordErrorText = 'Password is not correct';
export const unidentifiedErrorText = 'Sorry, something went wrong';
export const forbiddenErrorText = "Forbidden. You don't have permission to access this";
export const forbiddenBackupErrorText = "Forbidden. You don't have permission to access the backup data";
export const forbiddenExportToPSTErrorText = `Export to\u00A0PST requires Global Admin access.\n
                                              Contact your provider to\u00A0enable Global Admin access for the domain on\u00A0<span class="font-weight-semibold">M365/Google\u00A0Backup\u00A0>\u00A0Domains</span>`;
export const errorTitle = 'Error Details';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  private readonly excludedRoutes = [`/${RoutePath.Contacts}`, `/${RoutePath.Calendar}`];

  constructor(private toastService: ToastService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        // skip 401 or rethrow for prevent show toast
        if (error.status === 401 || error.status === 412) {
          return throwError(() => error);
        }

        if (error.status === 403) {
          this.toastService.error(this.getForbiddenMessage(error), errorTitle);

          return throwError(() => error);
        }

        if (error.status === 503) {
          void this.router.navigate([RoutePath.Account], { queryParams: { state: AuthState.ServiceUnavailable } });

          return throwError(() => error);
        }

        if (error.status === 400 && error.error?.value?.toLowerCase() === passwordErrorText.toLowerCase()) {
          return throwError(() => error);
        }

        const errorResponse = error.error as ODataError | (Blob & Body);

        if (errorResponse instanceof Blob) {
          errorResponse
            .text()
            .then((text) => {
              try {
                const message = getErrorText({ error: JSON.parse(text) } as HttpErrorResponse);

                this.toastService.error(message, errorTitle);
              } catch (e) {
                throw new Error('Cannot parse blob error text in `Error Handler Interceptor`!');
              }
            })
            .catch(() => this.toastService.error(unidentifiedErrorText, errorTitle));
        } else if (errorResponse?.value && !isNoLicensesAvailable(error)) {
          const message = errorResponse.value;

          this.toastService.error(message, errorTitle);
        }

        return throwError(() => error);
      })
    );
  }

  getForbiddenMessage(error: HttpErrorResponse): string {
    switch (true) {
      case error.url.endsWith('ExportUserData'):
        return forbiddenExportToPSTErrorText;
      case this.excludedRoutes.some((route) => location.pathname.includes(route)):
        return forbiddenBackupErrorText;
      default:
        return forbiddenErrorText;
    }
  }
}
