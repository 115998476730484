import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { APPEND_BUTTON_PASSWORD_STATE, CustomCVAccessor } from '@app/common';
import { AlternateEmail, AuthUser, hasAdminRole, hasSingleUserRole } from '@app/common/models';
import { AuthService, UserOdataService } from '@app/common/services';
import { getAppendButtonsIcon, getAppendButtonsState, hasAppendButtonsPasswordType, isConfirmPasswordValidator } from '@app/common/utils';
import { ChangeEmailModalComponent } from '@app/components/modals/change-email-modal/change-email-modal.component';
import { ChangePasswordModalComponent } from '@app/components/modals/change-password-modal/change-password-modal.component';
import { AlternateAccountTabForm } from '@app/components/my-account/my-account-sidepanel/my-account-sidepanel.model';
import { MyAccountSidepanelService } from '@app/components/my-account/my-account-sidepanel/my-account-sidepanel.service';
import { DeleteAlternateAccountModalComponent } from '@app/components/my-account/tabs/alternate-account-tab/delete-alternate-account-modal/delete-alternate-account-modal.component';
import { ResetPasswordModalComponent } from '@app/components/my-account/tabs/alternate-account-tab/reset-password-modal/reset-password-modal.component';
import { TwoFactorAuthenticationComponent } from '@app/components/my-account/tabs/alternate-account-tab/two-factor-authentication/two-factor-authentication.component';
import { TwoFactorDisableComponent } from '@app/components/my-account/tabs/alternate-account-tab/two-factor-disable/two-factor-disable.component';
import { I18_NAMESPACE_MODULE } from '@app/i18';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { camelCase, isEqual } from 'lodash';
import {
  InputButton,
  MbsSize,
  MbsValidators,
  ModalService,
  ModalSettings,
  TabBase,
  TabsetItemDirective,
  TabsService,
  ToastService
} from 'mbs-ui-kit';
import { BehaviorSubject, from, noop, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, finalize, first, map, share, switchMap, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-my-account-alternate-account-tab',
  templateUrl: './alternate-account-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MyAccountAlternateAccountTabComponent),
      multi: true
    }
  ]
})
export class MyAccountAlternateAccountTabComponent extends TabBase implements ControlValueAccessor, OnInit {
  @Output() changeAlternateEmail = new EventEmitter<void>();
  @Output() deleteAlternateEmail = new EventEmitter<void>();

  @Input() set alternateEmailVerified(state: boolean) {
    this.verifyEmail$.next(state);
  }
  @Input() set twoStepEnabled(state: boolean) {
    this.twoStepEnabled$.next(state);
  }
  @Input() set initialAlternateEmail(value: string) {
    this.initialAlternateEmail$.next(value);
  }

  #settings: AlternateAccountTabForm;
  readonly #toastTitleSuccess = this.i18nPipe.transform('toast.success.title', { format: 'title' });

  public changePasswordLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public twoFALoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public twoStepEnabled$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public passwordType$: BehaviorSubject<string> = new BehaviorSubject(APPEND_BUTTON_PASSWORD_STATE.hidden.type);
  public confirmPasswordType$: BehaviorSubject<string> = new BehaviorSubject(APPEND_BUTTON_PASSWORD_STATE.hidden.type);
  public verifyEmailLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public deleteEmailLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public verifyEmail$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public initialAlternateEmail$: BehaviorSubject<string> = new BehaviorSubject(null);

  private readonly user$: Observable<AuthUser>;
  private readonly close$: Observable<boolean>;

  public readonly isAdminOrSingleUserRole$: Observable<boolean>;
  public readonly getAppendButtonsIcon = getAppendButtonsIcon;
  public readonly moduleAccount = I18_NAMESPACE_MODULE.account;
  public readonly MbsSize = MbsSize;

  public formGroup: FormGroup;

  private onChange: (value: CustomCVAccessor<AlternateAccountTabForm>) => void;
  private onTouched: () => void;

  set settings(settings: AlternateAccountTabForm) {
    this.#settings = settings;
    this.notifyValueChange();
  }

  get settings(): AlternateAccountTabForm {
    return this.#settings;
  }

  get alternateEmailControl(): FormControl<string> {
    return <FormControl>this.formGroup.get('alternateEmail');
  }

  get passwordControl(): FormControl<string> {
    return <FormControl>this.formGroup.get('password');
  }

  get confirmPasswordControl(): FormControl<string> {
    return <FormControl>this.formGroup.get('confirmPassword');
  }

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private i18nPipe: I18NextPipe,
    private modalService: ModalService,
    private myAccountSidepanelService: MyAccountSidepanelService,
    private toastService: ToastService,
    private userService: UserOdataService,
    tabService: TabsService,
    tabsetItem: TabsetItemDirective
  ) {
    super(tabService, tabsetItem);
    this.user$ = this.authService.getAuthUser().pipe(share());
    this.close$ = this.myAccountSidepanelService.sidepanel.close;
    this.isAdminOrSingleUserRole$ = this.authService.getRoles().pipe(map((roles) => hasAdminRole(roles) || hasSingleUserRole(roles)));
  }

  ngOnInit(): void {
    this.initForm();

    this.formGroup.valueChanges
      .pipe(
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
        untilDestroyed(this)
      )
      .subscribe((settings: AlternateAccountTabForm) => {
        this.settings = settings;
        this.valid = this.formGroup.valid;
      });

    this.initialAlternateEmail$.pipe(untilDestroyed(this)).subscribe((alternateEmail) => this.checkExtraControls(alternateEmail));
    this.close$.pipe(untilDestroyed(this)).subscribe(() => this.resetFormGroup());
  }

  private initForm(): void {
    this.formGroup = this.fb.group({
      alternateEmail: [''],
      password: [''],
      confirmPassword: ['']
    });

    this.form = this.formGroup;
  }

  private checkExtraControls(alternateEmail: string): void {
    const action = (condition: boolean) => (condition ? 'disable' : 'enable');
    const options = { emitEvent: false };
    const hasAlternateEmail = Boolean(alternateEmail);

    this.passwordControl[action(hasAlternateEmail)](options);
    this.confirmPasswordControl[action(hasAlternateEmail)](options);
  }

  registerOnChange(fn: (value: CustomCVAccessor<AlternateAccountTabForm>) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(settings: CustomCVAccessor<AlternateAccountTabForm>): void {
    if (!settings) return;

    this.updateFormValue(settings.data);
    this.settings = settings.data;
  }

  notifyValueChange(): void {
    const value: CustomCVAccessor<AlternateAccountTabForm> = {
      valid: this.formGroup.valid,
      data: this.settings
    };

    this.onChange && this.onChange(value);
    this.onTouched && this.onTouched();
  }

  updateFormValue(settings: AlternateAccountTabForm): void {
    this.resetFormGroup();

    if (settings.alternateEmail) this.alternateEmailControl.setValue(settings.alternateEmail, { emitEvent: false });
    if (settings.password) this.passwordControl.setValue(null, { emitEvent: false });
    if (settings.confirmPassword) this.confirmPasswordControl.setValue(null, { emitEvent: false });

    this.updateValidators();
    this.afterFormInit();
  }

  private resetFormGroup(options: { onlySelf?: boolean; emitEvent?: boolean } = { emitEvent: false }): void {
    this.valid = true;
    this.formGroup.reset(this.defaultFormValue(), options);
  }

  private defaultFormValue = (): AlternateAccountTabForm => ({
    alternateEmail: '',
    password: '',
    confirmPassword: ''
  });

  private updateValidators(): void {
    const hasAlternateEmail = this.initialAlternateEmail$.value;

    if (hasAlternateEmail) {
      this.formGroup.clearValidators();
      this.alternateEmailControl.clearValidators();
      this.passwordControl.clearValidators();
      this.confirmPasswordControl.clearValidators();
    } else {
      this.formGroup.setValidators(isConfirmPasswordValidator.bind({}, { password: 'password', confirmPassword: 'confirmPassword' }));
      this.alternateEmailControl.setValidators([Validators.required, Validators.email]);
      this.passwordControl.setValidators([
        Validators.required,
        MbsValidators.createPasswordValidator({ minlength: 7, maxlength: 20, pattern: '\\S+' })
      ]);
      this.confirmPasswordControl.setValidators(Validators.required);
    }

    this.formGroup.updateValueAndValidity();
    this.formGroup.markAsUntouched();
  }

  handleChangeEmail(): void {
    this.user$
      .pipe(
        switchMap((user) => {
          const settings: ModalSettings = {
            size: MbsSize.sm,
            data: user.Id
          };

          return from(this.modalService.openCustom(ChangeEmailModalComponent, settings)).pipe(catchError(() => of(null)));
        }),
        switchMap((result) => {
          if (!result) return of(null);

          this.verifyEmailLoading$.next(true);
          return this.user$.pipe(
            switchMap((user) => {
              return this.userService.getAlternateEmail(user.Id).pipe(
                finalize(() => this.verifyEmailLoading$.next(false)),
                untilDestroyed(this)
              );
            })
          );
        }),
        first()
      )
      .subscribe({
        next: (info) => {
          if (!info) return;

          const toastBody = this.i18nPipe.transform(this.moduleAccount + ':toast.body.changeAlternateEmail', { format: 'capitalize' });
          this.toastService.success(toastBody, this.#toastTitleSuccess);

          this.verifyEmail$.next(info.Verified);
          this.twoStepEnabled$.next(info.TwoStepEnabled);
          this.initialAlternateEmail$.next(info.Email);
          this.changeAlternateEmail.emit();
        },
        error: () => {
          this.verifyEmail$.next(false);
        }
      });
  }

  handleDeleteAlternateAccount(): void {
    const settings: ModalSettings = {
      size: MbsSize.sm,
      data: {
        alternateAccount: this.initialAlternateEmail$.value
      }
    };

    this.modalService
      .openCustom(DeleteAlternateAccountModalComponent, settings)
      .then((result) => {
        if (!result) return;

        this.deleteAlternateEmail.emit();
        this.afterFormInit();

        const toastBody = this.i18nPipe.transform(this.moduleAccount + ':toast.body.deleteAlternateAccount', { format: 'capitalize' });

        this.toastService.success(toastBody, this.#toastTitleSuccess);
      })
      .catch(noop);
  }

  handleEnableTwoFA(): void {
    this.user$
      .pipe(
        switchMap((user) => {
          const settings: ModalSettings = {
            size: MbsSize.sm,
            data: {
              user: {
                id: user.Id,
                email: user.Email
              }
            }
          };

          return from(this.modalService.openCustom(TwoFactorAuthenticationComponent, settings)).pipe(catchError(() => of(null)));
        }),
        tap((result: boolean) => result && this.twoFALoading$.next(true)),
        switchMap((result) => (result ? this.getAlternateEmail() : of(null))),
        finalize(() => this.twoFALoading$.next(false))
      )
      .subscribe({
        next: (info) => {
          if (!info) return;

          const toastBody = this.i18nPipe.transform(this.moduleAccount + ':toast.body.enableTwoFA', { format: 'capitalize' });
          this.toastService.success(toastBody, this.#toastTitleSuccess);

          this.twoStepEnabled$.next(info.TwoStepEnabled);
        }
      });
  }

  handleDisableTwoFA(): void {
    this.user$
      .pipe(
        switchMap((user) => {
          const settings: ModalSettings = {
            size: MbsSize.sm,
            data: { id: user.Id }
          };

          return from(this.modalService.openCustom(TwoFactorDisableComponent, settings)).pipe(catchError(() => of(null)));
        }),
        tap((result: boolean) => result && this.twoFALoading$.next(true)),
        switchMap((result) => (result ? this.getAlternateEmail() : of(null))),
        finalize(() => this.twoFALoading$.next(false))
      )
      .subscribe({
        next: (info) => {
          if (!info) return;

          const toastBody = this.i18nPipe.transform(this.moduleAccount + ':toast.body.disableTwoFA', { format: 'capitalize' });
          this.toastService.success(toastBody, this.#toastTitleSuccess);

          this.twoStepEnabled$.next(info.TwoStepEnabled);
        }
      });
  }

  private getAlternateEmail(): Observable<AlternateEmail> {
    return this.user$.pipe(switchMap((user) => this.userService.getAlternateEmail(user.Id)));
  }

  handleChangePassword(): void {
    this.user$
      .pipe(
        switchMap((user) => {
          const settings: ModalSettings = {
            size: MbsSize.sm,
            data: user.Id
          };

          return from(this.modalService.openCustom(ChangePasswordModalComponent, settings)).pipe(
            tap(() => this.changePasswordLoading$.next(true)),
            map(() => true),
            catchError(() => of(null))
          );
        }),
        finalize(() => this.changePasswordLoading$.next(false)),
        first()
      )
      .subscribe({
        next: (result) => {
          if (!result) return;

          const toastBody = this.i18nPipe.transform(this.moduleAccount + ':toast.body.changeAlternatePassword', { format: 'capitalize' });
          this.toastService.success(toastBody, this.#toastTitleSuccess);
        }
      });
  }

  handleResetPassword(): void {
    const settings: ModalSettings = {
      size: MbsSize.sm
    };

    this.modalService.openCustom(ResetPasswordModalComponent, settings).catch(noop);
  }

  handleChangePasswordType(event: InputButton): void {
    if (!event) return;

    const id = camelCase(event.id.replace('append', ''));
    const subjectById: BehaviorSubject<string> = this[id + 'Type$'];
    const isPasswordType: boolean = hasAppendButtonsPasswordType(subjectById.getValue());

    subjectById.next(APPEND_BUTTON_PASSWORD_STATE[getAppendButtonsState(isPasswordType)].type);
  }
}
