import { APPEND_BUTTON_PASSWORD_STATE } from '@app/common';

export const normalizeWhitespace = (str: string): string => {
  return str.replace(/\s+/g, ' ');
};

export const getAppendButtonsIcon = (value: string): string => {
  return normalizeWhitespace(value) === APPEND_BUTTON_PASSWORD_STATE.hidden.type
    ? APPEND_BUTTON_PASSWORD_STATE.hidden.icon
    : APPEND_BUTTON_PASSWORD_STATE.visible.icon;
};
export const getAppendButtonsState = (condition) => (condition ? 'visible' : 'hidden');
export const hasAppendButtonsPasswordType = (value) => normalizeWhitespace(value) === APPEND_BUTTON_PASSWORD_STATE.hidden.type;
