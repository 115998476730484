import { Directive, forwardRef, Inject } from '@angular/core';
import { cloneDeep, isEqual } from 'lodash';
import { defer, iif, Observable, of } from 'rxjs';
import { ConfirmReason } from './ConfirmReason';
import { DataChangeWatcherService } from './data-change-watcher.service';

export type ObservableObject = {
  obj: any;
  getRawData: (obj: any) => any;
  previousData: any;
};

@Directive()
export abstract class DataChangeWatcherBase {
  abstract isValidSidepanel(): boolean;

  public canDiscardWithChanges = false;
  public detectChanges$: Observable<ConfirmReason>;

  protected changeDetector: DataChangeWatcherService;
  protected observableObjects: Array<ObservableObject> = [];

  protected constructor(@Inject(forwardRef(() => DataChangeWatcherService)) changeDetector: DataChangeWatcherService) {
    this.changeDetector = changeDetector;
    this.changeDetector.attachComponent(this);
    this.detectChanges$ = iif(
      () => this.isChanged && !this.canDiscardWithChanges,
      defer(() => this.changeDetector.showSaveModal(this)),
      of(ConfirmReason.DISCARD)
    );
  }

  protected attachObject<T>(obj: T, getRawData: (obj: T) => any): void {
    this.observableObjects = this.observableObjects.filter((ob) => ob.obj != obj);
    this.observableObjects.push({ obj, getRawData: getRawData, previousData: cloneDeep(getRawData(obj)) });
  }

  protected hold(obj?: any): void {
    if (obj) {
      const find = this.observableObjects.find((ob) => ob.obj == obj);
      find.previousData = find.getRawData(find.obj);
    } else {
      this.observableObjects.forEach((ob) => (ob.previousData = cloneDeep(ob.getRawData(ob.obj)) as unknown));
    }
  }

  get isChanged(): boolean {
    return this.observableObjects?.some((ob) => !isEqual(ob.getRawData(ob.obj), ob.previousData));
  }

  destroy(): void {
    this.changeDetector.deattachComponent(this);
  }
}
