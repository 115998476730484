import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { I18NextModule } from 'angular-i18next';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ButtonModule } from '../button/button.module';
import { LoaderModule } from '../loader/loader.module';
import { SidepanelComponent } from './sidepanel.component';

@NgModule({
  imports: [CommonModule, ButtonModule, LoaderModule, NgScrollbarModule, I18NextModule, NgbTooltipModule],
  exports: [SidepanelComponent],
  declarations: [SidepanelComponent]
})
export class SidepanelModule {}
