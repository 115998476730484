import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '../../button/button.module';
import { InputBaseModule } from '../input-base/input-base.module';
import { InputModule } from '../input/input.module';
import { InputRangeComponent } from './input-range.component';

@NgModule({
  imports: [ReactiveFormsModule, FormsModule, CommonModule, InputBaseModule, ButtonModule, InputModule],
  declarations: [InputRangeComponent],
  exports: [InputRangeComponent]
})
export class InputRangeModule {}
