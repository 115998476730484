import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { InputBaseModule } from '../input-base/input-base.module';
import { CheckboxComponent, CheckboxDescription } from './checkbox.component';

@NgModule({
  declarations: [CheckboxComponent, CheckboxDescription],
  imports: [CommonModule, FormsModule, NgbTooltipModule, InputBaseModule],
  exports: [CheckboxComponent, CheckboxDescription],
  providers: []
})
export class CheckboxModule {
  constructor() {
    // empty
  }
}
