export enum OperationTypeSkippedItemsHistory {
  None = 'None',
  Backup = 'Backup',
  Restore = 'Restore',
  Archive = 'Archive',
  MarkingForDelete = 'Flagging for deletion',
  DeleteItems = 'Deleting items',
  ProcessRetention = 'Retention policy',
  DeleteFromStorage = 'Deleting data',
  Export = 'Export',
  Test = 'Test'
}
