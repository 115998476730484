<mbs-modal [loading]="loading$ | async"
           [title]="moduleAccount + ':modal.title.twoFactorDisable' | i18next: { format: 'title' }">

  <ng-container modal-body
                [formGroup]="formGroup">

    <mbs-form-group class="mb-0">
      <mbs-input formControlName="password"
                 [label]="moduleAccount + ':modal.label.alternateEmailPassword' | i18next: { format: 'title' }"
                 [type]="passwordType$ | async"
                 [appendButtons]="[
                    {
                      id: 'append-password',
                      type: 'secondary',
                      icon: getAppendButtonsIcon(passwordType$ | async),
                      btnType: 'button',
                      loading: false,
                      disabled: false
                    }
                 ]"
                 (buttonClick)="handleChangePasswordType()"
                 mbsAutoFocus
                 autocomplete="off">
      </mbs-input>
    </mbs-form-group>

  </ng-container>

  <ng-container modalFooter>

    <mbs-button (click)="handleDisable()"
                [loading]="disableLoading$ | async"
                [disabled]="formGroup.invalid"
                type="primary">
      {{ moduleAccount + ':modal.button.disable' | i18next: { format: 'title' } }}
    </mbs-button>

    <mbs-button (click)="handleClose()">
      {{ moduleAccount + ':modal.button.cancel' | i18next: { format: 'title' } }}
    </mbs-button>

  </ng-container>

</mbs-modal>

