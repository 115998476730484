import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BOOLEAN_OPERATOR, COMPARISON_OPERATOR, SINGLE_QUOTES_REGEX } from '@app/common';
import { DriveItemDetails, DriveOdataItem, DriveODataOption, RestoreDriveItems, ServiceType } from '@app/common/models';
import { SharedOdataService } from '@app/common/services';
import { DriveBaseTagsEnum, DriveBaseTagsMap } from '@app/common/services/smart-search/drive-base/smart-search-tags-drive-base.model';
import { filterByWords } from '@app/common/utils';
import { containsWrapper } from '@app/common/utils/functions/search';
import { SmartSearchHelper } from '@app/common/utils/helper/smart-search.helper';
import { SmartSearchModel } from 'mbs-ui-kit';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ODataPagedResult, ODataService, ODataServiceFactory } from '@app/common/odata';

export type ICollectionAction = { ServiceType: ServiceType; Password: string; Ids: string[] };
export type ISiteCollectionAction = { Password: string; Ids: string[] };

@Injectable({ providedIn: 'root' })
export class DriveOdataService extends SharedOdataService<DriveOdataItem> {
  private odataMethods: ODataService<any>;
  private readonly odataDriveItems: ODataService<any>;

  readonly #fileName = DriveBaseTagsMap.get(DriveBaseTagsEnum.File);
  readonly #folderName = DriveBaseTagsMap.get(DriveBaseTagsEnum.Folder);
  readonly #name = DriveBaseTagsMap.get(DriveBaseTagsEnum.Name);

  readonly #fileType = `${this.#fileName.prop} ${COMPARISON_OPERATOR.eq} true`;
  readonly #folderType = `${this.#folderName.prop} ${COMPARISON_OPERATOR.eq} false`;

  constructor(odataFactory: ODataServiceFactory) {
    super(odataFactory, 'DriveItems');
    this.odataMethods = odataFactory.CreateService('');
    this.odataDriveItems = odataFactory.CreateService('DriveItems');
  }

  fetchItems(): Observable<ODataPagedResult<DriveOdataItem>> {
    return this.fetchData(this.odataDriveItems);
  }

  getPage(id: string): Observable<number> {
    const newOdata = this.odataFactory.CreateService(`/DriveItems/GetPage`);

    return newOdata
      .Query()
      .Top(this.pageSize)
      .OrderBy(this.orderBy)
      .CustomQueryOptions([
        { key: 'Id', value: id },
        { key: 'TeamDrive', value: this.customQueryOptions.get(DriveODataOption.TeamDrive) }
      ])
      .Exec() as unknown as Observable<number>;
  }

  restoreDriveItems(data: RestoreDriveItems): Observable<RestoreDriveItems> {
    return this.odataMethods
      .CustomCollectionAction('RestoreDriveItems', data)
      .pipe(map((res: HttpResponse<RestoreDriveItems>) => res.body));
  }

  getItemDetails(id: string): Observable<DriveItemDetails> {
    return this.odata.CustomFunction(id, 'GetDetails', null).pipe(map((res: HttpResponse<DriveItemDetails>) => res.body));
  }

  deleteDriveItems(data: ICollectionAction): Observable<ICollectionAction> {
    return this.odataMethods
      .CustomCollectionAction('DeleteDriveItems', { ...data, ServiceType: ServiceType[data.ServiceType] })
      .pipe(map((res: HttpResponse<ICollectionAction>) => res.body));
  }

  deleteSiteBlocks(data: ISiteCollectionAction): Observable<ISiteCollectionAction> {
    return this.odataMethods.CustomCollectionAction('DeleteSiteBlocks', data).pipe(map((res: HttpResponse<ICollectionAction>) => res.body));
  }

  deleteSiteBlockItems(data: ISiteCollectionAction): Observable<ISiteCollectionAction> {
    return this.odataMethods
      .CustomCollectionAction('DeleteSiteBlockItems', data)
      .pipe(map((res: HttpResponse<ICollectionAction>) => res.body));
  }

  updateFilter(searchObj: SmartSearchModel): void {
    const filter: string[] = [];
    const values = SmartSearchHelper.getValues(searchObj);

    if (searchObj[this.#fileName.tag] && !searchObj[this.#folderName.tag]) {
      const value = SmartSearchHelper.getJoinedValue(values, this.#fileName.tag);

      filter.push(`${this.#fileType} ${BOOLEAN_OPERATOR.and} ${containsWrapper(this.#name.prop, value)}`);
    }

    if (searchObj[this.#folderName.tag] && !searchObj[this.#fileName.tag]) {
      const value = SmartSearchHelper.getJoinedValue(values, this.#folderName.tag);

      filter.push(`${this.#folderType} ${BOOLEAN_OPERATOR.and} ${containsWrapper(this.#name.prop, value)}`);
    }

    if (searchObj[this.#fileName.tag] && searchObj[this.#folderName.tag]) {
      const fileValue = SmartSearchHelper.getJoinedValue(values, this.#fileName.tag);
      const folderValue = SmartSearchHelper.getJoinedValue(values, this.#folderName.tag);
      const fileCondition = `${this.#fileType} ${BOOLEAN_OPERATOR.and} ${containsWrapper(this.#name.prop, fileValue)}`;
      const folderCondition = `${this.#folderType} ${BOOLEAN_OPERATOR.and} ${containsWrapper(this.#name.prop, folderValue)}`;

      filter.push(`(${fileCondition}) ${BOOLEAN_OPERATOR.or} (${folderCondition})`);
    }

    if (searchObj.words?.filter(Boolean)) {
      const term = filterByWords(searchObj).replace(SINGLE_QUOTES_REGEX, '');
      const keys = [this.#name.prop];
      const values = keys.map((k) => containsWrapper(k, term));

      filter.push('('.concat(values.join(` ${BOOLEAN_OPERATOR.or} `), ')'));
    }

    this.filter = filter.length > 0 ? filter.join(` ${BOOLEAN_OPERATOR.and} `) : '';
  }

  updateCustomOptions(options: { key: string; value?: any }, isDelete = false): void {
    const { key, value } = options || {};

    if (isDelete) {
      this.removeCustomQueryOption(key);
    } else {
      this.addCustomQueryOption(key, value);
    }
  }
}
