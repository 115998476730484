import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transformTextBy',
  pure: true
})
export class TransformTextByPipe implements PipeTransform {
  transform(value: string, character: string): string {
    return value.length > 0 ? value.replace(/./gm, character) : '';
  }
}
