import { Directive, TemplateRef } from '@angular/core';

/**
 * This directive must be used to wrap content to be displayed in the Tab
 * */
@Directive({
  selector: '[mbsTabsetContent]'
})
export class TabsetContentDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}
