<mbs-modal [title]="'changeAlternatePasswordModal.title.changeAlternatePassword' | i18next: { format: 'title' }">

  <ng-container modal-body>

    <form [formGroup]="changePasswordForm">

      <mbs-form-group>
        <mbs-input [label]="'changeAlternatePasswordModal.label.oldPassword' | i18next: { format: 'title' }"
                   formControlName="Password"
                   [appendButtons]="[
                     {
                       id: 'append-old-password',
                       type: 'secondary',
                       icon: getAppendButtonsIcon(oldPasswordType$ | async),
                       btnType: 'button',
                       loading: false,
                       disabled: false
                     }
                   ]"
                   (buttonClick)="handleChangePasswordType($event)"
                   [type]="oldPasswordType$ | async"></mbs-input>
      </mbs-form-group>

      <mbs-form-group>
        <mbs-input [label]="'changeAlternatePasswordModal.label.newPassword' | i18next: { format: 'title' }"
                   formControlName="NewPassword"
                   [appendButtons]="[
                     {
                       id: 'append-new-password',
                       type: 'secondary',
                       icon: getAppendButtonsIcon(newPasswordType$ | async),
                       btnType: 'button',
                       loading: false,
                       disabled: false
                     }
                   ]"
                   (buttonClick)="handleChangePasswordType($event)"
                   [type]="newPasswordType$ | async"></mbs-input>
      </mbs-form-group>

      <mbs-form-group class="mb-0">
        <mbs-input [label]="'changeAlternatePasswordModal.label.confirmNewPassword' | i18next: { format: 'title' }"
                   formControlName="ConfirmPassword"
                   [appendButtons]="[
                     {
                       id: 'append-confirm-password',
                       type: 'secondary',
                       icon: getAppendButtonsIcon(confirmPasswordType$ | async),
                       btnType: 'button',
                       loading: false,
                       disabled: false
                     }
                   ]"
                   (buttonClick)="handleChangePasswordType($event)"
                   [type]="confirmPasswordType$ | async"></mbs-input>
      </mbs-form-group>

    </form>

  </ng-container>

  <ng-container modalFooter>

    <mbs-button type="primary"
                [loading]="resetLoading$ | async"
                [disabled]="changePasswordForm.invalid"
                (click)="changeButtonClick()">
      {{ 'changeAlternatePasswordModal.button.change' | i18next: { format: 'title' } }}
    </mbs-button>

    <mbs-button (click)="cancelButtonClick()">
      {{ 'changeAlternatePasswordModal.button.cancel' | i18next: { format: 'title' } }}
    </mbs-button>

  </ng-container>

</mbs-modal>
