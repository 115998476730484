<div [ngClass]="{'-loading': loading, '-selected-rows': isNeedSelectCard}"
     [ngStyle]="{'min-height': minHeight,  'max-height': maxHeight}"
     class="row mbs-tiles">
  <!-- TODO: Add status classnames to .mbs-tile. Possibility values: .-primary (default, everything's ok), .-dangerous, .-warning, .-secondary (offline computer) -->
  <app-loader *ngIf="loading"
              [type]="loaderType"></app-loader>
  <ng-container *ngFor="let item of (data || []); let cardIndex = index; trackBy:myTrackBy">
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2 mb-4">
      <mbs-tile (click)="cardClickHandler($event, cardIndex)"
                [headers]="gridHeaders"
                [headerTemplate]="headerTemplate"
                [data]="item"
                [selectable]="isNeedSelectCard"
                [selectedClass]="selectCardClass"
                [selected]="checkCardInSelected(item)"
                [templates]="templates"
                [statusClass]="[item.statusClass || '',
                  (multipleSelect && checkCardInSelected(item) && selectCardClass)
                  || (selectedCardIndex === cardIndex && selectCardClass) || '']"
                [tileTemplate]="tileTemplate">
      </mbs-tile>
    </div>
  </ng-container>
  <ng-container *ngIf="!loading && (!data || data.length === 0)">
    <ng-container *ngTemplateOutlet="noDataIsTemplate() ? noDataMessage : noDataDefault"></ng-container>
  </ng-container>
</div>

<ng-template #noDataDefault>
  <div class="col">
    <div class="mbs-table-grid_cell flex-grow-1 text-center text-sm border"
         role="cell">
      {{ noDataMessage || noDataMessageDefault }}
    </div>
  </div>
</ng-template>
