import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MbsSize } from '../utils';

export type FormSectionSize = MbsSize.sm | MbsSize.lg;
export type FormSectionTitleSize = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

@Component({
  selector: 'mbs-form-section',
  templateUrl: 'form-section.component.html',
  host: {
    '[class]': '"mbs-form-section" + getClassListForSection'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormSectionComponent {
  /**
   * Title text for form section
   */
  @Input() title: string;

  /**
   * Custom classes for form section title
   */
  @Input() titleClasses: string | string[];

  /**
   * Will set icon(s) to left `form-section`
   */
  @Input() icon: string;

  /**
   * Implements one of form section size<br />
   * Possible values: 'MbsSize.sm' | 'MbsSize.lg'<br />
   */
  @Input() size: FormSectionSize;

  get getClassListForSection(): string {
    return this.size ? ` -${this.size}` : '';
  }

  /**
   * Implements one of title size<br />
   * Possible values: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'<br />
   */
  @Input() titleSize: FormSectionTitleSize = 'h5';
}
