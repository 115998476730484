import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthService } from '@app/common/services';

@Component({
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotFoundComponent {
  get prefix(): string {
    return this.authService?.prefix;
  }

  constructor(private authService: AuthService) {}
}
