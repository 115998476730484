import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputBaseModule } from '../input-base/input-base.module';
import { RadioComponent, RadioDescription } from './radio.component';

@NgModule({
  declarations: [RadioComponent, RadioDescription],
  imports: [CommonModule, FormsModule, InputBaseModule],
  exports: [RadioComponent, RadioDescription],
  providers: []
})
export class RadioModule {
  constructor() {
    // empty
  }
}
