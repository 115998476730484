import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BOOLEAN_OPERATOR, COMPARISON_OPERATOR, FilterActionType, SINGLE_QUOTES_REGEX } from '@app/common';
import {
  AuthUser,
  BackupReport,
  ExportToCSVParams,
  hasAdminRole,
  hasSingleUserRole,
  ReportSendingOptions,
  Role,
  UserReport,
  UserReportsSummaryOData
} from '@app/common/models';
import { ODataPagedResult, ODataService, ODataServiceFactory } from '@app/common/odata';
import { AuthService, SharedOdataService } from '@app/common/services';
import { ReportsUserTagsEnum, ReportsUserTagsMap } from '@app/common/services/smart-search';
import { filterByWords } from '@app/common/utils';
import { containsWrapper, FilterOptions, getFilterByContains, getFilterByDate } from '@app/common/utils/functions/search';
import { cloneDeep, isNil } from 'lodash';
import { SmartSearchModel, SmartSearchModelField } from 'mbs-ui-kit';
import { forkJoin, Observable, of } from 'rxjs';
import { filter, map, pluck, shareReplay, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ReportsService extends SharedOdataService<any> {
  private readonly me$: Observable<[user: AuthUser, roles: Role[]]>;
  private readonly user$: Observable<AuthUser>;
  private readonly roles$: Observable<Role[]>;
  private readonly odataMethods: ODataService<any>;

  readonly #userName = ReportsUserTagsMap.get(ReportsUserTagsEnum.userName);
  readonly #license = ReportsUserTagsMap.get(ReportsUserTagsEnum.license);
  readonly #userState = ReportsUserTagsMap.get(ReportsUserTagsEnum.userState);

  constructor(odataFactory: ODataServiceFactory, private http: HttpClient, private authService: AuthService) {
    super(odataFactory, 'Reports');
    this.odataMethods = odataFactory.CreateService('');
    this.user$ = authService.getAuthUser();
    this.roles$ = authService.getRoles();
    this.me$ = forkJoin([this.user$, this.roles$]).pipe(
      filter(([user, roles]) => !isNil(user) && !isNil(roles)),
      shareReplay(1)
    );
  }

  backupReports(): Observable<BackupReport> {
    return this.me$.pipe(
      switchMap(([user, roles]) => (hasAdminRole(roles) ? this.domainBackupReport(user.DomainId) : this.userBackupReport(user.Id)))
    );
  }

  private domainBackupReport(id: string): Observable<BackupReport> {
    const odataService = this.odataFactory.CreateService<BackupReport>(`/Domains(${id})/BackupReport`);

    return this.http.get<BackupReport>(odataService.Query().GetUrl());
  }

  private userBackupReport(id: string): Observable<BackupReport> {
    const odataService = this.odataFactory.CreateService<BackupReport>(`/SingleUsers(${id})/BackupReport`);

    return this.http.get<BackupReport>(odataService.Query().GetUrl());
  }

  userReports(): Observable<ODataPagedResult<UserReport>> {
    return this.me$.pipe(
      switchMap(([user, roles]) => {
        const id = hasAdminRole(roles) ? user.DomainId : user.Id;

        return hasAdminRole(roles) ? this.domainUserReport(id) : this.singleUserReport(id);
      }),
      switchMap((oDataUserReports: ODataPagedResult<UserReport>) => {
        const payload = {
          UserIds: oDataUserReports.data.map((user) => user.UserId)
        };

        return forkJoin([this.userReportLicences(payload), of(oDataUserReports)]);
      }),
      map(([licenses, oDataUserReports]) => {
        const clone = cloneDeep(oDataUserReports);

        clone.data = clone.data.map((item) => {
          const license = licenses.find((l) => l.UserId === item.UserId);

          return { ...item, ...license };
        });

        return clone;
      })
    );
  }

  private domainUserReport(id: string): Observable<ODataPagedResult<UserReport>> {
    const odataService = this.odataFactory.CreateService<UserReport>(`/Domains(${id})/UserReports`);

    return this.fetchData<UserReport>(odataService);
  }

  private singleUserReport(id: string): Observable<ODataPagedResult<UserReport>> {
    const odataService = this.odataFactory.CreateService<UserReport>(`/SingleUsers(${id})/UserReports`);

    return this.fetchData<UserReport>(odataService);
  }

  getReportSendingOptions(): Observable<ReportSendingOptions> {
    return this.me$.pipe(switchMap(([user, roles]) => this.reportSendingOptions(hasAdminRole(roles) ? user.DomainId : user.Id)));
  }

  setReportSendingOptions(payload: ReportSendingOptions): Observable<ReportSendingOptions> {
    return this.me$.pipe(switchMap(([user, roles]) => this.reportSendingOptions(hasAdminRole(roles) ? user.DomainId : user.Id, payload)));
  }

  private reportSendingOptions(id: string, payload?: ReportSendingOptions): Observable<ReportSendingOptions> {
    const odataService = this.odataFactory.CreateService<ReportSendingOptions>(`/ReportSendingOptions(${id})`);

    if (payload) {
      payload.Id = id;

      return this.http.put<ReportSendingOptions>(odataService.Query().GetUrl(), payload);
    } else {
      return this.http.get<ReportSendingOptions>(odataService.Query().GetUrl());
    }
  }

  getUserReportsSummary(): Observable<UserReportsSummaryOData> {
    return this.me$.pipe(
      switchMap(([user, roles]) => (hasAdminRole(roles) ? this.domainSummary(user.DomainId) : this.singleUserSummary(user.Id)))
    );
  }

  private domainSummary(id: string): Observable<UserReportsSummaryOData> {
    const odataService = this.odataFactory.CreateService<UserReportsSummaryOData>(`/Domains(${id})/UserReportsSummary`);

    return this.http.get<UserReportsSummaryOData>(odataService.Query().GetUrl());
  }

  private singleUserSummary(id: string): Observable<UserReportsSummaryOData> {
    const odataService = this.odataFactory.CreateService<UserReportsSummaryOData>(`/SingleUsers(${id})/UserReportsSummary`);

    return this.http.get<UserReportsSummaryOData>(odataService.Query().GetUrl());
  }

  exportToCSV(report: 'backup' | 'user', params: ExportToCSVParams): Observable<Blob> {
    return this.me$.pipe(
      switchMap(([user, roles]) => {
        const defaultOptions = { report, params };

        return hasAdminRole(roles)
          ? this.domainExportToCSV(Object.assign(defaultOptions, { id: user.DomainId }))
          : this.singleUserExportToCSV(Object.assign(defaultOptions, { id: user.Id }));
      })
    );
  }

  private domainExportToCSV(options: { id: string; report: 'backup' | 'user'; params: ExportToCSVParams }): Observable<Blob> {
    const { id, report, params } = options;
    const paramsString = Object.entries(params)
      .map((key) => key.join('='))
      .join('&');
    let odataService: ODataService<Blob>;

    if (report === 'backup') {
      odataService = this.odataFactory.CreateService<Blob>(`/Domains(${id})/DownloadBackupReport?${paramsString}`);
    }

    if (report === 'user') {
      odataService = this.odataFactory.CreateService<Blob>(`/Domains(${id})/DownloadUserReports?${paramsString}`);
    }

    return this.http.get(odataService.Query().GetUrl(), { responseType: 'blob' });
  }

  private singleUserExportToCSV(options: { id: string; report: 'backup' | 'user'; params: ExportToCSVParams }): Observable<Blob> {
    const { id, report, params } = options;
    const paramsString = Object.entries(params)
      .map((key) => key.join('='))
      .join('&');
    let odataService: ODataService<Blob>;

    if (report === 'backup') {
      odataService = this.odataFactory.CreateService(`/SingleUsers(${id})/DownloadBackupReport?${paramsString}`);
    }

    if (report === 'user') {
      odataService = this.odataFactory.CreateService(`/SingleUsers(${id})/DownloadUserReports?${paramsString}`);
    }

    return this.http.get(odataService.Query().GetUrl(), { responseType: 'blob' });
  }

  updateFilter(searchObj: SmartSearchModel): void {
    const filter: string[] = [];
    const isResetButtonClick = Object.keys(searchObj).length === 0;

    if (searchObj[this.#userName.tag]) {
      const options: FilterOptions = {
        model: searchObj[this.#userName.tag] as SmartSearchModelField[],
        prop: this.#userName.prop
      };

      filter.push(getFilterByContains(options));
    }

    if (searchObj[this.#license.tag]) {
      const options: FilterOptions = {
        model: searchObj[this.#license.tag] as SmartSearchModelField[],
        prop: this.#license.prop
      };

      filter.push(getFilterByDate(options));
    }

    if (searchObj[this.#userState.tag]) {
      const userState = searchObj[this.#userState.tag][0];

      switch (userState.value) {
        case FilterActionType.containsIssue:
          filter.push(`${this.#userState.prop} ${COMPARISON_OPERATOR.eq} true`);
          filter.push(`ContainsIssue ${COMPARISON_OPERATOR.eq} true`);
          break;
        case FilterActionType.enabledUsers:
          filter.push(`${this.#userState.prop} ${COMPARISON_OPERATOR.eq} true`);
          break;
        case FilterActionType.disabledUsers:
          filter.push(`${this.#userState.prop} ${COMPARISON_OPERATOR.eq} false`);
          break;
      }
    }

    if (searchObj.words?.filter(Boolean)) {
      const term = filterByWords(searchObj).replace(SINGLE_QUOTES_REGEX, '');

      filter.push(containsWrapper(this.#userName.prop, term));
    }

    if (isResetButtonClick) {
      filter.push(`${this.#userState.prop} ${COMPARISON_OPERATOR.eq} true`);
    }

    this.filter = filter.length > 0 ? filter.join(` ${BOOLEAN_OPERATOR.and} `) : '';
  }

  userReportLicences(payload: { UserIds: string[] }): Observable<UserReportLicence[]> {
    return this.me$.pipe(
      switchMap(([user, roles]) =>
        hasSingleUserRole(roles) ? this.singleUserReportLicences(user.Id, payload) : this.domainReportLicences(user.DomainId, payload)
      )
    );
  }

  private domainReportLicences(domainId: string, payload: { UserIds: string[] }): Observable<UserReportLicence[]> {
    const odataService = this.odataFactory.CreateService(`/Domains(${domainId})/UserLicenses`);

    return this.http.post(odataService.Query().GetUrl(), payload).pipe(pluck('value')) as Observable<UserReportLicence[]>;
  }

  private singleUserReportLicences(userId: string, payload: { UserIds: string[] }): Observable<UserReportLicence[]> {
    const odataService = this.odataFactory.CreateService(`/SingleUsers(${userId})/UserLicenses`);

    return this.http.post(odataService.Query().GetUrl(), payload).pipe(pluck('value')) as Observable<UserReportLicence[]>;
  }
}

interface UserReportLicence {
  ExpireDate: string;
  IsLicense: boolean;
  IsTrial: boolean;
  UserId: string;
}
