import { Pipe, PipeTransform } from '@angular/core';
import { isNumber } from 'lodash';

@Pipe({
  name: 'priceToDisplay',
  pure: true
})
export class PriceToDisplayPipe implements PipeTransform {
  transform(value: number, float: number | string = 100): number {
    return value / (isNumber(float) ? float : Number(float));
  }
}
