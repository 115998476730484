import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbDate, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { MbsSize } from 'libs/mbs-ui-kit/utils/enums/mbs-size-enum';
import { DateFormat } from '../../utils/enums/date-enum';
import { KeyEventEnum } from './../../utils/enums/keys';
import { FocusedInput } from './enums';
import { Period } from './type';
import { getDateWithOffset, getPreviousMonth } from './utils';

@Component({
  selector: 'mbs-datepicker-range',
  templateUrl: './datepicker-range.component.html'
})
export class DatepickerRangeComponent implements OnInit {
  @Input() public placement: string | string[] = 'bottom-right';
  @Input() public disabled: boolean;
  @Input() public start: Date;
  @Input() public end: Date;
  @Input() public mask = DateFormat.mediumDate;

  @Input() public periods: Array<Period> = [
    {
      title: 'Today',
      start: new Date(),
      end: new Date()
    },
    {
      title: 'Last Week',
      start: getDateWithOffset(-7),
      end: new Date()
    },
    {
      title: 'Last Month',
      start: getPreviousMonth(),
      end: new Date()
    }
  ];

  @Output() public rangeSelected = new EventEmitter<{ start: Date; end: Date }>();

  @ViewChild('dropdown', { static: false, read: NgbDropdown }) dropdown: NgbDropdown;

  public hoveredDate: NgbDate | null = null;

  public FocusedInput = FocusedInput;
  public MbsSize = MbsSize;
  public focusedInput = FocusedInput.NONE;

  public calendarPage: NgbDate;

  public isValueChangedByDatepicker = true;

  public get today(): Date {
    return new Date();
  }

  public get prevMonthDate(): Date {
    const date = new Date(new Date().getTime());
    return new Date(date.setDate(0));
  }

  public elementSelectors = {
    id: {
      currentlyDate: 'currently-date',
      chosenPeriod: 'chosen-period',
      startDateInput: 'start-date-input',
      endDateInput: 'end-date-input',
      startDateCalendar: 'start-date-calendar',
      endDateCalendar: 'end-date-calendar'
    }
  };

  ngOnInit(): void {
    this.setPages();
  }

  public selectPeriod(period: Period) {
    this.start = period.start && this.getDate(period.start);
    this.end = period.end && this.getDate(period.end);

    this.emitRange();
  }

  public onEnter(event, isFirstCalendar = true) {
    const target = event.target;

    if (event.key !== KeyEventEnum.Enter) return;
    if (this.isInvalidDate(target.value)) {
      target.value = null;
      return;
    }

    this.onInputChange(event, isFirstCalendar);

    if (this.start && this.end) {
      this.dropdown.close();
    } else {
      this.toggleFocus();
    }

    target.blur();
  }

  private isInvalidDate(date: string | Date) {
    return isNaN(new Date(date) as any);
  }

  public onInputChange(event, isStart = true): void {
    const value = event.target.value || null;

    if (this.isInvalidDate(value)) {
      event.target.value = null;
      return;
    }

    const isEqual = new Date(value)?.getTime() === (isStart ? this.start : this.end)?.getTime();

    if (this.isValueChangedByDatepicker || isEqual) {
      return;
    }

    this.setValue(value, isStart);

    this.emitRange();

    const page = this.getNgbDate(new Date(value));

    if (value && !this.isEqualPages(page, this.calendarPage)) {
      this.setCalendarPage(page);
    }

    this.isValueChangedByDatepicker = false;
  }

  public isRange(date: NgbDate): boolean {
    return Boolean(date?.equals(this.getNgbDate(this.start)) || date?.equals(this.getNgbDate(this.end)));
  }

  public isInside(date: NgbDate): boolean {
    if (!this.start) {
      return false;
    }

    return (
      date.after(this.getNgbDate(this.start)) && ((date.before(this.hoveredDate) && !this.end) || date.before(this.getNgbDate(this.end)))
    );
  }

  public onPicked(date: { from: NgbDate; to: NgbDate }): void {
    this.start = date.from && this.getDate(date.from);
    this.end = date.to && this.getDate(date.to);

    this.isValueChangedByDatepicker = true;
    this.emitRange();
  }

  public emitRange() {
    this.rangeSelected.emit({
      start: this.start || null,
      end: this.end || null
    });
  }

  private isEqualPages(firstPage: NgbDate, secondPage: NgbDate) {
    return firstPage.year === secondPage.year && firstPage.month === secondPage.month;
  }

  private setPages(): void {
    this.calendarPage = this.getNgbDate(this.prevMonthDate);
  }

  private toggleFocus(): void {
    this.focusedInput = this.focusedInput === FocusedInput.START ? FocusedInput.END : FocusedInput.START;
  }

  private getNgbDate(date: Date | string): NgbDate {
    const preparedDate = new Date(date);

    return new NgbDate(preparedDate.getFullYear(), preparedDate.getMonth() + 1, preparedDate.getDate());
  }

  private getDate(date: NgbDate | Date) {
    return date instanceof NgbDate
      ? new Date(date.year, date.month - 1, date.day)
      : new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  private setValue(value: Date, isStart: boolean): void {
    const date = value ? new Date(value) : null;

    isStart ? (this.start = date) : (this.end = date);

    if (this.start && this.end && this.start.getTime() > this.end.getTime()) {
      isStart ? (this.end = null) : (this.start = null);
    }
  }

  private setCalendarPage(page: NgbDate): void {
    this.calendarPage = page;
  }
}
