export interface SystemInfo {
  AppsCblMode: boolean;
  AssemblyConfiguration: string;
  AssemblyVersion: string;
  CloudBerryMode: boolean;
  Configuration: string;
  GAA: boolean;
  FaviconUrl: string;
  IsImmutability: boolean;
  LogoUrl: string;
  ProductMode: ProductMode;
  SiteInBackup: boolean | null; // we get `null` when backup for SP is disabled
  StorageRegion: string;
  StorageState: StorageStateEnum;
}

export enum StorageStateEnum {
  ok = 'OK',
  notSet = 'NotSet',
  connectionError = 'ConnectionError',
  unknown = 'Unknown'
}

export enum ProductMode {
  mbs = 'Mbs',
  resale = 'Resale'
}
