import { Component, Input } from '@angular/core';
import { OperationType, ServiceType, TasksStateResponse, User } from '@app/common/models';
import { UserOdataService } from '@app/common/services';
import { I18_NAMESPACE_MODULE } from '@app/i18';
import { I18NextPipe } from 'angular-i18next';
import { DateFormat, StatusIconType } from 'mbs-ui-kit';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-user-information-control',
  templateUrl: './user-information-control.component.html'
})
export class UserInformationControlComponent {
  #user = {
    LastBackupDate: '',
    TotalSize: 0
  } as User;

  public readonly DateFormat = DateFormat;
  public readonly moduleUsers = I18_NAMESPACE_MODULE.users;
  public readonly StatusIconType = StatusIconType;

  constructor(private i18nPipe: I18NextPipe, private userService: UserOdataService) {}

  @Input() set user(u: User) {
    if (u && Object.keys(u).length > 0) {
      // TODO: probably this is condition has problem.
      // error-handler.service.ts:21 TypeError: Cannot convert undefined or null to object
      // It happens after quickly unchecking users until leave only 1 checked
      this.loading = true;
      this.tasksState = null;
      this.#user = u;
      this.userService
        .getTasksState(u.Id)
        .pipe(finalize(() => (this.loading = false)))
        .subscribe({
          next: (t) => (this.tasksState = t)
        });
    }
  }

  get user() {
    return this.#user;
  }

  public ServiceTypeEnum = ServiceType;
  public tasksState: TasksStateResponse;

  public loading = false;

  getBackupPercentage(type: ServiceType) {
    if (!this.tasksState) {
      return '';
    }

    const s = this.tasksState.TasksStatistic.find((t) => t.ServiceType == type);

    if (!s) {
      return '';
    }

    const p = (s.ItemsCountByStates['Finished'] / s.TotalItemsCount) * 100;

    return `<span class="loader loader-xs loader-primary mr-1"></span>${this.operationTypeDisplayNames(s.OperationType)}...${p}`;
  }

  operationTypeDisplayNames(o: OperationType) {
    switch (o) {
      case OperationType.Backup:
        return this.i18nPipe.transform(this.moduleUsers + ':sidepanel.label.backup', { format: 'title' });
      case OperationType.Restore:
        return this.i18nPipe.transform(this.moduleUsers + ':sidepanel.label.restore', { format: 'title' });
      case OperationType.Archive:
        return this.i18nPipe.transform(this.moduleUsers + ':sidepanel.label.archive', { format: 'title' });
      case OperationType.MarkingForDelete:
        return this.i18nPipe.transform(this.moduleUsers + ':sidepanel.label.flaggingForDeletion', { format: 'title' });
      case OperationType.DeleteItems:
        return this.i18nPipe.transform(this.moduleUsers + ':sidepanel.label.deletingItems', { format: 'title' });
      case OperationType.ProcessRetention:
        return this.i18nPipe.transform(this.moduleUsers + ':sidepanel.label.retentionPolicy', { format: 'title' });
      case OperationType.DeleteFromStorage:
        return this.i18nPipe.transform(this.moduleUsers + ':sidepanel.label.deletingData', { format: 'title' });
      case OperationType.Sync:
        return this.i18nPipe.transform(this.moduleUsers + ':sidepanel.label.sync', { format: 'title' });
      case OperationType.Export:
        return this.i18nPipe.transform(this.moduleUsers + ':sidepanel.label.creatingPST', { format: 'title' });
      case OperationType.ClearCustomer:
        return this.i18nPipe.transform(this.moduleUsers + ':sidepanel.label.clearingCustomerData', { format: 'title' });
      case OperationType.DeleteUser:
        return this.i18nPipe.transform(this.moduleUsers + ':sidepanel.label.deletingUser', { format: 'title' });
      default:
        return '';
    }
  }
}
