import { Renderer2 } from '@angular/core';
import { FIELD_CLASS, PLAIN_CLASS, SMART_SEARCH_CLASSES, TAG_CLASS } from './constants';
import { SmartSearchHashtag, SmartSearchKeywordType, SmartSearchTag, SmartSearchWord } from './models';

export class HighlightHelper {
  constructor(private renderer2: Renderer2) {}

  /**
   * Add a class to range of elements
   * @param {Element[]} range
   * @param {string} classValue
   */
  public addClassInRange(range: Element[], classValue: string): void {
    range.forEach((el) => {
      if (el) this.renderer2.addClass(el, classValue);
    });
  }

  /**
   * Remove a class to range of elements
   * @param {Element[]} range
   * @param {string} classValue
   */
  public removeClassInRange(range: Element[], classValue: string): void {
    range.forEach((el) => {
      if (el) this.renderer2.removeClass(el, classValue);
    });
  }

  public removeSmartSearchClassesInRange(range: Element[]): void {
    range.forEach((el) => {
      SMART_SEARCH_CLASSES.forEach((classValue) => {
        if (el) this.renderer2.removeClass(el, classValue);
      });
    });
  }

  /* eslint-disable no-case-declarations */
  public highlightNodes(models: SmartSearchKeywordType[] = []): void {
    for (const model of models) {
      switch (model.type) {
        case 'hashtag':
          const h = model as SmartSearchHashtag;
          this.addClassInRange(h.elements, TAG_CLASS);
          break;
        case 'tag':
          const t = model as SmartSearchTag;
          this.addClassInRange(t.tagElements, TAG_CLASS);
          t.fields.forEach((field) => this.addClassInRange(field.fieldElements, FIELD_CLASS));
          break;
        case 'word':
          const w = model as SmartSearchWord;
          this.addClassInRange(w.elements, PLAIN_CLASS);
          break;
      }
    }
  }
  /* eslint-enable no-case-declarations */

  public highlightWords(models: SmartSearchKeywordType[] = []): void {
    models.forEach((model) => {
      if (model.type === 'word') {
        this.addClassInRange(model.elements, PLAIN_CLASS);
      }
    });
  }

  /**
   * Replace style class to new in range
   * @param {Element[]} range source elements
   * @param {string} classValue source class
   */
  public replaceClassInRange(range: Element[], classValue: string): void {
    range.forEach((el) => {
      el.className = classValue;
    });
  }
}
