import { Injectable, TemplateRef } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MbsPopupType } from '../utils';
import Toast from './toast.model';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private subject = new Subject<Toast>();
  private keepAfterRouteChange = false;

  constructor(private router: Router) {
    // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterRouteChange) {
          this.keepAfterRouteChange = false;
        } else {
          // TODO: need refactored. Create empty toast with default setting
          // this.clearById();
        }
      }
    });
  }

  /**
   * Subscribe to toasts. Get toast by `Id`
   * @param {string} id
   * @return {Observable<any>}
   */
  getById(id?: string): Observable<any> {
    return this.subject.pipe(filter((x: Toast) => x && x.id === id));
  }

  /**
   * Alias for calling Toast by type: `MbsPopupType.success`
   * @default text for `content` is "Success"
   *
   * @param {string} message
   * @param {string} title
   */
  success(message?: string | TemplateRef<any>, title?: string): void {
    this.toast(
      new Toast({
        header: title,
        icon: true,
        content: message || 'Success',
        type: MbsPopupType.success
      })
    );
  }

  /**
   * Alias for calling Toast by type: `MbsPopupType.danger`
   * @default text for `content` is "Sorry, something went wrong"
   *
   * @param {string} message
   * @param {string} title
   */
  error(message?: string | TemplateRef<any>, title?: string): void {
    this.toast(
      new Toast({
        header: title,
        icon: true,
        content: message || 'Sorry, something went wrong',
        type: MbsPopupType.danger
      })
    );
  }

  /**
   * Alias for calling Toast by type: `MbsPopupType.info`
   *
   * @param {string} message
   * @param {string} title
   */
  info(message: string | TemplateRef<any>, title?: string): void {
    this.toast(
      new Toast({
        header: title,
        icon: true,
        content: message,
        type: MbsPopupType.info
      })
    );
  }

  /**
   * alias for calling Toast by type: `MbsPopupType.warning`
   *
   * @param {string} message
   * @param {string} title
   */
  warn(message: string | TemplateRef<any>, title?: string): void {
    this.toast(
      new Toast({
        header: title,
        icon: true,
        content: message,
        type: MbsPopupType.warning
      })
    );
  }

  /**
   * Method for calling custom Toast
   *
   * @param {Toast} toast
   */
  toast(toast: Toast): void {
    this.keepAfterRouteChange = toast.keepAfterRouteChange;
    this.subject.next(toast);
  }

  /**
   * Remove toast by id
   * @param {string} id
   */
  clearById(id?: string): void {
    this.subject.next(new Toast({ id }));
  }
}
