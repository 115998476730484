import { Pipe, PipeTransform } from '@angular/core';
import { UserActionType } from '@app/common/models';

@Pipe({
  name: 'userNameType'
})
export class UserActionTypePipe implements PipeTransform {
  transform(key: string): string {
    return UserActionType[key] ? UserActionType[key].displayName : '';
  }
}
