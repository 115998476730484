<mbs-alert *ngFor="let alert of visibleAlerts; let last = last"
           [class.mb-0]="last"
           [autoClose]="alert.autoClose"
           [type]="alert.type"
           [closable]="true"
           [icon]="true"
           [content]="alert.content"
           [context]="alert"
           (close)="removeAlert($event)">
</mbs-alert>
