import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ButtonDropdownDirective } from './button-dropdown.directive';
import { ButtonGroupComponent } from './button-group/button-group.component';
import { ButtonComponent } from './button.component';

@NgModule({
  declarations: [ButtonComponent, ButtonGroupComponent, ButtonDropdownDirective],
  exports: [ButtonComponent, ButtonGroupComponent, ButtonDropdownDirective],
  imports: [CommonModule, NgbDropdownModule, NgbTooltipModule]
})
export class ButtonModule {}
