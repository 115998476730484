import { PieDataItem } from '@app/common/models';
import { PieChartData, toFixed } from '@app/common/utils';

export function preparePieDataAndLabels(pieData: PieDataItem[]): PieChartData {
  const labelsCount: string[] = [];
  const labelsSize: string[] = [];
  const dataCount: number[] = [];
  const dataSize: number[] = [];

  pieData.forEach((item) => {
    if (item.Count > 0) {
      labelsCount.push(item.Name);
      dataCount.push(item.Count);
    }

    if (item.Size > 0) {
      labelsSize.push(item.Name);
      dataSize.push(toFixed(item.Size));
    }
  });

  return {
    count: {
      label: labelsCount,
      data: dataCount
    },
    size: {
      label: labelsSize,
      data: dataSize
    }
  };
}
