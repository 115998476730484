import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BOOLEAN_OPERATOR, COMPARISON_OPERATOR, SINGLE_QUOTES_REGEX } from '@app/common';
import { ContactDetails, ContactFolderODataItem, ContactODataItem } from '@app/common/models';
import { ODataPagedResult, ODataService, ODataServiceFactory } from '@app/common/odata';
import { AuthService, SharedOdataService } from '@app/common/services';
import { ContactsServiceTag, ContactsServiceTagsMap } from '@app/common/services/smart-search/contacts';
import { getOdataTop } from '@app/common/utils';
import { containsWrapper, filterByWords, FilterOptions, getFilterByContains } from '@app/common/utils/functions/search';
import { I18_NAMESPACE_MODULE } from '@app/i18';
import { I18NextPipe } from 'angular-i18next';
import { SmartSearchModel, SmartSearchModelField } from 'mbs-ui-kit';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContactsService extends SharedOdataService<ContactODataItem> {
  #groupId: string;

  public readonly moduleContacts = I18_NAMESPACE_MODULE.contacts;

  readonly #firstName = ContactsServiceTagsMap.get(ContactsServiceTag.FirstName);
  readonly #lastName = ContactsServiceTagsMap.get(ContactsServiceTag.LastName);
  readonly #primaryEmail = ContactsServiceTagsMap.get(ContactsServiceTag.PrimaryEmail);
  readonly #phoneNumber = ContactsServiceTagsMap.get(ContactsServiceTag.PhoneNumber);

  private odataMethods: ODataService<any>;
  private backupMethods: ODataService<ContactFolderODataItem>;

  set groupId(id: string) {
    this.#groupId = id;
  }

  get groupId(): string {
    return this.#groupId;
  }

  constructor(odataFactory: ODataServiceFactory, private authService: AuthService, private i18nPipe: I18NextPipe) {
    super(odataFactory, 'ContactItems');

    this.odataMethods = odataFactory.CreateService('');
    this.backupMethods = odataFactory.CreateService('BackupUsers');
  }

  public getContactsItemsByGroupId(id: string): Observable<ODataPagedResult<ContactODataItem>> {
    const newOdata = this.odataFactory.CreateService<ContactODataItem>(`/ContactGroups(${id})/ContactItems`);

    return newOdata
      .Query()
      .OrderBy(this.state.orderBy)
      .Filter(this.filter.length > 0 ? this.filter.concat(' and IsLast eq true') : 'IsLast eq true')
      .Top(this.state.pageSize)
      .Skip(this.state.pageSize * (this.state.page - 1))
      .ExecWithCount();
  }

  public getContactVersions(groupId: string, providerId: string, version: number): Observable<ODataPagedResult<ContactODataItem>> {
    const newOdata = this.odataFactory.CreateService<ContactODataItem>(`/ContactGroups(${groupId})/ContactItems`);

    return newOdata.Query().OrderBy('Version desc').Filter(`(ProviderId eq '${providerId}' and Version ne ${version})`).ExecWithCount();
  }

  public getContactDetails(id: string): Observable<ContactDetails> {
    return this.odata.CustomFunction(id, 'GetDetails').pipe(map((res: HttpResponse<ContactDetails>) => res.body));
  }

  public getContactsFolders(
    userId: string,
    options?: { inbox?: boolean; search?: string; skip?: number }
  ): Observable<ODataPagedResult<ContactFolderODataItem>> {
    const { inbox, search, skip } = options || {};
    const defaultFolderName = this.authService.isOffice
      ? this.i18nPipe.transform(this.moduleContacts + ':contacts', { format: 'title' })
      : this.i18nPipe.transform(this.moduleContacts + ':myContacts', { format: 'title' });
    const filter = inbox
      ? `Name ${COMPARISON_OPERATOR.eq} '${defaultFolderName}'`
      : `Name ${COMPARISON_OPERATOR.ne} '${defaultFolderName}'`;

    return this.odataFactory
      .CreateService<ContactFolderODataItem>(`/BackupUsers(${userId})/ContactGroups`)
      .Query()
      .OrderBy(inbox ? null : 'Name asc')
      .Top(getOdataTop(search || inbox))
      .Skip(skip)
      .Filter(search ? containsWrapper('Name', search) : filter)
      .ExecWithCount();
  }

  public restoreContacts(CategoryId: string, Ids: string[]): Observable<any> {
    return this.odataMethods.CustomCollectionAction('RestoreContacts', { CategoryId, Ids });
  }

  public deleteContacts(data: { Ids: string[]; Password: string; WithAllRevisions: boolean }): Observable<any> {
    return this.odataMethods.CustomCollectionAction('DeleteContactItems', data);
  }

  public restoreContactsGroup(CategoryId: string): Observable<any> {
    return this.odataMethods.CustomCollectionAction('RestoreContactsGroup', { CategoryId });
  }

  public deleteContactsGroup(data: { Ids: string[]; Password: string }): Observable<any> {
    return this.odataMethods.CustomCollectionAction('DeleteContactGroups', data);
  }

  public restoreAllContacts(UserId: string): Observable<any> {
    return this.odataMethods.CustomCollectionAction('RestoreAllContacts', { UserId });
  }

  updateFilter(searchObj: SmartSearchModel): void {
    const filter: string[] = [];

    if (searchObj[this.#firstName.tag]) {
      const options: FilterOptions = {
        model: searchObj[this.#firstName.tag] as SmartSearchModelField[],
        prop: this.#firstName.prop
      };

      filter.push(this.convertFiltersToStr(this.#firstName.prop, getFilterByContains(options)));
    }

    if (searchObj[this.#lastName.tag]) {
      const options: FilterOptions = {
        model: searchObj[this.#lastName.tag] as SmartSearchModelField[],
        prop: this.#lastName.prop
      };

      filter.push(this.convertFiltersToStr(this.#lastName.prop, getFilterByContains(options)));
    }

    if (searchObj[this.#primaryEmail.tag]) {
      const options: FilterOptions = {
        model: searchObj[this.#primaryEmail.tag] as SmartSearchModelField[],
        prop: this.#primaryEmail.prop
      };

      filter.push(this.convertFiltersToStr(this.#primaryEmail.prop, getFilterByContains(options)));
    }

    if (searchObj[this.#phoneNumber.tag]) {
      const options: FilterOptions = {
        model: searchObj[this.#phoneNumber.tag] as SmartSearchModelField[],
        prop: this.#phoneNumber.prop
      };

      filter.push(this.convertFiltersToStr(this.#phoneNumber.prop, getFilterByContains(options)));
    }

    if (searchObj.words?.filter(Boolean)) {
      const term = filterByWords(searchObj).replace(SINGLE_QUOTES_REGEX, '');
      const keys = [this.#firstName.prop, this.#lastName.prop, this.#primaryEmail.prop, this.#phoneNumber.prop];
      const values = keys.map((k) => `(${k} ${COMPARISON_OPERATOR.ne} null ${BOOLEAN_OPERATOR.and} ${containsWrapper(k, term)})`);

      filter.push('('.concat(values.join(` ${BOOLEAN_OPERATOR.or} `), ')'));
    }

    this.filter = filter.length > 0 ? filter.join(` ${BOOLEAN_OPERATOR.and} `) : '';
  }

  convertFiltersToStr = (prop: string, filters: string) => `(${prop} ${COMPARISON_OPERATOR.ne} null ${BOOLEAN_OPERATOR.and} ${filters})`;
}
