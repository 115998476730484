import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewRef
} from '@angular/core';
import { TextEllipsisAppendDirective } from './text-ellipsis-append.directive';
import { TextEllipsisPrependDirective } from './text-ellipsis-prepend.directive';

@Component({
  selector: 'mbs-text-ellipsis',
  templateUrl: './text-ellipsis.component.html',
  host: {
    '[class]': 'innerHostClasses'
  }
})
export class TextEllipsisComponent implements AfterViewInit {
  @Input() autoClose = false;
  @Input() closeDelay = 0;
  @Input() container = 'body';
  @Input() disableTooltip = false;
  @Input() openDelay = 0;
  @Input('class') hostClasses = '';
  @Input() placement = 'top-left';
  @Input() tooltip: string | TemplateRef<any>;
  @Input() tooltipClass: string;
  @Input() triggers = 'hover focus';
  @Output() checkFit = new EventEmitter<boolean>();

  public isTruncated = true;
  private defaultHostClasses = 'mbs-text-ellipsis row no-gutters flex-nowrap mw-100';

  @ContentChild(TextEllipsisAppendDirective, { static: false, read: TextEllipsisAppendDirective })
  public append: TextEllipsisAppendDirective;

  @ContentChild(TextEllipsisPrependDirective, { static: false, read: TextEllipsisPrependDirective })
  public prepend: TextEllipsisPrependDirective;

  @ViewChild('wrapper', { static: false, read: ElementRef }) public wrapperSpan: ElementRef<HTMLSpanElement>;
  @ViewChild('content', { static: false, read: ElementRef }) public contentSpan: ElementRef<HTMLSpanElement>;

  constructor(private elem: ElementRef<HTMLElement>, private cdr: ChangeDetectorRef) {}

  get innerHostClasses(): string {
    return this.defaultHostClasses + ' ' + this.hostClasses;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hostClasses && !changes.hostClasses.firstChange) {
      queueMicrotask(this.check.bind(this) as () => void);
    }
  }

  ngAfterViewInit(): void {
    this.check();
  }

  onContentChange(): void {
    this.check();
  }

  @HostListener('mouseenter')
  check(): void {
    if (!this.wrapperSpan || !this.contentSpan || !this.canAbilityCdRef()) return;

    this.updateIsTruncated();

    this.checkFit.emit(!this.isTruncated);
    this.cdr.detectChanges();
  }

  private updateIsTruncated(): void {
    const textEllipsisWidth = this.wrapperSpan.nativeElement.getBoundingClientRect().width;
    const spanWidth = this.contentSpan.nativeElement.getBoundingClientRect().width;
    this.isTruncated = textEllipsisWidth < spanWidth;
  }

  private canAbilityCdRef(): boolean {
    return this.cdr && !(this.cdr as ViewRef).destroyed;
  }
}
