import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BOOLEAN_OPERATOR, SINGLE_QUOTES_REGEX } from '@app/common';
import { AutoActivationSettings, NewGroupSettings, OrganizationalUnit } from '@app/common/models';
import { ODataPagedResult, ODataServiceFactory } from '@app/common/odata';
import { AuthService } from '@app/common/services/auth.service';
import { SharedOdataService } from '@app/common/services/shared.odata.service';
import { UserGroupsTagsEnum, UserGroupsTagsMap } from '@app/common/services/smart-search/user-groups';
import { FilterOptions, containsWrapper, filterByWords, getFilterByContains, getFilterByEq } from '@app/common/utils/functions/search';
import { I18_NAMESPACE_MODULE } from '@app/i18';
import { I18NextPipe } from 'angular-i18next';
import { GuidEmpty, SmartSearchModel, SmartSearchModelField } from 'mbs-ui-kit';
import { Observable } from 'rxjs';

@Injectable()
export class OrganizationalUnitsService extends SharedOdataService<OrganizationalUnit> {
  readonly #name = UserGroupsTagsMap.get(UserGroupsTagsEnum.Path);
  readonly #autoActivation = UserGroupsTagsMap.get(UserGroupsTagsEnum.AutoActivation);

  public readonly moduleUsers = I18_NAMESPACE_MODULE.users;

  get name(): { tagKey: string; prop: string } {
    return this.#name;
  }

  get isGoogle(): boolean {
    return this.authService.isGoogle;
  }

  constructor(
    odataFactory: ODataServiceFactory,
    private http: HttpClient,
    private i18nPipe: I18NextPipe,
    private authService: AuthService
  ) {
    super(odataFactory, 'OrgUnits');
  }

  fetchGroups(): Observable<ODataPagedResult<OrganizationalUnit>> {
    const odataService = this.odataFactory.CreateService<OrganizationalUnit>(`/OrgUnits(${GuidEmpty})/GetAllOrgUnits`);

    return this.fetchData<OrganizationalUnit>(odataService);
  }

  getNewGroupSettings(): Observable<NewGroupSettings> {
    const url = this.odataFactory.CreateService<string>(`/NewGroupSettings`).Query().GetUrl();

    return this.http.get<NewGroupSettings>(url);
  }

  updateNewGroupSettings(payload: NewGroupSettings): Observable<NewGroupSettings> {
    const url = this.odataFactory.CreateService<string>(`/NewGroupSettings`).Query().GetUrl();

    return this.http.post<NewGroupSettings>(url, payload);
  }

  updateAutoActivation(payload: AutoActivationSettings): Observable<AutoActivationSettings> {
    return this.odataFactory.CreateService<OrganizationalUnit>(`/OrgUnits/AutoActivationMode`).Post(payload).Exec();
  }

  updateFilter(searchObj: SmartSearchModel): void {
    const filter: string[] = [];
    const nameTag = this.i18nPipe.transform(this.moduleUsers + this.#name.tagKey, { format: 'title' });
    const autoActivateTag = this.i18nPipe.transform(this.moduleUsers + this.#autoActivation.tagKey, {
      format: 'title'
    });

    if (searchObj[nameTag]) {
      const options: FilterOptions = {
        model: searchObj[nameTag] as SmartSearchModelField[],
        prop: this.#name.prop
      };

      filter.push(getFilterByContains(options));
    }

    if (searchObj[autoActivateTag]) {
      const options: FilterOptions = {
        model: searchObj[autoActivateTag] as SmartSearchModelField[],
        prop: this.#autoActivation.prop
      };

      filter.push(getFilterByEq(options, true));
    }

    if (searchObj.words?.filter(Boolean)) {
      const term = filterByWords(searchObj).replace(SINGLE_QUOTES_REGEX, '');

      filter.push(containsWrapper(this.#name.prop, term));
    }

    this.filter = filter.length > 0 ? filter.join(` ${BOOLEAN_OPERATOR.and} `) : '';
  }
}
