import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateInvoicePayload, PlanInfoOdata, TariffsOdata, UnsubscribePayload } from '@app/common/models';
import { ODataService, ODataServiceFactory } from '@app/common/odata';
import { SharedOdataService } from '@app/common/services';
import { Observable, of } from 'rxjs';
import { map, pluck, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TariffsService extends SharedOdataService<TariffsOdata> {
  private odataMethods: ODataService<any>;
  private _planInfo: PlanInfoOdata;

  constructor(odataFactory: ODataServiceFactory, private http: HttpClient) {
    super(odataFactory, 'Tariffs');
    this.odataMethods = odataFactory.CreateService('');
  }

  getPlanInfo(forceUpdate = false): Observable<PlanInfoOdata> {
    if (this._planInfo && !forceUpdate) return of(this._planInfo);

    const odataService = this.odataFactory.CreateService<PlanInfoOdata>(`/Me/PlanInfo`);

    return this.http.get<PlanInfoOdata>(odataService.Query().GetUrl()).pipe(tap((planInfo: PlanInfoOdata) => (this._planInfo = planInfo)));
  }

  getTariffs(): Observable<TariffsOdata[]> {
    const odataService = this.odataFactory.CreateService<TariffsOdata>('Tariffs');

    return this.fetchData<TariffsOdata>(odataService).pipe(map((res) => res.data));
  }

  createInvoices(payload: CreateInvoicePayload): Observable<string> {
    const odataService = this.odataFactory.CreateService(`Tariffs/CreateInvoices`);

    return <Observable<string>>odataService.Post(payload).Exec().pipe(pluck('value'));
  }

  // createInvoicesRedirect(data: AdditionPaymentPayload): Observable<any> {
  //   const formData = new FormData();
  //   for (const prop in data.payload) {
  //     formData.append(prop, data.payload[prop]);
  //   }
  //   return this.http.post(data.url, formData, {
  //     headers: {
  //       'Content-Type': 'application/x-www-form-urlencoded'
  //     }
  //   });
  // }

  createInvoice(payload: CreateInvoicePayload, id: string): Observable<string> {
    const odataService = this.odataFactory.CreateService(`Tariffs(${id})/CreateInvoice`);

    return <Observable<string>>odataService.Post(payload).Exec().pipe(pluck('value'));
  }

  // payInvoice(payload: InvoiceIdPayload): Observable<any> {
  //   const url = `/api/PayInvoice`;
  //   return this.http.post(url, payload);
  // }

  unsubscribeActivatedPlan(payload: UnsubscribePayload): Observable<void> {
    const odataService = this.odataFactory.CreateService(`Tariffs/CancelSubscription`);

    return odataService.Post(payload).Exec() as unknown as Observable<void>;
  }
}
