import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '../button/button.module';
import { TextEllipsisModule } from '../text-ellipsis';
import { TagComponent } from './tag.component';

@NgModule({
  imports: [CommonModule, ButtonModule, TextEllipsisModule],
  declarations: [TagComponent],
  exports: [TagComponent]
})
export class TagModule {
  constructor() {}
}
