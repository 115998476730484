import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DomainModel,
  DomainServiceInfo,
  DomainServicesState,
  DomainStatistic,
  hasAdminRole,
  ServiceType,
  UserServiceBackupInfo
} from '@app/common/models';
import { ODataService, ODataServiceFactory } from '@app/common/odata';
import { AuthService } from '@app/common/services';
import { hasActionsQueue } from '@app/common/utils';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, finalize, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class DomainService {
  readonly #domainData = new BehaviorSubject<DomainModel>(null);
  readonly #domainStatistic = new BehaviorSubject<DomainStatistic>(null);
  readonly #domainServicesState = new BehaviorSubject<DomainServicesState>(null);

  readonly #loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private domainOdata: ODataService<DomainModel>;
  private odata: ODataService<any>;

  public requestPending$: Observable<boolean>;

  constructor(private odataFactory: ODataServiceFactory, private authService: AuthService) {
    this.odata = this.odataFactory.CreateService('');
    this.domainOdata = this.odataFactory.CreateService('Domains');
    this.requestPending$ = this.#loading$.pipe(hasActionsQueue(), shareReplay(1));
  }

  get currentDomain$(): Observable<DomainModel> {
    return this.#domainData.asObservable();
  }

  get domainStatistic$(): Observable<DomainStatistic> {
    return this.#domainStatistic.asObservable();
  }

  get domainServicesState$(): Observable<DomainServicesState> {
    return this.#domainServicesState.asObservable();
  }

  initAuthGuard(): Observable<boolean> {
    return this.currentDomain$.pipe(
      map((domain) => {
        domain ? this.authService.logout() : this.fetchDomain();

        return !domain;
      }),
      catchError(() => of(false))
    );
  }

  fetchDomain(): void {
    this.authService
      .getRoles()
      .pipe(
        filter((r) => hasAdminRole(r)),
        switchMap(() => this.authService.getAuthUser()),
        switchMap((user) => this.domainOdata.Get(user.DomainId).Exec())
      )
      .subscribe({
        next: (domain) => {
          this.#domainData.next(domain);
        }
      });
  }

  getFetchDomain(): Observable<DomainModel> {
    return this.authService.getRoles().pipe(
      filter((r) => hasAdminRole(r)),
      switchMap(() => this.authService.getAuthUser()),
      switchMap((user) => this.domainOdata.Get(user.DomainId).Exec()),
      tap((domain) => this.#domainData.next(domain))
    );
  }

  getDomainServiceInfo(
    service: ServiceType.TeamDrives | ServiceType.SharePoint | ServiceType.Teams | ServiceType.Contacts
  ): Observable<UserServiceBackupInfo> | Observable<null> {
    const serviceName: string = this.getServiceName(service);

    if (!serviceName) return of(null);

    return this.authService.getAuthUser().pipe(
      switchMap((user) => this.domainOdata.Get(user.DomainId).Expand(serviceName).Exec()),
      map((domain) => {
        const domainServiceInfo = domain[serviceName] as DomainServiceInfo;

        return {
          LastBackupDate: domainServiceInfo.LastBackupDate,
          TotalSize: domainServiceInfo.Size
        };
      })
    );
  }

  private getServiceName = (service: ServiceType): string => {
    switch (service) {
      case ServiceType.TeamDrives:
        return 'TeamDrive';
      case ServiceType.SharePoint:
        return 'Site';
      case ServiceType.Teams:
        return 'Team';
      case ServiceType.Contacts:
        return 'Contact';
      default:
        return '';
    }
  };

  fetchDomainStatistic(): void {
    this.callOdataFunc('GetDomainUsersStatistic()', (res) => this.#domainStatistic.next(res));
  }

  fetchDomainServicesState(): void {
    this.callOdataFunc('GetDomainServicesState()', (res) => this.#domainServicesState.next(res));
  }

  private callOdataFunc(funcName: string, successCallback: (a: any) => void) {
    this.#loading$.next(true);
    this.odata
      .CustomCollectionFunction(funcName)
      .pipe(
        take(1),
        finalize(() => this.#loading$.next(false))
      )
      .subscribe({
        next: (res: HttpResponse<any>) => successCallback(res.body)
      });
  }

  syncDomain(): Observable<any> {
    return this.domainOdata.CustomAction(this.#domainData.value.Id, 'Sync', null);
  }

  resetCache(): void {
    this.#domainData.next(null);
    this.#domainStatistic.next(null);
    this.#domainServicesState.next(null);
  }
}
