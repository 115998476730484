import { Pipe, PipeTransform } from '@angular/core';
import { ServiceType, ServiceTypeByAPIEnum } from '@app/common/models';

@Pipe({
  name: 'serviceTypeToDisplayName',
  pure: true
})
export class ServiceTypeToDisplayNamePipe implements PipeTransform {
  transform(key: string): string {
    const item: { serviceType: ServiceType; displayName: string } = ServiceTypeByAPIEnum[key];
    return item ? item.displayName : '';
  }
}
