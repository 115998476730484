import { Injectable } from '@angular/core';
import { ToastSettings } from '@app/common';
import { I18NextPipe } from 'angular-i18next';
import { MbsPopupType, ToastService } from 'mbs-ui-kit';

@Injectable({
  providedIn: 'root'
})
export class ToastFactory {
  constructor(private i18nPipe: I18NextPipe, private toastService: ToastService) {}

  showToast(settings: ToastSettings): void {
    const toastTitle = this.#getToastTitle(settings);
    const toastBody = this.#getToastBody(settings);
    const toastMethod = this.#getToastMethod(settings);

    if (toastMethod) {
      this.toastService[toastMethod](toastBody, toastTitle);
    }
  }

  #getToastTitle(settings: ToastSettings): string {
    switch (settings.type) {
      case MbsPopupType.success:
        return this.i18nPipe.transform('toast.success.title', { format: 'title' });
      case MbsPopupType.danger:
        return this.i18nPipe.transform('toast.error.title', { format: 'title' });
      case MbsPopupType.info:
        return this.i18nPipe.transform('toast.info.title', { format: 'title' });
      case MbsPopupType.warning:
        return this.i18nPipe.transform('toast.warning.title', { format: 'title' });
      default:
        return null;
    }
  }

  #getToastBody(settings: ToastSettings): string {
    return settings.body.key
      ? this.i18nPipe.transform(
          settings.nameSpace ? settings.nameSpace + settings.body.key : settings.body.key,
          settings.body.options ? settings.body.options : { format: 'capitalize' }
        )
      : null;
  }

  #getToastMethod(settings: ToastSettings): string {
    switch (settings.type) {
      case MbsPopupType.success:
        return 'success';
      case MbsPopupType.danger:
        return 'error';
      case MbsPopupType.info:
        return 'info';
      case MbsPopupType.warning:
        return 'warn';
      default:
        throw new Error('Unknown toast service method!');
    }
  }

  showForbiddenPermissionToast(event: Event, isAvailable: boolean): void {
    if (isAvailable) return;

    event.preventDefault();

    this.showToast({
      type: MbsPopupType.danger,
      body: { key: 'toast.body.fileDownload' }
    });
  }
}
