import { BackupHistoryItem, ServiceType } from '@app/common/models';
import { ServiceColors, getServiceUiInfo, toFixed } from '@app/common/utils';
import { ChartDataset } from 'chart.js';

export function prepareLastDaysBackupSizeDataSet(
  data: BackupHistoryItem[],
  serviceType: ServiceType,
  allowedTypes: ServiceType[] = []
): ChartDataset[] {
  if (serviceType === ServiceType.All && data?.length > 0) {
    const allTypes: ServiceType[] = [];

    for (const [key] of Object.entries(data[0].Usage)) {
      if (ServiceType[key] >= 0 && allowedTypes.includes(ServiceType[key])) {
        allTypes.push(ServiceType[key]);
      }
    }

    return allTypes.map((type: ServiceType) => {
      const arrayData = data.reduce(
        (acc: BackupHistoryItem[], item: BackupHistoryItem) => [...acc, toFixed(item.Usage[ServiceType[type]])],
        []
      );

      return {
        data: arrayData,
        label: getServiceUiInfo(type).displayName,
        backgroundColor: ServiceColors[ServiceType[type]] as ServiceColors,
        hoverBackgroundColor: ServiceColors[ServiceType[type]] as ServiceColors
      };
    }) as ChartDataset[];
  } else {
    const arrayData = data.reduce(
      (acc: BackupHistoryItem[], item: BackupHistoryItem) => [...acc, toFixed(item.Usage[ServiceType[serviceType]])],
      []
    );
    return [
      {
        data: arrayData,
        label: getServiceUiInfo(serviceType).displayName,
        backgroundColor: ServiceColors[ServiceType[serviceType]] as ServiceColors,
        hoverBackgroundColor: ServiceColors[ServiceType[serviceType]] as ServiceColors
      }
    ] as ChartDataset[];
  }
}
