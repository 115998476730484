import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Host,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  SkipSelf,
  ViewChild
} from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { KeyCode, MbsSize } from '../../utils';
import { InputBase } from '../input-base/input-base';
import { TagInputItem } from './tags-input.types';
import { distinctUntilChanged } from 'rxjs/operators';
import { isEqual } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

enum Mode {
  Collapsed = 'collapsed',
  Opened = 'opened'
}

@UntilDestroy()
@Component({
  selector: 'mbs-tags-input',
  templateUrl: './tags-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagsInputComponent extends InputBase<TagInputItem[]> implements OnInit, OnDestroy, AfterContentInit {
  @ViewChild('container', { static: true }) container: ElementRef;

  public control: AbstractControl;
  public readonly idPrefix = 'mbs-input-tag-';
  public isFocused = false;
  public filteredSuggestions = [];
  public inputControl = new FormControl('');
  public dropdownClass = 'mbs-tags-input_dropdown';

  private passedSuggestions = [];
  private observer: MutationObserver;

  public mode: Mode = Mode.Collapsed;
  public showExtendButton$: BehaviorSubject<Mode> = new BehaviorSubject(null);
  public scrollHeight = null;
  public readonly modeEnum = Mode;

  /** Skips adding tags from input. Tags can be added from suggestions only */
  @Input() fromSuggestionsOnly?: boolean;

  @Input() formControlName?: string;

  @Input() actionButton?: string;

  @Input() loading?: boolean;

  /**
   * Add font-weight-bold;
   */
  @Input() public boldLabel = false;

  @Input() set suggestions(suggestions: string[]) {
    this.passedSuggestions = suggestions;
    this.prepareSuggestions(this.inputControl.value);
  }

  @Input() resetInnerValue?: BehaviorSubject<boolean>;

  /* Need add input with dropdown */
  @Input() isStatic = false;

  @Input() isCollapseMode = false;
  @Input() collapseHeight: number;

  /* Need for empty tags wrapper */
  @Input() minHeight: number;
  @Input() inputSize: MbsSize;

  @Output() innerValue = new EventEmitter<string>();

  @Output() addItem: EventEmitter<string> = new EventEmitter();

  @ViewChild(NgbDropdown, { static: true }) public dropdown: NgbDropdown;
  @ViewChild('inputField') public inputField: ElementRef<HTMLInputElement>;

  constructor(
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
    @Optional() @Self() protected ngControl: NgControl,
    @Optional() protected cdRef: ChangeDetectorRef
  ) {
    super(ngControl, cdRef);
  }

  ngOnInit() {
    if (this.controlContainer) {
      if (this.formControlName) {
        this.control = this.controlContainer.control.get(this.formControlName);
      } else {
        console.warn('Missing formControlName directive');
      }
    } else {
      this.control = this.ngControl.control;
    }

    this.prepareSuggestions(this.inputControl.value);

    this.inputControl.valueChanges.pipe(untilDestroyed(this)).subscribe((val) => {
      this.prepareSuggestions(val);
      this.innerValue.emit(this.inputControl.valid ? val : null);
      this.dropdown?.open();
    });

    this.control.valueChanges.pipe(
      distinctUntilChanged((prev, current) => isEqual(prev, current)),
      untilDestroyed(this)
    ).subscribe((value) => {
      if (value[value.length - 1]?.name === this.inputControl?.value) {
        this.inputControl.patchValue('');
      }
    })

    this.control.statusChanges.pipe(
      untilDestroyed(this)
    ).subscribe((value) => {
      this.cdRef.detectChanges();
    })

    this.resetInnerValue && this.resetInnerValue
      .pipe(untilDestroyed(this))
      .subscribe((reset) => {
        reset && this.inputControl.patchValue('', { emitEvent: false });
      });
  }

  ngOnDestroy(): void {
    this.isCollapseMode && this.observer?.disconnect();
  }

  ngAfterContentInit(): void {
    this.isCollapseMode && this.createObserver();
  }

  createObserver() {
    const callback = () => {
      this.showExtendButton$.next(null);
      this.scrollHeight = this.container.nativeElement.scrollHeight;

      if (this.scrollHeight > this.collapseHeight) {
        this.showExtendButton$.next(this.mode);
      }
    };

    this.observer?.disconnect();
    this.observer = new MutationObserver(callback);
    this.observer.observe(this.container.nativeElement, {
      attributes: true,
      childList: true,
      subtree: false
    });
  }

  writeValue(val: TagInputItem[]) {
    super.writeValue(val);
    this.value = val;
  }

  public get bindClasses(): string[] {
    return Object.entries(this.validClasses)
      .filter(([, v]) => !!v)
      .map(([k]) => k)
      .concat([this.inputSizeClass || this.sizeClass]);
  }

  private get inputSizeClass(): string {
    return this.inputSize ? 'form-control-' + this.inputSize : '';
  }

  public handleKeyDown({ target, keyCode }): void {
    if (keyCode === KeyCode.Enter && !this.fromSuggestionsOnly) {
      const value = this.getPreparedInputValue(target.value);

      if (this.inputControl.valid) {
        this.writeValue([...this.value, ...this.getPreparedUpdateValue(value)]);
        this.inputControl.patchValue('');
        this.prepareSuggestions(this.inputControl.value);
        this.inputField?.nativeElement?.focus();
      }
    }

    if (keyCode === KeyCode.BackSpace && !this.inputControl.value && this.value?.length) {
      this.writeValue(this.value.slice(0, this.value.length - 1));
      this.inputField?.nativeElement?.focus();
    }
  }

  public handleChange({ target }): void {
    if (!target.value.endsWith(',') || this.fromSuggestionsOnly) return;

    if (target.value.length > 1) {
      const value = this.getPreparedInputValue(target.value);

      if (this.inputControl.valid) {
        this.writeValue([...this.value, ...this.getPreparedUpdateValue(value)]);
        this.inputField?.nativeElement?.focus();
        this.inputControl.patchValue('');
      } else {
        this.inputControl.patchValue(target.value.substring(0, target.value.length - 1));
      }
    } else {
      this.inputControl.patchValue('');
    }
  }

  private getPreparedInputValue(value: string): TagInputItem[] {
    return value
      .split(',')
      .filter(Boolean)
      .map((el) => ({ name: el.trim(), errors: [] }));
  }

  public handleClose(idx: number): void {
    const value = [...this.value.slice(0, idx), ...this.value.slice(idx + 1, this.value.length)];

    this.writeValue(value);
    this.inputField?.nativeElement?.focus();
    this.prepareSuggestions(this.inputControl.value);
  }

  public handleBlur(): void {
    this.isFocused = false;
  }

  public handleFocus(): void {
    this.isFocused = true;
  }

  public onSuggestionItemClick(suggestion: string): void {
    this.writeValue([...this.value, ...[{ name: suggestion, errors: [] }]]);
    this.inputField?.nativeElement?.focus();
    this.inputControl.patchValue('');
    this.prepareSuggestions(this.inputControl.value);
  }

  public onActionButtonPressed() {
    this.addItem.emit(this.inputControl.value);
    this.inputControl.patchValue('');
  }

  private getPreparedUpdateValue(elements: TagInputItem[]): TagInputItem[] {
    return Array.from(new Set(elements)).map((el) => ({ name: this.trimWhiteSpaces(el.name), errors: el.errors }));
  }

  private trimWhiteSpaces(el: string) {
    return el.split(' ').filter(Boolean).join(' ');
  }

  private prepareSuggestions(val: string): void {
    this.filteredSuggestions = this.passedSuggestions.filter((s) => {
      return !(this.value || []).some((tag) => tag.name === s) && s.toLowerCase().includes(val.toLowerCase());
    });

    const needToDisplayActionButton =
      this.actionButton &&
      this.inputControl.valid &&
      this.inputControl.value &&
      !this.value?.some((item) => item.name === this.inputControl.value) &&
      !this.passedSuggestions?.includes(this.inputControl.value);

    this.dropdownClass =
      this.filteredSuggestions.length || needToDisplayActionButton ? 'mbs-tags-input_dropdown' : 'mbs-tags-input_dropdown-empty';
  }
}
