export default abstract class SmartSearchTemplatesBase {
  protected readonly storedTimeout = 4 * 60 * 60 * 1000;

  protected cache: {
    [key: string]: any[];
  } = {};
  protected cacheTimeout: {
    [key: string]: any;
  } = {};

  /*
   * Save API results in memory
   * @param term current term
   */
  protected writeToCache<TData>(term: string): (x: TData[]) => void {
    return (res: TData[]) => {
      this.cache[term] = res;
      clearTimeout(this.cacheTimeout[term + '_timeout']);
      this.cacheTimeout[term + '_timeout'] = setTimeout(() => delete this.cache[term], this.storedTimeout);
    };
  }
}
