export enum CustomerProblem {
  AlmostExceededStorageLimit = 'AlmostExceededStorageLimit',
  ExceededStorageLimit = 'ExceededStorageLimit',
  InvoiceProblem = 'InvoiceProblem',
  LicenseProblem = 'LicenseProblem',
  NoProblem = 'NoProblem',
  RestoreAbilityExpired = 'RestoreAbilityExpired',
  PlanExpired = 'PlanExpired',
  PlanWillExpired = 'PlanWillExpired',
  StorageImmutabilityActive = 'StorageImmutabilityActive',
  StorageImmutabilityWithoutPolicyActive = 'StorageImmutabilityWithoutPolicyActive',
  TrialRestriction = 'TrialRestriction',
  TrialWillExpired = 'TrialWillExpired'
}
