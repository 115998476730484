import { Location } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { BrowserStorage, WINDOW } from '@app/common';
import { Provider } from '@app/common/models';
import { AccessRequestPayload } from '@app/common/models/auth/access-request.model';
import { AuthModelBasel } from '@app/common/models/auth/auth.model';
import { AuthService, SessionStorageService } from '@app/common/services';
import { AuthMode, AuthState, OtherState } from '@app/components/login/enums';
import { I18_NAMESPACE_MODULE } from '@app/i18';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { MbsPopupType, MbsSize, ToastService } from 'mbs-ui-kit';
import { BehaviorSubject, EMPTY, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

@UntilDestroy()
@Component({
  templateUrl: '/account.component.html',
  styleUrls: ['./account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountComponent {
  readonly #toastTitleSuccess = this.i18nPipe.transform('toast.success.title', { format: 'title' });
  readonly #toastTitleError = this.i18nPipe.transform('toast.error.title', { format: 'title' });

  public readonly AuthState = AuthState;
  public readonly OtherState = OtherState;
  public readonly Provider = Provider;
  public readonly MbsSize = MbsSize;
  public readonly MbsPopupType = MbsPopupType;
  public readonly moduleButton = I18_NAMESPACE_MODULE.button;

  public readonly loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    @Inject(WINDOW) private window: Window,
    private authService: AuthService,
    private http: HttpClient,
    private i18nPipe: I18NextPipe,
    private location: Location,
    private sessionStorageService: SessionStorageService,
    private toastService: ToastService
  ) {}

  get URLSearchParams(): URLSearchParams {
    return new URLSearchParams(this.window.location.search);
  }

  get model(): AuthModelBasel {
    return this.authService?.modelBasel || this.sessionStorageService?.get(BrowserStorage.ErrorPage)?.['model'];
  }

  get state(): string {
    return this.URLSearchParams?.get('state') || this.URLSearchParams?.get('State');
  }

  handleLogOut(): void {
    this.authService.logout();
  }

  handleSignIn(provider: Provider): void {
    this.authService
      .login(provider, AuthMode.SecondSignIn)
      .pipe(catchError((err: HttpErrorResponse) => (err instanceof HttpErrorResponse ? throwError(() => err) : EMPTY)))
      .subscribe({
        next: (data) => {
          this.window.open(data.RedirectUrl, '_top');
        },
        error: () => {
          this.authService.logout();
        }
      });
  }

  handleAccessRequest(): void {
    const payload: AccessRequestPayload = {
      OwnerId: this.model?.OwnerId,
      UserEmail: this.model?.UserEmail,
      UserDomain: this.model?.UserDomain
    };

    this.loading$.next(true);
    this.http
      .post<boolean>('/api/SendAccessRequest', payload)
      .pipe(
        finalize(() => this.loading$.next(false)),
        untilDestroyed(this)
      )
      .subscribe({
        next: () => {
          const toastBody = this.i18nPipe.transform('account.toast.success.request', { format: 'capitalize' });

          this.toastService.success(toastBody, this.#toastTitleSuccess);
          setTimeout(() => this.handleLogOut(), 1500);
        },
        error: (res: HttpErrorResponse) => {
          this.toastService.error(res?.error?.message || res?.error?.error?.message, this.#toastTitleError);
        }
      });
  }

  handleReturnByRoute(): void {
    this.location.back();
  }
}
