import { ObserversModule } from '@angular/cdk/observers';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TextEllipsisAppendDirective } from './text-ellipsis-append.directive';
import { TextEllipsisPrependDirective } from './text-ellipsis-prepend.directive';
import { TextEllipsisComponent } from './text-ellipsis.component';

@NgModule({
  imports: [CommonModule, NgbTooltipModule, ObserversModule],
  declarations: [TextEllipsisComponent, TextEllipsisAppendDirective, TextEllipsisPrependDirective],
  exports: [TextEllipsisComponent, TextEllipsisAppendDirective, TextEllipsisPrependDirective]
})
export class TextEllipsisModule {}
