export interface BackupHistoryItem {
  Day: number;
  Month: number;
  Usage: BackupHistoryItemUsage;
  Year: number;
}
interface BackupHistoryItemUsage {
  Calendar: number;
  Contacts: number;
  Drive: number;
  Mail: number;
  SharePoint: number;
  TeamDrives: number;
  Units: HistoryUnits;
}

export interface PieDataItem {
  Name: string;
  Size: number; // float number
  Count: number;
}

export enum HistoryPeriods {
  Month = 'month',
  Year = 'year'
}

export enum HistoryUnits {
  Count = 'count',
  Size = 'size'
}
