<div class="mbs-checkbox"
     [ngClass]="{
       '-pin-mode': pinMode,
       '-sm': size === MbsSize.sm,
       '-lg': size === MbsSize.lg
     }"
     mbs-input-label
     [labelSize]="labelSize"
     [boldLabel]="boldLabel"
     [label]="label"
     [labelPosition]="labelPosition"
     [center]="centerCheckbox"
     [labelContext]="labelContext"
     [id]="id"
     [type]="'checkbox'"
     [disabled]="disabledState"
     [info]="info"
     [infoPlacement]="infoPlacement"
     [infoTriggers]="infoTriggers"
     [infoContainer]="infoContainer"
     [required]="hasRequiredValidator && showRequiredMark"
     [labelClasses]="labelClasses"
     [labelContentClasses]="labelContentClasses">

  <ng-content select="[prependLabel]"
              ngProjectAs="[prependLabel]"></ng-content>

  <input [id]="id"
         class="mbs-checkbox_input"
         type="checkbox"
         [disabled]="disabledState"
         [checked]="checked"
         (change)="handleChange($event)"
         [tabindex]="tabindex"
         [indeterminate]="indeterminate">

  <ng-content></ng-content>

  <ng-content select="[appendLabel]"
              ngProjectAs="[appendLabel]"></ng-content>

</div>

<div *ngIf="subtitle && !subtitleHide"
     class="mbs-checkbox_subtitle"
     [class.-inside]="!subtitleOutside"
     [class.-sm]="!subtitleOutside && size === MbsSize.sm"
     [class.-lg]="!subtitleOutside && size === MbsSize.lg"
     [class.-disabled]="disabledState"
     [ngClass]="subtitleClasses">
  {{ subtitle }}
</div>

<div *ngIf="checkboxDescription && !checkboxDescription.hide"
     class="mbs-checkbox_description"
     [class.-inside]="!descriptionOutside"
     [class.-sm]="!descriptionOutside && size === MbsSize.sm"
     [class.-lg]="!descriptionOutside && size === MbsSize.lg"
     [ngClass]="descriptionClasses">

  <ng-template [ngTemplateOutlet]="checkboxDescription.template"></ng-template>

</div>
