<form [formGroup]="formGroup"
      autocomplete="off">

  <mbs-form-section>

    <h5 class="mbs-form-section_title">

      <ng-container *ngIf="verified$ | async; else elseBlock">
        <div class="form-row flex-nowrap overflow-hidden">
          <div class="col-auto">
            {{ moduleAccount + ':sidepanel.text.currentDestination' | i18next: { format: 'title' } }}:
          </div>
          <div class="col overflow-hidden">
            <mbs-text-ellipsis placement="top-left auto"
                               tooltipClass="tooltip-lg"
                               [tooltip]="formGroup.get('name').value">
              <span class="mbs-form-section_header-ico fa fa-check-circle text-success text-sm ml-1"></span>
              <span class="text-success text-sm">
                {{ formGroup.get('name').value }}
              </span>
            </mbs-text-ellipsis>
          </div>
        </div>
      </ng-container>

      <ng-template #elseBlock>
        {{ moduleAccount + ':sidepanel.text.currentDestination' | i18next: { format: 'title' } }}:
        <span class="fa fa-exclamation-circle text-muted text-sm ml-2 mr-1"></span>
        <span class="text-muted text-sm font-weight-base">
          {{ moduleAccount + ':sidepanel.text.notVerified' | i18next: { format: 'title' } }}
        </span>
      </ng-template>

    </h5>

    <mbs-form-group>
      <mbs-select formControlName="storageType"
                  [style.pointer-events]="{ 'pointer-events': readonly ? 'none' : 'initial' }"
                  [ngClass]="{ 'ng-select-disabled': readonly }"
                  [disabled]="readonly"
                  [searchable]="false"
                  [clearable]="false"
                  [items]="storageTypes"
                  [label]="moduleAccount + ':sidepanel.label.storageType' | i18next: { format: 'title' }">
      </mbs-select>
    </mbs-form-group>

    <mbs-form-group>
      <mbs-input formControlName="name"
                 [label]="moduleAccount + ':sidepanel.label.name' | i18next: { format: 'title' }"
                 type="text">
      </mbs-input>
    </mbs-form-group>

    <mbs-form-group>
      <mbs-input formControlName="bucketName"
                 [disabled]="readonly"
                 [label]="moduleAccount + ':sidepanel.label.' + bucketNameLabelKey | i18next: { format: 'title' }"
                 type="text">
      </mbs-input>
    </mbs-form-group>

    <mbs-form-group>
      <mbs-input formControlName="rootFolder"
                 [disabled]="readonly"
                 [label]="moduleAccount + ':sidepanel.label.rootFolder' | i18next: { format: 'title' }"
                 type="text">
      </mbs-input>
    </mbs-form-group>

    <mbs-form-group>
      <mbs-input formControlName="accessKey"
                 [label]="moduleAccount + ':sidepanel.label.' + accessKeyLabelKey | i18next: { format: 'title' }"
                 type="text">
      </mbs-input>
    </mbs-form-group>

    <mbs-form-group [ngClass]="{ 'mb-0': !isAvailableEndpoint && !isAvailableEndpointSuffix }">
      <mbs-input formControlName="secretKey"
                 [disabled]="disabledSecretKey$ | async"
                 [label]="moduleAccount + ':sidepanel.label.' + secretKeyLabelKey | i18next: { format: 'title' }"
                 [appendButtons]="[
                  {
                    id: 'append-secret-key',
                    type: 'secondary',
                    icon: (disabledSecretKey$ | async) ? secretKeyIconClasses.lock : secretKeyIconClasses.unlock,
                    btnType: 'button',
                    loading: false,
                    disabled: false
                  }
                ]"
                 (buttonClick)="handleToggleDisabledSecretKey()"
                 type="text">
      </mbs-input>
    </mbs-form-group>

    <mbs-form-group *ngIf="isAvailableEndpoint"
                    [ngClass]="{ 'mb-0': !isAvailableEndpointSuffix }">
      <mbs-input formControlName="endpoint"
                 [label]="moduleAccount + ':sidepanel.label.endpoint' | i18next: { format: 'title' }"
                 type="text">
      </mbs-input>
    </mbs-form-group>

    <mbs-form-group *ngIf="isAvailableEndpointSuffix"
                    class="mb-0">
      <mbs-input formControlName="endpointSuffix"
                 [label]="moduleAccount + ':sidepanel.label.endpointSuffix' | i18next: { format: 'title' }"
                 type="text">
      </mbs-input>
    </mbs-form-group>

  </mbs-form-section>

  <mbs-form-section>

    <div class="form-row">

      <div class="col">
        <mbs-button type="outline-danger"
                    [disabled]="formGroup.invalid || (disableDelete$ | async)"
                    [loading]="deleteLoading$ | async"
                    (click)="handleDelete()">
          {{ moduleAccount + ':sidepanel.button.deleteDestination' | i18next: { format: 'title' } }}
        </mbs-button>
      </div>

      <div class="col-auto">
        <mbs-button type="primary"
                    [disabled]="formGroup.invalid"
                    [loading]="testLoading$ | async"
                    (click)="handleTest()">
          {{ moduleAccount + ':sidepanel.button.testAccess' | i18next: { format: 'title' } }}
        </mbs-button>
      </div>

    </div>

  </mbs-form-section>

</form>

<ng-template #deleteBDestinationConfirmTempRef>
  <p>
    {{ moduleAccount + ':modal.body.confirmDeleteBackupDestinationDeleteThe' | i18next: { format: 'capitalize' } }}:
    <span class="font-weight-semibold">{{ settings?.name }}</span> ?
  </p>

  <mbs-alert [type]="MbsPopupType.warning"
             [icon]="true"
             class="mb-0">
    {{ moduleAccount + ':modal.body.confirmDeleteBackupDestinationUserBackups' | i18next: { format: 'capitalize' } }}.
  </mbs-alert>

</ng-template>
