export type SortDirection = 'asc' | 'desc' | '';

export interface SortEvent {
  column: string;
  direction: SortDirection;
};

export const rotate: { [key: string]: SortDirection } = {
  asc: 'desc',
  desc: '',
  '': 'asc'
};
export const sortNumber: { [key: string]: number } = {
  asc: 1,
  desc: -1,
  '': 0
};

export const commonRotateSequence: { [key: string]: SortDirection } = {
  asc: '',
  desc: 'asc',
  '': 'desc'
};
