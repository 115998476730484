import { Pipe, PipeTransform } from '@angular/core';
import { I18NextPipe } from 'angular-i18next';

@Pipe({
  name: 'selectGridButtonText',
  standalone: true,
  pure: true
})
export class SelectGridButtonTextPipe implements PipeTransform {
  constructor(private i18nPipe: I18NextPipe) {}

  transform(count: number, oneEntityKey: string, manyEntitiesKey: string, isSelectAllOnAllPages: boolean): string {
    const selected = this.i18nPipe.transform('common.selected', { format: 'title' });
    const entities = this.i18nPipe.transform(count === 1 ? oneEntityKey : manyEntitiesKey);
    const page = this.i18nPipe.transform('paging.' + (isSelectAllOnAllPages ? 'onAllPages' : 'onThisPage'));

    return `${selected} ${count} ${entities} ${page}`;
  }
}
