<div class="mbs-radio"
     [ngClass]="{
       '-sm': size === 'sm',
       '-lg': size === 'lg'
     }"
     mbs-input-label
     [boldLabel]="boldLabel"
     [labelPosition]="labelPosition"
     [label]="label"
     [labelSize]="labelSize"
     [center]="centerRadio"
     [id]="id"
     [type]="'radio'"
     [disabled]="disabledState"
     [info]="info"
     [infoPlacement]="infoPlacement"
     [infoTriggers]="infoTriggers"
     [infoContainer]="infoContainer"
     [required]="hasRequiredValidator && showRequiredMark"
     [labelContext]="labelContext"
     [labelClasses]="labelClasses"
     [labelContentClasses]="labelContentClasses">
  <ng-content select="[prependLabel]"
              ngProjectAs="[prependLabel]"></ng-content>
  <input [id]="id"
         class="mbs-radio_input"
         type="radio"
         [name]="name"
         [value]="value"
         [disabled]="disabledState"
         [checked]="checked"
         (change)="handleChange($event)"
         [tabindex]="tabindex">

  <ng-content></ng-content>
  <ng-content select="[appendLabel]"
              ngProjectAs="[appendLabel]"></ng-content>
</div>

<div *ngIf="subtitle && !subtitleHide"
     class="mbs-radio_subtitle"
     [class.-inside]="!subtitleOutside"
     [class.-sm]="!subtitleOutside && size === 'sm'"
     [class.-lg]="!subtitleOutside && size === 'lg'"
     [class.-disabled]="disabledState"
     [ngClass]="subtitleClasses">{{subtitle}}</div>

<div *ngIf="radioDescription && !radioDescription.hide"
     class="mbs-radio_description"
     [class.-inside]="!descriptionOutside"
     [class.-sm]="!descriptionOutside && size === 'sm'"
     [class.-lg]="!descriptionOutside && size === 'lg'"
     [ngClass]="descriptionClasses">
  <ng-template [ngTemplateOutlet]="radioDescription.template"></ng-template>
</div>
