import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbTooltipModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { IMaskModule } from 'angular-imask';
import { ButtonModule } from '../../button/button.module';
import { InputBaseModule } from '../input-base/input-base.module';
import { InputComponent } from './input.component';

@NgModule({
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    NgbTooltipModule,
    IMaskModule,
    InputBaseModule,
    NgbTypeaheadModule,
    ButtonModule
  ],
  declarations: [InputComponent],
  exports: [InputComponent]
})
export class InputModule {}
