<span *ngIf="prepend && !prepend.hide"
      class="col-auto">
  <ng-container *ngTemplateOutlet="prepend.template"></ng-container>
</span>
<span class="col text-overflow"
      #wrapper>
  <span #content
        (cdkObserveContent)="onContentChange()"
        (resize)="check()"
        [autoClose]="autoClose"
        [closeDelay]="closeDelay"
        [container]="container"
        [disableTooltip]="disableTooltip || !isTruncated"
        [ngbTooltip]="tooltip"
        [openDelay]="openDelay"
        [placement]="placement"
        [tooltipClass]="tooltipClass"
        [triggers]="triggers">
    <ng-content></ng-content>
  </span>
</span>
<span *ngIf="append && !append.hide"
      class="col-auto">
  <ng-container *ngTemplateOutlet="append.template"></ng-container>
</span>