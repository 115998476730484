import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AttachedPolicies, DefaultParams, DisplayNameItemType, RetentionPolicyOdata, ServiceTypeByAPIEnum } from '@app/common/models';
import { ODataServiceFactory } from '@app/common/odata';
import { BaseOdataService } from '@app/common/services';
import { RetentionPolicyTagsEnum, RetentionPolicyTagsMap } from '@app/common/services/smart-search';
import { filterByWords, getLoadingState, hasActionsQueue } from '@app/common/utils';
import { containsWrapper, FilterOptions, getFilterByContains, getFilterByEqFromEnum } from '@app/common/utils/functions/search';
import { extendedPolicies } from '@app/common/utils/helper/attached-policies';
import { I18NextPipe } from 'angular-i18next';
import { SmartSearchModel, SmartSearchModelField } from 'mbs-ui-kit';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RetentionPolicyService extends BaseOdataService<RetentionPolicyOdata> {
  public searchTerm = '';
  public requestPending$: Observable<boolean>;

  protected _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private myAttachedPolicies$: BehaviorSubject<AttachedPolicies> = new BehaviorSubject<AttachedPolicies>(undefined);
  private baseUrl: string;

  private readonly tagName = RetentionPolicyTagsMap.get(RetentionPolicyTagsEnum.policyName);
  private readonly tagType = RetentionPolicyTagsMap.get(RetentionPolicyTagsEnum.serviceType);
  private readonly tagLegalHold = RetentionPolicyTagsMap.get(RetentionPolicyTagsEnum.legalHold);

  private legalHoldType: Partial<DisplayNameItemType> = {
    true: {
      displayName: this.i18nPipe.transform('common.yes', { format: 'title' })
    },
    false: {
      displayName: this.i18nPipe.transform('common.no', { format: 'title' })
    }
  };

  get attachedPolicies$(): Observable<AttachedPolicies> {
    return this.myAttachedPolicies$.asObservable();
  }

  set smartSearchFilter(obj: SmartSearchModel) {
    const filter: string[] = [];

    if (obj[this.tagName.tag]) {
      const options: FilterOptions = {
        model: obj[this.tagName.tag] as SmartSearchModelField[],
        prop: this.tagName.prop
      };

      filter.push(getFilterByContains(options));
    }

    if (obj[this.tagType.tag]) {
      const options: FilterOptions = {
        model: obj[this.tagType.tag] as SmartSearchModelField[],
        prop: this.tagType.prop,
        enumItems: ServiceTypeByAPIEnum
      };

      filter.push(getFilterByEqFromEnum(options));
    }

    if (obj[this.tagLegalHold.tag]) {
      const options: FilterOptions = {
        model: obj[this.tagLegalHold.tag] as SmartSearchModelField[],
        prop: this.tagLegalHold.prop,
        enumItems: this.legalHoldType
      };

      filter.push(getFilterByEqFromEnum(options, false));
    }

    if (obj.words?.filter(Boolean)) {
      const term = filterByWords(obj);

      filter.push(containsWrapper(this.tagName.prop, term));
    }

    this.filter = filter.length > 0 ? filter.join(' and ') : '';
    this.fetchData();
  }

  constructor(odataFactory: ODataServiceFactory, private http: HttpClient, private i18nPipe: I18NextPipe) {
    super(odataFactory, 'RetentionPolicy');
    this.state.orderBy = 'Name';
    this.state.page = 1;
    this.state.pageSize = 50;
    this.baseUrl = `${odataFactory.CreateService('').Query().GetUrl()}/`;
    this.requestPending$ = getLoadingState([this._loading$.pipe(hasActionsQueue()), this.baseRequestPending$]);
  }

  prepareFilter(): void {
    this.filter = this.searchTerm ? containsWrapper(this.state.orderBy, this.searchTerm) : '';
  }

  fetchPolicies(): void {
    this.prepareFilter();
    this.fetchData();
  }

  fetchAttachedPolicies(userIds: string[], errorCallBack?: (error: any) => void): void {
    this._loading$.next(true);

    this.http
      .get<AttachedPolicies>(`${this.baseUrl}GetAttachedPolicies?userIds=${userIds.join(',')}`)
      .pipe(
        map((d: AttachedPolicies) => extendedPolicies(d)),
        finalize(() => this._loading$.next(false))
      )
      .subscribe({
        next: (policies: AttachedPolicies) => {
          this.myAttachedPolicies$.next(policies);
        },
        error: (e) => {
          this.myAttachedPolicies$.next(null);
          if (errorCallBack) {
            errorCallBack(e);
          }
        }
      });
  }

  getAttachedPolicies(userIds: string[]): Observable<AttachedPolicies> {
    return this.http
      .get<AttachedPolicies>(`${this.baseUrl}GetAttachedPolicies?userIds=${userIds.join(',')}`)
      .pipe(map((d: AttachedPolicies) => extendedPolicies(d)));
  }

  createPolicy(policy: RetentionPolicyOdata): Observable<RetentionPolicyOdata> {
    this._loading$.next(true);
    return this.odata
      .Post(policy)
      .Exec()
      .pipe(finalize(() => this._loading$.next(false)));
  }

  updatePolicy(policy: RetentionPolicyOdata): Observable<RetentionPolicyOdata> {
    this._loading$.next(true);
    return this.odata
      .Put(policy, policy.Id)
      .Exec()
      .pipe(finalize(() => this._loading$.next(false)));
  }

  deletePolicy(id: string): Observable<RetentionPolicyOdata> {
    this._loading$.next(true);
    return this.odata
      .Delete(id)
      .Exec()
      .pipe(finalize(() => this._loading$.next(false)));
  }

  setDefaultPolicy(policies: DefaultParams): Observable<any> {
    const odataDefaultPolicyService = this.odataFactory.CreateService(`/RetentionPolicy/SetDefault`);

    this._loading$.next(true);
    return this.http.post(odataDefaultPolicyService.Query().GetUrl(), policies).pipe(finalize(() => this._loading$.next(false)));
  }
}
