<section class="mbs-steps_item"
         [ngClass]="[(status ? (lastStep && status === 'active' ? '' : '-' + status) :
         (readOnly && !lastStep ? '-active' : ''))]">
  <header class="mbs-steps_item-header">
    <div class="col-12 col-sm-auto">
      <div class="row align-items-center">
        <div class="col-auto">
          <div class="mbs-steps_item-index">
            <span *ngIf="status === 'success' || singleStep"
                  class="mbs-steps_item-index-ico ico ico-ok"></span>
            {{  status === 'success' || singleStep ? '' : number }}
          </div>
        </div>
        <div class="col-auto">
          <h5 class="mbs-steps_item-title">
            <ng-content select="[title]"></ng-content>
          </h5>
        </div>
      </div>
    </div>
    <div *ngIf="status === 'active'"
         class="col-12 py-3 col-md-auto py-md-0"
         [hidden]="headerControlsContent.innerHTML.length === 0">
      <div class="row mbs-steps_item-header_controls"
           #headerControlsContent>
        <ng-content select="[controls]"></ng-content>
      </div>
    </div>
  </header>
  <div *ngIf="status  === 'active' || readOnly"
       class="mbs-steps_item-content">
    <div class="mbs-steps_item-text small" [class]="bodyClasses">
      <ng-content select="[body]"></ng-content>
    </div>
    <ng-content select="[bodyTemplate]"></ng-content>
  </div>
</section>
