import { Injectable } from '@angular/core';
import { Tagged } from './types';

export type EnumToSelectItem = { value: number; label: string | number };

const StringIsNumber = value => isNaN(Number(value)) === false;

@Injectable()
export class EnumHelper {
  static EnumToArray<T>(enumm: T): string[] {
    return Object.keys(enumm)
      .filter(StringIsNumber)
      .map(x => enumm[x] as string);
  }

  static EnumToSelectArray<T, D>(enumm: T, displayEnumm: D): Array<{ value: string; label: string | number }> {
    return Object.keys(enumm)
      .filter(StringIsNumber)
      .map(x => {
        return { value: enumm[x] as string, label: displayEnumm[x] as string | number };
      });
  }

  /** Returns array for ng-select elements where "value" is enum element's number
   * and "label" is display name for that enum element
   * @param {T} enumm Enum
   * @param {D} displayEnumm Display enum
   * @param {T[]} orderArray An array of enum elements to sort
   * @return {Array<{ value: number, label: string }>}
   */
  static EnumToSelectIndexesArray<T, D>(enumm: T, displayEnumm: D = null, orderArray: number[] = null): Array<EnumToSelectItem> {
    const result = Object.keys(enumm)
      .filter(StringIsNumber)
      .map(x => {
        return {
          value: Number(x),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          label: (displayEnumm !== null ? displayEnumm[x] || displayEnumm[enumm[x]] : enumm[x]) as string | number
        };
      });
    return orderArray ? result.sort((first, second) =>
      orderArray.findIndex((type) => type === first.value) < orderArray.findIndex((type) => type === second.value) ? -1 : 1
    ) : result;
  }

  static HasFlag<T extends number>(value: T, enumm: T): boolean {
    return Boolean(enumm & value);
  }
}
