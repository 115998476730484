import { Pipe, PipeTransform } from '@angular/core';
import { OperationType, OperationTypeByAPIEnum } from '@app/common/models';

@Pipe({
  name: 'operationTypeToDisplayName',
  pure: true
})
export class OperationTypeToDisplayNamePipe implements PipeTransform {
  transform(key: string): string {
    const item: { operationType: OperationType; displayName: string } = OperationTypeByAPIEnum[key];
    return item ? item.displayName : '';
  }
}
