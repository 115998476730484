<div class="mbs-list-select">

  <ng-container [ngSwitch]="useAdvancedSearch">

    <div *ngIf="showSearch"
         class="form-group mbs-list-select_search">

      <mbs-advanced-search *ngSwitchCase="true"
                           [(ngModel)]="advancedSearchFilters"
                           [accessUrlQuery]="false"
                           [data]="searchTemplates"
                           (search)="updateFilters($event)"></mbs-advanced-search>

      <mbs-input *ngSwitchDefault
                 type="accented"
                 [(ngModel)]="filterTerm"
                 [label]="filterLabel"
                 [placeholder]="placeholder"
                 (change)="searchChangeHandler($event)"></mbs-input>

    </div>

  </ng-container>

  <ng-container *ngTemplateOutlet="template"></ng-container>

  <ng-template #template>

    <div *ngIf="showTopControls"
         class="mbs-list-select_controls">

      <div class="row align-items-center justify-content-between">
        <div *ngIf="showTopControlsCheckbox" class="col-auto mbs-list-select_controls-checkbox">
          <mbs-checkbox *ngIf="data?.length > 0"
                        [checked]="checkedTop"
                        [indeterminate]="indeterminateTop"
                        [label]="topControlsLabel"
                        (change)="topControlsCheckboxChangeHandler($event)"></mbs-checkbox>
        </div>

        <div *ngIf="topControlsDescription"
             class="col-auto mbs-list-select_controls-description">
          {{ topControlsDescription }}
        </div>

      </div>

    </div>

    <div class="{{ classesTable }}-wrapper"
         [ngClass]="{ '-invalid': !mySelectedData.length && invalid, '-maxHeight': maxHeight, '-showTopControls': showTopControls }">

      <mbs-table [bindDisabledValues]="bindDisabledValues"
                 [bindSelected]="bindSelected"
                 [bordered]="bordered"
                 [changeSortState]="changeSortState"
                 [checkboxCellClass]="checkboxCellClass"
                 [checkboxCellWidth]="checkboxCellWidth"
                 [classesTable]="classesTable"
                 [contentClass]="tableContentClass"
                 [data]="filtered"
                 [headers]="headers"
                 [hover]="hover"
                 [isNeedSelectRow]="isNeedSelectRow"
                 [rowClasses]="rowClasses"
                 [keepState]="keepState"
                 [loaderType]="loaderType"
                 [loading]="loading"
                 [maxHeight]="maxHeight"
                 [multipleSelect]="multipleSelect"
                 [noDataMessage]="noDataMessage"
                 [selectAllIgnoreDisabled]="selectAllIgnoreDisabled"
                 [selectRowClass]="selectRowClass"
                 [selectedItems]="mySelectedData"
                 [showCheckboxes]="showCheckboxes"
                 [selectOnlyOnCheckboxClick]="selectOnlyOnCheckboxClick"
                 [showHeaders]="showHeaders"
                 [size]="size"
                 [striped]="striped"
                 [templates]="tableTemplates?.toArray()"
                 (changeSelected)="handleChangeItemChecked($event)"
                 (sort)="sort.emit($event)">
      </mbs-table>

    </div>

  </ng-template>

  <ng-template #topControlsLabel>

    <ng-template [ngTemplateOutlet]="prependTopControlsLabel?.template"></ng-template>

    <span *ngIf="topControlsLabelSelected">
      {{ selectedData?.length }}
    </span>

    <ng-template [ngTemplateOutlet]="appendTopControlsLabel?.template"></ng-template>

  </ng-template>

</div>
