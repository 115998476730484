import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { ButtonModule } from '../button/button.module';
import { AdvancedSearchComponent } from './advanced-search.component';

@NgModule({
  declarations: [AdvancedSearchComponent],
  imports: [CommonModule, NgbDropdownModule, NgbTypeaheadModule, FormsModule, ButtonModule],
  exports: [AdvancedSearchComponent],
  providers: []
})
export class AdvancedSearchModule {}
