<ng-container [formGroup]="form">
  <mbs-input formControlName="password"
             [autocomplete]="'off'"
             [id]="id"
             [label]="label"
             [placeholder]="placeholder"
             [size]="size"
             [trim]="false"
             [type]="passwordInputType"
             [validationWhitespace]="validationWhitespace"
             (blur)="handleBlur($event)"
             (focus)="handleFocus($event)"
             (input)="inputPassHandler($event)"
             (buttonClick)="changeTypePassword()"
             [prependButtons]="prependButtons"
             [appendButtons]="[
               {
                 icon: passwordInputType === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash',
                 id: 'changeTypePassword',
                 disabled: disabledState || disabledEye,
                 type: 'secondary'
               }
             ]">
    <ng-content select="[prependLabel]"
                ngProjectAs="[prependLabel]"></ng-content>
    <ng-content select="[appendLabel]"
                ngProjectAs="[appendLabel]"></ng-content>
  </mbs-input>
</ng-container>
