import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { pad } from '../../../utils/constants';

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class ShortDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const [month, day, year] = new Date(value).toLocaleDateString('en-US').split(this.DELIMITER);
      return {
        month: parseInt(month, 10),
        day: parseInt(day, 10),
        year: parseInt(year, 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? `${pad(date.month)} ${this.DELIMITER} ${pad(date.day)} ${this.DELIMITER} ${date.year}` : '';
  }
}
