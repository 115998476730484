import { Directive, Inject, Optional } from '@angular/core';
import { TabsetItemDirectiveToken, TabsetItemDirectiveType } from '../tokens/tabset-item.directive.token';
import { TabsetDirectiveToken, TabsetDirectiveType } from '../tokens/tabset.directive.token';

@Directive({
  selector: '[mbsTabsetLink]',
  host: {
    '[class.mbs-tabset_link]': '!nested',
    '[class.mbs-tabset_group-link]': 'nested',
    '[class.-disabled]': 'isDisabled',
    '(click)': 'parentTabset.click(parentTab); $event.stopPropagation(); $event.preventDefault()'
  }
})
export class TabsetLinkDirective {
  public nested = false;

  get isDisabled(): boolean {
    return this.parentTab.isDisabled;
  }

  constructor(
    @Optional() @Inject(TabsetItemDirectiveToken) public parentTab: TabsetItemDirectiveType,
    @Optional() @Inject(TabsetDirectiveToken) public parentTabset: TabsetDirectiveType
  ) {
    this.nested = this.parentTab.nested;
  }
}
