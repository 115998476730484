import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { ButtonModule } from '../button/button.module';
import { SharedPipesModule } from '../utils/Pipes/pipes.module';
import { ToastComponent } from './toast.component';

@NgModule({
  imports: [CommonModule, NgbToastModule, SharedPipesModule, ButtonModule],
  declarations: [ToastComponent],
  exports: [ToastComponent]
})
export class ToastModule {}
