export enum Role {
  ProviderSignIn = 'ProviderSignIn',
  DomainAdmin = 'DomainAdmin',
  UserAccountAdministrator = 'UserAccountAdministrator',
  SingleUser = 'SingleUser',
  DomainUser = 'DomainUser',
  EmergencySignIn = 'EmergencySignIn',
  SystemAdmin = 'SystemAdmin',
  LimitedAdmin = 'LimitedAdmin' // it isn't hasLimitedAdminRole()
}

export enum ProviderRole {
  CompanyAdministrator = 'CompanyAdministrator',
  UserAccountAdministrator = 'UserAccountAdministrator',
  Other = 'UserAccountAdministrator'
}

export function hasDomainAdminRole(roles: Role[]): boolean {
  return roles.some((r) => r == Role.DomainAdmin); // sign in with GAA permission
}

export function hasLimitedAdminRole(roles: Role[], providerRoles: ProviderRole[]): boolean {
  return roles.some((r) => r == Role.UserAccountAdministrator) && providerRoles.some((pr) => pr == ProviderRole.CompanyAdministrator); // sign in without GAA permission
}

export function hasAdminRole(roles: Role[]): boolean {
  return roles.some((r) => r == Role.DomainAdmin || r == Role.UserAccountAdministrator);
}

export function isEmergencySignIn(roles: Role[]): boolean {
  return roles.some((r) => r == Role.EmergencySignIn);
}

export function hasUserAccountAdminRole(roles: Role[]): boolean {
  return roles.some((r) => r == Role.UserAccountAdministrator);
}

// TODO: need check different between `hasUserAccountAdminRole` and current method with `!CompanyAdministrator` in components conditions
// export function isUserAccountAdmin(roles: Roles[], providerRoles: ProviderRole[]): boolean {
//   return roles.some((r) => r == Roles.UserAccountAdministrator) && !providerRoles.some((pr) => pr == ProviderRole.CompanyAdministrator);
// }

export function hasDomainUserRole(roles: Role[]): boolean {
  return roles.some((r) => r == Role.DomainUser);
}

export function hasSingleUserRole(roles: Role[]): boolean {
  return roles.some((r) => r == Role.SingleUser);
}

export function hasProviderRole(roles: Role[]): boolean {
  return roles.some((r) => r == Role.ProviderSignIn);
}
