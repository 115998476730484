<mbs-alert *ngIf="disabledServices.length > 0 && disabledServicesWarningText"
           [type]="MbsPopupType.warning"
           [icon]="true">
  {{ disabledServicesWarningText }}
</mbs-alert>

<form [formGroup]="formGroup">

  <mbs-table-grid [headers]="headers"
                  [data]="servicesInfo"
                  [viewMode]="viewMode"
                  [stripedTable]="false"
                  [switcherView]="false"
                  [disableViewSwitch]="true">

    <ng-template mbsTableHeader="service">
      <label for="backupEnabled"
             class="mbs-form_label mb-0 text-sm">
        {{ moduleUsers + ':sidepanel.grid.user' | i18next: { format: 'title' } }}
      </label>
    </ng-template>

    <ng-template mbsTableHeader="enabled">
      <mbs-switcher formControlName="backupEnabled"
                    [disabled]="!backupEnabled"
                    [size]="MbsSize.sm"
                    [id]="'backupEnabled'"
                    [labelPosition]="'left'"
                    [statusPosition]="'right'"
                    [showStatus]="true"></mbs-switcher>
    </ng-template>

    <ng-template mbsTableCell
                 let-service>
      <a [routerLink]="prefix + service.info.url(userIdForLink)"
         class="text-decoration-none">
        <span [ngClass]="service.info.iconCssClass"
              class="text-dark text-lg mr-3"></span>
        <span class="text-md text-decoration-underline-hover">
          {{ service.info.displayName }}
        </span>
      </a>
    </ng-template>

    <ng-template mbsTableCell
                 let-service>
      <mbs-switcher [formControlName]="service.value.serviceType"
                    [size]="MbsSize.sm"
                    [labelPosition]="'left'"
                    [statusPosition]="'right'"
                    [showStatus]="true"></mbs-switcher>
    </ng-template>

  </mbs-table-grid>

</form>
