import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import Alert from '../alert.model';
import { AlertService } from '../alert.service';

@UntilDestroy()
@Component({
  selector: 'mbs-alert-container',
  templateUrl: 'alert-container.component.html',
  host: {
    class: 'mbs-alert-container',
    '[style.width]': 'width',
    '[style.min-width]': 'minWidth',
    '[style.max-width]': 'maxWidth'
  }
})
export class AlertContainerComponent implements OnInit {
  public alerts: Alert[] = [];
  private maxVisible = 2;

  public get visibleAlerts(): Alert[] {
    return this.alerts.slice(0, this.maxVisible);
  }

  /**
   * Set style `width` as inline style
   */
  @Input() width: string;

  /**
   * Set style `min-width` as inline style
   */
  @Input() minWidth: string;

  /**
   * Set style `max-width` as inline style
   */
  @Input() maxWidth: string;

  constructor(private alertService: AlertService) {}

  ngOnInit(): void {
    this.alertService.alert$.pipe(untilDestroyed(this)).subscribe((alert: Alert) => {
      // drop `alert` with broken data
      if (!alert.content) {
        // filter out alerts without 'keepAfterRouteChange' flag
        this.alerts = this.alerts.filter((x) => x.keepAfterRouteChange);

        // remove 'keepAfterRouteChange' flag on the rest
        this.alerts.forEach((x) => delete x.keepAfterRouteChange);
        return;
      }

      // add new `alert` to array
      this.alerts.push(alert);

      // auto close alert if required
      if (alert.autoClose > 0) {
        setTimeout(() => this.removeAlert(alert), alert.autoClose);
      }
    });
  }

  /**
   * Removed `Alert` from visible
   * @param {Alert} alert
   */
  removeAlert(alert: Alert): void {
    // check if already removed to prevent error on auto close
    if (!this.alerts.includes(alert)) return;

    if (alert.closeCallback) {
      alert.closeCallback(() => {
        this.alerts = this.alerts.filter((x) => x !== alert);
        this.alertService.close(alert);
      });
    } else {
      this.alerts = this.alerts.filter((x) => x !== alert);
      this.alertService.close(alert);
    }
  }
}
