export enum ServiceColors {
  Mail = '#4285F4',
  Email = '#4285F4',
  Drive = '#EA4335',
  Contacts = '#34A853',
  Calendar = '#FBBC05',
  SharePoint = '#4dbfd9',
  TeamDrives = '#4dbfd9'
}

export const COLORS = [
  '#3366cc',
  '#dc3912',
  '#109618',
  '#990099',
  '#0099c6',
  '#dd4477',
  '#66aa00',
  '#b82e2e',
  '#316395',
  '#994499',
  '#22aa99',
  '#aaaa11',
  '#6633cc',
  '#e67300',
  '#8b0707',
  '#329262',
  '#5574a6',
  '#3b3eac',
  '#b77322'
];

export interface PieChartData {
  count: {
    label: string[];
    data: number[];
  };
  size: {
    label: string[];
    data: number[];
  };
}
