import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExportToPST, ExportToPSTEvents } from '@app/common/models';
import { ODataService, ODataServiceFactory } from '@app/common/odata';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExportToPSTService {
  private odataMethods: ODataService<void>;

  constructor(private odataFactory: ODataServiceFactory) {
    this.odataMethods = odataFactory.CreateService('');
  }

  /*
   * Use for Users page
   * @param payload
   */
  exportUserData(payload: ExportToPST): Observable<ExportToPST> {
    return this.odataMethods.CustomCollectionAction('ExportUserData', payload).pipe(map((res: HttpResponse<ExportToPST>) => res.body));
  }

  /*
   * Use for Mail page
   * @param payload
   */
  exportMailMsgs(payload: ExportToPSTEvents): Observable<ExportToPSTEvents> {
    return this.odataMethods
      .CustomCollectionAction('ExportMailMsgs', payload)
      .pipe(map((res: HttpResponse<ExportToPSTEvents>) => res.body));
  }

  /*
   * Use for Mail page
   * @param payload
   */
  exportMailFolders(payload: ExportToPSTEvents): Observable<ExportToPSTEvents> {
    return this.odataMethods
      .CustomCollectionAction('ExportMailFolders', payload)
      .pipe(map((res: HttpResponse<ExportToPSTEvents>) => res.body));
  }

  /*
   * Use for Contacts page
   * @param payload
   */
  exportContactItems(payload: ExportToPSTEvents): Observable<ExportToPSTEvents> {
    return this.odataMethods
      .CustomCollectionAction('ExportContactItems', payload)
      .pipe(map((res: HttpResponse<ExportToPSTEvents>) => res.body));
  }

  /*
   * Use for Contacts page
   * @param payload
   */
  exportContactGroups(payload: ExportToPSTEvents): Observable<ExportToPSTEvents> {
    return this.odataMethods
      .CustomCollectionAction('ExportContactGroups', payload)
      .pipe(map((res: HttpResponse<ExportToPSTEvents>) => res.body));
  }

  /*
   * Use for Calendar page
   * @param payload
   */
  exportCalendarEvents(payload: ExportToPSTEvents): Observable<ExportToPSTEvents> {
    return this.odataMethods
      .CustomCollectionAction('ExportCalendarEvents', payload)
      .pipe(map((res: HttpResponse<ExportToPSTEvents>) => res.body));
  }

  /*
   * Use for Calendar page
   * @param payload
   */
  exportCalendarItems(payload: ExportToPSTEvents): Observable<ExportToPSTEvents> {
    return this.odataMethods
      .CustomCollectionAction('ExportCalendarItems', payload)
      .pipe(map((res: HttpResponse<ExportToPSTEvents>) => res.body));
  }

  /*
   * Use for Calendar page
   * @param payload
   */
  exportCalendarGroups(payload: ExportToPSTEvents): Observable<ExportToPSTEvents> {
    return this.odataMethods
      .CustomCollectionAction('ExportCalendarGroups', payload)
      .pipe(map((res: HttpResponse<ExportToPSTEvents>) => res.body));
  }
}
