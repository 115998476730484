import {
  Attribute,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Renderer2,
  TemplateRef
} from '@angular/core';
import { InputButton } from './input-button';

export type Position = 'prepend' | 'append';

@Component({
  selector: 'mbs-input-pre-append',
  templateUrl: './input-pre-append.component.html'
})
export class InputPreAppendComponent {
  @Input() data: InputButton[];
  @Input() disabled: boolean;
  @Input('dataTemplate') set myDataTemplate(template: TemplateRef<any>) {
    this.dataTemplate = template;
  }

  /**
   * Radio size `lg` or `sm`. From parent input.
   */
  @Input() public size: 'lg' | 'sm' = null;

  @Output() buttonClick = new EventEmitter<InputButton>();
  @Output() keyupEnter = new EventEmitter<InputButton>();
  @ContentChild(TemplateRef, { read: TemplateRef, static: true }) dataTemplate: TemplateRef<any>;

  constructor(@Attribute('position') position: Position, private el: ElementRef<any>, renderer2: Renderer2) {
    renderer2.addClass(el.nativeElement, 'input-group-' + position);
  }

  generateButtonClasses(button: InputButton): string[] {
    const size = button.size || this.size;
    return ['btn', 'btn-' + button.type || '', size ? 'btn-' + size : '', button.loading ? '-loading' : ''];
  }

  @HostListener('document:keyup.enter', ['$event'])
  handleKeyupEnter(event: InputButton): void {
    if (!event) return;
    this.keyupEnter.emit(event);
  }
}
