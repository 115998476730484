<div ngbDropdown
     container="body"
     [dropdownClass]="dropdownClass"
     placement="bottom-left"
     class="mbs-tags-input">

  <mbs-input-label *ngIf="label && id"
                   [label]="label"
                   [id]="id"
                   [disabled]="disabledState"
                   [boldLabel]="boldLabel"
                   [info]="info"
                   [labelSize]="labelSize"
                   [infoPlacement]="infoPlacement"
                   [infoTriggers]="infoTriggers"
                   [infoContainer]="infoContainer"
                   [required]="hasRequiredValidator && showRequiredMark"
                   [labelClasses]="labelClasses"
                   [labelContentClasses]="labelContentClasses">

    <ng-content select="[prependLabel]"
                ngProjectAs="[prependLabel]"></ng-content>

    <ng-content select="[appendLabel]"
                ngProjectAs="[appendLabel]"></ng-content>

  </mbs-input-label>

  <div class="mbs-tags-input_box"
       ngbDropdownAnchor
       [class.-focused]="isFocused"
       [class.overflow-hidden]="isCollapseMode"
       [style.max-height.px]="mode === modeEnum.Collapsed ? collapseHeight : scrollHeight"
       [style.min-height.px]="minHeight"
       [class.-error]="control?.invalid"
       #container>

    <ng-container *ngFor="let item of value; let index = index">
      <mbs-tag [closable]="true"
               [noBorder]="true"
               [class.-error]="item.errors?.length"
               [class.bg-red-20]="item.errors?.length"
               [disabled]="loading || item.disabled || disabled"
               [active]="item.active"
               [id]="idPrefix + item.name"
               [size]="size"
               (mbsClose)="handleClose(index)">
        {{ item.name }}
      </mbs-tag>
    </ng-container>

    <ng-container *ngIf="(showExtendButton$ | async) === modeEnum.Collapsed">
      <div class="mbs-tags-input_extend-button">
        <mbs-button [isDashed]="true"
                    [disabled]="disabled"
                    (click)="(mode = modeEnum.Opened)"
                    type="muted">Show All</mbs-button>
      </div>
    </ng-container>

    <ng-container *ngIf="(showExtendButton$ | async) === modeEnum.Opened">
      <mbs-button [isDashed]="true"
                  [disabled]="disabled"
                  (click)="(mode = modeEnum.Collapsed)"
                  class="align-self-center"
                  type="muted">Hide All</mbs-button>
    </ng-container>

    <input *ngIf="!isStatic"
           class="mbs-tags-input_input"
           #inputField
           [class.-invalid]="inputControl.invalid"
           ngbDropdownToggle
           [formControl]="inputControl"
           [tabindex]="tabindex"
           [attr.aria-describedby]="id"
           [attr.aria-labelledby]="label"
           [placeholder]="placeholder"
           [ngClass]="bindClasses"
           [id]="id"
           [autocomplete]="'off'"
           (blur)="handleBlur()"
           (focus)="handleFocus()"
           (input)="handleChange($event)"
           (keydown)="handleKeyDown($event)"
           [readonly]="loading" />

    <span *ngIf="loading"
          class="loader ml-auto mr-2 d-inline-flex align-self-center"></span>
  </div>

  <mbs-input-errors *ngIf="control.invalid"
                    [errors]="mapErrors(control.errors)"></mbs-input-errors>

  <div *ngIf="!isStatic"
       [style.max-width.px]="container.offsetWidth > 250 ? container.offsetWidth : 250"
       aria-labelledby="tags-menu-button"
       ngbDropdownMenu>

    <mbs-button *ngIf="actionButton && inputControl.value && !filteredSuggestions.includes(inputControl.value)"
                class="border-bottom pb-2"
                [customClasses]="'overflow-hidden'"
                (click)="onActionButtonPressed()"
                ngbDropdownItem
                [isCtrl]="true">
      <span class="text-overflow lh-base">
        {{ actionButton }} {{ inputControl.value }}
      </span>
    </mbs-button>

    <button *ngFor="let suggestion of filteredSuggestions"
            ngbDropdownItem
            class="overflow-hidden"
            (click)="onSuggestionItemClick(suggestion)">
      <span class="text-overflow">
        {{ suggestion }}
      </span>
    </button>

  </div>

</div>
