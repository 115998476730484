import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AdvancedSelectComponent } from './advanced-select.component';

@NgModule({
  declarations: [AdvancedSelectComponent],
  imports: [CommonModule, FormsModule, NgSelectModule],
  exports: [AdvancedSelectComponent],
  providers: []
})
export class AdvancedSelectModule {
  constructor() {
    // empty
  }
}
