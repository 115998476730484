import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuditODataItem, UserActionType, UserActionTypeArea } from '@app/common/models';
import { ODataPagedResult, ODataService, ODataServiceFactory } from '@app/common/odata';
import { UserActionTypeAreaPipe, UserActionTypePipe } from '@app/common/pipes';
import { SharedOdataService } from '@app/common/services';
import { AuditLogTagsEnum, AuditLogTagsMap } from '@app/common/services/advanced-search';
import { getUniqueListByProp } from '@app/common/services/smart-search';
import {
  FilterOptions,
  containsWrapper,
  filterByWords,
  getFilterByContains,
  getFilterByDateRange,
  getFilterByEqFromEnum
} from '@app/common/utils/functions/search';
import { SmartSearchModel, SmartSearchModelField } from 'mbs-ui-kit';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuditService extends SharedOdataService<AuditODataItem> {
  readonly #event = AuditLogTagsMap.get(AuditLogTagsEnum.event);
  readonly #action = AuditLogTagsMap.get(AuditLogTagsEnum.action);
  readonly #userName = AuditLogTagsMap.get(AuditLogTagsEnum.userName);
  readonly #from = AuditLogTagsMap.get(AuditLogTagsEnum.from);
  readonly #to = AuditLogTagsMap.get(AuditLogTagsEnum.to);

  private userActionTypePipe = new UserActionTypePipe();
  private userActionTypeAreaPipe = new UserActionTypeAreaPipe();

  private odataMethods: ODataService<any>;

  constructor(odataFactory: ODataServiceFactory, private http: HttpClient) {
    super(odataFactory, 'Audit');
    this.odataMethods = odataFactory.CreateService('');
    this.orderBy = 'Date desc';
  }

  getAuditLog(): Observable<ODataPagedResult<AuditODataItem>> {
    const odataService = this.odataFactory.CreateService<AuditODataItem>(`/Audit`);

    return this.fetchData<AuditODataItem>(odataService);
  }

  /*
   * Get top 20 events in 'Event' section that match `containsValue`
   * @param {string} containsValue search string
   */
  public getAuditLogInEventSection(containsValue: string): Observable<string[]> {
    return this.getAuditLog().pipe(
      map(({ count, data }) => data),
      map((items) => {
        const filtered = items.filter((item) => item.UserActionTypeArea.toLowerCase().includes(containsValue));

        return getUniqueListByProp(filtered, this.#event.prop)
          .slice(0, 10)
          .map((item) => this.userActionTypeAreaPipe.transform(item.UserActionTypeArea));
      })
    );
  }

  /*
   * Get top 20 actions in 'Action' section that match `containsValue`
   * @param {string} containsValue search string
   */
  public getAuditLogInActionSection(containsValue: string): Observable<string[]> {
    return this.getAuditLog().pipe(
      map(({ count, data }) => data),
      map((items) => {
        const filtered = items.filter((item) => item.UserActionType.toLowerCase().includes(containsValue));

        return getUniqueListByProp(filtered, this.#action.prop)
          .slice(0, 10)
          .map((item) => this.userActionTypePipe.transform(item.UserActionType));
      })
    );
  }

  /*
   * Get top 10 user names that match `containsValue`
   * @param {string} containsValue search string
   */
  public getAuditLogInUserNameSection(containsValue: string): Observable<string[]> {
    return this.getAuditLog().pipe(
      map(({ count, data }) => data),
      map((items) => {
        const filtered = items.filter((item) => item.UserName.toLowerCase().includes(containsValue));

        return getUniqueListByProp(filtered, this.#userName.prop)
          .slice(0, 10)
          .map((item) => item.UserName);
      })
    );
  }

  exportToCSV(): Observable<Blob> {
    const odataService: ODataService<Blob> = this.odataFactory.CreateService<Blob>(`/Audit/ExportToCsv?${this.getQueryParams(this.odata)}`);

    return this.http.get(odataService.Query().GetUrl(), { responseType: 'blob' });
  }

  updateFilter(obj: SmartSearchModel): void {
    const filter: string[] = [];

    if (obj[this.#userName.tag]) {
      const options: FilterOptions = {
        model: obj[this.#userName.tag] as SmartSearchModelField[],
        prop: this.#userName.prop
      };

      filter.push(getFilterByContains(options));
    }

    if (obj[this.#event.tag]) {
      const options: FilterOptions = {
        model: obj[this.#event.tag] as SmartSearchModelField[],
        prop: this.#event.prop,
        enumItems: UserActionTypeArea
      };

      filter.push(getFilterByEqFromEnum(options));
    }

    if (obj[this.#action.tag]) {
      const options: FilterOptions = {
        model: obj[this.#action.tag] as SmartSearchModelField[],
        prop: this.#action.prop,
        enumItems: UserActionType
      };

      filter.push(getFilterByEqFromEnum(options));
    }

    if (obj[this.#from.tag]) {
      const options: FilterOptions = {
        model: obj[this.#from.tag] as SmartSearchModelField[],
        prop: this.#from.prop
      };
      const value = getFilterByDateRange(options, 'from');

      value && filter.push(value);
    }

    if (obj[this.#to.tag]) {
      const options: FilterOptions = {
        model: obj[this.#to.tag] as SmartSearchModelField[],
        prop: this.#to.prop
      };
      const value = getFilterByDateRange(options, 'to');

      value && filter.push(value);
    }

    if (obj.words?.filter(Boolean)) {
      const term = filterByWords(obj);

      filter.push(containsWrapper(this.#userName.prop, term));
    }

    this.filter = filter.length > 0 ? filter.join(' and ') : '';
  }
}
