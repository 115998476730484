import {
  AfterContentChecked,
  ChangeDetectorRef,
  ContentChildren,
  Directive,
  forwardRef,
  Inject,
  Input,
  Optional,
  QueryList,
  SkipSelf
} from '@angular/core';
import { isNil } from 'lodash';
import { TabsetItemDirectiveToken } from '../tokens/tabset-item.directive.token';
import { TabsetDirectiveToken, TabsetDirectiveType } from '../tokens/tabset.directive.token';
import { TabsetContentDirective } from './tabset-content.directive';

/**
 * The directive used to group Tab link and related Tab content.
 * As well as set Tab identifier and some options.
 */
@Directive({
  selector: '[mbsTabsetItem]',
  exportAs: 'mbsTabsetItem',
  host: {
    '[class.mbs-tabset_item]': '!nested',
    '[class.mbs-tabset_group-item]': 'nested',
    '[class.-active]': 'isActiveAndNotDisabled',
    '[class.-disabled]': 'isDisabled',
    '[class.-loading]': 'isLoading'
  },
  providers: [{ provide: TabsetItemDirectiveToken, useExisting: forwardRef(() => TabsetItemDirective) }]
})
export class TabsetItemDirective implements AfterContentChecked {
  public nested = false;
  public forceDisabled = false;
  public forceLoading = false;
  public contentTemplate: TabsetContentDirective;

  @Input() disabled: boolean;
  @Input() loading: boolean;
  @Input() fullHeight: boolean;
  @Input() loadOnAppearance: boolean;
  @Input('mbsTabsetItem') id: string;
  @Input() linkId: string;

  @Input() set active(isActive: boolean) {
    isActive && this.activateSelf();
  }

  @ContentChildren(TabsetContentDirective, { descendants: false }) contentDirectives: QueryList<TabsetContentDirective>;
  @ContentChildren(forwardRef(() => TabsetItemDirective), { descendants: true }) nestedItems: QueryList<TabsetItemDirective>;

  get isActiveAndNotDisabled(): boolean {
    return this.isActive ? !this.disabled && !this.forceDisabled : false;
  }

  get isActive(): boolean {
    if (this.id === this.parentTabset.activeId) return true;

    const nested = this.nestedItems.toArray();

    return nested.length > 0 && !!nested.find((item) => item.id === this.parentTabset.activeId);
  }

  get isDisabled(): boolean {
    return this.disabled || this.forceDisabled || (this.parentTab && this.parentTab.isDisabled);
  }

  get isLoading(): boolean {
    return this.loading || this.forceLoading || (this.parentTab && this.parentTab.isLoading);
  }

  constructor(
    @Optional() @Inject(TabsetDirectiveToken) public parentTabset: TabsetDirectiveType,
    @SkipSelf() @Optional() @Inject(forwardRef(() => TabsetItemDirective)) public parentTab: TabsetItemDirective,
    private cdRef: ChangeDetectorRef
  ) {
    this.nested = !isNil(this.parentTab);

    if (isNil(this.loadOnAppearance)) {
      this.loadOnAppearance = this.parentTabset.loadOnAppearance;
    }
  }

  detectChanges(): void {
    this.cdRef.detectChanges();
  }

  activateSelf(): void {
    this.parentTabset.select(this.id);
  }

  isPanelInDom(): boolean {
    return (
      (isNil(this.loadOnAppearance) ? !this.loadOnAppearance : !this.parentTabset.loadOnAppearance) ||
      (this.isActive && !this.nested && this.nestedItems.toArray().length === 0) ||
      (this.isActive && !isNil(this.parentTab))
    );
  }

  ngAfterContentChecked(): void {
    // We are using @ContentChildren instead of @ContentChild as in the Angular version being used
    // only @ContentChildren allows us to specify the {descendants: false} option.
    // Without {descendants: false} we are hitting bugs described in:
    // https://github.com/ng-bootstrap/ng-bootstrap/issues/2240
    this.contentTemplate = this.contentDirectives.first;
  }
}
