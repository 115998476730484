<table class="table table-hover border">
  <colgroup>
    <col style="width: 85%;">
    <col style="width: 15%;">
  </colgroup>
  <thead>
    <tr class="background-light">
      <th>{{ moduleUsers + ':sidepanel.grid.userPermission' | i18next: { format: 'title' } }}</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>{{ moduleUsers + ':sidepanel.grid.signIn' | i18next: { format: 'title' } }}</td>
      <td class="text-right py-2">
        <mbs-switcher [labelPosition]="'left'"
                      [statusPosition]="'right'"
                      [showStatus]="true"
                      [(ngModel)]="user.SignInEnable"></mbs-switcher>
      </td>
    </tr>
    <tr>
      <td>{{ moduleUsers + ':sidepanel.grid.restore' | i18next: { format: 'title' } }}</td>
      <td class="text-right py-2">
        <mbs-switcher [labelPosition]="'left'"
                      [statusPosition]="'right'"
                      [showStatus]="true"
                      [(ngModel)]="user.RestoreEnable"></mbs-switcher>
      </td>
    </tr>
  </tbody>
</table>
<app-alternate-account-settings *ngIf="!(isProviderSignIn$ | async) && !forManyUsers"
                                [user]="user"
                                [alternateEmailInfo]="alternateEmailInfo"
                                [alternateSelected]="alternateSelected"
                                (alternateSelectedChange)="alternateSelectedChange.emit($event)"
                                (formsInvalid)="formsInvalid.emit($event)"
                                (editAlternateEmailModel)="editAlternateEmailModel.emit($event)"
                                (newAlternateEmailModel)="newAlternateEmailModel.emit($event)">
</app-alternate-account-settings>
