import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { I18_NAMESPACE_MODULE } from '@app/i18';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { I18NextModule, I18NEXT_NAMESPACE } from 'angular-i18next';
import { MbsUiKitModule } from 'mbs-ui-kit';
import { EditCreateRetentionPolicyModalComponent } from './edit-create-retention-policy-modal.component';

@NgModule({
  imports: [I18NextModule, CommonModule, MbsUiKitModule, FormsModule, ReactiveFormsModule, NgbTooltipModule],
  declarations: [EditCreateRetentionPolicyModalComponent],
  providers: [
    {
      provide: I18NEXT_NAMESPACE,
      useValue: [I18_NAMESPACE_MODULE.policy]
    }
  ]
})
export class EditCreateRetentionPolicyModalModule {}
