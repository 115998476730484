import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AuthUser,
  ConfigureServices,
  hasAdminRole,
  OdataServiceStatistics,
  OdataServiceStatisticsSave,
  Role,
  ServiceStatistics,
  ServiceStatisticsSaveObject,
  ServiceType
} from '@app/common/models';
import { ODataService, ODataServiceFactory } from '@app/common/odata';
import { AuthService, OdataObject } from '@app/common/services';
import { get, isNil } from 'lodash';
import { forkJoin, Observable } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private readonly backupMethods: ODataService<any>;
  private readonly domainMethods: ODataService<any>;
  private readonly requestScopeUrl: ODataService<any>;
  private readonly userData$: { user: Observable<AuthUser>; roles: Observable<Role[]> };

  constructor(odataFactory: ODataServiceFactory, authService: AuthService, private http: HttpClient) {
    this.backupMethods = odataFactory.CreateService('BackupUsers');
    this.domainMethods = odataFactory.CreateService('Domains');
    this.requestScopeUrl = odataFactory.CreateService('RequestScopeUrl');
    this.userData$ = {
      user: authService.getAuthUser().pipe(shareReplay(1)),
      roles: authService.getRoles().pipe(shareReplay(1))
    };
  }

  getServiceStatistics(): Observable<ServiceStatistics[]> {
    const methodName = 'ServiceStatistics';

    return forkJoin(this.userData$).pipe(
      switchMap(({ user, roles }) => {
        const isAdmin = hasAdminRole(roles);
        const id: string = this.getId(isAdmin, user);
        const service: ODataService<any> = this.getServiceMethod(isAdmin);

        return service.ItemProperty<OdataObject<OdataServiceStatistics[]>>(id, methodName).pipe(
          map((r: OdataObject<OdataServiceStatistics[] | null>) => {
            if (isNil(r)) return [] as ServiceStatistics[];

            return r.value.map((odataObj: OdataServiceStatistics) => new ServiceStatistics(odataObj));
          })
        );
      })
    );
  }

  configureServices(saveData: ServiceStatisticsSaveObject): Observable<ConfigureServices[]> {
    return forkJoin(this.userData$).pipe(
      switchMap(({ user, roles }) => {
        const isAdmin = hasAdminRole(roles);
        const id: string = this.getId(isAdmin, user);
        const service: ODataService<ConfigureServices[]> = this.getServiceMethod(isAdmin);
        const url = `${service.Query().GetUrl()}(${id})/ConfigureServices`;

        const postBody: OdataServiceStatisticsSave = {
          ApplyToEnabledUsers: saveData.forAllEnabledUsers,
          Services: saveData.services.map((s) => {
            return {
              Service: ServiceType[s.serviceType],
              Enabled: s.enabled
            };
          })
        };

        return this.http.post<ConfigureServices[]>(url, postBody).pipe(map((res) => get(res, 'value')));
      })
    );
  }

  private getId = (isAdmin: boolean, user: AuthUser): string => (isAdmin ? user.DomainId : user.Id);

  private getServiceMethod(isAdmin: boolean): ODataService<any> {
    return isAdmin ? this.domainMethods : this.backupMethods;
  }

  getRequestScopeUrl(services: ServiceType[]): Observable<string> {
    return this.requestScopeUrl
      .Query()
      .CustomQueryOptions({ key: 'services', value: services.map((s) => ServiceType[s]).join(',') })
      .Exec() as unknown as Observable<string>;
  }
}
