export enum RoutePath {
  Account = 'account',
  AuditLog = 'audit-log',
  AuthCallback = 'auth-callback',
  BackupHistory = 'backup-history',
  Calendar = 'calendar',
  Contacts = 'contacts',
  Dashboard = 'dashboard',
  Drive = 'drive',
  ExportResults = 'export-results',
  GoogleOffice365 = 'gapps-office365',
  Help = 'help',
  History = 'history',
  Login = 'login',
  Mail = 'mail',
  NotFound = 'not-found',
  Payments = 'payments',
  Reports = 'reports',
  RetentionPolicy = 'retention-policy',
  SharedDrives = 'shared-drives',
  Sharepoint = 'sharepoint',
  SkipItems = 'skipped-items-history',
  TaskManager = 'task-manager',
  Teams = 'teams',
  UserGroups = 'user-groups',
  Users = 'users'
}

export enum ProviderPath {
  Alternate = 'alternate',
  Google = 'gapps',
  Office = 'office'
}
