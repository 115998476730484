<span class="mbs-tag_content overflow-hidden">
  <mbs-text-ellipsis [tooltip]="tooltip">
    <ng-content></ng-content>
  </mbs-text-ellipsis>
</span>

<mbs-button class="mbs-tag_close-btn"
            [isCtrl]="true"
            [icon]="closeIcon"
            [type]="mbsType.dark"
            [disabled]="disabled"
            [size]="size ? mbsSize.xxs : undefined"
            (click)="mbsClose.emit(id)"
            *ngIf="!clickable && closable"></mbs-button>
