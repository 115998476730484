import { Pipe, PipeTransform } from '@angular/core';
import { ReportStatus } from '@app/common';

@Pipe({
  name: 'backupStatus',
  pure: true
})
export class BackupStatusPipe implements PipeTransform {
  private readonly iconMap: Map<ReportStatus, string> = new Map([
    [ReportStatus.issue, 'fa fa-exclamation-circle'],
    [ReportStatus.overdue, 'fa fa-clock-o'],
    [ReportStatus.warning, 'fa fa-exclamation-triangle'],
    [ReportStatus.success, 'fa fa-check-circle']
  ]);

  private readonly typeMap: Map<ReportStatus, string> = new Map([
    [ReportStatus.issue, 'danger'],
    [ReportStatus.overdue, 'yellow'],
    [ReportStatus.warning, 'warning'],
    [ReportStatus.success, 'success']
  ]);

  transform(status: ReportStatus, param: 'icon' | 'type' = 'icon'): string {
    if (!status) return '';

    switch (param) {
      case 'icon': {
        return this.iconMap.get(status);
      }
      case 'type': {
        return this.typeMap.get(status);
      }
      default:
        throw new Error('Unknown report status. BackupStatusPipe');
    }
  }
}
