import { Inject } from '@angular/core';
import { BrowserStorage, WINDOW } from '@app/common';

export abstract class AbstractStorage {
  protected readonly prefix = BrowserStorage.Prefix;

  protected constructor(@Inject(WINDOW) protected window: Window) {}

  abstract set<T>(key: string, data: T): void;

  abstract get<T>(key: string): T;

  abstract remove(key: string): void;
}
