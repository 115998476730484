import { TagInputItem } from 'mbs-ui-kit/form/tags-input/tags-input.types';

export interface DefaultFoldersFilter {
  IncludeType: FolderFilterType;
}

export interface FoldersFilter extends DefaultFoldersFilter {
  Included: string[];
  Excluded: string[];
}

export interface FoldersFilterDev extends DefaultFoldersFilter {
  Included: TagInputItem[];
  Excluded: TagInputItem[];
}

export enum FolderFilterType {
  All = 'AllFolders',
  Except = 'AllFoldersExceptDeletedAndJunk',
  Selected = 'OnlySelected'
}

export enum FolderType {
  Include,
  Exclude
}

export enum OfficeExcludeFolder {
  JunkEmail = 'Junk Email',
  DeletedItems = 'Deleted Items'
}

export enum GoogleExcludeFolder {
  Spam = 'SPAM',
  Trash = 'TRASH',
  SpamIMAP = '[Gmail]/Spam',
  TrashIMAP = '[Gmail]/Trash'
}
