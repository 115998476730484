import { TreeElement } from 'mbs-ui-kit';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export function updateTotalChildren<T>(
  treeElem: TreeElement,
  update: boolean
): (source: Observable<{ node: T[]; count: number }>) => Observable<T[]> {
  return (source: Observable<{ node: T[]; count: number }>): Observable<T[]> => {
    return source.pipe(
      tap(({ count }) => update && (treeElem.totalChildren = count)),
      map(({ node }) => node)
    );
  };
}
