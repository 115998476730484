export enum BrowserStorage {
  Prefix = 'apps__',
  AuthPrefix = 'prefix',
  CustomerProblem = 'CustomerProblem',
  ErrorPage = 'ErrorPage',
  BHistoryGridFilters = 'BHistoryGridFilters',
  BHistorySPanelFilters = 'BHistorySPanelFilters',
  RestoreFileTeams = 'RestoreFileTeams',
  RestoreToAnotherUser = 'RestoreToAnotherUser',
  RestoreToAnotherUserSettings = 'RestoreToAnotherUserSettings',
  SPointDisabled = 'SPointDisabled',
  SPointNoSiteBackup = 'SPointNoSiteBackup',
  SkippedItemsHistory = 'SkippedItemsHistory',
  Token = 'token',
  UpdateInterval = 'UpdateInterval'
}
