import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DebugPipe } from './debug.pipe';
import { SafePipe } from './safe.pipe';
import { SizeConverterPipe } from './size.pipe';

const pipes = [SafePipe, DebugPipe, SizeConverterPipe];

@NgModule({
  imports: [CommonModule],
  declarations: pipes,
  exports: pipes
})
export class SharedPipesModule {}
