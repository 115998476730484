export enum RetentionPolicyTagsEnum {
  policyName = 'policyName',
  serviceType = 'serviceType',
  legalHold = 'legalHold'
}
export const RetentionPolicyTagsMap: Map<RetentionPolicyTagsEnum, { tag: string; prop: string }> = new Map([
  [
    RetentionPolicyTagsEnum.policyName,
    {
      tag: 'Policy Name',
      prop: 'Name'
    }
  ],
  [
    RetentionPolicyTagsEnum.serviceType,
    {
      tag: 'Service Type',
      prop: 'ServiceType'
    }
  ],
  [
    RetentionPolicyTagsEnum.legalHold,
    {
      tag: 'Disabled',
      prop: 'HasLegalHold'
    }
  ]
]);
