export function getColumnMaxHeight(options?: {
  hasFormFilter?: boolean;
  customFormFilterMaxHeight?: number;
  hasUserSelect?: boolean;
  customPadding?: number;
  hasTableNavHeight?: boolean;
}): string {
  const {
    hasFormFilter = true,
    customFormFilterMaxHeight = 0,
    hasUserSelect = true,
    customPadding = 0,
    hasTableNavHeight = true
  } = options || {};
  const columnBorderHeight = 1;
  const footerHeight = 100;
  const formFilterHeight = 46;
  const headerHeight = 41;
  const pagePaddings = 35;
  const tableNavHeight = 58;
  const userSelectHeight = 61;
  const permanent = [headerHeight, pagePaddings, footerHeight, columnBorderHeight, customPadding];

  if (hasUserSelect) {
    permanent.push(userSelectHeight || 0);
  }

  if (hasFormFilter) {
    permanent.push(customFormFilterMaxHeight || formFilterHeight);
  }

  if (hasTableNavHeight) {
    permanent.push(tableNavHeight);
  }

  return `calc(${permanent.reduce((acc, next) => acc + ' - ' + next + 'px', '100vh')})`;
}
