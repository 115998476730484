import { AfterContentInit, Component, ContentChildren, Input, OnChanges, OnDestroy, QueryList, SimpleChanges } from '@angular/core';
import { merge, Subscription } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { ButtonComponent, ButtonSize, ButtonType } from '../button.component';
import { isNil } from 'lodash';

@Component({
  selector: 'mbs-button-group',
  templateUrl: './button-group.component.html'
})
export class ButtonGroupComponent implements AfterContentInit, OnChanges, OnDestroy {
  @Input() customClasses: string | string[] = '';
  @Input() multiple = false;
  @Input() toggle = false;
  @Input() size: ButtonSize;
  @Input() vertical = false;
  @Input() type: ButtonType;
  @Input() disabled: boolean = undefined;

  @ContentChildren(ButtonComponent, { read: ButtonComponent, descendants: true }) buttons: QueryList<ButtonComponent>;
  private btnChangeSubscription: Subscription;
  constructor() {}

  ngAfterContentInit(): void {
    this.subscribeClickButtons();
    if (!isNil(this.disabled) && this.buttons) {
      this.buttons.forEach((b) => (b.disabled = this.disabled));
    }

    this.type && this.updateType(this.type);
  }

  ngOnDestroy(): void {
    if (this.btnChangeSubscription) {
      this.btnChangeSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.buttons) {
      changes.disabled && this.updateDisabled(changes.disabled.currentValue as boolean);
      changes.type && this.updateType(changes.type.currentValue as ButtonType);
    }
  }

  subscribeClickButtons(): void {
    if (this.toggle) {
      if (this.btnChangeSubscription) {
        this.btnChangeSubscription.unsubscribe();
      }
      this.btnChangeSubscription = merge(...this.buttons.map((btn) => btn.click.pipe(mapTo(btn)))).subscribe((btn: ButtonComponent) => {
        if (!this.multiple) {
          this.buttons.filter((b) => b != btn).forEach((b) => (b.active = false));
        }
        btn.active = !btn.active;
      });
    }
  }

  updateType(type: ButtonType): void {
    this.buttons.forEach((b) => (b.type = b.type ? b.type : type));
  }

  updateDisabled(disabled: boolean): void {
    this.buttons.forEach((b) => (b.disabled = disabled));
  }
}
