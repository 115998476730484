import { Injectable } from '@angular/core';
import {
  SmartSearchHashtag,
  SmartSearchKeywordType,
  SmartSearchModel,
  SmartSearchModelField,
  SmartSearchTag,
  SmartSearchWord
} from './models';
import { getNormalizeTerm } from './utils';

@Injectable()
export class SmartSearchConfig {
  public convertCondition(rawCondition: string): string {
    return rawCondition;
  }

  public convertModel<T>(model: SmartSearchKeywordType[] = []): T | SmartSearchModel {
    const newValue: T | SmartSearchModel = {};
    model.forEach((m) => {
      if (m.type === 'tag') {
        const tag = m as SmartSearchTag;
        const fields = tag.fields.map((f) => {
          return { value: getNormalizeTerm(f.fieldValue), condition: this.convertCondition(f.conditionValue) };
        });
        newValue[tag.tagValue] = newValue[tag.tagValue] ? (newValue[tag.tagValue] as SmartSearchModelField[]).concat(fields) : fields;
      }

      if (m.type === 'word') {
        const word = m as SmartSearchWord;
        newValue.words = (newValue.words || []).concat([
          {
            value: word.value,
            condition: this.convertCondition(word.conditionValue)
          }
        ]);
      }

      if (m.type === 'hashtag') {
        const { hashtag } = m as SmartSearchHashtag;
        newValue[hashtag] = true;
      }
    });

    return newValue;
  }
}
