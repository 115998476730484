import { ODataPagedResult, ODataQuery, ODataService, ODataServiceFactory } from '@app/common/odata';
import { hasActionsQueue } from '@app/common/utils';
import { IKeyValue } from 'linq-collections';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, shareReplay, tap } from 'rxjs/operators';

export interface State {
  page: number;
  pageSize: number;
  orderBy: string;
  filter: string;
  customQueryOptions: Map<string, any>;
}

export interface OdataObject<T> {
  '@odata.context': string;
  value: T;
}

export class BaseOdataService<T> {
  private _data$ = new BehaviorSubject<T[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  protected _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  protected odata: ODataService<T>;
  protected _error$ = new BehaviorSubject<string>('');
  protected state: State = {
    page: 1,
    pageSize: 50,
    orderBy: null,
    filter: null,
    customQueryOptions: new Map<string, any>()
  };

  public baseRequestPending$: Observable<boolean>;

  constructor(protected odataFactory: ODataServiceFactory, typeName: string) {
    this.odata = this.odataFactory.CreateService<T>(typeName);

    this.baseRequestPending$ = this._loading$.pipe(hasActionsQueue(), shareReplay(1));
  }

  get data$() {
    return this._data$.asObservable();
  }

  get total$() {
    return this._total$.asObservable();
  }

  get error$() {
    return this._error$.asObservable();
  }

  set page(page: number) {
    this.state.page = page;
    this.fetchData();
  }

  set pageSize(size: number) {
    this.state.pageSize = size;
    if (size != null) {
      this.fetchData();
    }
  }

  set orderBy(orderBy: string) {
    this.state.orderBy = orderBy;
    if (orderBy != null) {
      this.fetchData();
    }
  }

  protected defaultOrderBy: string = null;

  protected set filter(filter: string) {
    this.state.filter = filter;
  }

  protected set customQueryOptions(value: IKeyValue<string, any>[]) {
    this.state.customQueryOptions = new Map<string, any>();
    if (value) {
      value.forEach((item) => {
        this.state.customQueryOptions.set(item.key, item.value);
      });
    }
  }
  protected addCustomQueryOption(key: string, value: any) {
    this.state.customQueryOptions.set(key, value);
  }

  protected removeCustomQueryOption(key: string) {
    this.state.customQueryOptions.delete(key);
  }

  protected getQuery(): ODataQuery<T> {
    const skip = (this.state.page - 1) * this.state.pageSize;
    let query = this.odata.Query().Top(this.state.pageSize).Skip(skip);
    if (this.state.orderBy) {
      query = query.OrderBy(this.state.orderBy);
    } else if (this.defaultOrderBy) {
      query = query.OrderBy(this.defaultOrderBy);
    }
    if (this.state.filter) {
      query = query.Filter(this.state.filter);
    }
    if (this.state.customQueryOptions && this.state.customQueryOptions.size > 0) {
      const options: IKeyValue<string, any>[] = Array.from(this.state.customQueryOptions)
        .map((item: [string, any]) => {
          return { key: item[0], value: item[1] as unknown };
        })
        .filter((i) => i.value !== null);
      query = query.CustomQueryOptions(options);
    }
    return query;
  }

  protected getQueryParams(): string {
    const query = this.getQuery();
    const url = new URL(query.GetUrl() + '&$count=true', location.origin);
    return url.searchParams.toString();
  }

  protected fetchDataObservable(): Observable<ODataPagedResult<T>> {
    this._loading$.next(true);
    return this.getQuery()
      .ExecWithCount()
      .pipe(
        tap((res) => {
          this._data$.next(res.data);
          this._total$.next(res.count);
        }),
        finalize(() => this._loading$.next(false))
      );
  }

  protected fetchData(): void {
    this._loading$.next(true);

    this.getQuery()
      .ExecWithCount()
      .pipe(finalize(() => this._loading$.next(false)))
      .subscribe({
        next: (res) => {
          this._data$.next(res.data);
          this._total$.next(res.count);
        }
      });
  }

  protected bindOdataResult(res: OdataObject<any>): void {
    this._data$.next(res.value);
    this._total$.next(res['@odata.count']);
  }

  clearCache() {
    this.filter = '';
    this.clearData();
  }

  clearData() {
    this._data$.next([]);
    this._total$.next(0);
  }
}
