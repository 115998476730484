<mbs-card>

  <h4>{{ 'changeAlternatePasswordModal.title.resetPassword' | i18next: { format: 'title' } }}</h4>

  <ng-container *ngIf="isSuccessChanged; else formTRef">

    <mbs-status [icon]="'fa fa-check-circle'"
                [size]="MbsSize.lg"
                [type]="'success'">
      {{ 'changeAlternatePasswordModal.body.resetPassword' | i18next: { format: 'capitalize' } }}
    </mbs-status>

    <div class="mt-3">
      <mbs-button type="primary"
                  (click)="handleLogOut()">
        {{ moduleButton + ':button.signInToBackup' | i18next: { format: 'title' } }}
      </mbs-button>
    </div>

  </ng-container>

  <ng-template #formTRef>

    <mbs-alert *ngIf="formGroup.errors?.validation as errorText"
               [icon]="true"
               [type]="MbsPopupType.danger"
               class="mb-4">
      {{ errorText }}
    </mbs-alert>

    <form [formGroup]="formGroup">

      <mbs-form-group>
        <mbs-input [label]="'changeAlternatePasswordModal.label.newPassword' | i18next: { format: 'title' }"
                   formControlName="NewPassword"
                   [appendButtons]="[
                   {
                     id: 'append-new-password',
                     type: 'secondary',
                     icon: getAppendButtonsIcon(newPasswordType$ | async),
                     btnType: 'button',
                     loading: false,
                     disabled: false
                     }
                   ]"
                   (buttonClick)="handleChangePasswordType($event)"
                   [type]="newPasswordType$ | async"></mbs-input>
      </mbs-form-group>

      <mbs-form-group>
        <mbs-input [label]="'changeAlternatePasswordModal.label.confirmNewPassword' | i18next: { format: 'title' }"
                   formControlName="ConfirmPassword"
                   [appendButtons]="[
                   {
                     id: 'append-confirm-password',
                     type: 'secondary',
                     icon: getAppendButtonsIcon(confirmPasswordType$ | async),
                     btnType: 'button',
                     loading: false,
                     disabled: false
                     }
                   ]"
                   (buttonClick)="handleChangePasswordType($event)"
                   [type]="confirmPasswordType$ | async"></mbs-input>
      </mbs-form-group>

      <mbs-button type="primary"
                  class="mt-4"
                  [loading]="resetLoading$ | async"
                  [disabled]="formGroup.invalid"
                  [block]="true"
                  (click)="handleResetPassword()">
        {{ moduleButton + ':button.reset' | i18next: { format: 'title' } }}
      </mbs-button>

    </form>

  </ng-template>

</mbs-card>
