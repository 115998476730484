<mbs-modal [title]="moduleAccount + ':modal.title.confirmDeleteAlternateAccount' | i18next: { format: 'title' }">

  <ng-container modal-body>

    <p>
      {{ moduleAccount + ':modal.body.confirmDeleteAlternateAccount' | i18next: { format: 'capitalize' } }}:
      <span class="font-weight-semibold">{{ alternateAccount$ | async }}</span> ?
    </p>

    <mbs-alert [type]="MbsPopupType.warning"
               [icon]="true"
               class="mb-0">
      {{ moduleAccount + ':modal.body.confirmDeleteAlternateAccountWarning' | i18next: { format: 'capitalize' } }}.
    </mbs-alert>

  </ng-container>

  <ng-container modalFooter>

    <mbs-button (click)="handleDelete()"
                [loading]="deleteLoading$.asObservable() | async"
                type="danger">
      {{ moduleAccount + ':modal.button.delete' | i18next: { format: 'title' } }}
    </mbs-button>

    <mbs-button (click)="handleClose()">
      {{ moduleAccount + ':modal.button.cancel' | i18next: { format: 'title' } }}
    </mbs-button>

  </ng-container>

</mbs-modal>
