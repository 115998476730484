import { UntypedFormGroup } from '@angular/forms';
import { isNil } from 'lodash';

export function isConfirmPasswordValidator(names: { password: string; confirmPassword: string }, formGroup: UntypedFormGroup): void {
  const password = formGroup.controls[names.password];
  const confirmPassword = formGroup.controls[names.confirmPassword];

  if (password.disabled && confirmPassword.disabled) {
    confirmPassword.setErrors(null);

    return;
  }

  if (password.touched && !isNil(password.value) && password.value.length == 0) {
    password.setErrors({ isEmpty: { message: 'Password is Empty' } });
  }

  if (confirmPassword.touched && !isNil(confirmPassword.value) && confirmPassword.value.length == 0) {
    confirmPassword.setErrors({ isEmpty: { message: 'Password is Empty' } });
  }

  if (confirmPassword.errors && !confirmPassword.errors.notMatch) {
    return;
  }

  if (confirmPassword.touched && password.value !== confirmPassword.value) {
    confirmPassword.setErrors({ notMatch: { message: "Passwords don't Match" } });
  } else {
    confirmPassword.setErrors(null);
  }
}
