import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Directive,
  Input,
  OnInit,
  Optional,
  Self,
  TemplateRef
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { InputBase } from '../input-base/input-base';
import { DOMEvent } from '../input-base/input-base.model';

@Directive({
  selector: '[radioDescription]'
})
export class RadioDescription {
  @Input() hide = false;
  constructor(public template: TemplateRef<any>) {}
}

/**
 * Component that can be presented as `checkbox` or `radio-input`; based on `type` parameter
 */
@Component({
  selector: 'app-radio,mbs-radio',
  templateUrl: './radio.component.html'
})
export class RadioComponent<T> extends InputBase<T> implements ControlValueAccessor, OnInit, AfterViewChecked {
  @Input() subtitle: string;
  @Input() subtitleClasses: string;
  @Input() subtitleHide = false;
  @Input() subtitleOutside = true;

  @Input() descriptionOutside = true;
  @Input() descriptionClasses: string;

  @Input() centerRadio = true;

  /**
   * Add font-weight-bold;
   */
  @Input() public boldLabel = false;

  @Input() public set value(value: T) {
    this.myValue = value;
    this.myChecked = this.modelValue === this.value;
  }
  public get value(): T {
    return this.myValue;
  }
  protected labelContentClassesDefault = 'mbs-radio_label-content';
  private get modelValue(): T {
    return this.ngControl.value as T;
  }

  protected myChecked = false;
  /**
   * context for label template
   */
  @Input() labelContext: any;

  /**
   * Native checked radio button
   * @param {boolean} state
   */
  @Input() public set checked(state: boolean) {
    this.myChecked = state;
    if (this.onChange) {
      this.onChange(this.value);
    }
    this.change.emit(this.value);
  }
  public get checked(): boolean {
    return this.myChecked;
  }

  @Input() labelClasses = '';

  @ContentChild(RadioDescription, { static: true, read: RadioDescription }) radioDescription: RadioDescription;

  constructor(@Optional() @Self() ngControl: NgControl, protected cd: ChangeDetectorRef) {
    super(ngControl, cd);
  }

  ngAfterViewChecked(): void {
    if (this.ngControl) {
      this.ngControl.valueChanges.subscribe({
        next: val => this.writeValue(val)
      });
    }
  }

  /**
   * Default change method. Can be replaced.
   * @param {DOMEvent<HTMLInputElement>}event - native DOM input event
   */
  handleChange(event: DOMEvent<HTMLInputElement>): void {
    event.stopPropagation();
    this.checked = event.target.checked;
    this.onTouched && this.onTouched();
  }

  writeValue(obj: any): void {
    this.myChecked = obj === this.value;
    this.cd.markForCheck();
  }

  ngOnInit(): void {
    if (this.label && !this.id) {
      console.error(`Radio id must be specified if label in use`);
    }
    if (this.value === null || typeof this.value === undefined) {
      console.error('Radio value must be specified');
    }
  }
}
