import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';

@Component({
  selector: 'app-list-editor,mbs-list-editor',
  templateUrl: './list-editor.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => ListEditorComponent)
    }
  ]
})
export class ListEditorComponent implements OnInit, OnChanges, ControlValueAccessor {
  /**
   * Current value in FormControl
   * @ignore
   */
  currentValue: FormControl;
  /**
   * Copy source data
   * @ignore
   */
  copyData: Set<any>;

  /**
   * Source data
   */
  @Input() data: any[];

  /**
   * Input validation function @type ValidatorFn
   */
  @Input() validators: ValidatorFn[] = [];

  /**
   * Input type
   */
  @Input() inputType: 'text' | 'password' = 'text';

  /**
   * Identifier DOM element for mapping label and input
   */
  @Input() id = 'listAppend';

  /**
   * Append entity name
   */
  @Input() entityName: string;

  /**
   * Show label control
   */
  @Input() showLabel = true;

  /**
   * Save event on append items
   */
  @Output() save = new EventEmitter<any>();

  /**
   * Close event
   */
  @Output() cancel: EventEmitter<any> = new EventEmitter();

  onChange: (value) => void;
  onTouched: () => void;

  constructor() {
    // empty
  }

  ngOnInit(): void {
    const uniqueValidator = (control: AbstractControl): null | ValidationErrors => {
      if (this.copyData && this.copyData.has(control.value)) {
        return { unique: { message: 'This value has already been added' } };
      }
      return null;
    };

    this.currentValue = new FormControl('', this.validators.concat([Validators.required, uniqueValidator]));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.copyData = new Set(this.data);
    }
  }

  /**
   * Append new value to list
   */
  handleAddValue() {
    if (this.checkValid()) {
      return;
    }
    this.copyData.add(this.currentValue.value);
    this.handleReset();
    if (this.onChange) {
      this.onChange(this.copyData);
    }
  }

  /**
   * Checking valid
   * @return {boolean}
   */
  checkValid(): boolean {
    return this.currentValue.value.length === 0 || (this.currentValue.invalid && (this.currentValue.dirty || this.currentValue.touched));
  }

  /**
   * Emit cancel
   */
  handleCancel(): void {
    this.cancel.emit();
  }

  /**
   *  Emit appended items
   */
  handleSave(): void {
    this.currentValue.reset('');
    this.save.emit(this.copyData);
  }

  /**
   * Delete item by index
   * @param {any} item
   */
  handleDeleteValue(item): void {
    this.copyData.delete(item);
    if (this.onChange) {
      this.onChange(this.copyData);
    }
  }

  /**
   * Reset input after clear or add
   */
  handleReset(): void {
    this.currentValue.reset('');
  }

  writeValue(obj: Array<any>): void {
    this.copyData = new Set(obj ? Array.from(obj) : []);
  }

  registerOnChange(fn: (value) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
