<div class="mbs-input mbs-datepicker_input"
     *ngIf="!plain && !range">
  <mbs-input-label *ngIf="label && id"
                   [label]="label"
                   [id]="id"
                   [disabled]="disabledState"
                   [info]="info"
                   [labelSize]="labelSize"
                   [infoPlacement]="infoPlacement"
                   [infoTriggers]="infoTriggers"
                   [infoContainer]="infoContainer"
                   [required]="hasRequiredValidator && showRequiredMark"
                   [labelClasses]="labelClasses"
                   [labelContentClasses]="labelContentClasses">
    <ng-content select="[prependLabel]"
                ngProjectAs="[prependLabel]"></ng-content>
    <ng-content select="[appendLabel]"
                ngProjectAs="[appendLabel]"></ng-content>
  </mbs-input-label>

  <div class="input-group mbs-datepicker_input-group">
    <mbs-input-pre-append *ngIf="prependButtons || prepend"
                          position="prepend"
                          [disabled]="disabledState"
                          [dataTemplate]="prepend"
                          [data]="prependButtons"
                          [size]="size"
                          (keyupEnter)="handleButtonEnter()"
                          (buttonClick)="buttonClickPrepend.emit($event)">
    </mbs-input-pre-append>

    <div *ngIf="viewMode === DatepickerViewMode.month"
         class="input-group_control flex-grow-1 mbs-datepicker_input-group_control"
         [ngClass]="[ size ? 'input-group_control-' + size : '']"
         ngbDropdown
         #dropdown="ngbDropdown"
         container="body"
         [placement]="placement"
         [autoClose]="autoClose">
      <input class="form-control"
             [ngClass]="[size ? ('form-control-' + size) : '', isValidated ? bindClasses : '']"
             ngbDropdownToggle
             [name]="name"
             [tabindex]="tabindex"
             [attr.aria-describedby]="id"
             [attr.aria-labelledby]="label"
             [id]="id"
             [disabled]="disabledState"
             [value]="date"
             [autocomplete]="'off'"
             (click)="handleClick($event)"
             (blur)="handleBlur($event)"
             (focus)="handleFocus($event)"
             (change)="handleChange($event)"
             (closed)="onClosed($event)"
             (dateSelect)="handleChange($event)"
             [readonly]="readonlyState" />

      <div ngbDropdownMenu
           #dropdownMenu
           class="mbs-datepicker_dropdown -arrow">
        <ngb-datepicker #datepicker
                        class="mbs-datepicker_datepicker"
                        [(ngModel)]="date"
                        (select)="onChange($event)"
                        [firstDayOfWeek]="firstDayOfWeek"
                        [dayTemplate]="customDay"
                        [displayMonths]="displayMonths"
                        [footerTemplate]="footerTemplate"
                        (navigate)="onNavigate($event)"
                        [markDisabled]="markDisabled"
                        [minDate]="dateAdapter.fromModel(minDate)"
                        [maxDate]="dateAdapter.fromModel(maxDate)"
                        [navigation]="navigation"
                        [outsideDays]="outsideDays"
                        [weekdays]="showWeekdays"
                        [showWeekNumbers]="showWeekNumbers"
                        [startDate]="startDate">
        </ngb-datepicker>
      </div>

      <ng-container *ngIf="loading">
        <ng-container *ngTemplateOutlet="loaderTemplate"></ng-container>
      </ng-container>
    </div>

    <div *ngIf="viewMode === DatepickerViewMode.year"
         class="input-group_control flex-grow-1 mbs-datepicker_input-group_control"
         [ngClass]="[ size ? 'input-group_control-' + size : '']"
         ngbDropdown
         #dropdown="ngbDropdown"
         container="body"
         [placement]="placement"
         [autoClose]="autoClose">
      <input class="form-control"
             [ngClass]="[ size ? ('form-control-' + size) : '', isValidated ? bindClasses : '']"
             ngbDropdownToggle
             [name]="name"
             [tabindex]="tabindex"
             [attr.aria-describedby]="id"
             [attr.aria-labelledby]="label"
             [id]="id"
             [disabled]="disabledState"
             [value]="date"
             [readonly]="readonlyState"
             [autocomplete]="'off'"
             (click)="handleClick($event)"
             (blur)="handleBlur($event)"
             (focus)="handleFocus($event)"
             (change)="handleChange($event)" />

      <div ngbDropdownMenu
           #dropdownMenu
           class="mbs-datepicker_dropdown -arrow">
        <ngb-datepicker #datepicker
                        class="mbs-datepicker_datepicker -year"
                        [(ngModel)]="date"
                        [displayMonths]="12"
                        [showWeekdays]="false"
                        [footerTemplate]="footerTemplateYear"
                        [maxDate]="dateAdapter.fromModel(maxDate)"
                        [minDate]="dateAdapter.fromModel(minDate)"
                        [startDate]="startDate"
                        (select)="onChange($event)"
                        (navigate)="onNavigate($event)">
          <ng-template ngbDatepickerContent>
            <div class="mbs-datepicker_datepicker-months">
              <ng-container *ngFor="let month of monthList$.asObservable() | async">
                <button ngbDropdownItem
                        class="mbs-datepicker_datepicker-month"
                        [class.-current]="isTodayMonth(month)"
                        [class.-selected]="isSelectedMonth(month)"
                        [class.-disabled]="isDisabledMonth(month)"
                        (click)="handleMonthSelect(month)">
                  {{ i18n.getMonthShortName(month.month) }}
                  <ngb-datepicker-month [month]="month"
                                        class="d-none"></ngb-datepicker-month>
                </button>
              </ng-container>
            </div>
          </ng-template>
        </ngb-datepicker>
      </div>

      <ng-container *ngIf="loading">
        <ng-container *ngTemplateOutlet="loaderTemplate"></ng-container>
      </ng-container>
    </div>

    <mbs-input-pre-append position="append"
                          [disabled]="disabledState"
                          [dataTemplate]="append"
                          [data]="[{
                            type:'secondary',
                            icon:'ico ico-Calendar'
                          }]"
                          [size]="size"
                          (keyupEnter)="handleButtonEnter()"
                          (buttonClick)="handleButtonClick($event)">
    </mbs-input-pre-append>
    <mbs-input-pre-append *ngIf="appendButtons || append"
                          position="append"
                          [dataTemplate]="append"
                          [disabled]="disabledState"
                          [size]="size"
                          [data]="appendButtons"
                          (keyupEnter)="handleButtonEnter()"
                          (buttonClick)="buttonClickAppend.emit($event)">
    </mbs-input-pre-append>
  </div>

  <mbs-input-errors [errors]="errors"
                    *ngIf="isShowErrors"></mbs-input-errors>
</div>

<ng-container *ngIf="plain">
  <ngb-datepicker #datepicker
                  [firstDayOfWeek]="firstDayOfWeek"
                  [displayMonths]="displayMonths"
                  [id]="id"
                  [dayTemplate]="dayTemplate || customDay"
                  (dateSelect)="handleChange($event);"
                  class="mbs-datepicker_datepicker -plain"
                  [ngModel]="date"
                  [footerTemplate]="hideFooter ? null : footerTemplate"
                  (navigate)="onNavigate($event)"
                  [markDisabled]="markDisabled"
                  [maxDate]="dateAdapter.fromModel(maxDate)"
                  [minDate]="dateAdapter.fromModel(minDate)"
                  [navigation]="navigation"
                  [outsideDays]="outsideDays"
                  [showWeekNumbers]="showWeekNumbers"
                  [startDate]="startDate"
                  [disabled]="disabledState">
  </ngb-datepicker>
</ng-container>

<ng-container *ngIf="range">
  <ngb-datepicker #datepicker
                  [firstDayOfWeek]="firstDayOfWeek"
                  [displayMonths]="2"
                  [id]="id"
                  [dayTemplate]="dayTemplate || rangeDay"
                  (select)="onChange($event)"
                  class="mbs-datepicker_datepicker -plain"
                  [ngModel]="date"
                  (dateSelect)="onDateSelection($event)"
                  [footerTemplate]="hideFooter ? null : footerTemplate"
                  (navigate)="onNavigate($event)"
                  [markDisabled]="markDisabled"
                  [maxDate]="dateAdapter.fromModel(maxDate)"
                  [minDate]="dateAdapter.fromModel(minDate)"
                  [navigation]="navigation"
                  [outsideDays]="outsideDays"
                  [showWeekdays]="showWeekdays"
                  [showWeekNumbers]="showWeekNumbers"
                  [startDate]="startDate"
                  [disabled]="disabledState">
  </ngb-datepicker>
</ng-container>

<ng-template #rangeDay
             let-date
             let-focused="focused">
  <span class="mbs-datepicker_datepicker-day"
        [class.focused]="focused"
        [class.range]="isRange(date)"
        [class.faded]="isHovered(date) || isInside(date)"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null">
    {{ date.day }}
  </span>
</ng-template>

<ng-template #customDay
             let-date
             let-currentMonth="currentMonth"
             let-disabled="disabled"
             let-focused="focused"
             let-today="today">
  <span class="mbs-datepicker_datepicker-day"
        [class.-today]="today"
        [class.-focused]="focused"
        [class.-selected]="isSelected(date)"
        [class.-outside]="date.month !== currentMonth"
        [class.-disabled]="disabled"
        [class.-weekend]="isWeekend(date)">
    {{ date.day }}
  </span>
</ng-template>

<ng-template #footerTemplate>
  <footer class="mbs-datepicker_datepicker-footer">
    <mbs-button [isCtrl]="true"
                [type]="'primary'"
                [size]="MbsSize.xs"
                (click)="handleSetToday()">
      Currently: {{ currentDate | date : 'mediumDate'}}
    </mbs-button>
  </footer>
</ng-template>

<ng-template #footerTemplateYear>
  <footer class="mbs-datepicker_datepicker-footer">
    <mbs-button [isCtrl]="true"
                [type]="'primary'"
                [size]="MbsSize.xs"
                (click)="handleSetToYears()">
      Currently: {{ currentDate | date : 'MMMM, yyyy'}}
    </mbs-button>
  </footer>
</ng-template>

<ng-template #loaderTemplate>
  <div class="loader-container">
    <span class="loader"></span>
  </div>
</ng-template>
