import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InvoiceStateByAPI } from '@app/common';
import { InvoiceOData, PayInvoicePayload } from '@app/common/models';
import { ODataPagedResult, ODataService, ODataServiceFactory } from '@app/common/odata';
import { SharedOdataService } from '@app/common/services';
import { PaymentsTagsEnum, PaymentsTagsMap } from '@app/common/services/smart-search';
import { currencyInvert } from '@app/common/utils';
import {
  containsWrapper,
  filterByWords,
  FilterOptions,
  getFilterByContains,
  getFilterByDate,
  getFilterByEq,
  getFilterByEqFromEnum
} from '@app/common/utils/functions/search';
import { SmartSearchModel, SmartSearchModelField } from 'mbs-ui-kit';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class InvoicesService extends SharedOdataService<InvoiceOData> {
  readonly #paymentState = PaymentsTagsMap.get(PaymentsTagsEnum.state);
  readonly #quantity = PaymentsTagsMap.get(PaymentsTagsEnum.quantity);
  readonly #price = PaymentsTagsMap.get(PaymentsTagsEnum.price);
  readonly #amount = PaymentsTagsMap.get(PaymentsTagsEnum.amount);
  readonly #date = PaymentsTagsMap.get(PaymentsTagsEnum.date);
  readonly #expireDate = PaymentsTagsMap.get(PaymentsTagsEnum.expireDate);
  readonly #invoiceId = PaymentsTagsMap.get(PaymentsTagsEnum.invoiceId);

  private odataMethods: ODataService<any>;

  constructor(odataFactory: ODataServiceFactory, private http: HttpClient) {
    super(odataFactory, 'Invoices');
    this.odataMethods = odataFactory.CreateService('');
  }

  getInvoices(): Observable<ODataPagedResult<InvoiceOData>> {
    const odataService = this.odataFactory.CreateService<InvoiceOData>('Invoices');

    return this.fetchData(odataService);
  }

  payInvoice(payload: PayInvoicePayload): Observable<any> {
    const endpoint = '/api/PayInvoice';

    return this.http.post(endpoint, payload);
  }

  updateFilter(obj: SmartSearchModel): void {
    const filter: string[] = [];

    if (obj[this.#paymentState.tag]) {
      const options: FilterOptions = {
        model: obj[this.#paymentState.tag] as SmartSearchModelField[],
        prop: this.#paymentState.prop,
        enumItems: InvoiceStateByAPI
      };

      filter.push(getFilterByEqFromEnum(options));
    }

    if (obj[this.#quantity.tag]) {
      const options: FilterOptions = {
        model: obj[this.#quantity.tag] as SmartSearchModelField[],
        prop: this.#quantity.prop
      };

      filter.push(getFilterByEq(options, false));
    }

    if (obj[this.#price.tag]) {
      const price = obj[this.#price.tag][0];
      price.value = currencyInvert(price.value);
      const options: FilterOptions = {
        model: obj[this.#price.tag] as SmartSearchModelField[],
        prop: this.#price.prop
      };

      filter.push(getFilterByEq(options, false));
    }

    if (obj[this.#amount.tag]) {
      const amount = obj[this.#amount.tag][0];
      amount.value = currencyInvert(amount.value);
      const options: FilterOptions = {
        model: obj[this.#amount.tag] as SmartSearchModelField[],
        prop: this.#amount.prop
      };

      filter.push(getFilterByEq(options, false));
    }

    if (obj[this.#date.tag]) {
      const options: FilterOptions = {
        model: obj[this.#date.tag] as SmartSearchModelField[],
        prop: this.#date.prop
      };

      filter.push(getFilterByDate(options));
    }

    if (obj[this.#expireDate.tag]) {
      const options: FilterOptions = {
        model: obj[this.#expireDate.tag] as SmartSearchModelField[],
        prop: this.#expireDate.prop
      };

      filter.push(getFilterByDate(options));
    }

    if (obj[this.#invoiceId.tag]) {
      const options: FilterOptions = {
        model: obj[this.#invoiceId.tag] as SmartSearchModelField[],
        prop: this.#invoiceId.prop
      };

      filter.push(getFilterByContains(options));
    }

    if (obj.words?.filter(Boolean)) {
      const term = filterByWords(obj);

      filter.push(containsWrapper(this.#invoiceId.prop, term));
    }

    this.filter = filter.length > 0 ? filter.join(' and ') : '';
  }
}
