export enum MailFolderType {
  All = 'All',
  Archive = 'Archive',
  Flagged = 'Flagged',
  Trash = 'Trash',
  Clutter = 'Clutter',
  Conflicts = 'Conflicts',
  Conversationhistory = 'Conversationhistory',
  Drafts = 'Drafts',
  Inbox = 'Inbox',
  Localfailures = 'Localfailures',
  Msgfolderroot = 'Msgfolderroot',
  Other = 'Other',
  Outbox = 'Outbox',
  Recoverableitemsdeletions = 'Recoverableitemsdeletions',
  Scheduled = 'Scheduled',
  Searchfolders = 'Searchfolders',
  Sentitems = 'Sentitems',
  Serverfailures = 'Serverfailures',
  Spam = 'Spam',
  Syncissues = 'Syncissues'
}
