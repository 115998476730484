export interface DomainModel {
  Id: string;
  Name: string;
  Provider: Provider;
  AutoAddUserToBackup: boolean;
  AutoAddGroupToBackup: boolean;
  TeamDrive?: DomainServiceInfo;
  Site?: DomainServiceInfo;
}

export enum Provider {
  GoogleBusiness = 'GoogleBusiness',
  OfficeBusiness = 'OfficeBusiness',
  OfficeHome = 'OfficeHome',
  GoogleHome = 'GoogleHome'
}

export interface DomainServiceInfo {
  Id: string;
  LastBackupDate: string;
  Size: number;
}

export interface DomainStatistic {
  ProviderUsersCnt: number;
  AllCnt: number;
  BackupCnt: number;
  NotInBackupCnt: number;
  InBackupNotInDomainCnt: number;
  SuspendedCnt: number;
}

export interface DomainServicesState {
  EmailEnable: boolean;
  DriveEnable: boolean;
  ContactEnable: boolean;
  CalendarEnable: boolean;
}
