import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewRef
} from '@angular/core';
import { FormControlDirective, FormControlName, FormGroupDirective, FormGroupName, NgForm, NgModel } from '@angular/forms';
import { Subject } from 'rxjs';
import { WizardStep } from './WizardStep';
import { isNil } from 'lodash';

@Component({
  selector: 'mbs-wizard-step',
  template: '<ng-container *ngIf="visible"><ng-content></ng-content></ng-container>',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': 'activated ? "-active" : ""'
  }
})
export class WizardStepComponent implements OnDestroy {
  @Input() title = 'Some Step';
  @Input() blockEnterIfControlFocus = true;

  change = new Subject<void>();
  public changesBeforeValidation = false;

  private myValid = false;

  public isNext = true;
  public isSave = false;

  public get valid(): boolean {
    return this.myValid;
  }
  @Input()
  public set valid(v: boolean) {
    this.myValid = v;
    this.change.next();
  }

  @Input()
  public changes: boolean = undefined;

  private myVisible = false;
  public get visible(): boolean {
    return this.myVisible;
  }
  public set visible(v: boolean) {
    this.myVisible = v;
    if (!(this.cd as ViewRef).destroyed) {
      this.cd.markForCheck();
    }
  }

  public completed = false;
  public activated = false;
  public disabled = false;

  public canOpen = false;

  public get hasChanges(): boolean {
    if (!isNil(this.changes)) {
      return this.changes;
    }
    return (
      (this.ngModel && this.ngModel.dirty) ||
      (this.ngFormControlName && this.ngFormControlName.dirty) ||
      (this.ngFormControlDirective && this.ngFormControlDirective.dirty) ||
      (this.ngFormGroupDirective && this.ngFormGroupDirective.dirty) ||
      (this.ngFormGroupName && this.ngFormGroupName.dirty) ||
      (this.ngForm && this.ngForm.dirty) ||
      false
    );
  }

  @Output('next') nextEvent = new EventEmitter<WizardStep>();

  @ContentChild(NgModel, { static: false }) ngModel: NgModel;
  @ContentChild(FormControlName, { static: false }) ngFormControlName: FormControlName;
  @ContentChild(FormControlDirective, { static: false }) ngFormControlDirective: FormControlDirective;
  @ContentChild(FormGroupDirective, { static: false }) ngFormGroupDirective: FormGroupDirective;
  @ContentChild(FormGroupName, { static: false }) ngFormGroupName: FormGroupName;
  @ContentChild(NgForm, { static: false }) ngForm: NgForm;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    this.cd.detach();
    this.change.unsubscribe();
  }

  activate(): void {
    this.activated = true;
    this.completed = false;
    this.canOpen = true;
    this.visible = true;
    this.disabled = false;
  }

  notActivate(): void {
    this.completed = this.valid;
    this.activated = false;
    this.visible = false;
  }

  complete(): void {
    this.activated = false;
    this.completed = this.valid;
    this.canOpen = true;
    this.visible = false;
    this.disabled = false;
  }

  reset(): void {
    this.disabled = true;
    this.canOpen = false;
    this.visible = false;
    this.activated = false;
    this.completed = false;
  }
}
