interface WizardActionMark {
  description: string;
  important?: boolean;
}

export interface WizardMarkableActions {
  back?: WizardActionMark;
  next?: WizardActionMark;
  save?: WizardActionMark;
}

/**
 * Abstraction for marking wizard buttons directly from its steps
 * It`s semi-finished class. Now it works only for "save" button
 * *
 * How to use:
 *     // Add mark reason. Important will block button
 *     instance.addMark('foo', {
 *       back: { description: 'Back important reason', important: true },
 *       save: { description: 'Important reason', important: true }
 *     });
 *     ...
 *     instance.getMark('foo', 'back').description; // Will return 'Back important reason'
 *     instance.needBlock('foo', 'back'); // TRUE because current step is foo
 *     instance.needBlock('save'); // TRUE because for save button current step isn`t matter
 */
export class WizardStepMarks {
  public get isEmpty(): boolean {
    return !Object.keys(this.marks).length;
  }

  private pause: { back?: boolean; next?: boolean; save?: boolean } = {};
  private marks: { [stepName: string]: WizardMarkableActions } = {};

  public getMark(stepName: string, action: keyof WizardMarkableActions): WizardActionMark {
    const step = this.marks[stepName] || {};
    const mark = (step[action] || {}) as WizardActionMark;

    return { description: mark.description || '' };
  }

  public needBlock(action: keyof Pick<WizardMarkableActions, 'save'>): boolean;
  public needBlock(stepName: string, action: keyof Omit<WizardMarkableActions, 'save'>): boolean;
  public needBlock(...args: string[]): boolean {
    let stepName: string;
    let action: keyof WizardMarkableActions;

    if (args.length === 2) {
      stepName = args[0];
      action = args[1] as keyof WizardMarkableActions;

      const step = this.marks[stepName] || {};
      const mark = (step[action] || {}) as Partial<WizardActionMark>;

      return !!mark.important;
    }

    action = args[0] as keyof WizardMarkableActions;

    return !!this.pause[action];
  }

  public addMark(stepName: string, actions: WizardMarkableActions): void {
    this.marks[stepName] = { ...(this.marks[stepName] || {}), ...actions };

    this.cleanUp();
    this.updatePause();
  }

  public removeMark(stepName: string, actions: Array<keyof WizardMarkableActions>): void {
    const step = this.marks[stepName] || {};

    actions.forEach((action) => delete step[action]);

    this.cleanUp();
    this.updatePause();
  }

  public clear(): void {
    this.marks = {};
    this.pause = {};
  }

  public updatePause(): void {
    const steps = Object.keys(this.marks);

    this.pause = steps.reduce((result, step) => {
      Object.keys(this.marks[step]).forEach((mark) => {
        if (this.marks[step][mark].important) {
          result[mark] = true;
        }
      });

      return result;
    }, {});
  }

  private cleanUp(): void {
    const steps = Object.keys(this.marks);

    steps.forEach((step) => {
      if (!Object.keys(this.marks[step]).length) {
        delete this.marks[step];
      }
    });
  }
}
