import { Directive, Input, TemplateRef } from '@angular/core';

/**
 * Common table cell by group
 */
@Directive({
  selector: '[mbsTableFilter]'
})
export class TableFilterDirective {
  @Input('mbsTableFilter') public mode: 'item' | 'label' | 'divider' = 'item';

  constructor(public template: TemplateRef<any>) {}
}
