import { AfterViewInit, Directive, EventEmitter, Output, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Sidepanel } from './Sidepanel';
import { SidepanelComponent } from './sidepanel.component';

@Directive({})
export abstract class SidepanelBase implements Sidepanel, AfterViewInit {
  @ViewChild(SidepanelComponent, { static: true }) genericPanel: SidepanelComponent;

  @Output() close = new EventEmitter();
  @Output() open = new EventEmitter();

  ngAfterViewInit(): void {
    this.genericPanel.panelClosed.subscribe((event) => this.close.emit(event));
    this.genericPanel.panelOpened.subscribe(() => this.open.emit());
    this.close.subscribe(() => this.genericPanel.close());
  }
  handleClose(): Observable<boolean> {
    this.close.emit();
    return of(true);
  }
}
