export enum RestoreToEnum {
  all,
  folder,
  messages
}

export interface AnotherUserSettings {
  type: RestoreToEnum;
  userId: string;
  folder: string;
  messages: string[];
  subFolders?: boolean;
}
