function getClearTerm(termCurrent: string): string {
  const regex = /^(\*)?(\S+?)(\*)?$/;
  const match = regex.exec(termCurrent);
  return match ? match[2] : termCurrent;
}

function getWildcardTerm(termCurrent: string): string {
  // without wrap
  if (termCurrent.startsWith('*') || termCurrent.endsWith('*')) {
    return termCurrent;
  }

  return `*${termCurrent}*`;
}

function getNormalizeTerm(termCurrent: string): string {
  const spaceReg = /\s/g;
  let result = '';

  let match: RegExpExecArray;
  for (match = spaceReg.exec(termCurrent); match; match = spaceReg.exec(termCurrent)) {
    result += termCurrent.substring(result.length, match.index) + ' ';
  }

  if (result) {
    result += termCurrent.substring(result.length);
  } else {
    result = termCurrent;
  }

  return result;
}

export { getClearTerm, getWildcardTerm, getNormalizeTerm };
