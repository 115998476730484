<ng-container *ngIf="!loading">
  <header [ngClass]="getHeaderSizeClass()"
          class="modal-header mbs-modal_header">
    <div class="form-row">
      <div class="col-auto"
           *ngIf="titleIconClass">
        <span class="ico {{titleIconClass}} mbs-modal_title-ico"></span>
      </div>
      <div class="col"
           [ngClass]="{'overflow-hidden' : titleTextOverflow}">
        <h5 class="modal-title mbs-modal_title"
            [ngClass]="{'text-overflow' : titleTextOverflow}">
          {{ title }}
          <ng-content select="[modal-header]"></ng-content>
          <ng-container *ngTemplateOutlet="isHeaderSettingsTemplateRef() ? headerRefTemplate : headerInnerTemplate">
          </ng-container>
        </h5>
      </div>
      <div class="col-auto"
           *ngIf="showExpandedCross || showCloseCross">
        <div class="form-row">
          <div class="col-auto"
               *ngIf="showExpandedCross">
            <mbs-button (click)="toggleExpand()"
                        [isCtrl]="true"
                        [icon]="isExpanded? 'fa fa-compress':'fa fa-expand'"
                        type="dark"></mbs-button>
          </div>
          <div class="col-auto"
               *ngIf="showCloseCross">
            <mbs-button (click)="close()"
                        [isCtrl]="true"
                        icon="ico ico-Close"
                        type="dark"
                        aria-label="Close"
                        data-dismiss="modal"></mbs-button>
          </div>
        </div>
      </div>
    </div>
    <ng-template #headerInnerTemplate>
      <span *ngIf="isHeaderSettingsString()"
           [innerHTML]="header"></span>
    </ng-template>
    <ng-template #headerRefTemplate>
      <ng-container *ngTemplateOutlet="header; context: { $implicit: this.context }"></ng-container>
    </ng-template>
  </header>
  <div [class]="computedBodyClass">
    {{message}}
    <ng-template #innerTemplate>
      <div [innerHTML]="body"></div>
    </ng-template>
    <ng-template #refTemplate>
      <ng-container *ngTemplateOutlet="body; context: { $implicit: this.context }"></ng-container>
    </ng-template>
    <ng-template #contentBody>
      <ng-content select="[modal-body]"></ng-content>
    </ng-template>
    <ng-container
                  *ngTemplateOutlet="isCustomModal ? contentBody : (body?.length ? innerTemplate : refTemplate); context: { $implicit: this.context }">
    </ng-container>
  </div>
  <div *ngIf="showFooter"
       class="modal-footer">
    <ng-template #footerDefault>
      <mbs-button (click)="save()"
                  *ngIf="showOkButton"
                  [disabled]="okButtonDisabled$ | async"
                  [loading]="okButtonLoading$ | async"
                  [type]="okButtonType">{{okButtonText}}</mbs-button>
      <mbs-button (click)="close()"
                  *ngIf="showCancelButton"
                  data-dismiss="modal"
                  type="secondary">
        {{ cancelButtonText }}
      </mbs-button>
    </ng-template>
    <ng-container *ngIf="customFooter; else footerDefault">
      <ng-content select="[modalFooter]"></ng-content>
    </ng-container>
  </div>
</ng-container>
<app-loader *ngIf="loading"></app-loader>
