import { Pipe, PipeTransform } from '@angular/core';
import { TaskStatus } from '@app/common';

@Pipe({
  name: 'statusType',
  pure: true
})
export class TaskStatusTypePipe implements PipeTransform {
  private readonly _map: Map<TaskStatus, string> = new Map([
    [TaskStatus.Pending, 'muted'],
    [TaskStatus.Running, 'primary'],
    [TaskStatus.Finished, 'success'],
    [TaskStatus.Failed, 'danger'],
    [TaskStatus.Paused, 'muted'],
    [TaskStatus.LimitExceeded, 'warning'],
    [TaskStatus.Cancelled, 'danger']
  ]);

  transform(status: TaskStatus): any {
    const classes = [];

    if (status) {
      classes.push(this._map.get(status));
    }

    return classes.join(' ');
  }
}
