import { replaceNbsps } from '@app/common/utils';
import { get } from 'lodash';
import { SmartSearchModel, SmartSearchModelField, SmartSearchState } from 'mbs-ui-kit';
import { Observable, of } from 'rxjs';

export function getUniqueListByProp<T>(items: T[], prop: string): T[] {
  return items.reduce((uniqueList: T[], item: T): T[] => {
    return uniqueList.some((uniqueItem: T) => getNestedObjectByPath(uniqueItem, prop) === getNestedObjectByPath(item, prop))
      ? uniqueList
      : [...uniqueList, item];
  }, [] as T[]);
}

export function getNestedObjectByPath(obj: any, path: string): any {
  const parsedPath = path.split('/');

  parsedPath.forEach((path) => {
    obj = obj?.[path];
  });

  return obj;
}

export function getSizeFilter(tag: string, defaultTagName = 'Size Less Than'): sizeFilterEnum {
  return tag === defaultTagName ? sizeFilterEnum.less : sizeFilterEnum.more;
}

export function getSize(value: string): string {
  if (!value) return '';

  return value.replace(/[^0-9]/g, '');
}

export function getSizeMagnitude(value: string): sizeMagnitudesEnum {
  if (!value) return sizeMagnitudesEnum.Mb;

  const magnitude = value.replace(/[^a-z]/gi, '').toLowerCase();
  switch (magnitude) {
    case sizeMagnitudesEnum.Gb.toLowerCase():
      return sizeMagnitudesEnum.Gb;
    case sizeMagnitudesEnum.Mb.toLowerCase():
      return sizeMagnitudesEnum.Mb;
    case sizeMagnitudesEnum.Kb.toLowerCase():
      return sizeMagnitudesEnum.Kb;
    case sizeMagnitudesEnum.b.toLowerCase():
      return sizeMagnitudesEnum.b;
    default:
      return sizeMagnitudesEnum.Mb;
  }
}

export const getStringItemFormatter = (prop: string): string => (prop.split(/\s/).length > 1 ? `{${prop}}` : prop);

export const getDefaultStringItemFormatter = (prop: unknown): string => String(prop);

export function getCustomSizeItems(state: SmartSearchState, callback: (t) => void): Observable<string[]> {
  const sizes = ['500 KB', '1 MB', '10 MB', '50 MB', '100 MB', '500 MB', '1 GB', '10 GB', '50 GB', '100 GB', '150 GB'];
  const term = state.leftCaretValue;

  callback(term);

  return of(sizes.filter((size: string) => size.toLowerCase().includes(term.toLowerCase())));
}

export const convertToPropSmartSearchModel = (data: string): SmartSearchModelField[] => new Array({ value: data });

export function getValueFromSmartSearchModel(model: SmartSearchModel, prop: string): string {
  const value = get(model[prop], '[0].value', null) as string;
  return typeof value === 'string' ? replaceNbsps(value) : value;
}

export enum sizeFilterEnum {
  more = 'more',
  less = 'less'
}

export enum sizeMagnitudesEnum {
  Gb = 'Gb',
  Mb = 'Mb',
  Kb = 'Kb',
  b = 'b'
}

export function isSmartSearchPersist(): (curr: SmartSearchModel) => boolean {
  let last: SmartSearchModel = {};

  return function (curr: SmartSearchModel) {
    const isPersist = !curr || (Object.keys(last).length === 0 && Object.keys(curr).length === 0);

    last = curr;

    return isPersist;
  };
}
