import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BOOLEAN_OPERATOR, SINGLE_QUOTES_REGEX } from '@app/common';
import {
  PageDriveItemsPayload,
  PageSiteItemsPayload,
  RestoreSiteItemsPayload,
  SearchODataOptions,
  SearchType,
  SetupSitesBackupPayload,
  Site,
  SiteItemDetails,
  SiteItemODataModel
} from '@app/common/models';
import { ODataPagedResult, ODataService, ODataServiceFactory } from '@app/common/odata';
import { SharedOdataService } from '@app/common/services';
import { SharePointTagsEnum, SharePointTagsMap } from '@app/common/services/smart-search/sharePoint';
import { filterByWords, getDefaultPaginationOptions } from '@app/common/utils';
import { containsWrapper } from '@app/common/utils/functions/search';
import { SmartSearchHelper } from '@app/common/utils/helper/smart-search.helper';
import { isArray } from 'lodash';
import { PaginationOptions, SmartSearchModel } from 'mbs-ui-kit';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SiteItemsODataService extends SharedOdataService<SiteItemODataModel> {
  private odataMethods: ODataService<any>;
  private odataSiteLists: ODataService<SiteItemODataModel>;
  private odataSiteListsItems: ODataService<SiteItemODataModel>;
  private odataSiteDriveItems: ODataService<SiteItemODataModel>;
  private odataSite: ODataService<Site>;

  public readonly defaultPaginationOptions: PaginationOptions;

  readonly #siteName = SharePointTagsMap.get(SharePointTagsEnum.SiteName);
  readonly #fileName = SharePointTagsMap.get(SharePointTagsEnum.FileName);
  readonly #mixinName = SharePointTagsMap.get(SharePointTagsEnum.Mixin);

  constructor(odataFactory: ODataServiceFactory) {
    super(odataFactory, 'SiteItems');
    this.odataMethods = odataFactory.CreateService('');
    this.odataSiteLists = odataFactory.CreateService('SiteLists');
    this.odataSiteListsItems = odataFactory.CreateService('SiteListItems');
    this.odataSite = odataFactory.CreateService<Site>('Sites');
    this.odataSiteDriveItems = odataFactory.CreateService('SiteDriveItems');
    this.defaultPaginationOptions = getDefaultPaginationOptions();
  }

  getSiteItems({ top = 50, skip = 0, orderBy = 'BackupOn desc, Name asc', filter = null } = {}): Observable<
    ODataPagedResult<SiteItemODataModel>
  > {
    return this.odataFactory
      .CreateService<SiteItemODataModel>('SiteItems')
      .Query()
      .Top(top)
      .Skip(skip)
      .OrderBy(orderBy)
      .Filter(filter)
      .ExecWithCount();
  }

  siteItems(options?: SearchODataOptions): Observable<ODataPagedResult<SiteItemODataModel>> {
    const { filter, orderBy, page, pageSize } = options || {};

    return this.odataFactory
      .CreateService<SiteItemODataModel>('SiteItems/GetActiveSiteItems')
      .Query()
      .Top(pageSize || this.pageSize)
      .Skip((pageSize || this.pageSize) * ((page || this.page) - 1))
      .OrderBy(this.orderByForSearch(orderBy) || 'BackupOn desc, Name asc') // must be `orderByGlobal`
      .Filter(filter || this.filter)
      .ExecWithCount();
  }

  listsOfSiteItems(blockId: string, id?: string): Observable<ODataPagedResult<SiteItemODataModel>> {
    const newOdata = this.odataFactory.CreateService<SiteItemODataModel>(`/SiteItems(${blockId})/Lists`);

    return newOdata
      .Query()
      .Top(this.pageSize)
      .Skip(this.pageSize * (this.page - 1))
      .OrderBy(this.orderBy) // must be `orderByGlobal`
      .CustomQueryOptions([{ key: 'Id', value: id }])
      .ExecWithCount();
  }

  driveItemsOfSiteLists(blockId: string, id: string): Observable<ODataPagedResult<SiteItemODataModel>> {
    const newOdata = this.odataFactory.CreateService<SiteItemODataModel>(`/SiteLists(${blockId})/DriveItems`);

    return (
      newOdata
        .Query()
        .Top(this.pageSize)
        .Skip(this.pageSize * (this.page - 1))
        .OrderBy(this.orderBy) // must be `orderByForDriveItems`
        // .Filter(this.prepareFilter(searchTerm))
        .CustomQueryOptions([{ key: 'Id', value: id }])
        .ExecWithCount()
    );
  }

  getPageOfSiteItems(payload: PageSiteItemsPayload): Observable<number> {
    const newOdata = this.odataFactory.CreateService(`/SiteItems/GetPage`);

    return newOdata
      .Query()
      .Top(this.pageSize)
      .OrderBy(this.orderBy) // must be `orderByForDriveItems`
      .CustomQueryOptions([{ key: 'siteId', value: payload.siteId }])
      .Exec() as unknown as Observable<number>;
  }

  getPageDriveItemsOfSiteLists(payload: PageDriveItemsPayload): Observable<number> {
    const newOdata = this.odataFactory.CreateService(`/SiteDriveItems/GetPage`);

    return newOdata
      .Query()
      .Top(this.pageSize)
      .OrderBy(this.orderBy) // must be `orderByForDriveItems`
      .CustomQueryOptions([
        { key: 'siteBlockId', value: payload.siteBlockId },
        { key: 'parentId', value: payload.parentId },
        { key: 'driveItemId', value: payload.driveItemId }
      ])
      .Exec() as unknown as Observable<number>;
  }

  listItemsOfSiteLists(blockId: string, id: string): Observable<ODataPagedResult<SiteItemODataModel>> {
    const newOdata = this.odataFactory.CreateService<SiteItemODataModel>(`/SiteLists(${blockId})/ListItems`);

    return (
      newOdata
        .Query()
        .Top(this.pageSize)
        .Skip(this.pageSize * (this.page - 1))
        .OrderBy(null) // must be `orderByGlobal`
        // .Filter(this.prepareFilter(searchTerm))
        .CustomQueryOptions([{ key: 'Id', value: id }])
        .ExecWithCount()
    );
  }

  findDriveItemsOfSiteList(options?: SearchODataOptions): Observable<ODataPagedResult<SiteItemODataModel>> {
    const { filter, orderBy, page, pageSize } = options || {};

    return this.odataSiteDriveItems
      .Query()
      .Top(pageSize || this.pageSize)
      .Skip((pageSize || this.pageSize) * ((page || this.page) - 1))
      .OrderBy(this.orderByForSearch(orderBy)) // this.searchTerm ? this.orderByForDriveItems : this.orderByGlobal
      .Filter(filter || this.filter)
      .ExecWithCount();
  }

  siteItemDetails(id: string): Observable<SiteItemDetails> {
    return this.odata.CustomFunction(id, 'GetDetails').pipe(map((res: HttpResponse<SiteItemDetails>) => res.body));
  }

  siteListsDetails(id: string): Observable<SiteItemDetails> {
    return this.odataSiteLists.CustomFunction(id, 'GetDetails').pipe(map((res: HttpResponse<SiteItemDetails>) => res.body));
  }

  siteDriveItemsDetails(id: string): Observable<SiteItemDetails> {
    return this.odataSiteDriveItems.CustomFunction(id, 'GetDetails').pipe(map((res: HttpResponse<SiteItemDetails>) => res.body));
  }

  siteListsItemsDetails(id: string): Observable<SiteItemDetails> {
    return this.odataSiteListsItems.CustomFunction(id, 'GetDetails').pipe(map((res: HttpResponse<SiteItemDetails>) => res.body));
  }

  setAllSitesBackup(value: boolean): Observable<any> {
    return this.odataMethods
      .CustomCollectionAction('SetupAllSitesBackup', { addAllToBackup: value })
      .pipe(map((res: HttpResponse<{ body: any }>) => res.body));
  }

  getAutoAddSiteToBackupStatus(domainId: string): Observable<Site> {
    return this.odataSite.Get(domainId).Exec();
  }

  setAutoAddSiteToBackup(id: string, value: boolean): Observable<{ AutoAddSiteToBackup: boolean }> {
    return this.odataSite.Put({ AutoAddSiteToBackup: value }, id).Exec();
  }

  setupSitesBackup(payload: SetupSitesBackupPayload): Observable<void> {
    return this.odataMethods.CustomCollectionAction(`SetupSitesBackup`, payload);
  }

  restoreSiteItems(data: RestoreSiteItemsPayload): Observable<void> {
    return this.odataMethods.CustomCollectionAction('RestoreSiteItems', data);
  }

  updateFilter(data: SmartSearchModel | SmartSearchModel[]): string {
    const filter: string[] = [];

    if (isArray(data)) {
      for (const d of data) {
        filter.push(this.getFilter(d));
      }
    } else {
      filter.push(this.getFilter(data));
    }

    return filter.length > 0 ? filter.filter(Boolean).join(` ${BOOLEAN_OPERATOR.and} `) : '';
  }

  private getFilter(searchObj: SmartSearchModel): string {
    const filter: string[] = [];
    const values = SmartSearchHelper.getValues(searchObj);
    const type = this.getTypeFromSearchObj(searchObj);

    if (searchObj[this.#siteName.tag] && type === SearchType.SiteItems) {
      const value = SmartSearchHelper.getJoinedValue(values, this.#siteName.tag);

      filter.push(containsWrapper(this.#siteName.prop, value));
    }

    if (searchObj[this.#fileName.tag] && type === SearchType.Files) {
      const value = SmartSearchHelper.getJoinedValue(values, this.#fileName.tag);

      filter.push(containsWrapper(this.#fileName.prop, value));
    }

    if (type === SearchType.FilesInSiteItem) {
      const valueMixinName = SmartSearchHelper.getJoinedValue(values, this.#mixinName.tag);
      const valueSiteName = SmartSearchHelper.getJoinedValue(values, this.#siteName.tag);
      const valueFileName = SmartSearchHelper.getJoinedValue(values, this.#fileName.tag);

      searchObj[this.#mixinName.tag] && filter.push(containsWrapper(this.#mixinName.prop, valueMixinName));
      searchObj[this.#siteName.tag] && filter.push(containsWrapper(this.#mixinName.prop, valueSiteName));
      searchObj[this.#fileName.tag] && filter.push(containsWrapper(this.#fileName.prop, valueFileName));
    }

    if (searchObj.words?.filter(Boolean)) {
      const term = filterByWords(searchObj).replace(SINGLE_QUOTES_REGEX, '');
      const keys = [this.#fileName.prop];
      const values = keys.map((k) => containsWrapper(k, term));

      filter.push('('.concat(values.join(` ${BOOLEAN_OPERATOR.or} `), ')'));
    }

    return filter.length > 0 ? filter.filter(Boolean).join(` ${BOOLEAN_OPERATOR.and} `) : '';
  }

  getTypeFromSearchObj(searchObj: SmartSearchModel): SearchType {
    switch (true) {
      case searchObj[this.#siteName.tag] && !searchObj[this.#fileName.tag]:
        return SearchType.SiteItems;
      case searchObj[this.#fileName.tag] && !searchObj[this.#siteName.tag]:
      case !!searchObj.words?.filter(Boolean):
        return SearchType.Files;
      case !!(searchObj[this.#fileName.tag] && searchObj[this.#siteName.tag]):
      case !!searchObj[this.#mixinName.tag]:
        return SearchType.FilesInSiteItem;
      default:
        return null;
    }
  }

  private orderByForSearch(orderBy: string): string {
    return orderBy === '' ? null : this.orderBy;
  }
}
