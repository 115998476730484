import { MbsSize } from './mbs-size-enum';

export enum MbsLabelSize {
  xxs = MbsSize.xxs,
  xs = MbsSize.xs,
  md = MbsSize.md,
  sm = MbsSize.sm,
  lg = MbsSize.lg,
  inherit = MbsSize.inherit
}
