import { InjectionToken, Provider } from '@angular/core';
import { SharedPersistentStateService } from './shared-persistent-state.service';

export const SHARED_PERSISTENT_STATE = new InjectionToken<SharedPersistentStateService>('A shared persistent state information');

export function initSharedPersistentStateService(): SharedPersistentStateService {
  return new SharedPersistentStateService('sharedPersistentState');
}
export const SHARED_PERSISTENT_STATE_PROVIDERS: Provider[] = [
  {
    provide: SHARED_PERSISTENT_STATE,
    useFactory: initSharedPersistentStateService
  }
];
