export enum MimeCategory {
  None = 'None',
  Archive = 'Archive',
  Audio = 'Audio',
  Document = 'Document',
  Drawing = 'Drawing',
  Drive = 'Drive',
  Excel = 'Excel',
  File = 'File',
  Folder = 'Folder',
  Form = 'Form',
  Fusion = 'Fusion',
  Generic = 'Generic',
  Image = 'Image',
  OneNoteFolder = 'OneNoteFolder',
  OneNoteFile = 'OneNoteFile',
  Pdf = 'Pdf',
  Powerpoint = 'Powerpoint',
  Presentation = 'Presentation',
  Script = 'Script',
  Site = 'Site',
  SitePage = 'SitePage',
  SiteDisabled = 'SiteDisabled', // processed manually on FE only
  Spreadsheet = 'Spreadsheet',
  Text = 'Text',
  Video = 'Video',
  Word = 'Word'
}

export enum MimeCategorySiteBlock {
  Agenda = 'Agenda',
  Announcements = 'Announcements',
  AppCatalog = 'AppCatalog',
  AppRequests = 'AppRequests',
  CallTrack = 'CallTrack',
  Categories = 'Categories',
  Circulation = 'Circulation',
  Commcat = 'Commcat',
  Commem = 'Commem',
  Comments = 'Comments',
  Contacts = 'Contacts',
  Decision = 'Decision',
  DiscussionBoard = 'DiscussionBoard',
  DocumentLibrary = 'DocumentLibrary',
  Events = 'Events',
  GanttTasks = 'GanttTasks',
  GenericList = 'GenericList',
  Grid = 'Grid',
  Itrl = 'Itrl',
  ICXDDOC = 'ICXDDOC',
  Issues = 'Issues',
  Links = 'Links',
  KpiList = 'KpiList',
  MeetingUser = 'MeetingUser',
  MeetingObjective = 'MeetingObjective',
  Facility = 'Facility',
  PictureLibrary = 'PictureLibrary',
  Posts = 'Posts',
  Pwsris = 'Pwsris',
  Pwscom = 'Pwscom',
  ReportsLibrary = 'ReportsLibrary',
  SlideLibrary = 'SlideLibrary',
  Survey = 'Survey',
  Tal = 'Tal',
  Tasks = 'Tasks',
  Teams = 'Teams',
  Timecard = 'Timecard',
  Users = 'Users',
  Whereabouts = 'Whereabouts',
  WorkflowHistory = 'WorkflowHistory',
  WebPageLibrary = 'WebPageLibrary',
  WebTemplateExtensionsList = 'WebTemplateExtensionsList',
  XmlForm = 'XmlForm'
}
