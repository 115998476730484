import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { addBreadcrumb, SeverityLevel } from '@sentry/browser';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class SentryInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const start = new Date().getTime();

    return next.handle(req).pipe(
      tap((res) => {
        if (res instanceof HttpResponse) {
          this.sendBreadcrumb(req, res, start);
        }

        return res;
      }),
      catchError((err: HttpErrorResponse) => {
        if (err instanceof HttpErrorResponse) {
          this.sendBreadcrumb(req, err, start);
        }

        throw err;
      })
    );
  }

  private sendBreadcrumb(req: HttpRequest<any>, res: HttpResponse<any> | HttpErrorResponse, start: number): void {
    const now = new Date().getTime();
    const duration = (now - start) / 1000;

    addBreadcrumb({
      category: 'xhr',
      data: {
        method: req.method,
        url: req.url,
        status_code: res.status,
        duration,
        traceId: res.headers.get('trace-id')
      },
      timestamp: now / 1000,
      type: 'https',
      level: res instanceof HttpErrorResponse ? ('error' as SeverityLevel) : ('info' as SeverityLevel)
    });
  }
}
