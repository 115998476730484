export function converterSeconds(secs: number, isDefault = true): string {
  const hours = Math.floor(secs / 3600);
  const minutes = Math.floor(secs / 60) % 60;
  const seconds = secs % 60;

  if (isDefault) {
    return [hours, minutes, seconds]
      .map((val) => (val < 10 ? '0' + val : val))
      .filter((val, i) => val !== '00' || i > 0)
      .join(':');
  } else {
    return [hours, minutes, seconds].reduce((acc, curr, index) => {
      if (index === 0) {
        return curr > 0 ? acc + curr + 'h ' : acc;
      }

      if (index === 1) {
        return curr > 0 ? acc + curr + 'min ' : acc + '0min ';
      }

      if (index === 2) {
        return curr > 0 ? acc + curr + 'sec ' : acc + '0sec';
      }

      return acc;
    }, '');
  }
}
