import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ExportToPSTFilter, ExportToPSTServices } from '@app/common/models';
import { isValidDate, ucFirst, validateSearchFilterForm } from '@app/common/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { MbsPopupType, MbsSize } from 'mbs-ui-kit';

type ServicesForm = {
  contacts: {
    checked: boolean;
    filter?: ServiceFilter; // now unsupported for contacts
  };
  calendar: {
    checked: boolean;
    filter?: ServiceFilter; // now unsupported for calendar
  };
  mail: {
    checked: boolean;
    filter: ServiceFilter;
  };
};
type ServiceFilter = {
  from: Date;
  to: Date;
};
@UntilDestroy()
@Component({
  selector: 'app-export-to-PST-control',
  templateUrl: './export-to-pst.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportToPstComponent implements OnInit {
  @Input() hidden: boolean;
  @Input() set needResetForm(e) {
    if (this.form) this.resetForm();
  }

  @Output() exportServices: EventEmitter<ExportToPSTServices[]> = new EventEmitter(null);
  @Output() formValid: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  public form: UntypedFormGroup;
  public isMailFilter = true;
  public readonly MbsSize = MbsSize;
  public readonly currentDay = new Date();
  public readonly MbsPopupType = MbsPopupType;

  get mailFilterFromControl(): FormControl {
    return this.form.get('mail.filter.from') as FormControl;
  }

  get mailFilterToControl(): FormControl {
    return this.form.get('mail.filter.to') as FormControl;
  }

  get mailCheckedControl(): FormControl {
    return this.form.get('mail.checked') as FormControl;
  }

  get calendarCheckedControl(): FormControl {
    return this.form.get('calendar.checked') as FormControl;
  }

  get contactsCheckedControl(): FormControl {
    return this.form.get('contacts.checked') as FormControl;
  }

  constructor(private fb: FormBuilder, private i18nPipe: I18NextPipe) {}

  ngOnInit(): void {
    this.initForm();

    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe({
      next: () => {
        this.emitServices();
        this.updateDatesValidation();
      }
    });
  }

  private updateDatesValidation(): void {
    validateSearchFilterForm(this.form, this.mailFilterFromControl, this.mailFilterToControl);
    !this.mailFilterFromControl.dirty && this.mailFilterFromControl.markAsDirty();
    this.mailFilterFromControl.untouched && this.mailFilterFromControl.markAsTouched();
  }

  private getFromDate = (): Date => {
    const timestampFromDate = new Date().setMonth(new Date().getMonth() - 1);
    return new Date(timestampFromDate);
  };

  private initForm(): void {
    this.form = this.fb.group({
      contacts: this.fb.group({
        checked: true
      }),
      calendar: this.fb.group({
        checked: true
      }),
      mail: this.fb.group({
        checked: true,
        filter: this.fb.group({
          from: this.getFromDate(),
          to: new Date()
        })
      })
    });
    this.setValidators();
    this.emitServices();
  }

  private setValidators(): void {
    const extendValidators = Validators.compose([this.ValidateDatepicker.bind(this), this.ValidateFormRequired.bind(this)]);
    this.form.setValidators(extendValidators);
  }

  private resetForm(): void {
    this.isMailFilter = true;

    this.form.reset({
      contacts: {
        checked: true
      },
      calendar: {
        checked: true
      },
      mail: {
        checked: true,
        filter: {
          from: this.getFromDate(),
          to: new Date()
        }
      }
    });
    this.setValidators();
  }

  private emitServices(): void {
    const services: ExportToPSTServices[] = this.getServices(this.form.value);

    this.exportServices.emit(services);
    this.formValid.emit(this.form.valid);
  }

  private getServices(form: ServicesForm): ExportToPSTServices[] {
    return Object.entries(form)
      .filter(([, value]) => value && value.checked)
      .map(([key, value]) => {
        return {
          Service: ucFirst(key),
          Filter: this.isMailFilter ? this.convertFilterToISO(value.filter) : null
        } as ExportToPSTServices;
      });
  }

  private convertFilterToISO = (filter: ServiceFilter): ExportToPSTFilter => {
    const availableConvert = filter && isValidDate(filter.from) && isValidDate(filter.to);
    return availableConvert ? this.formattedFilterData(filter) : null;
  };

  private formattedFilterData = (filter: ServiceFilter): ExportToPSTFilter => {
    const formattedFrom = filter.from.setHours(0, 0, 0, 0);
    const formattedTo = filter.to.setHours(23, 59, 59, 999);
    return {
      From: new Date(formattedFrom).toISOString(),
      To: new Date(formattedTo).toISOString()
    };
  };

  mailFilterChange(state: boolean): void {
    if (state) {
      this.setValidators();
    } else {
      this.form.clearValidators();
    }
    this.form.updateValueAndValidity();
  }

  private ValidateDatepicker(): ValidationErrors | null {
    if ((this.mailFilterFromControl.value as Date).getTime() > (this.mailFilterToControl.value as Date).getTime()) {
      return {
        datepicker: { message: this.i18nPipe.transform('exportToPST.validation.datepicker', { format: 'capitalize' }) }
      };
    }
    return null;
  }

  private ValidateFormRequired(): ValidationErrors | null {
    if (!this.mailCheckedControl.value && !this.calendarCheckedControl.value && !this.contactsCheckedControl.value) {
      return {
        form: { message: this.i18nPipe.transform('exportToPST.validation.notBeEmpty', { format: 'capitalize' }) }
      };
    }
    return null;
  }
}
