import { AfterViewInit, Directive, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';

@Directive({
  selector: '[mbsFocusBlurHandler]'
})
export class FocusBlurHandlerDirective implements AfterViewInit, OnDestroy {
  @Output() public onFocus = new EventEmitter<number>();
  @Output() public onBlur = new EventEmitter<number>();

  private get inputs(): NodeListOf<Element> {
    return this.elRef.nativeElement.querySelectorAll('[tabindex]');
  }

  constructor(private elRef: ElementRef<HTMLElement>) {}

  ngAfterViewInit(): void {
    queueMicrotask(() => {
      this.setListeners();
    });
  }

  private handleFocus() {
    this.onFocus.emit();
  }

  private handleblur() {
    this.onBlur.emit();
  }

  private setListeners(): void {
    this.inputs.forEach(input => {
      input.addEventListener('focus', () => this.handleFocus());
      input.addEventListener('blur', () => this.handleblur());
    });
  }

  private removeListeners(): void {
    this.inputs.forEach(input => {
      input.removeEventListener('focus', () => this.handleFocus());
      input.removeEventListener('blur', () => this.handleblur());
    });
  }

  ngOnDestroy(): void {
    this.removeListeners();
  }
}
