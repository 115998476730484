import { AdvancedSearchModel } from './advanced-search-model';

export class AdvancedSearchParser {
  public static get tagsRegex(): RegExp {
    return new RegExp(/(([\s\S]+?):\s*({([\s\S]+?)}|[\s\S]+?)?|(#\S+?))(\s|$)/g);
  }

  
  public static getModel(content: string, tags: string[]): AdvancedSearchModel {
    const reg = AdvancedSearchParser.tagsRegex;
    let result;
    const model: AdvancedSearchModel = {};

    const findFullMatchTag = (tag: string, sourceTags: string[]): string => {
      // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
      const lastWord = tag.match(/\S+\s*$/)[0];
      const filterTags = sourceTags.filter(t => t.includes(lastWord));

      return filterTags.length > 1
        ? findFullMatchTag(tag.substring(0, Math.max(tag.length - lastWord.length, 0)), filterTags)
        : filterTags[0];
    };

    const matchWords = (value: string): void => {
      const words = value.split(/\s+/).filter(v => !!v);
      if (words.length > 0) {
        if (!model.words) {
          model.words = words;
        } else {
          model.words.push(...words);
        }
      }
    };

    let copyContent = String(content);

    while ((result = reg.exec(content))) {
      const resultArr = result as RegExpExecArray;
      const tag = findFullMatchTag(resultArr[2], tags) || '';
      let nonTagPart = resultArr[2].substring(0, resultArr[2].length - tag.length);
      if (tag) {
        if (!model[tag]) {
          model[tag] = [];
        }
        const r = resultArr[4] || resultArr[3];
        if (r) {
          (model[tag] as string[]).push(r);
        }
      } else {
        nonTagPart = resultArr[1];
      }

      matchWords(nonTagPart);

      const index = copyContent.indexOf(resultArr[1]);
      copyContent = copyContent.substring(0, index) + copyContent.substring(index + resultArr[1].length);
    }

    matchWords(copyContent);
    return model;
  }
}
