export enum PaymentsTagsEnum {
  state = 'state',
  quantity = 'quantity',
  price = 'price',
  amount = 'amount',
  date = 'date',
  expireDate = 'expireDate',
  invoiceId = 'invoiceId'
}

export const PaymentsTagsMap: Map<PaymentsTagsEnum, { tag: string; prop: string }> = new Map([
  [
    PaymentsTagsEnum.state,
    {
      tag: 'State',
      prop: 'State'
    }
  ],
  [
    PaymentsTagsEnum.quantity,
    {
      tag: 'Quantity',
      prop: 'Quantity'
    }
  ],
  [
    PaymentsTagsEnum.price,
    {
      tag: 'Price',
      prop: 'Price'
    }
  ],
  [
    PaymentsTagsEnum.amount,
    {
      tag: 'Amount',
      prop: 'Amount'
    }
  ],
  [
    PaymentsTagsEnum.date,
    {
      tag: 'Date',
      prop: 'Date'
    }
  ],
  [
    PaymentsTagsEnum.expireDate,
    {
      tag: 'Expires',
      prop: 'ExpireDate'
    }
  ],
  [
    PaymentsTagsEnum.invoiceId,
    {
      tag: 'Invoice Id',
      prop: 'OrderId'
    }
  ]
]);
