import { Chart } from 'chart.js';
import { isNil } from 'lodash';

export function noItemDisplayChart(baseChart: Chart): void {
  if (isNil(baseChart)) {
    throw new Error('Caught error manually: The `noItemDisplayChart` method has been null or undefined');
  }

  const { datasets } = baseChart.data;
  let hasData = false;

  for (let i = 0; i < datasets.length; i += 1) {
    const dataset = datasets[i];
    const { data } = dataset;

    hasData = data.length > 0 || !data.every((item) => item === 0);
  }

  // TODO: after release 4.0 need delete
  // const chartData = baseChart.data;
  // const chartDataSets = chartData?.datasets?.[0]?.data as number[];
  // const isNotData: boolean = isArray(chartDataSets) && (chartDataSets.length === 0 || chartDataSets.every((item) => item === 0));

  if (!hasData) {
    const { ctx, width, height } = baseChart;

    baseChart.clear();
    ctx.save();
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText('No Data to Display', width / 2, height / 2);
    ctx.restore();
  }
}
