import { Pipe, PipeTransform } from '@angular/core';
import { AutoActivationMode } from '@app/common/models/user/auto-activation-mode.model';
import { I18_NAMESPACE_MODULE } from '@app/i18';
import { I18NextPipe } from 'angular-i18next';

@Pipe({
  name: 'autoActivationMode',
  standalone: true,
  pure: true
})
export class AutoActivationModePipe implements PipeTransform {
  private readonly moduleUsers = I18_NAMESPACE_MODULE.users;

  constructor(private i18nPipe: I18NextPipe) {}

  transform(mode: AutoActivationMode): string {
    switch (mode) {
      case AutoActivationMode.AllUsers:
        return this.i18nPipe.transform(this.moduleUsers + ':grid.allUsers', { format: 'title' });
      case AutoActivationMode.NewUsers:
        return this.i18nPipe.transform(this.moduleUsers + ':grid.newUsers', { format: 'title' });
      case AutoActivationMode.Disabled:
        return this.i18nPipe.transform(this.moduleUsers + ':grid.disabled', { format: 'title' });
      default:
        return '';
    }
  }
}
