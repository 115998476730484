<ng-container *ngIf="!retentionListShown; else retentionPolicyList">
  <header class="mbs-sidepanel_content-header">
    <div class="row align-items-center">
      <div class="col">
        <h2 class="mbs-sidepanel_content-title">
          {{ moduleUsers + ':sidepanel.tab.retentionPolicy' | i18next: { format: 'title' } }}
        </h2>
      </div>
      <div class="col-auto">
        <mbs-button type="dark"
                    [isCtrl]="true"
                    (click)="toggleRetentionListShown()">
          <span class="ml-2">
            {{ modulePolicy + ':sidepanel.button.goToList' | i18next: { format: 'title' } }}
          </span>
          <span class="ml-2 ico ico-arrRight"></span>
        </mbs-button>
      </div>
    </div>
  </header>

  <ng-container *ngIf="attachedPolicies$ | async as policies">
    <div class="mbs-form_content">
      <mbs-form-group>
        <mbs-select [label]="modulePolicy + ':sidepanel.label.mail' | i18next: { format: 'title' }"
                    [items]="policies.EmailPolicies"
                    [labelClasses]="'-' + MbsSize.sm"
                    [size]="MbsSize.sm"
                    bindLabel="Text"
                    bindValue="Value"
                    [clearable]="showClearButton(policies.EmailPolicies)"
                    [(ngModel)]="selectedPolicies.emailPolicyId"
                    [disabled]="policies.EmailPolicies?.length === 0"
                    [placeholder]="modulePolicy + ':sidepanel.placeholder.selectPolicy' | i18next: { format: 'title' }"
                    name="mailPolicy">
        </mbs-select>
      </mbs-form-group>
      <mbs-form-group>
        <mbs-select [label]="modulePolicy + ':sidepanel.label.drive' | i18next: { format: 'title' }"
                    [items]="policies.DrivePolicies"
                    [labelClasses]="'-' + MbsSize.sm"
                    [size]="MbsSize.sm"
                    bindLabel="Text"
                    bindValue="Value"
                    [clearable]="showClearButton(policies.DrivePolicies)"
                    [(ngModel)]="selectedPolicies.drivePolicyId"
                    [disabled]="policies.DrivePolicies?.length === 0"
                    [placeholder]="modulePolicy + ':sidepanel.placeholder.selectPolicy' | i18next: { format: 'title' }"
                    name="divePolicy">
        </mbs-select>
      </mbs-form-group>
      <mbs-form-group>
        <mbs-select [label]="modulePolicy + ':sidepanel.label.contacts' | i18next: { format: 'title' }"
                    [items]="policies.ContactPolicies"
                    [labelClasses]="'-' + MbsSize.sm"
                    [size]="MbsSize.sm"
                    bindLabel="Text"
                    bindValue="Value"
                    [clearable]="showClearButton(policies.ContactPolicies)"
                    [(ngModel)]="selectedPolicies.contactPolicyId"
                    [disabled]="policies.ContactPolicies?.length === 0"
                    [placeholder]="modulePolicy + ':sidepanel.placeholder.selectPolicy' | i18next: { format: 'title' }"
                    name="contactPolicy">
        </mbs-select>
      </mbs-form-group>
      <mbs-form-group>
        <mbs-select [label]="modulePolicy + ':sidepanel.label.calendar' | i18next: { format: 'title' }"
                    [items]="policies.CalendarPolicies"
                    [labelClasses]="'-' + MbsSize.sm"
                    [size]="MbsSize.sm"
                    bindLabel="Text"
                    bindValue="Value"
                    [clearable]="showClearButton(policies.CalendarPolicies)"
                    [(ngModel)]="selectedPolicies.calendarPolicyId"
                    [disabled]="policies.CalendarPolicies?.length === 0"
                    [placeholder]="modulePolicy + ':sidepanel.placeholder.selectPolicy' | i18next: { format: 'title' }"
                    name="calendarPolicy">
        </mbs-select>
      </mbs-form-group>
      <mbs-form-group *ngIf="showSharedPointOrSharedDrivesSelect$ | async"
                      [class.mb-0]="isHomeUser$ | async">
        <mbs-select [label]="driveTitle"
                    [labelClasses]="'-' + MbsSize.sm"
                    [size]="MbsSize.sm"
                    bindLabel="Text"
                    bindValue="Value"
                    [clearable]="showClearButton(policies.SitePolicies)"
                    [items]="getDriveItems(policies)"
                    [(ngModel)]="filePolicyId"
                    [disabled]="getDriveItems(policies)?.length === 0"
                    [placeholder]="modulePolicy + ':sidepanel.placeholder.selectPolicy' | i18next: { format: 'title' }"
                    name="sitePolicy">
        </mbs-select>
      </mbs-form-group>
      <mbs-form-group *ngIf="(showSharedPointOrSharedDrivesSelect$ | async) && !(isHomeUser$ | async) && isOffice"
                      class="mb-0">
        <mbs-select [label]="modulePolicy + ':sidepanel.label.teams' | i18next: { format: 'title' }"
                    [labelClasses]="'-' + MbsSize.sm"
                    [size]="MbsSize.sm"
                    bindLabel="Text"
                    bindValue="Value"
                    [clearable]="showClearButton(policies.TeamsPolicies)"
                    [items]="policies.TeamsPolicies"
                    [(ngModel)]="selectedPolicies.teamsPolicyId"
                    [disabled]="policies.TeamsPolicies?.length === 0"
                    [placeholder]="modulePolicy + ':sidepanel.placeholder.selectPolicy' | i18next: { format: 'title' }"
                    name="teamsPolicy">
        </mbs-select>
      </mbs-form-group>
    </div>
  </ng-container>
</ng-container>

<ng-template #retentionPolicyList>
  <header class="mbs-sidepanel_content-header">
    <div class="row align-items-center">
      <div class="col">
        <h2 class="mbs-sidepanel_content-title">
          {{ moduleUsers + ':sidepanel.tab.retentionPolicy' | i18next: { format: 'title' } }}
        </h2>
      </div>
      <div class="col-auto">
        <mbs-button type="dark"
                    icon="ico ico-arrLeft"
                    [isCtrl]="true"
                    (click)="toggleRetentionListShown()">
          <span class="ml-2">
            {{ modulePolicy + ':sidepanel.button.back' | i18next: { format: 'title' } }}
          </span>
        </mbs-button>
      </div>
    </div>
  </header>

  <div class="mbs-form_content">
    <mbs-form-group class="text-right">
      <mbs-button type="primary"
                  (click)="handleShowCreateRetention()">
        {{ modulePolicy + ':createPolicy' | i18next: { format: 'title' } }}
      </mbs-button>
    </mbs-form-group>

    <mbs-form-group *ngFor="let policy of (policies$ | async); last as isLast"
                    [class.mb-0]="isLast">
      <mbs-accordion size="small">
        <mbs-accordion-panel>
          <ng-container panelTitle>
            <span class="mr-2"
                  [ngClass]="policyIconCssClass(policy.ServiceType)"></span>
              {{ policy.Name }}
          </ng-container>

          <div panelAppendHeader
               class="mbs-accordion_menu"
               *ngIf="false === policy.ReadOnly">
            <mbs-button icon="ico ico-EllipsisV px-3 mr-1"
                        type="dark"
                        [hideDropdownIcon]="true"
                        [isCtrl]="true">
              <ng-template mbsButtonDropdown>
                <button ngbDropdownItem
                        class="text-sm"
                        (click)="handleShowEditRetention(policy)">
                  {{ modulePolicy + ':editPolicy' | i18next: { format: 'title' } }}
                </button>
                <button ngbDropdownItem
                        class="text-danger text-sm"
                        (click)="handleDeleteRetentionPolicy(policy)">
                  {{ modulePolicy + ':deletePolicy' | i18next: { format: 'title' } }}
                </button>
              </ng-template>
            </mbs-button>
          </div>

          <table class="table table-simple table-sm mb-0">
            <tr *ngIf="policy.Name">
              <td class="text-muted">
                {{ modulePolicy + ':sidepanel.accordion.policyName' | i18next: { format: 'title' } }}
              </td>
              <td>{{ policy.Name }}</td>
            </tr>
            <tr *ngIf="policy.ServiceType">
              <td class="text-muted">
                {{ modulePolicy + ':sidepanel.accordion.serviceType' | i18next: { format: 'title' } }}
              </td>
              <td>{{ policy.ServiceType }}</td>
            </tr>
            <tr>
              <td class="text-muted">
                {{ modulePolicy + ':sidepanel.accordion.currentRetention' | i18next: { format: 'title' } }}
              </td>
              <td>{{ policy.KeepCntUnits ? policy.KeepCntUnits + ' ' + policy.KeepUnit : '-' }}</td>
            </tr>
            <tr>
              <td class="text-muted">
                {{ modulePolicy + ':sidepanel.accordion.lastRetention' | i18next: { format: 'title' } }}
              </td>
              <td>{{ policy.AlwaysKeepLastRevision ? 'Yes' : 'No' }}</td>
            </tr>
            <tr>
              <td class="text-muted">
                {{ modulePolicy + ':sidepanel.accordion.revisionsToKeep' | i18next: { format: 'title' } }}
              </td>
              <td>{{ policy.KeepRevisionsCnt ? policy.KeepRevisionsCnt : '-' }}</td>
            </tr>
            <tr>
              <td class="text-muted">
                {{ modulePolicy + ':sidepanel.accordion.purgeDelay' | i18next: { format: 'title' } }}
              </td>
              <td>{{ policy.DelayPurgeCntUnits }} {{ policy.DelayPurgeUnit }}</td>
            </tr>
            <tr>
              <td class="text-muted">
                {{ modulePolicy + ':sidepanel.accordion.from' | i18next: { format: 'title' } }}
              </td>
              <td>{{ policy.ByModifyOrBackupDate ? 'Date of Backup' : 'Date Modified' }}</td>
            </tr>
          </table>

        </mbs-accordion-panel>
      </mbs-accordion>
    </mbs-form-group>
  </div>
</ng-template>
