import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from '../button/button.module';
import { CheckboxModule } from '../form/checkbox/checkbox.module';
import { TreeAppendDirective } from './directives/treeAppend.directive';
import { TreeElementDirective } from './directives/treeElement.directive';
import { TreeLoadMoreDirective } from './directives/treeLoadMore.directive';
import { TreeLoadMorePipe } from './pipes/treeLoadMore.pipe';
import { TreeComponent } from './tree.component';

@NgModule({
  imports: [CommonModule, CheckboxModule, FormsModule, ButtonModule, ScrollingModule, CdkTreeModule],
  declarations: [TreeComponent, TreeElementDirective, TreeAppendDirective, TreeLoadMoreDirective, TreeLoadMorePipe],
  exports: [TreeComponent, TreeElementDirective, TreeAppendDirective, TreeLoadMoreDirective, TreeLoadMorePipe]
})
export class TreeModule {}
