export enum ReportsServiceTagsEnum {
  service = 'Service',
  serviceStatus = 'Service Status',
  lastBackupDate = 'Last Backup Date',
  size = 'Size',
  backupStatus = 'Backup Status'
}

export const ReportsServiceTagsMap: Map<ReportsServiceTagsEnum, { tag: string; prop: string }> = new Map([
  [
    ReportsServiceTagsEnum.service,
    {
      tag: 'Service',
      prop: 'Service'
    }
  ],
  [
    ReportsServiceTagsEnum.serviceStatus,
    {
      tag: 'Service Status',
      prop: 'ServiceStatus'
    }
  ],
  [
    ReportsServiceTagsEnum.lastBackupDate,
    {
      tag: 'Last Backup Date',
      prop: 'LastBackupDate'
    }
  ],
  [
    ReportsServiceTagsEnum.size,
    {
      tag: 'Size',
      prop: 'Size'
    }
  ],
  [
    ReportsServiceTagsEnum.backupStatus,
    {
      tag: 'Backup Status',
      prop: 'BackupStatus'
    }
  ]
]);

export enum ReportsUserTagsEnum {
  userName = 'User Name',
  license = 'License',
  userState = 'User State',
  mail = 'Mail',
  drive = 'Drive',
  contacts = 'Contacts',
  calendar = 'Calendar'
}

export const ReportsUserTagsMap: Map<ReportsUserTagsEnum, { tag: string; prop: string }> = new Map([
  [
    ReportsUserTagsEnum.userName,
    {
      tag: 'User Name',
      prop: 'UserName'
    }
  ],
  [
    ReportsUserTagsEnum.license,
    {
      tag: 'License',
      prop: 'ExpireDate'
    }
  ],
  [
    ReportsUserTagsEnum.userState,
    {
      tag: 'User',
      prop: 'InBackup'
    }
  ],
  [
    ReportsUserTagsEnum.mail,
    {
      tag: 'Mail',
      prop: 'Mail'
    }
  ],
  [
    ReportsUserTagsEnum.drive,
    {
      tag: 'Drive',
      prop: 'Drive'
    }
  ],
  [
    ReportsUserTagsEnum.contacts,
    {
      tag: 'Contacts',
      prop: 'Contacts'
    }
  ],
  [
    ReportsUserTagsEnum.calendar,
    {
      tag: 'Calendar',
      prop: 'Calendar'
    }
  ]
]);
