import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import {
  AttachedPolicies,
  AttachedPolicy,
  AuthUser,
  RetentionPolicyOdata,
  SelectedPolicies,
  ServiceType,
  hasUserAccountAdminRole
} from '@app/common/models';
import { AuthService, RetentionPolicyService } from '@app/common/services';
import { getServiceUiInfo, isHomeUser } from '@app/common/utils';
import { EditCreateRetentionPolicyModalComponent } from '@app/components/modals/edit-create-retention-policy-modal/edit-create-retention-policy-modal.component';
import { I18_NAMESPACE_MODULE } from '@app/i18';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { cloneDeep, isNil } from 'lodash';
import { MbsSize, ModalService, ModalSettings, ToastService } from 'mbs-ui-kit';
import { Observable, noop, of } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-user-retention-policies-control',
  templateUrl: './user-retention-policies-control.component.html'
})
export class UserRetentionPoliciesControlComponent implements OnInit {
  @Input() public set userIds(ids: string[]) {
    if (ids?.length > 0) {
      this._userIds = ids;
      this.retentionPolicyService.fetchAttachedPolicies(ids);

      this.showSharedPointOrSharedDrivesSelect$ = ids.length === 1 ? this.isGlobalAdmin(ids) : of(false);
    }
  }

  readonly #toastTitleSuccess = this.i18nPipe.transform('toast.success.title', { format: 'title' });
  readonly #toastTitleError = this.i18nPipe.transform('toast.error.title', { format: 'title' });

  public readonly MbsSize = MbsSize;
  public readonly modulePolicy = I18_NAMESPACE_MODULE.policy;
  public readonly moduleUsers = I18_NAMESPACE_MODULE.users;
  public isHomeUser$: Observable<boolean>;
  public policies$: Observable<RetentionPolicyOdata[]>;
  public attachedPolicies$: Observable<AttachedPolicies>;
  public showSharedPointOrSharedDrivesSelect$: Observable<boolean>;
  public driveTitle: string;
  public retentionListShown: boolean;
  // used for unique id
  public prefix = Math.random().toString(36).substring(2) + '_';
  public selectedPolicies: SelectedPolicies = {
    emailPolicyId: null,
    drivePolicyId: null,
    contactPolicyId: null,
    calendarPolicyId: null,
    sitePolicyId: null,
    teamDriveId: null,
    teamsPolicyId: null
  };

  private initPolicies: SelectedPolicies;
  private _userIds: string[] = [];

  constructor(
    private i18nPipe: I18NextPipe,
    private retentionPolicyService: RetentionPolicyService,
    private modal: ModalService,
    private toastService: ToastService,
    private authService: AuthService
  ) {
    this.policies$ = retentionPolicyService.data$;
    this.attachedPolicies$ = retentionPolicyService.attachedPolicies$.pipe(filter((policy) => !isNil(policy)));
    this.isHomeUser$ = authService.getAuthUser().pipe(
      filter(Boolean),
      map((user: AuthUser) => isHomeUser(user)),
      shareReplay(1)
    );
  }

  get policiesChanged(): boolean {
    if (!this.selectedPolicies || !this.initPolicies) return false;

    return (
      this.selectedPolicies.emailPolicyId !== this.initPolicies.emailPolicyId ||
      this.selectedPolicies.drivePolicyId !== this.initPolicies.drivePolicyId ||
      this.selectedPolicies.contactPolicyId !== this.initPolicies.contactPolicyId ||
      this.selectedPolicies.calendarPolicyId !== this.initPolicies.calendarPolicyId ||
      this.selectedPolicies.sitePolicyId !== this.initPolicies.sitePolicyId ||
      this.selectedPolicies.teamDriveId !== this.initPolicies.teamDriveId ||
      this.selectedPolicies.teamsPolicyId !== this.initPolicies.teamsPolicyId
    );
  }

  get filePolicyId(): string {
    if (this.isGoogle) {
      return this.selectedPolicies.teamDriveId;
    }
    if (this.isOffice) {
      return this.selectedPolicies.sitePolicyId;
    }
    return '';
  }

  set filePolicyId(id: string) {
    if (this.isGoogle) {
      this.selectedPolicies.teamDriveId = id;
    }
    if (this.isOffice) {
      this.selectedPolicies.sitePolicyId = id;
    }
  }

  get isOffice(): boolean {
    return this.authService?.isOffice;
  }

  get isGoogle(): boolean {
    return this.authService?.isGoogle;
  }

  ngOnInit(): void {
    this.attachedPolicies$.pipe(untilDestroyed(this)).subscribe((policy: AttachedPolicies) => {
      this.selectedPolicies.emailPolicyId = this.getSelectedPolicyId(policy.EmailPolicies);
      this.selectedPolicies.drivePolicyId = this.getSelectedPolicyId(policy.DrivePolicies);
      this.selectedPolicies.contactPolicyId = this.getSelectedPolicyId(policy.ContactPolicies);
      this.selectedPolicies.calendarPolicyId = this.getSelectedPolicyId(policy.CalendarPolicies);
      this.selectedPolicies.sitePolicyId = this.getSelectedPolicyId(policy.SitePolicies);
      this.selectedPolicies.teamDriveId = this.getSelectedPolicyId(policy.TeamDrivePolicies);
      this.selectedPolicies.teamsPolicyId = this.getSelectedPolicyId(policy.TeamsPolicies);

      this.initPolicies = cloneDeep(this.selectedPolicies) as SelectedPolicies;
    });

    this.retentionPolicyService.fetchPolicies();
    this.selectedPolicies = {
      emailPolicyId: null,
      drivePolicyId: null,
      contactPolicyId: null,
      calendarPolicyId: null,
      sitePolicyId: null,
      teamDriveId: null,
      teamsPolicyId: null
    };

    this.driveTitle = this.getDriveTitle();
  }

  private getSelectedPolicyId = (policies: AttachedPolicy[]): string => {
    if (!policies) return null;

    const selected = policies.find((p: AttachedPolicy) => p.Selected);

    return selected?.Value || null;
  };

  toggleRetentionListShown(): void {
    this.retentionListShown = !this.retentionListShown;
  }

  handleShowCreateRetention(): void {
    this.modal
      .openCustom(EditCreateRetentionPolicyModalComponent, this.getPopupPolicySettings())
      .then((saveResult: RetentionPolicyOdata) => {
        const bodyCreated = this.i18nPipe.transform(this.modulePolicy + ':toast.body.create', { format: 'capitalize' });
        const bodyError = this.i18nPipe.transform(this.modulePolicy + ':toast.error.create', { format: 'capitalize' });

        this.retentionPolicyService.createPolicy(saveResult).subscribe({
          next: () => {
            this.retentionPolicyService.fetchPolicies();
            this.retentionPolicyService.fetchAttachedPolicies(this._userIds);
            this.toastService.success(bodyCreated, this.#toastTitleSuccess);
          },
          error: (res: HttpErrorResponse) =>
            this.toastService.error(res.status !== 500 && (res.error?.value || bodyError), this.#toastTitleError)
        });
      })
      .catch(() => noop);
  }

  handleShowEditRetention(policy: RetentionPolicyOdata): void {
    this.modal
      .openCustom(EditCreateRetentionPolicyModalComponent, this.getPopupPolicySettings(policy))
      .then((saveResult: RetentionPolicyOdata) => {
        const bodyUpdated = this.i18nPipe.transform(this.modulePolicy + ':toast.body.update', { format: 'capitalize' });
        const bodyError = this.i18nPipe.transform(this.modulePolicy + ':toast.error.update', { format: 'capitalize' });

        this.retentionPolicyService.updatePolicy(saveResult).subscribe({
          next: () => {
            this.retentionPolicyService.fetchPolicies();
            this.retentionPolicyService.fetchAttachedPolicies(this._userIds);
            this.toastService.success(bodyUpdated, this.#toastTitleSuccess);
          },
          error: (res: HttpErrorResponse) =>
            this.toastService.error(res.status !== 500 && (res.error?.value || bodyError), this.#toastTitleError)
        });
      })
      .catch(() => noop);
  }

  handleDeleteRetentionPolicy(policy: RetentionPolicyOdata): void {
    const bodyDeleted = this.i18nPipe.transform(this.modulePolicy + ':toast.body.delete', { format: 'capitalize' });
    const bodyError = this.i18nPipe.transform(this.modulePolicy + ':toast.error.delete', { format: 'capitalize' });

    this.modal
      .open(
        {
          header: { title: this.i18nPipe.transform(this.modulePolicy + ':modal.title.delete', { format: 'title' }) },
          footer: {
            okButton: { text: this.i18nPipe.transform(this.modulePolicy + ':modal.button.delete', { format: 'title' }), type: 'danger' }
          }
        },
        `${this.i18nPipe.transform(this.modulePolicy + ':modal.body.delete')} <b>${policy.Name}</b>?`
      )
      .then((isDeletedPolicy: boolean) => {
        if (isDeletedPolicy) {
          this.retentionPolicyService.deletePolicy(policy.Id).subscribe({
            next: () => {
              this.retentionPolicyService.fetchPolicies();
              this.retentionPolicyService.fetchAttachedPolicies(this._userIds);
              this.toastService.success(bodyDeleted, this.#toastTitleSuccess);
            },
            error: (res: HttpErrorResponse) =>
              this.toastService.error(res.status !== 500 && (res.error?.value || bodyError), this.#toastTitleError)
          });
        }
      })
      .catch(() => noop);
  }

  getPopupPolicySettings(data?: RetentionPolicyOdata): ModalSettings {
    const settings: ModalSettings = {
      responsive: true
    };

    if (data) {
      settings.data = data;
    }

    return settings;
  }

  getDriveItems(policies: AttachedPolicies): AttachedPolicy[] {
    switch (true) {
      case this.isGoogle:
        return policies.TeamDrivePolicies;
      case this.isOffice:
        return policies.SitePolicies;
      default:
        return null;
    }
  }

  getDriveTitle(): string {
    switch (true) {
      case this.isGoogle:
        return this.i18nPipe.transform(this.modulePolicy + ':sidepanel.label.sharedDrives', { format: 'title' });
      case this.isOffice:
        return this.i18nPipe.transform(this.modulePolicy + ':sidepanel.label.sharePoint', { format: 'title' });
      default:
        return '';
    }
  }

  policyIconCssClass(policyTypeName: string): string {
    const serviceType = ServiceType[policyTypeName] as ServiceType;

    return getServiceUiInfo(serviceType).iconCssClass;
  }

  private isGlobalAdmin(ids: string[]): Observable<boolean> {
    return (this.showSharedPointOrSharedDrivesSelect$ = this.authService.getRoles().pipe(
      map((roles) => {
        const isGlobalAdmin: boolean = ids[0] === this.authService.id;
        const isUserAccountAdmin: boolean = hasUserAccountAdminRole(roles);

        return isGlobalAdmin && !isUserAccountAdmin;
      })
    ));
  }

  showClearButton(policies: AttachedPolicy[]): boolean {
    return policies.every((police) => !isNil(police.Value));
  }
}
