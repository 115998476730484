import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@app/common';
import { AbstractStorage } from '@app/common/services/storage/abstract-storage';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService extends AbstractStorage {
  constructor(@Inject(WINDOW) protected window: Window) {
    super(window);
  }

  public set<T>(key: string, data: T): void {
    this.window.sessionStorage.setItem(this.prefix + key, JSON.stringify(data));
  }

  public get<T>(key: string): T {
    try {
      return JSON.parse(this.window.sessionStorage.getItem(this.prefix + key)) as T;
    } catch (e) {
      console.error(`${String(e.name)}: ${String(e.message)}`);
      return null;
    }
  }

  public remove(key: string): void {
    this.window.sessionStorage.removeItem(this.prefix + key);
  }
}
