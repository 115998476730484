import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OverlayDirective } from './overlay.directive';
import { TypedTemplateDirective } from './typed-template.directive';

const directives = [OverlayDirective, TypedTemplateDirective];

@NgModule({
  imports: [CommonModule],
  declarations: directives,
  exports: directives
})
export class SharedDirectivesModule {}
