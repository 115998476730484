<div class="mbs-input mbs-input-range form-control"
     mbsFocusBlurHandler
     (onBlur)="isFocus = false"
     (onFocus)="isFocus = true"
     [ngClass]="{ focus: isFocus, disabled: disabled }">
  <mbs-input-label *ngIf="label"
                   [label]="label"
                   [disabled]="disabledState"
                   [info]="info"
                   [labelSize]="labelSize"
                   [infoPlacement]="infoPlacement"
                   [infoTriggers]="infoTriggers"
                   [infoContainer]="infoContainer"
                   [required]="hasRequiredValidator && !isLabelTemplate"
                   [labelClasses]="labelClasses"
                   [labelContentClasses]="labelContentClasses">
    <ng-content select="[prependLabel]"
                ngProjectAs="[prependLabel]"></ng-content>
    <ng-content select="[appendLabel]"
                ngProjectAs="[appendLabel]"></ng-content>
  </mbs-input-label>
  <div class="input-group">
    <mbs-input-pre-append *ngIf="prependButtons"
                          position="prepend"
                          [disabled]="disabledState"
                          [dataTemplate]="prepend"
                          [data]="prependButtons"
                          [size]="size"
                          (buttonClick)="buttonClick.emit($event)">
    </mbs-input-pre-append>
    <div class="input-group_control d-flex align-items-center justify-content-between">
      <ng-content select="startDate"></ng-content>
      <span>-</span>
      <ng-content select="endDate"></ng-content>
      <div class="form-control_loader"
           [ngClass]="size ? '-' + size : ''"
           *ngIf="loading">
        <span class="loader"
              [ngClass]="size === 'xxs' ? 'loader-sm' : ''"></span>
      </div>
    </div>
    <mbs-input-pre-append *ngIf="appendButtons"
                          position="append"
                          [disabled]="disabledState"
                          [dataTemplate]="append"
                          [data]="appendButtons"
                          [size]="size"
                          (buttonClick)="buttonClick.emit($event)">
    </mbs-input-pre-append>
  </div>
</div>
