import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CheckboxModule } from '../checkbox/checkbox.module';
import { InputBaseModule } from '../input-base/input-base.module';
import { SelectComponent } from './select.component';

@NgModule({
  declarations: [SelectComponent],
  imports: [CommonModule, FormsModule, NgSelectModule, InputBaseModule, ReactiveFormsModule, CheckboxModule],
  exports: [SelectComponent, NgSelectModule],
  providers: []
})
export class SelectModule {
  constructor() {
    // empty
  }
}
