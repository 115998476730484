import { ElementRef } from '@angular/core';
import { BOOLEAN_OPERATOR, COMPARISON_OPERATOR } from '@app/common';
import { DisplayNameItemType } from '@app/common/models';
import { getSize, getSizeMagnitude, sizeFilterEnum, sizeMagnitudesEnum } from '@app/common/services/smart-search/smart-search.helper';
import { isValidDate, quoteODataEncoding } from '@app/common/utils';
import { isArray } from 'lodash';
import { SmartSearchModel, SmartSearchModelField, Template } from 'mbs-ui-kit';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

export type FilterOptions = {
  model: SmartSearchModelField[];
  prop: string;
  enumItems?: DisplayNameItemType;
};

export const containsWrapper = (prop: string, term: string): string => {
  return `contains(tolower(${prop}),'${quoteODataEncoding(term).toLowerCase()}')`;
};

export const getFilterByContains = (options: FilterOptions): string => {
  const { model, prop } = options;
  const term = isArray(model) ? model.map((word) => word.value).join(' ') : '';

  return containsWrapper(prop, term);
};
export const getFilterByEq = (options: FilterOptions, quotes = true): string => {
  const { model, prop } = options;
  const term = isArray(model) ? model.map((word) => word.value).join(' ') : '';

  return quotes ? `${prop} ${COMPARISON_OPERATOR.eq} '${term}'` : `${prop} ${COMPARISON_OPERATOR.eq} ${term}`;
};

export const getFilterByDate = (options: FilterOptions): string => {
  const { model, prop } = options;
  const term = isArray(model) ? model.map((word) => word.value).join(' ') : '';
  const date = new Date(term);

  if (isValidDate(date)) {
    const formattedFrom = new Date(date.setHours(0, 0, 0, 0));
    const formattedTo = new Date(date.setHours(23, 59, 59, 999));

    return `${prop} ${COMPARISON_OPERATOR.gt} ${formattedFrom.toISOString()} ${BOOLEAN_OPERATOR.and} ${prop} ${
      COMPARISON_OPERATOR.lt
    } ${formattedTo.toISOString()}`;
  }

  return `${prop} ${COMPARISON_OPERATOR.lt} '${term}' ${BOOLEAN_OPERATOR.and} ${prop} ${COMPARISON_OPERATOR.gt} '${term}'`;
};

export const getFilterByDateRange = (options: FilterOptions, direction: 'from' | 'to'): string => {
  const { model, prop } = options;
  const term = isArray(model) ? model.map((word) => word.value).join(' ') : '';
  const date = new Date(term);

  if (isValidDate(date)) {
    const formattedFrom = new Date(date.setHours(0, 0, 0, 0));
    const formattedTo = new Date(date.setHours(23, 59, 59, 999));

    switch (direction) {
      case 'from':
        return `${prop} ${COMPARISON_OPERATOR.gt} ${formattedFrom.toISOString()}`;
      case 'to':
        return `${prop} ${COMPARISON_OPERATOR.lt} ${formattedTo.toISOString()}`;
      default:
        return '';
    }
  }

  return '';
};

export const getFilterByEqFromEnum = (options: FilterOptions, quotes = true): string => {
  const { model, prop, enumItems } = options;
  const term = isArray(model)
    ? model
        .map((word: { value: string }) => {
          const pair = Object.entries(enumItems).find(([, value]) => value.displayName.toLowerCase() === word.value.toLowerCase());

          return pair ? pair[0] : word.value;
        })
        .join('')
    : '';

  return quotes ? `${prop} ${COMPARISON_OPERATOR.eq} '${term}'` : `${prop} ${COMPARISON_OPERATOR.eq} ${term}`;
};

export const getFilterBySize = (options: FilterOptions & { filter: sizeFilterEnum }): string => {
  const { model, prop, filter } = options;
  const term = isArray(model) ? model.map((word) => word.value).join(' ') : '';
  const sign = filter == sizeFilterEnum.less ? COMPARISON_OPERATOR.lt : COMPARISON_OPERATOR.gt;
  const size = getSize(term);
  const magnitude = getSizeMagnitude(term);
  let bytes = +size;

  if (size && magnitude) {
    if (magnitude.toLowerCase() == sizeMagnitudesEnum.Gb.toLowerCase()) bytes = +size * 1024 * 1024 * 1024;
    if (magnitude.toLowerCase() == sizeMagnitudesEnum.Mb.toLowerCase()) bytes = +size * 1024 * 1024;
    if (magnitude.toLowerCase() == sizeMagnitudesEnum.Kb.toLowerCase()) bytes = +size * 1024;
  }

  return `${prop} ${sign} ${Math.trunc(bytes)}`;
};

export const filterByWords = (obj: SmartSearchModel): string => {
  return obj.words.map((word) => word.value).join(' ');
};

export function createTemplate(tag: string, getItems: (string) => Observable<any[]> = null): Template<any> {
  if (getItems == null) {
    getItems = () => of([]);
  }

  return {
    tag,
    items: (text$: Observable<string>) => {
      return text$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((text) => getItems(text))
      );
    },
    itemFormatter: (value: string) => (typeof value === 'string' && value.split(/\s/).length > 1 ? `{${value.trim()}}` : value)
  };
}

export function firstOrDefault(arr: string[]): string | null {
  return arr?.length > 0 ? arr[0] : null;
}

export function addTypeResetToInputSearch(elRef: ElementRef<HTMLElement>): (events: string) => void {
  let flag = false;

  return function (event: string): void {
    if (event) {
      if (flag) return;

      queueMicrotask(() => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        const button = elRef.nativeElement.querySelector('.ctrl-clear') as HTMLElement;

        button && button.setAttribute('type', 'reset');
        flag = true;
      });
    } else {
      flag = false;
    }
  };
}

export function smartSearchModelFactory(key: string, data: string | string[]): SmartSearchModel {
  if (!data) new Error('Something wrong. There is no data to convert to search model. Look smartSearchModelFactory function');

  if (isArray(data)) {
    return {
      [key]: data.map((v) => ({ value: v }))
    };
  } else {
    return {
      [key]: [{ value: data }]
    };
  }
}
