/* Environment */
export const DNS = 'https://0e38b8e12b814f4ab27a249075c5f36b@weblog.mspbackups.com/3';
export const STANDALONE_MODE = 'https://scloud.resale-stage.mspbackups.com';
export const MBS_MODE = 'https://apps-stage.mspbackups.com';
/* Environment end*/

export const MIN_SCREEN_HEIGHT = 650;
export const SINGLE_QUOTES_REGEX = /^[']|[']*$/;
export const FIRST_SYMBOL_$ = /^[$]/;
export const TEXT_OVERFLOW = 'text-overflow';
export const TABLE_MIN_HEIGHT = '253px';
export const DOUBLE_SLASH_REGEX = /([^:]\/)\/+/g;
export const PHONE_REGEX =
  '^(\\+\\s?)?((?<!\\+.*)\\(\\+?\\d+([\\s\\-\\\\.]?\\d+)?\\)|\\d+)([\\s\\-\\\\.]?(\\(\\d+([\\s\\-\\\\.]?\\d+)?\\)|\\d+))*(\\s?(x|ext\\.?)\\s?\\d+)?$'; // quotes need for safari don't broken
export const PHONE_REGEX_SAFARI =
  /^(\+\s?)?((?!\+.*)\(\+?\d+([\s\-\\.]?\d+)?\)|\d+)([\s\-\\.]?(\(\d+([\s\-\\.]?\d+)?\)|\d+))*(\s?(x|ext\.?)\s?\d+)?$/;
export const NO_LICENSES_AVAILABLE_ERROR_DETAIL = 'NoLicensesAvailable';
export const SECRET_KEY_MASK = '********************';
export const APPEND_BUTTON_PASSWORD_STATE = {
  visible: {
    icon: 'fa fa-eye',
    type: 'text'
  },
  hidden: {
    icon: 'fa fa-eye-slash',
    type: 'password'
  }
};

export const STANDALONE_ICON = {
  logo: `assets/standalone/logo.jpg`,
  favicon: `assets/standalone/favicon.jpg`
};
