import { PaginationOptions } from 'mbs-ui-kit';

export function getDefaultPaginationOptions(): PaginationOptions {
  return {
    page: 1,
    dataSize: 0,
    pageSize: 50,
    maxSize: 3,
    rotate: true,
    pageSizeList: [25, 50, 75, 100, 200]
  };
}
