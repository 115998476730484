import { EventEmitter, Inject, Injectable } from '@angular/core';
import { WINDOW } from '@app/common';
import { AbstractStorage } from '@app/common/services/storage/abstract-storage';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService extends AbstractStorage {
  #change = new EventEmitter<StorageEvent>();

  get change(): Observable<StorageEvent> {
    return this.#change.asObservable();
  }

  constructor(@Inject(WINDOW) protected window: Window) {
    super(window);
  }

  listenStorageChange(): void {
    this.window.addEventListener('storage', (event) => {
      if (event.storageArea === localStorage) {
        this.#change.emit(event);
      }
    });
  }

  set<T>(key: string, data: T): void {
    this.window.localStorage.setItem(this.prefix + key, JSON.stringify(data));
  }

  get<T>(key: string): T {
    try {
      return JSON.parse(this.window.localStorage.getItem(this.prefix + key)) as T;
    } catch (e) {
      throw new Error(`${String(e.name)}: ${String(e.message)}`);
    }
  }

  remove(key: string): void {
    this.window.localStorage.removeItem(this.prefix + key);
  }

  removeAllListeners(): void {
    this.window.removeAllListeners('storage');
  }
}
