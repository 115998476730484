import { isString } from 'lodash';

const k = 1024;
const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] as const;
export type Size = typeof sizes[number];
export function bytesFrom(type: Size): number {
  return Math.pow(k, sizes.indexOf(type));
}

export function formatBytes(bytes: number, decimals = 2): string {
  if (bytes === 0) return `0 ${sizes[0]}`;
  else if (bytes === undefined || isNaN(bytes) || isString(bytes)) {
    console.error(`[format size pipe] Can't format "${String(bytes)}" to bytes. Invalid data type.`);
    return 'NaN';
  }

  const dm = decimals < 0 ? 0 : decimals;

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}
