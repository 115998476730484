import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormSectionComponent } from './form-section.component';

@NgModule({
  imports: [CommonModule],
  declarations: [FormSectionComponent],
  exports: [FormSectionComponent]
})
export class FormSectionModule {
  constructor() {}
}
