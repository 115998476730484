import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { IKeyValue } from 'linq-collections';

import { isNil } from 'lodash';
import { ODataConfiguration } from './angularODataConfiguration';
import { ODataExecReturnType } from './angularODataEnums';
import { ODataOperation } from './angularODataOperation';
import { ODataPagedResult } from './angularODataPagedResult';
import { IODataResponseModel, RequestOptions } from './angularODataResponseModel';

export class ODataQuery<T> extends ODataOperation<T> {
  readonly #entitiesUri: string;
  #filter: string | undefined;
  #top: number | undefined;
  #skip: number | undefined;
  #search: string | undefined;
  #orderBy: string[] = [];
  #apply: string[] = [];
  #maxPerPage: number | undefined;
  #customQueryOptions: IKeyValue<string, any>[] = [];

  constructor(typeName: string, config: ODataConfiguration, http: HttpClient) {
    super(typeName, config, http);

    this.#entitiesUri = config.getEntitiesUri(this.typeName);
  }

  public Filter(filter: string): ODataQuery<T> {
    if (filter) {
      this.#filter = filter;
    }

    return this;
  }

  public Search(search: string): ODataQuery<T> {
    if (search) {
      this.#search = search;
    }

    return this;
  }

  public Top(top: number): ODataQuery<T> {
    if (top > 0) {
      this.#top = top;
    }

    return this;
  }

  public Skip(skip: number): ODataQuery<T> {
    if (skip > 0) {
      this.#skip = skip;
    }

    return this;
  }

  public OrderBy(orderBy: string | string[]): ODataQuery<T> {
    if (orderBy) {
      this.#orderBy = this.toStringArray(orderBy);
    }

    return this;
  }

  public MaxPerPage(maxPerPage: number): ODataQuery<T> {
    if (maxPerPage > 0) {
      this.#maxPerPage = maxPerPage;
    }

    return this;
  }

  public Apply(apply: string | string[]): ODataQuery<T> {
    if (apply) {
      this.#apply = this.toStringArray(apply);
    }

    return this;
  }

  public CustomQueryOptions(customOptions: IKeyValue<string, any> | IKeyValue<string, any>[]): ODataQuery<T> {
    if (customOptions) {
      this.#customQueryOptions = Array.isArray(customOptions) ? customOptions : [customOptions];
    }

    return this;
  }

  public GetUrl(returnType?: ODataExecReturnType): string {
    let url: string = this.#entitiesUri;
    if (returnType === ODataExecReturnType.Count) {
      url = `${url}/${this.config.keys.count}`;
    }

    const params: HttpParams = this.getQueryParams(returnType === ODataExecReturnType.PagedResult);
    if (params.keys().length > 0) {
      return `${url}?${params}`;
    }

    return url;
  }

  public Exec(): Observable<T[]>;
  public Exec(returnType: ODataExecReturnType.Count): Observable<number>;
  public Exec(returnType: ODataExecReturnType.PagedResult): Observable<ODataPagedResult<T>>;
  public Exec(returnType?: ODataExecReturnType): Observable<T[] | ODataPagedResult<T> | number> {
    const requestOptions: RequestOptions = this.getQueryRequestOptions(returnType === ODataExecReturnType.PagedResult);

    switch (returnType) {
      case ODataExecReturnType.Count:
        return this.execGetCount(requestOptions);

      case ODataExecReturnType.PagedResult:
        return this.execGetArrayDataWithCount(this.#entitiesUri, requestOptions);

      default:
        return this.execGetArrayData(requestOptions);
    }
  }

  public ExecWithCount(): Observable<ODataPagedResult<T>> {
    return this.Exec(ODataExecReturnType.PagedResult);
  }

  public NextPage(pagedResult: ODataPagedResult<T>): Observable<ODataPagedResult<T>> {
    const requestOptions: RequestOptions = this.getQueryRequestOptions(false);

    return this.execGetArrayDataWithCount(<string>pagedResult.nextLink, requestOptions);
  }

  private execGetCount(requestOptions: RequestOptions): Observable<number> {
    const countUrl = `${this.#entitiesUri}/${this.config.keys.count}`;

    return this.http.get<number>(countUrl, requestOptions).pipe(
      map((res) => this.extractDataAsNumber(res, this.config)),
      catchError((err: HttpErrorResponse, caught: Observable<number>) => this.execGetCatchError(err, caught))
    );
  }

  private execGetArrayDataWithCount(url: string, requestOptions: RequestOptions): Observable<ODataPagedResult<T>> {
    return this.http.get<IODataResponseModel<T>>(url, requestOptions).pipe(
      map((res) => this.extractArrayDataWithCount(res, this.config)),
      catchError((err: HttpErrorResponse, caught: Observable<ODataPagedResult<T>>) => this.execGetCatchError(err, caught))
    );
  }

  private execGetArrayData(requestOptions: RequestOptions): Observable<T[]> {
    return this.http.get<IODataResponseModel<T>>(this.#entitiesUri, requestOptions).pipe(
      map((res) => this.extractArrayData(res, this.config)),
      catchError((err: HttpErrorResponse, caught: Observable<Array<T>>) => this.execGetCatchError(err, caught))
    );
  }

  private execGetCatchError(
    err: HttpErrorResponse,
    caught: Observable<T[]> | Observable<number> | Observable<ODataPagedResult<T>>
  ): Observable<never> {
    if (this.config.handleError) {
      this.config.handleError(err, caught);
    }

    return throwError(() => err);
  }

  private getQueryRequestOptions(odata4: boolean): RequestOptions {
    const options = Object.assign({}, this.config.defaultRequestOptions);

    options.params = this.getQueryParams(odata4);

    if (this.#maxPerPage && this.#maxPerPage > 0) {
      if (!options.headers) {
        options.headers = new HttpHeaders();
      }

      options.headers = options.headers.set('Prefer', `${this.config.keys.maxPerPage}=${this.#maxPerPage}`);
    }

    return options;
  }

  private getQueryParams(odata4: boolean): HttpParams {
    let params = super.getParams();

    if (this.#filter) {
      params = params.append(this.config.keys.filter, this.#filter);
    }

    if (this.#search) {
      params = params.append(this.config.keys.search, this.#search);
    }

    if (this.#top && this.#top > 0) {
      params = params.append(this.config.keys.top, this.#top.toString());
    }

    if (this.#skip && this.#skip > 0) {
      params = params.append(this.config.keys.skip, this.#skip.toString());
    }

    if (this.#orderBy.length > 0) {
      params = params.append(this.config.keys.orderBy, this.toCommaString(this.#orderBy));
    }

    if (this.#apply.length > 0) {
      params = params.append(this.config.keys.apply, this.toCommaString(this.#apply));
    }

    if (this.#customQueryOptions.length > 0) {
      this.#customQueryOptions.forEach(
        (customQueryOption) =>
          (params = params.append(this.checkReservedCustomQueryOptionKey(customQueryOption.key), customQueryOption.value))
      );
    }

    if (odata4) {
      params = params.append('$count', 'true'); // OData v4 only
    }

    return params;
  }

  private extractDataAsNumber(res: HttpResponse<number>, config: ODataConfiguration): number {
    return config.extractQueryResultDataAsNumber(res);
  }

  private extractArrayData(res: HttpResponse<IODataResponseModel<T>>, config: ODataConfiguration): T[] {
    return config.extractQueryResultData(res);
  }

  private extractArrayDataWithCount(res: HttpResponse<IODataResponseModel<T>>, config: ODataConfiguration): ODataPagedResult<T> {
    return config.extractQueryResultDataWithCount(res);
  }

  private checkReservedCustomQueryOptionKey(key: string): string {
    if (isNil(key)) {
      throw new Error('Custom query options MUST NOT be null or undefined.');
    }

    if (key.indexOf('$') === 0 || key.indexOf('@') === 0) {
      throw new Error('Custom query options MUST NOT begin with a $ or @ character.');
    }

    return key;
  }
}
