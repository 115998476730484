<div class="mbs-card"
     [ngClass]="cardSize"
     [class.-no-border]="!border"
     [class.border-danger]="border && invalid"
     [class.inner-bordered]="innerBordered"
     [class.overflow-y-auto]="!!staticHeight">

  <div class="mbs-card_content">
    <ng-content select="mbs-card-header"></ng-content>
    <div class="mbs-card_body">

      <div class="mbs-card_groups"
           *ngIf="cardGroups">
        <ng-content select="mbs-card-group"></ng-content>
      </div>
      <ng-content></ng-content>
    </div>
  </div>

  <ng-content select="mbs-card-footer"></ng-content>
</div>
