export enum TaskManagerTagsEnum {
  userName = 'userName',
  serviceType = 'serviceType',
  task = 'task',
  status = 'status'
}

export const TaskManagerTagsMap: Map<TaskManagerTagsEnum, { tag: string; prop: string }> = new Map([
  [
    TaskManagerTagsEnum.userName,
    {
      tag: 'User Name',
      prop: 'UserName'
    }
  ],
  [
    TaskManagerTagsEnum.serviceType,
    {
      tag: 'Service Type',
      prop: 'ServiceType'
    }
  ],
  [
    TaskManagerTagsEnum.task,
    {
      tag: 'Task',
      prop: 'OperationType'
    }
  ],
  [
    TaskManagerTagsEnum.status,
    {
      tag: 'Status',
      prop: 'State'
    }
  ]
]);
