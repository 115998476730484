import { Injectable } from '@angular/core';
import { SINGLE_QUOTES_REGEX } from '@app/common';
import { ContactODataItem } from '@app/common/models';
import { SortOrderByPipe } from '@app/common/pipes';
import { ContactsService } from '@app/common/services';
import { getUniqueListByProp } from '@app/common/services/smart-search';
import { ContactsServiceTag, ContactsServiceTagsMap } from '@app/common/services/smart-search/contacts/smart-search-tags-contacts.model';
import SmartSearchTemplatesBase from '@app/common/services/smart-search/smart-search-template-base';
import { FilterOptions, getFilterByContains } from '@app/common/utils/functions/search';
import { isNil } from 'lodash';
import { ModelTemplate, SmartSearchModelField, SmartSearchState } from 'mbs-ui-kit';
import { asapScheduler, Observable, scheduled } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SmartSearchModelTemplateContactsService extends SmartSearchTemplatesBase {
  readonly #firstName = ContactsServiceTagsMap.get(ContactsServiceTag.FirstName);
  readonly #lastName = ContactsServiceTagsMap.get(ContactsServiceTag.LastName);
  readonly #primaryEmail = ContactsServiceTagsMap.get(ContactsServiceTag.PrimaryEmail);
  readonly #phoneNumber = ContactsServiceTagsMap.get(ContactsServiceTag.PhoneNumber);

  constructor(private contactsService: ContactsService, private sortOrderByPipe: SortOrderByPipe<ContactODataItem>) {
    super();
  }

  public readonly FirstNameTag: ModelTemplate<ContactODataItem> = {
    tag: this.#firstName.tag,
    items: (state: SmartSearchState): Observable<ContactODataItem[]> => {
      const term = state.leftCaretValue?.replace(SINGLE_QUOTES_REGEX, '');
      const options: FilterOptions = {
        model: [{ value: term }] as SmartSearchModelField[],
        prop: this.#firstName.prop
      };

      this.contactsService.filter = term
        ? this.contactsService.convertFiltersToStr(this.#firstName.prop, getFilterByContains(options))
        : '';

      return scheduled(
        this.contactsService.getContactsItemsByGroupId(this.contactsService.groupId).pipe(
          map((res) => res.data),
          map((contacts: ContactODataItem[]) => {
            const filtered = contacts.filter((contact) => !isNil(contact.FirstName) && contact.FirstName.includes(term));
            const uniqueList = getUniqueListByProp(filtered, this.#firstName.prop).slice(0, 10);

            return this.sortOrderByPipe.transform(uniqueList, this.#firstName.prop);
          })
        ),
        asapScheduler
      );
    },
    itemFormatter: ({ FirstName }): string => FirstName,
    addGroupBrackets: true
  };

  public readonly LastNameTag: ModelTemplate<ContactODataItem> = {
    tag: this.#lastName.tag,
    items: (state: SmartSearchState): Observable<ContactODataItem[]> => {
      const term = state.leftCaretValue?.replace(SINGLE_QUOTES_REGEX, '');
      const options: FilterOptions = {
        model: [{ value: term }] as SmartSearchModelField[],
        prop: this.#lastName.prop
      };

      this.contactsService.filter = term ? this.contactsService.convertFiltersToStr(this.#lastName.prop, getFilterByContains(options)) : '';

      return scheduled(
        this.contactsService.getContactsItemsByGroupId(this.contactsService.groupId).pipe(
          map((res) => res.data),
          map((contacts: ContactODataItem[]) => {
            const filtered = contacts.filter((contact) => !isNil(contact.LastName) && contact.LastName.includes(term));
            const uniqueList = getUniqueListByProp(filtered, this.#lastName.prop).slice(0, 10);

            return this.sortOrderByPipe.transform(uniqueList, this.#lastName.prop);
          })
        ),
        asapScheduler
      );
    },
    itemFormatter: ({ LastName }): string => LastName,
    addGroupBrackets: true
  };

  public readonly PrimaryEmailTag: ModelTemplate<ContactODataItem> = {
    tag: this.#primaryEmail.tag,
    items: (state: SmartSearchState): Observable<ContactODataItem[]> => {
      const term = state.leftCaretValue?.replace(SINGLE_QUOTES_REGEX, '');
      const options: FilterOptions = {
        model: [{ value: term }] as SmartSearchModelField[],
        prop: this.#primaryEmail.prop
      };

      this.contactsService.filter = term
        ? this.contactsService.convertFiltersToStr(this.#primaryEmail.prop, getFilterByContains(options))
        : '';

      return scheduled(
        this.contactsService.getContactsItemsByGroupId(this.contactsService.groupId).pipe(
          map((res) => res.data),
          map((contacts: ContactODataItem[]) => {
            const filtered = contacts.filter((contact) => !isNil(contact.PrimaryEmail) && contact.PrimaryEmail.includes(term));
            const uniqueList = getUniqueListByProp(filtered, this.#primaryEmail.prop).slice(0, 10);

            return this.sortOrderByPipe.transform(uniqueList, this.#primaryEmail.prop);
          })
        ),
        asapScheduler
      );
    },
    itemFormatter: ({ PrimaryEmail }): string => PrimaryEmail,
    addGroupBrackets: true
  };

  public readonly PhoneNumberTag: ModelTemplate<ContactODataItem> = {
    tag: this.#phoneNumber.tag,
    items: (state: SmartSearchState): Observable<ContactODataItem[]> => {
      const term = state.leftCaretValue?.replace(SINGLE_QUOTES_REGEX, '');
      const options: FilterOptions = {
        model: [{ value: term }] as SmartSearchModelField[],
        prop: this.#phoneNumber.prop
      };

      this.contactsService.filter = term
        ? this.contactsService.convertFiltersToStr(this.#phoneNumber.prop, getFilterByContains(options))
        : '';

      return scheduled(
        this.contactsService.getContactsItemsByGroupId(this.contactsService.groupId).pipe(
          map((res) => res.data),
          map((contacts: ContactODataItem[]) => {
            const filtered = contacts.filter((contact) => !isNil(contact.PhoneNumber) && contact.PhoneNumber.includes(term));
            const uniqueList = getUniqueListByProp(filtered, this.#phoneNumber.prop).slice(0, 10);

            return this.sortOrderByPipe.transform(uniqueList, this.#phoneNumber.prop);
          })
        ),
        asapScheduler
      );
    },
    itemFormatter: ({ PhoneNumber }): string => PhoneNumber,
    addGroupBrackets: true
  };
}
