<mbs-sidepanel id="my_account"
               side="right"
               [isCreate]="false"
               [loadingData]="loadingData"
               headerClass="border-bottom-0"
               innerContentType="tabs"
               [formGroup]="formGroup">

  <div header-title
       class="mbs-sidepanel_title">

    <div class="mbs-sidepanel_title-ico">
      <i class="fa fa-user-o text-success"
         [style]="{ fontSize: '25px', lineHeight: 1 }"></i>
    </div>

    <div class="overflow-hidden"
         *ngIf="contactInfo$ | async as contactInfo">

      <div class="mbs-sidepanel_title-name">
        <mbs-text-ellipsis [tooltip]="contactInfo.Name"
                           *ngIf="contactInfo.Name"
                           placement="top-left auto"
                           tooltipClass="tooltip-lg">
          {{ contactInfo.Name }}
        </mbs-text-ellipsis>
      </div>
    </div>

  </div>

  <div loading-data-title
       class="mbs-sidepanel_title">

    <div class="mbs-sidepanel_title-ico">
      <i class="fa fa-user-o"
         [ngStyle]="{ fontSize: '25px', lineHeight: 1 }"></i>
    </div>

    <div class="mbs-sidepanel_title-name">
      {{ moduleAccount + ':sidepanel.loadingDataTitle' | i18next: { format: 'title' } }}
    </div>

  </div>

  <div body
       class="mbs-sidepanel_tabset">

    <mbs-loader *ngIf="(loading$ | async) && !loadingData"></mbs-loader>

    <ul mbsTabset
        mbsTabSetValidated
        (beforeUpdate)="handleBeforeUpdateTabset($event)"
        [loadOnAppearance]="true"
        #activeContent="mbsTabset">

      <!-- General -->
      <li [mbsTabsetItem]="MyAccountTab.general">

        <button mbsTabsetLink
                class="px-4">
          {{ moduleAccount + ':sidepanel.tab.general' | i18next: { format: 'title' } }}
        </button>

        <ng-template mbsTabsetContent>
          <div class="mbs-sidepanel_content-scrolling tab-pane">
            <app-my-account-general-tab class="mbs-form_content"
                                        *ngIf="!(loading$ | async)"
                                        [formControlName]="MyAccountTab.general"></app-my-account-general-tab>
          </div>
        </ng-template>

      </li>

      <!-- Alternate Account -->
      <li [mbsTabsetItem]="MyAccountTab.alternateAccount">

        <button mbsTabsetLink
                class="px-4">
          {{ moduleAccount + ':sidepanel.tab.alternateAccount' | i18next: { format: 'title' } }}
        </button>

        <ng-template mbsTabsetContent>
          <div class="mbs-sidepanel_content-scrolling tab-pane">
            <app-my-account-alternate-account-tab class="mbs-form_content"
                                                  *ngIf="!(loading$ | async)"
                                                  [twoStepEnabled]="twoStepEnabled"
                                                  [alternateEmailVerified]="alternateEmailVerified"
                                                  [initialAlternateEmail]="initialAlternateEmail"
                                                  (changeAlternateEmail)="updateAlternateAccountTab()"
                                                  (deleteAlternateEmail)="updateAlternateAccountTab()"
                                                  [formControlName]="MyAccountTab.alternateAccount"></app-my-account-alternate-account-tab>
          </div>
        </ng-template>

      </li>

      <!-- Backup Destination -->
      <li [mbsTabsetItem]="MyAccountTab.backupDestination"
          *ngIf="(isStandaloneMode$ | async) && (isSuperAdmin$ | async)">

        <button mbsTabsetLink
                class="px-4">
          {{ moduleAccount + ':sidepanel.tab.backupDestination' | i18next: { format: 'title' } }}
        </button>

        <ng-template mbsTabsetContent>
          <div class="mbs-sidepanel_content-scrolling tab-pane">
            <app-my-account-backup-destination-tab class="mbs-form_content"
                                                   *ngIf="!(loading$ | async)"
                                                   [readonly]="addedStorage"
                                                   [formControlName]="MyAccountTab.backupDestination"></app-my-account-backup-destination-tab>
          </div>
        </ng-template>

      </li>

    </ul>

    <div [mbsTabsetOutlet]="activeContent"></div>

  </div>

  <ng-template #footerTemplate>

    <div class="mbs-sidepanel_footer-col">
      <mbs-button *ngIf="isAvailableDeleteAccount$ | async"
                  type="outline-danger"
                  [disabled]="(saveLoading$ | async) || (loading$ | async)"
                  [loading]="deleteLoading$ | async"
                  (click)="handleDelete().subscribe()">
        {{ moduleAccount + ':sidepanel.button.deleteAccount' | i18next: { format: 'title' } }}
      </mbs-button>
    </div>

    <div class="d-flex">

      <div class="mbs-sidepanel_footer-col">
        <mbs-button type="primary"
                    [disabled]="(deleteLoading$ | async) || (loading$ | async)"
                    [loading]="saveLoading$ | async"
                    (click)="handleSave().subscribe()">
          {{ moduleAccount + ':sidepanel.button.save' | i18next: { format: 'title' } }}
        </mbs-button>
      </div>

      <div class="mbs-sidepanel_footer-col">
        <mbs-button type="secondary"
                    [disabled]="loading$ | async"
                    (click)="genericPanel.panelClosed.emit()">
          {{ moduleAccount + ':sidepanel.button.cancel' | i18next: { format: 'title' } }}
        </mbs-button>
      </div>

    </div>

  </ng-template>

</mbs-sidepanel>

<ng-template #deleteAccountConfirmTemplate>

  <ng-container *ngIf="isImmutability; else defaultStateTempRef">

    <p class="mb-3">
      {{ moduleAccount + ':modal.body.confirmDeleteAccountDeleteTheAccount' | i18next: { format: 'capitalize' } }}
      <span class="font-weight-semibold">{{ (user$ | async).Email }}</span>.
      {{ moduleAccount + ':modal.body.confirmDeleteAccountDeleteTheAccountDomain' | i18next: { format: 'capitalize' } }}
      <span class="font-weight-semibold">{{ (user$ | async).DomainName }}</span>
    </p>

    <p class="mb-3">
      {{ moduleAccount + ':modal.body.confirmDeleteAccountAllDataRelatedToImmutability' | i18next: { format: 'capitalize' } }}
    </p>

    <mbs-alert [type]="MbsPopupType.warning"
               [icon]="true"
               class="mb-0">
      {{ moduleAccount + ':modal.body.confirmDeleteAccountAllYourDomainImmutability' | i18next: { format: 'capitalize' } }}
    </mbs-alert>

  </ng-container>

  <ng-template #defaultStateTempRef>

    <p class="mb-3">
      {{ moduleAccount + ':modal.body.confirmDeleteAccountDeleteTheAccount' | i18next: { format: 'capitalize' } }}
      <span class="font-weight-semibold">{{ (user$ | async).Email }}</span>.
      {{ moduleAccount + ':modal.body.confirmDeleteAccountDeleteTheAccountDomain' | i18next: { format: 'capitalize' } }}
      <span class="font-weight-semibold">{{ (user$ | async).DomainName }}</span>
    </p>

    <p class="mb-3">
      {{ moduleAccount + ':modal.body.confirmDeleteAccountAllDataRelatedTo' | i18next: { format: 'capitalize' } }}
    </p>

    <mbs-alert [type]="MbsPopupType.warning"
               [icon]="true"
               class="mb-0">
      {{ moduleAccount + ':modal.body.confirmDeleteAccountAllYourDomain' | i18next: { format: 'capitalize' } }}
    </mbs-alert>

  </ng-template>

</ng-template>

<ng-template #backupDestinationConfirmTemplate>
  <mbs-alert [type]="MbsPopupType.warning"
             [icon]="true"
             class="mb-0">
    {{ moduleAccount + ':modal.body.specifiedBackupDestination' | i18next: { format: 'capitalize' } }}?
  </mbs-alert>
</ng-template>
