import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { ButtonModule } from '../button/button.module';
import { SmartSearchConfig } from './smart-search-config';
import { SmartSearchKeywordDirective } from './smart-search-keyword.directive';
import { SmartSearchComponent } from './smart-search.component';

@NgModule({
  declarations: [SmartSearchComponent, SmartSearchKeywordDirective],
  imports: [CommonModule, NgbDropdownModule, NgbTypeaheadModule, FormsModule, ButtonModule],
  exports: [SmartSearchComponent, SmartSearchKeywordDirective],
  providers: [SmartSearchConfig]
})
export class SmartSearchModule {}
