import { Pipe, PipeTransform } from '@angular/core';

const nonResizableClassesWithPanel = 'col-12 col-md-4 col-lg-6 order-sm-3 order-md-2';
const nonResizableClassesWithoutPanel = 'col-12 col-sm-6 col-md-8 col-lg-9';

@Pipe({
  name: 'mainColumnLayout',
  pure: true
})
export class MainColumnLayoutPipe implements PipeTransform {
  transform(resizableMode: boolean, showInfoPanel: boolean): string {
    const classes: string[] = [];

    if (showInfoPanel) {
      classes.push('-showInfoPanel');
    }

    if (resizableMode) {
      classes.push('col');
    } else {
      classes.push(showInfoPanel ? nonResizableClassesWithPanel : nonResizableClassesWithoutPanel);
    }

    return classes.join(' ');
  }
}
