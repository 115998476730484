export enum TaskAction {
  Cancel = 'cancel',
  CancelAll = 'cancelAll',
  CancelSelected = 'cancelSelected',
  Delete = 'delete',
  Pause = 'pause',
  Restart = 'restart',
  Resume = 'resume'
}
export enum TaskStatus {
  Cancelled = 'Cancelled', // temporary state; records in this state persist in db only while workers are busy with the task; it really means "Cancellation in progress"
  Failed = 'Failed', // error occurred
  Finished = 'Finished', // successfully completed (to be kept only in details: the records with such state are immediately deleted from header)
  LimitExceeded = 'LimitExceeded', // trial period limitations (is set in header only when worker returns the appropriate flag on items processing)
  Paused = 'Paused', // paused (is set automatically when too many errors occurred on processing the task items/ can be also set manually by <Pause> command)
  Pending = 'Pending', // added to db queue
  Running = 'Running' // passed to worker (for header may be partially on tasks with several items)
}

export enum TaskActionState {
  Completed,
  InProgress,
  Failed
}

export enum TaskManagerType {
  Artifact = 'Artifact',
  Task = 'Task',
  Unknown = 'Unknown'
}
