import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { StatusModule } from '../../status/status.module';
import { SwitcherComponent, SwitcherDescription } from './switcher.component';

@NgModule({
  imports: [CommonModule, FormsModule, StatusModule],
  declarations: [SwitcherComponent, SwitcherDescription],
  exports: [SwitcherComponent, SwitcherDescription]
})
export class SwitcherModule {
  constructor() {
    // empty
  }
}
