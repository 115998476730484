export class MailAttachment {
  name: string;
  downloadLink: string;
  query?: string;

  constructor(name: string, downloadLink: string, query?: string) {
    this.name = name;
    this.downloadLink = downloadLink;

    if (query) {
      this.query = query;
    }
  }
}
