import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { CheckboxModule } from '../form/checkbox/checkbox.module';
import { TreeModule } from '../tree/tree.module';
import {
  AccordionComponent,
  AccordionPanelComponent,
  AccordionRowTemplate,
  PanelIconHeader,
  TopControlsAppendDescription,
  TopControlsPrependDescription
} from './accordion.component';

@NgModule({
  declarations: [
    AccordionComponent,
    AccordionPanelComponent,
    PanelIconHeader,
    TopControlsAppendDescription,
    TopControlsPrependDescription,
    AccordionRowTemplate
  ],
  imports: [
    CommonModule,
    NgbAccordionModule,
    CheckboxModule,
    TreeModule,
    NgScrollbarModule
  ],
  exports: [
    AccordionComponent,
    AccordionPanelComponent,
    TopControlsAppendDescription,
    TopControlsPrependDescription,
    AccordionRowTemplate,
    PanelIconHeader
  ]
})
export class AccordionModule {}
