import { Component, Input, SimpleChanges, TemplateRef } from '@angular/core';
import { MbsLabelSize } from '../../../utils';
import { convertCssClassesToString } from '../../../utils/helpers/convertCssClassesToString';
import { InputLabelContext, MbsNgClass } from './input-label.model';
import { isNil } from 'lodash';

@Component({
  selector: 'mbs-input-label,[mbs-input-label]',
  templateUrl: './input-label.component.html'
})
export class InputLabelComponent {
  /**
   * HTML Id
   */
  @Input() public id: string;

  @Input() labelContext: InputLabelContext;

  public get context(): InputLabelContext {
    return (
      this.labelContext || {
        id: this.id,
        label: this.label,
        info: this.info,
        infoPlacement: this.infoPlacement,
        infoTriggers: this.infoTriggers,
        infoContainer: this.infoContainer,
        required: this.required,
        disabled: this.disabled,
        type: this.type,
        labelClasses: this.labelClasses,
        isString: this.isString.bind(this) as boolean
      }
    );
  }

  /**
   * Input label.
   * if `string` then use default template
   * `TemplateRef` will insert
   */
  @Input() public label: string | TemplateRef<any>;
  /**
   * Label info. Append info icon as tooltip.
   */
  @Input() public info: string;

  /**
   * Label tooltip position
   */
  @Input() public infoPlacement = 'auto';

  /**
   * Label position
   */
  @Input() public labelPosition: 'left' | 'right' | 'top' | 'bottom';

  /**
   * Centered Label position. only for checkboxes and radio with "top" and "bottom" `position` property
   */
  @Input() public center: boolean;

  /**
   * Specifies events that should trigger the tooltip
   */
  @Input() public infoTriggers = 'hover focus';

  /**
   * A selector specifying the element the tooltip should be appended to.
   * Currently only supports "body".
   */
  @Input() public infoContainer: string;

  /**
   * Append red `*` after label for `string` type
   */
  @Input() public required = false;

  /**
   * Text size for Default label template;
   */
  @Input() public labelSize: MbsLabelSize;

  /**
   * Add font-weight-bold;
   */
  @Input() public boldLabel: boolean;

  /*
   * Disable state
   */
  @Input()
  public set disabled(value: boolean) {
    this._disabled = value;
  }
  public get disabled() {
    return this._disabled;
  }
  private _disabled = false;
  get disabledState(): boolean {
    return !isNil(this._disabled) && this._disabled !== false;
  }

  @Input() public type: 'checkbox' | 'radio' | 'form' = 'form';
  @Input() public labelClasses: MbsNgClass;
  public labelClassesInner: string;
  private labelClassesDefault = '';

  /**
   * CSS Classes for label content wrapper
   */
  @Input() public labelContentClasses: MbsNgClass;
  private labelContentClassesDefault = '';
  public labelContentClassesInner = '';

  ngOnInit(): void {
    if (!this.labelPosition) {
      this.labelPosition = this.type === 'form' ? 'top' : 'right';
    }
    this.labelClassesDefault = `mbs-${this.type}_label`;
    this.labelContentClassesDefault = `mbs-${this.type}_label-content`;
    this.labelClassesInner = this.getCurrentClasses(this.labelClasses, this.labelClassesDefault);
    if (this.disabledState) {
      this.labelClassesInner += ' -disabled';
    }
    if (this.center && this.type !== 'form' && (this.labelPosition === 'top' || this.labelPosition === 'bottom')) {
      this.labelClassesInner += ' -center';
    }
    if (this.type !== 'form' && !this.label) {
      this.labelClassesInner += ' -no-text';
    }
    if (this.labelSize) {
      this.labelClassesInner += ` -${this.labelSize}`;
    }
    this.labelClassesInner += ' -' + this.labelPosition;
    this.labelContentClassesInner =
      this.getCurrentClasses(this.labelContentClasses, this.labelContentClassesDefault) + ' ' + this.getSizeClass();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.labelContentClasses || changes.labelSize) {
      this.labelContentClassesInner =
        this.getCurrentClasses(this.labelContentClasses, this.labelContentClassesDefault) + ' ' + this.getSizeClass();
    }
    if (changes.labelClasses || changes.disabled || changes.label || changes.labelSize) {
      this.labelClassesInner = this.getCurrentClasses(this.labelClasses, this.labelClassesDefault);
      if (this.disabledState) {
        this.labelClassesInner += ' -disabled';
      }
      if (this.type !== 'form' && changes.label && !changes.label.currentValue) {
        this.labelClassesInner += ' -no-text';
      }
      if (this.labelSize) {
        this.labelClassesInner += ` -${this.labelSize}`;
      }
    }
  }

  public isString(source: any): boolean {
    return isNil(source) || typeof source === 'string';
  }

  getCurrentClasses(classes: MbsNgClass, defaultClass = ''): string {
    return convertCssClassesToString(classes, defaultClass, !isNil(this.label));
  }

  getSizeClass(): string {
    return this.labelSize ? 'text-' + this.labelSize.toString() : '';
  }
}
