import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MyAccountRecoveryPassword } from '@app/common/models';
import { AuthService, MyAccountService } from '@app/common/services';
import { getErrorText } from '@app/common/utils';
import { I18_NAMESPACE_MODULE } from '@app/i18';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { MbsPopupType, ModalComponent, ToastService } from 'mbs-ui-kit';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, map, shareReplay } from 'rxjs/operators';

@UntilDestroy()
@Component({
  templateUrl: './reset-password-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetPasswordModalComponent implements OnInit {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  readonly #toastTitleSuccess = this.i18nPipe.transform('toast.success.title', { format: 'title' });

  public loading: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public formGroup: FormGroup;
  public primaryEmail$: Observable<string>;

  public readonly moduleAccount = I18_NAMESPACE_MODULE.account;
  public readonly MbsPopupType = MbsPopupType;

  get alternateEmailCtrl(): FormControl<string> {
    return <FormControl>this.formGroup.get('alternateEmail');
  }

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private myAccountService: MyAccountService,
    private toastService: ToastService,
    private i18nPipe: I18NextPipe
  ) {
    this.primaryEmail$ = this.authService.getAuthUser().pipe(
      map((user) => user?.Email),
      shareReplay(1)
    );
  }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.formGroup = this.fb.group({
      alternateEmail: ['', [Validators.required, Validators.email]]
    });
  }

  handleSend(): void {
    const payload = {
      Email: this.formGroup.value.alternateEmail
    } as MyAccountRecoveryPassword;

    this.loading.next(true);
    this.myAccountService
      .resetAlternatePassword(payload)
      .pipe(
        finalize(() => this.loading.next(false)),
        untilDestroyed(this)
      )
      .subscribe({
        next: () => {
          this.toastService.success('Request was sent successfully', this.#toastTitleSuccess);
          this.handleClose();
        },
        error: (error: HttpErrorResponse) => {
          if (error.status === 400) {
            this.setEmailErrorState(getErrorText(error));
          }
        }
      });
  }

  private setEmailErrorState(message: string): void {
    this.alternateEmailCtrl.setValue('');
    this.alternateEmailCtrl.setErrors({ alternateEmail: { message } });
  }

  handleClose(): void {
    this.baseModal.close();
  }
}
