import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ButtonModule } from '../button/button.module';
import { CheckboxModule } from '../form/checkbox/checkbox.module';
import { LoaderModule } from '../loader/loader.module';
import { TileModule } from '../tile/tile.module';
import { GridTileDirective } from './directives/grid-tile.directive';
import { TableHeaderNameDirective } from './directives/headerFor.directive';
import { TableCellDirective } from './directives/table-cell.directive';
import { TableControlDirective } from './directives/table-control.directive';
import { TableFilterDirective } from './directives/table-filter.directive';
import { TableNavContentDirective } from './directives/table-nav-content.directive';
import { TableSortArrowDirective } from './directives/table-sort-arrow.directive';
import { TableSortGroupDirective } from './directives/table-sort-group.directive';
import { TableSortDirective } from './directives/table-sort.directive';
import { GridComponent } from './grid/grid.component';
import { TableNavComponent } from './nav/table-nav.component';
import { TableGridComponent } from './table-grid.component';
import { TableComponent } from './table/table.component';
import { FiltersGroupDirective } from './directives/table-filters-group.directive';
import { TextEllipsisModule } from '../text-ellipsis';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    LoaderModule,
    CheckboxModule,
    FormsModule,
    ButtonModule,
    TileModule,
    ScrollingModule,
    NgScrollbarModule,
    InfiniteScrollModule,
    TextEllipsisModule
  ],
  declarations: [
    TableGridComponent,
    TableSortDirective,
    TableSortArrowDirective,
    TableHeaderNameDirective,
    TableComponent,
    GridComponent,
    TableCellDirective,
    TableSortGroupDirective,
    GridTileDirective,
    TableFilterDirective,
    TableNavComponent,
    TableNavContentDirective,
    TableControlDirective,
    FiltersGroupDirective
  ],
  exports: [
    TableGridComponent,
    TableSortDirective,
    TableSortArrowDirective,
    TableHeaderNameDirective,
    TableComponent,
    TableCellDirective,
    TableSortGroupDirective,
    GridTileDirective,
    TableFilterDirective,
    TableNavComponent,
    TableNavContentDirective,
    TableControlDirective,
    FiltersGroupDirective
  ]
})
export class TableGridModule {}
