import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { IMaskModule } from 'angular-imask';
import { ButtonModule } from '../../button/button.module';
import { InputBaseModule } from '../input-base/input-base.module';
import { NumberComponent } from './number.component';

@NgModule({
  declarations: [NumberComponent],
  imports: [CommonModule, FormsModule, NgbTooltipModule, IMaskModule, InputBaseModule, ButtonModule],
  exports: [NumberComponent],
  providers: []
})
export class NumberModule {
  constructor() {
    // empty
  }
}
