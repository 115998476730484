import { Pipe, PipeTransform } from '@angular/core';
import { InvoiceStateByAPI } from '@app/common';

@Pipe({
  name: 'serviceTypeToDisplayName',
  pure: true
})
export class InvoiceStateToDisplayNamePipe implements PipeTransform {
  transform(key: string): string {
    return InvoiceStateByAPI[key] ? (InvoiceStateByAPI[key].displayName as string) : '';
  }
}
