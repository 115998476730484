<div class="mbs-input mbs-timepicker_input">
  <mbs-input-label *ngIf="label && id"
                   [label]="label"
                   [id]="id"
                   [disabled]="disabledState"
                   [info]="info"
                   [infoPlacement]="infoPlacement"
                   [infoTriggers]="infoTriggers"
                   [infoContainer]="infoContainer"
                   [boldLabel]="boldLabel"
                   [required]="hasRequiredValidator && showRequiredMark"
                   [labelClasses]="labelClasses"
                   [labelContentClasses]="labelContentClassesInner"
                   [labelSize]="labelSize">
    <ng-content select="[prependLabel]"
                ngProjectAs="[prependLabel]"></ng-content>
    <ng-content select="[appendLabel]"
                ngProjectAs="[appendLabel]"></ng-content>
  </mbs-input-label>

  <div class="input-group mbs-timepicker_input-group">
    <mbs-input-pre-append *ngIf="prependButtons || prepend"
                          position="prepend"
                          [disabled]="disabledState"
                          [dataTemplate]="prepend"
                          [data]="prependButtons"
                          [size]="size"
                          (keyupEnter)="dropdown.close()"
                          (buttonClick)="buttonClickPrepend.emit($event)">
    </mbs-input-pre-append>

    <div class="input-group_control flex-grow-1 mbs-timepicker_input-group_control"
         [ngClass]="[ size ? 'input-group_control-' + size : '']"
         ngbDropdown
         container="body"
         #mbsTimepicker="ngbDropdown"
         [placement]="placement"
         [dropdownClass]="dropdownClass"
         [autoClose]="'outside'">

      <input class="form-control mbs-timepicker_form-control"
             [name]="name"
             [tabindex]="tabindex"
             [attr.aria-describedby]="id"
             [attr.aria-labelledby]="label"
             [placeholder]="placeholder"
             [ngClass]="bindClasses"
             [id]="id"
             [disabled]="disabledState"
             [value]="value"
             [autocomplete]="autocomplete"
             (blur)="timePickerBlurHandler($event)"
             (focus)="handleFocus($event)"
             (input)="handleChange($event)"
             (change)="handleChange($event)"
             [imask]="imaskOptions"
             [unmask]="unmask"
             [readonly]="readonlyState"
             ngbDropdownToggle
             triggers="manual" />

      <div ngbDropdownMenu
           class="mbs-timepicker_dropdown -arrow">
        <ngb-timepicker [meridian]="meridian"
                        [seconds]="showSeconds"
                        [hourStep]="hourStep"
                        [minuteStep]="minuteStep"
                        [secondStep]="secondStep"
                        [spinners]="spinners"
                        [(ngModel)]="value"
                        class="mbs-timepicker_timepicker"></ngb-timepicker>
      </div>

      <div class="loader-container"
           *ngIf="loading">
        <span class="loader"></span>
      </div>
    </div>

    <mbs-input-pre-append position="append"
                          [disabled]="disabledState"
                          [data]="[{
                            icon:'ico ico-ClockO',
                            type: 'secondary'
                          }]"
                          [size]="size"
                          (keyupEnter)="mbsTimepicker.close()"
                          (buttonClick)="mbsTimepicker.open();">
    </mbs-input-pre-append>

    <mbs-input-pre-append *ngIf="appendButtons || append"
                          [dataTemplate]="append"
                          position="append"
                          [disabled]="disabledState"
                          [size]="size"
                          [data]="appendButtons"
                          (keyupEnter)="dropdown.close()"
                          (buttonClick)="buttonClickAppend.emit($event)">
    </mbs-input-pre-append>
  </div>

  <mbs-input-errors [errors]="errors"
                    *ngIf="isShowErrors"></mbs-input-errors>
</div>
