import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgbTooltipModule,NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { IMaskModule } from 'angular-imask';
import { ButtonModule } from '../../button';
import { InputBaseModule } from '../input-base/input-base.module';
import { InputModule } from '../input/input.module';
import { InputPasswordComponent } from './input-password.component';

@NgModule({
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    NgbTooltipModule,
    IMaskModule,
    InputModule,
    InputBaseModule,
    NgbTypeaheadModule,
    ButtonModule
  ],
  declarations: [InputPasswordComponent],
  exports: [InputPasswordComponent]
})
export class InputPasswordModule {}
