import { Directive, Input, TemplateRef } from '@angular/core';

/**
 * Common table cell by group
 */
@Directive({
  selector: '[mbsTableControl]'
})
export class TableControlDirective {
  @Input('mbsTableControl') public mode: 'append' | 'prepend' = 'append';

  constructor(public template: TemplateRef<any>) {}
}
