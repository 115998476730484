import { Pipe, PipeTransform } from '@angular/core';

const nonResizableClasses = 'col-12 col-sm-6 col-md-4 col-lg-3';

@Pipe({
  name: 'navColumnLayout',
  pure: true
})
export class NavColumnLayoutPipe implements PipeTransform {
  transform(resizableMode: boolean, showInfoPanel: boolean): string {
    const classes: string[] = [];

    if (showInfoPanel) {
      classes.push('-showInfoPanel');
    }

    if (resizableMode) {
      classes.push('col-auto');
    } else {
      classes.push(nonResizableClasses);
    }

    return classes.join(' ');
  }
}
