import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChannelItem, DriveItemDetails, OperationType, ServiceType, TeamFile, TeamItem, TeamOdataPost, TeamPost } from '@app/common/models';
import { ODataPagedResult, ODataService, ODataServiceFactory } from '@app/common/odata';
import { SharedOdataService } from '@app/common/services/shared.odata.service';
import {
  RestoreTeamsItemsPayload,
  RestoreTeamsParameters,
  RestoreTeamsParametersPayload
} from '@app/pages/teams/modals/restore-team-item-modal/restore-team-item-modal.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TeamsOdataService extends SharedOdataService<TeamItem> {
  private odataMethods: ODataService<any>;

  private driveItemsService: ODataService<DriveItemDetails>;

  constructor(odataFactory: ODataServiceFactory, private http: HttpClient) {
    super(odataFactory, 'Teams');

    this.driveItemsService = odataFactory.CreateService('DriveItems');
    this.odataMethods = odataFactory.CreateService('');
    this.orderBy = 'Name asc';
  }

  getTeams(): Observable<ODataPagedResult<TeamItem>> {
    return this.odataFactory
      .CreateService<TeamItem>('Teams')
      .Query()
      .Top(this.pageSize)
      .Skip(this.pageSize * (this.page - 1))
      .Filter(this.filter)
      .OrderBy(this.orderBy)
      .ExecWithCount();
  }

  getChannels(teamId: string): Observable<ODataPagedResult<TeamItem>> {
    return this.odataFactory
      .CreateService<TeamItem>(`/Teams(${teamId})/Channels`)
      .Query()
      .Top(this.pageSize)
      .Skip(this.pageSize * (this.page - 1))
      .Filter(this.filter)
      .OrderBy(this.orderBy)
      .ExecWithCount();
  }

  getChannelItems(channelId: string): Observable<ChannelItem[]> {
    return this.odataFactory
      .CreateService(`/Channels(${channelId})/TeamThirdLevelItems`)
      .Query()
      .CustomQueryOptions([{ key: 'Id', value: channelId }])
      .Exec()
      .pipe(
        map((items: ChannelItem[]) =>
          items.map((item: ChannelItem) => {
            item.ChannelId = channelId;
            return item;
          })
        )
      );
  }

  getPosts(channelId: string): Observable<TeamPost[]> {
    return this.odataFactory
      .CreateService<TeamOdataPost>(`/Channels(${channelId})/ChatMessages`)
      .Query()
      .Exec()
      .pipe(map((posts: TeamOdataPost[]) => posts.map((post) => new TeamPost(post)).sort((a, b) => a.date.getTime() - b.date.getTime())));
  }

  getReplies(messageId: number): Observable<TeamPost[]> {
    return this.odataFactory
      .CreateService<TeamOdataPost>(`/ChatMessages(${messageId})/Replies`)
      .Query()
      .OrderBy('CreatedDate asc')
      .Exec()
      .pipe(
        map((posts: TeamOdataPost[]) => posts.map((post) => new TeamPost(post)).sort((a, b) => a.date.getTime() - b.date.getTime()))
      ) as Observable<TeamPost[]>;
  }

  getFiles(channelId: string, treeId: string): Observable<ODataPagedResult<TeamFile>> {
    return this.odataFactory.CreateService<TeamFile>(`Channels(${channelId})/Files?Id=${treeId}`).Query().ExecWithCount();
  }

  getFileDetails(id: string): Observable<DriveItemDetails> {
    return this.driveItemsService.CustomFunction(id, 'GetDetails', null).pipe(map((res) => res.body)) as Observable<DriveItemDetails>;
  }

  restoreTeamItems(payload: RestoreTeamsItemsPayload): Observable<void> {
    return this.odataMethods.CustomCollectionAction('RestoreTeamsItems', payload);
  }

  restoreTeamsParameters(payload: RestoreTeamsParametersPayload): Observable<RestoreTeamsParameters> {
    return this.odataMethods.CustomCollectionAction('RestoreTeamsParameters', payload).pipe(map((res) => res.body));
  }

  checkAppPermissions(serviceType: ServiceType, operationType: OperationType): Observable<boolean> {
    const odataService = this.odataFactory.CreateService(
      `/CheckAppPermissions(serviceType='${serviceType}',operationType='${operationType}')`
    );

    return this.http.get<{ Allowed: boolean }>(odataService.Query().GetUrl()).pipe(map((res) => res.Allowed));
  }
}
