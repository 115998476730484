import { Pipe, PipeTransform } from '@angular/core';
import { TeamItemType } from '@app/common/models';

@Pipe({
  name: 'handlingMimeCategoryIcon',
  pure: true
})
export class MimeCategoryIconPipe implements PipeTransform {
  transform(type: TeamItemType): boolean {
    return [TeamItemType.Folder, TeamItemType.Root, TeamItemType.File].includes(type);
  }
}
