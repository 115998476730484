import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '../button/button.module';
import { LoaderModule } from '../loader/loader.module';
import { ModalFooterDirective } from './modal-footer.directive';
import { ModalComponent } from './modal.component';
import { ModalService } from './modal.service';

@NgModule({
  declarations: [ModalComponent, ModalFooterDirective],
  imports: [CommonModule, LoaderModule, ButtonModule],
  exports: [ModalComponent, ModalFooterDirective],
  providers: [ModalService]
})
export class ModalModule {}
