import { Pipe, PipeTransform } from '@angular/core';

const nonResizableClasses = 'col-12 col-sm-6 col-md-4 col-lg-3 order-sm-2 order-md-3';

@Pipe({
  name: 'infoColumnLayout',
  pure: true
})
export class InfoColumnLayoutPipe implements PipeTransform {
  transform(resizableMode: boolean): string {
    const classes: string[] = [];

    if (resizableMode) {
      classes.push('col-auto');
    } else {
      classes.push(nonResizableClasses);
    }

    return classes.join(' ');
  }
}
