<div class="mbs-input">

  <mbs-input-label *ngIf="label && id"
                   [label]="label"
                   [id]="id"
                   [disabled]="disabledState"
                   [info]="info"
                   [boldLabel]="boldLabel"
                   [labelSize]="labelSize"
                   [infoPlacement]="infoPlacement"
                   [infoTriggers]="infoTriggers"
                   [infoContainer]="infoContainer"
                   [required]="hasRequiredValidator && showRequiredMark && !isLabelTemplate"
                   [labelClasses]="labelClasses"
                   [labelContentClasses]="labelContentClasses">

    <ng-content select="[prependLabel]"
                ngProjectAs="[prependLabel]"></ng-content>

    <ng-content select="[appendLabel]"
                ngProjectAs="[appendLabel]"></ng-content>

  </mbs-input-label>

  <div class="input-group">

    <mbs-input-pre-append *ngIf="prependButtons || prepend"
                          position="prepend"
                          [disabled]="disabledState"
                          [dataTemplate]="prepend"
                          [data]="prependButtons"
                          [size]="size"
                          (buttonClick)="buttonClick.emit($event)">
    </mbs-input-pre-append>

    <div class="input-group_control"
         [ngClass]="[ size ? 'input-group_control-' + size : '']">

      <ng-container *ngIf="type !== 'file' && type !== 'textarea'">

        <input *ngIf="showErrorsInTooltip; else withoutTooltipInput"
               #errorsTooltip="ngbTooltip"
               class="form-control"
               triggers="none"
               [tooltipClass]="tooltipClass"
               [placement]="placement"
               [container]="container"
               [ngbTooltip]="errorsTooltipData"
               [autoClose]="autoClose"
               [maxLength]="maxLength"
               [minLength]="minLength"
               [name]="name"
               [type]="type"
               [tabindex]="tabindex"
               [attr.aria-describedby]="id"
               [attr.aria-labelledby]="label"
               [placeholder]="placeholder"
               [ngClass]="bindClasses"
               [id]="id"
               [disabled]="disabledState"
               [value]="value"
               [autocomplete]="autocomplete"
               (blur)="handleBlur($event)"
               (focus)="handleFocus($event)"
               (input)="handleChange($event.target.value)"
               [imask]="imaskOpts"
               [unmask]="unmask"
               [imaskElement]="imaskElement"
               [readonly]="readonlyState" />

        <ng-template #withoutTooltipInput>
          <input class="form-control"
                 triggers="none"
                 [placement]="placement"
                 [maxLength]="maxLength"
                 [minLength]="minLength"
                 [name]="name"
                 [type]="type"
                 [tabindex]="tabindex"
                 [attr.aria-describedby]="id"
                 [attr.aria-labelledby]="label"
                 [placeholder]="placeholder"
                 [ngClass]="bindClasses"
                 [id]="id"
                 [disabled]="disabledState"
                 [value]="value"
                 [autocomplete]="autocomplete"
                 (blur)="handleBlur($event)"
                 (focus)="handleFocus($event)"
                 (input)="handleChange($event.target.value)"
                 (selectItem)="handleTypeaheadItemSelected($event)"
                 [imask]="imaskOpts"
                 [unmask]="unmask"
                 [imaskElement]="imaskElement"
                 [readonly]="readonlyState"
                 [ngbTypeahead]="typeahead" />
        </ng-template>

        <div *ngIf="loading"
             class="form-control_loader"
             [ngClass]="size ? '-' + size : ''">

          <span class="loader"
                [ngClass]="size === 'xxs' ? 'loader-sm' : ''"></span>

        </div>

        <mbs-button *ngIf="clearButton && value && !loading"
                    [isCtrl]="true"
                    icon="ico ico-Delete-text mr-0"
                    type="dark"
                    [buttonType]="'reset'"
                    (click)="handleClear()"
                    tabindex="-1"
                    class="form-control_clear"
                    [ngClass]="size ? '-' + size : ''"
                    [customClasses]="'form-control_clear-btn' + (size ? ' -' + size : '')"
                    [size]="size"></mbs-button>
      </ng-container>

      <ng-container *ngIf="type === 'file'">

        <div class="mbs-file">

          <input type="file"
                 class="mbs-file-input"
                 [autofocus]="autofocus"
                 [tabindex]="tabindex"
                 [attr.aria-describedby]="id"
                 [attr.aria-labelledby]="label"
                 [placeholder]="placeholder"
                 [ngClass]="bindClasses"
                 [id]="id"
                 [disabled]="disabledState"
                 [autocomplete]="autocomplete"
                 (blur)="handleBlur($event)"
                 (focus)="handleFocus($event)"
                 (input)="handleChange($event, $event.target.value)"
                 [accept]="accept"
                 [multiple]="multiple"
                 #fileInputElement />

          <label class="mbs-file-label input-group"
                 [class.-disabled]="disabledState"
                 [for]="id">

            <span class="input-group_control"
                  [class.overflow-hidden]="filePlaceholderOverflow">

              <span class="form-control"
                    [class.disabled]="disabledState"
                    [ngClass]="bindClasses"
                    [class.text-overflow]="filePlaceholderOverflow">
                {{ placeholder }}
              </span>

              <span *ngIf="loading"
                    class="form-control_loader"
                    [ngClass]="size ? '-' + size : ''">

                <span class="loader"
                      [ngClass]="size === 'xxs' ? 'loader-sm' : ''"></span>

              </span>

              <!-- span is using here because of inline tags only can be used inside '<label>' element -->
              <span class="form-control_clear"
                    [ngClass]="size ? '-' + size : ''"
                    *ngIf="clearButton && value && !errors.length">

                <span class="ctrl ctrl-dark form-control_clear-btn"
                      [ngClass]="size ? 'ctrl-' + size : ''"
                      (click)="handleClear($event, fileInputElement)"
                      tabindex="-1">
                  <span class="btn-ico ico ico-Delete-text mr-0"></span>
                </span>

              </span>

            </span>

            <mbs-input-pre-append position="append"
                                  [disabled]="disabledState"
                                  [dataTemplate]="append"
                                  [data]="[{
                                    text: 'Browse',
                                    id: id + '-browse-file',
                                    type: 'secondary'
                                  }]"
                                  [size]="size"
                                  (buttonClick)="loadFileHandler($event, fileInputElement)">
            </mbs-input-pre-append>

          </label>

        </div>

      </ng-container>

      <ng-container *ngIf="type === 'textarea'">
        <textarea class="form-control"
                  [style.max-height]="maxHeight"
                  [attr.maxlength]="maxLength"
                  [tabindex]="tabindex"
                  [attr.aria-describedby]="id"
                  [attr.aria-labelledby]="label"
                  [placeholder]="placeholder"
                  [ngClass]="bindClasses"
                  [id]="id"
                  [disabled]="disabledState"
                  [value]="value"
                  [rows]="rows"
                  (blur)="handleBlur($event)"
                  (focus)="handleFocus($event)"
                  (input)="handleChange($event.target.value)"
                  [readonly]="readonlyState"
                  [ngStyle]="{'resize': resize}">
        </textarea>
      </ng-container>

    </div>

    <mbs-input-pre-append *ngIf="appendButtons || append"
                          position="append"
                          [disabled]="disabledState"
                          [dataTemplate]="append"
                          [data]="appendButtons"
                          [size]="size"
                          (buttonClick)="buttonClick.emit($event)">
    </mbs-input-pre-append>

  </div>

  <mbs-input-errors [errors]="errors"
                    *ngIf="isErrorsShow && !showErrorsInTooltip"></mbs-input-errors>

  <ng-template #errorsTooltipData>

    <div class="text-left text-sm">

      <p *ngIf="errorTooltipTitle"
         class="mb-3 font-weight-bold">
        {{ errorTooltipTitle }}
      </p>

      <ul class="pl-3">
        <li *ngFor="let item of getErrorsArray()"
            class="text-danger mb-2">
          {{ item.message }}
        </li>
      </ul>

    </div>

  </ng-template>

</div>
