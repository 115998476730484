import { Directive, HostListener, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';
import { DOMEvent } from '../../form/input-base/input-base.model';
import { isNil } from 'lodash';

/**
 * @deprecated since release/4.7
 * trim include mbs-input
 */
@Directive({
  selector: 'input[mbsTrimInput]'
})
export class TrimInputDirective {
  constructor(@Optional() private ngControl: NgControl) {}

  @HostListener('blur', ['$event'])
  trim(event: DOMEvent<HTMLInputElement>) {
    const input = event.target;
    if (isNil(input.value)) {
      return;
    }
    input.value = input.value.trim();
    if (this.ngControl) {
      this.ngControl.control.patchValue(input.value);
    }
  }
}
