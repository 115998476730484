import { FormControl } from '@angular/forms';
import { DateUnitEnum } from '@app/common/models';

export enum MyAccountTab {
  general = 'generalTab',
  alternateAccount = 'alternateAccountTab',
  backupDestination = 'backupDestinationTab'
}

export type MyAccountForm = {
  [MyAccountTab.general]: FormControl;
  [MyAccountTab.alternateAccount]: FormControl;
  [MyAccountTab.backupDestination]: FormControl;
};

export type GeneralTabForm = {
  email: string;
  phone: string;
  version: string;
  exportSettings: {
    keepAllowed: {
      checkbox: boolean;
      password: string;
    };
    keepCntUnits: {
      checkbox: boolean;
      number: number;
      filter: DateUnitEnum;
    };
  };
};

export type AlternateAccountTabForm = {
  alternateEmail: string;
  password: string;
  confirmPassword: string;
};

export type BackupDestinationTabForm = {
  storageType: string;
  name: string;
  bucketName: string;
  rootFolder: string;
  accessKey: string;
  secretKey: string;
  endpoint?: string;
  endpointSuffix?: string;
};
