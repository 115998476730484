import { Directive, Input, TemplateRef } from '@angular/core';

/**
 * mbs-table-nav custom content
 */
@Directive({
  selector: '[mbsTableNavContent]'
})
export class TableNavContentDirective {
  constructor(public template: TemplateRef<any>) {}

  @Input('mbsTableNavContent') context: any;
}
