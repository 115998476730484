<header class="mbs-form-section_header"
        [ngSwitch]="titleSize"
        *ngIf="title">
  <h1 *ngSwitchCase="'h1'"
      [ngClass]="titleClasses"
      class="mbs-form-section_title">
    <ng-container *ngTemplateOutlet="tagTemplate;"></ng-container>
  </h1>
  <h2 *ngSwitchCase="'h2'"
      [ngClass]="titleClasses"
      class="mbs-form-section_title">
    <ng-container *ngTemplateOutlet="tagTemplate;"></ng-container>
  </h2>
  <h3 *ngSwitchCase="'h3'"
      [ngClass]="titleClasses"
      class="mbs-form-section_title">
    <ng-container *ngTemplateOutlet="tagTemplate;"></ng-container>
  </h3>
  <h4 *ngSwitchCase="'h4'"
      [ngClass]="titleClasses"
      class="mbs-form-section_title">
    <ng-container *ngTemplateOutlet="tagTemplate;"></ng-container>
  </h4>
  <h5 *ngSwitchCase="'h5'"
      [ngClass]="titleClasses"
      class="mbs-form-section_title">
    <ng-container *ngTemplateOutlet="tagTemplate;"></ng-container>
  </h5>
  <h6 *ngSwitchCase="'h6'"
      [ngClass]="titleClasses"
      class="mbs-form-section_title">
    <ng-container *ngTemplateOutlet="tagTemplate;"></ng-container>
  </h6>
  <h5 *ngSwitchDefault
      [ngClass]="titleClasses"
      class="mbs-form-section_title">
    <ng-container *ngTemplateOutlet="tagTemplate;"></ng-container>
  </h5>
</header>

<div class="mbs-form-section_content">
  <ng-container *ngTemplateOutlet="formSectionContent"></ng-container>
</div>

<ng-template #formSectionContent>
  <ng-content></ng-content>
</ng-template>

<ng-template #tagTemplate>
  <span class="mbs-form-section_header-ico"
        *ngIf="icon"
        [ngClass]="[icon]"></span>
  {{ title }}
</ng-template>
