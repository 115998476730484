import { ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
  selector: 'mbs-step',
  templateUrl: './step.component.html'
})
export class StepComponent {
  @Input() number = 1;
  @Input() status: 'active' | 'success' = null;
  @Input() readOnly = false;
  @Input() bodyClasses = '';
  lastStep = false;
  singleStep = false;

  constructor(private cd: ChangeDetectorRef) {}

  activate(): void {
    this.status = 'active';
  }

  complete(): void {
    this.status = 'success';
  }

  pending(): void {
    this.status = null;
  }
}
