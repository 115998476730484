<div class="mbs-page">

  <div class="mbs-page_content d-flex align-items-center justify-content-center flex-column text-center">

    <figure class="mb-4 mb-xl-5">
      <svg-icon-sprite [src]="'not-found'"
                       [width]="'98px'"
                       [height]="'77px'"></svg-icon-sprite>
    </figure>

    <h1 class="text-primary mt-0 pb-1 pb-xl-2 mb-3 mb-xl-4">
      {{ 'not-found.title' | i18next: { format: 'capitalize' } }}
    </h1>

    <div class="container pb-1 pb-xl-2 mb-3 mb-xl-4">
      <div class="row justify-content-center">
        <div class="col-3 col-xl-2">
          <div class="border-top"></div>
        </div>
      </div>
    </div>

    <p class="pb-3 pb-xl-4 mb-3 mb-xl-4">
      {{ 'not-found.textStart' | i18next: { format: 'capitalize' } }}<br />
      {{ 'not-found.textEnd' | i18next }}
    </p>

    <a [routerLink]="[prefix]"
       class="btn btn-primary">
      {{ 'not-found.button.goToHome' | i18next: { format: 'title' } }}
    </a>
  </div>
</div>
