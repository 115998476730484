export enum DateUnit {
  days = 'days',
  weeks = 'weeks',
  months = 'months',
  years = 'years'
}

export enum ShortMonth {
  'Jan' = 1,
  'Feb' = 2,
  'Mar' = 3,
  'Apr' = 4,
  'May' = 5,
  'Jun' = 6,
  'Jul' = 7,
  'Aug' = 8,
  'Sep' = 9,
  'Oct' = 10,
  'Nov' = 11,
  'Dec' = 12
}
