<apps-invoice-plan [planInfo]="planInfo$ | async"></apps-invoice-plan>

<!-- mbs-form-filter-->
<div class="mbs-form mbs-form-filter">

  <div class="form-row py-2">

    <div class="col-12 col-md-4 col-xl-3">
      <mbs-smart-search [accessUrlQuery]="false"
                        (search)="handleUpdateFilter($event)"
                        [data]="searchTemplates"></mbs-smart-search>
    </div>

    <div class="col-12 col-md-8 col-xl-9 pt-2 pt-md-0">

      <div class="form-row">

        <div class="col pb-2">

          <div class="form-row">

            <div class="col-auto">
              <mbs-button type="secondary"
                          [disabled]="loading$ | async"
                          (click)="handlePlan(ModePlansCompareEnum.select)">
                {{ modulePayments + ':button.selectYourPlan' | i18next: { format: 'title' } }}
              </mbs-button>
            </div>

            <div *ngIf="(planInfo$ | async)?.ActivatedPlanId && isAvailableEditMode$ | async"
                 class="col-auto">
              <mbs-button type="secondary"
                          [disabled]="loading$ | async"
                          (click)="handlePlan(ModePlansCompareEnum.edit)">
                {{ modulePayments + ':button.editSubscription' | i18next: { format: 'title' } }}
              </mbs-button>
            </div>

          </div>

        </div>

        <div *ngIf="(planInfo$ | async)?.SubscriptionUrl"
             class="col-auto pb-2">

          <a [href]="(planInfo$ | async)?.SubscriptionUrl"
             [attr.disabled]="loading$ | async"
             class="btn btn-secondary"
             target="_blank">
            {{ modulePayments + ':button.subscriptionDetails' | i18next: { format: 'title' } }}
            <i class="fa fa-external-link"></i>
          </a>

        </div>

      </div>

    </div>

  </div>

</div>
<!-- mbs-form-filter end-->

<mbs-table-grid *ngIf="contentAvailable$ | async"
                [stickyHeader]="true"
                bindSelected="Id"
                [selectable]="false"
                [data]="invoices$ | async"
                [loading]="loading$ | async"
                [headers]="headers"
                [showRefreshButton]="true"
                [disableViewSwitch]="true"
                [switcherView]="false"
                [viewMode]="viewMode"
                [serverSidePagination]="true"
                [paginationOptions]="paginationOptions"
                (refresh)="fetchData()"
                (pageChange)="pageChange($event)"
                (pageSizeChange)="pageSizeChange($event)"
                [changeSortState]="orderBy"
                (sort)="handleSort($event)"
                [maxHeight]="maxHeight$ | async"
                #mbsTableGridRef>

  <ng-template mbsTableFilter="item">
    <div class="text-sm">
      {{ modulePayments + ':grid-nav.invoicesLength' | i18next: { format: 'title', length: (invoices$ | async).length } }}
    </div>
  </ng-template>

  <ng-template mbsTableCell
               let-invoice>

    <mbs-status [type]="invoice.State | invoiceStatus: 'type'"
                [icon]="invoice.State | invoiceStatus: 'icon'"
                [clickable]="invoice.Comment"
                [ngbPopover]="invoice.Comment ? tooltipContent : null"
                container="body"
                placement="top auto"></mbs-status>

    <ng-template #tooltipContent>

      {{ invoice.Comment }}

      <div *ngIf="invoice.OrderSubscriptionDetailsUrl"
           class="d-flex mt-1">

        <div class="flex-shrink-0 mr-1">
          {{ modulePayments + ':orderUrl' | i18next: { format: 'title' } }}:
        </div>

        <a [href]="invoice.OrderSubscriptionDetailsUrl"
           [style]="{ 'word-break': 'break-all' }"
           target="_blank">
          {{ invoice.OrderSubscriptionDetailsUrl }}
        </a>

      </div>

    </ng-template>

  </ng-template>

  <ng-template mbsTableCell
               let-invoice>
    {{ invoice.Quantity }}
  </ng-template>

  <ng-template mbsTableCell
               let-invoice>
    {{ invoice.Price | priceToDisplay | currency: 'USD' }}
  </ng-template>

  <ng-template mbsTableCell
               let-invoice>
    {{ invoice.Amount | priceToDisplay | currency: 'USD' }}
  </ng-template>

  <ng-template mbsTableCell
               let-invoice>
    <mbs-text-ellipsis *ngIf="invoice.Date | date: DateFormat.medium as date"
                       placement="top-left"
                       tooltipClass="text-left"
                       [tooltip]="date">
      {{ date }}
    </mbs-text-ellipsis>
  </ng-template>

  <ng-template mbsTableCell
               let-invoice>
    <mbs-text-ellipsis *ngIf="invoice.ExpireDate | date: DateFormat.medium as expireDate"
                       placement="top-left"
                       tooltipClass="text-left"
                       [tooltip]="expireDate">
      {{ expireDate }}
    </mbs-text-ellipsis>
  </ng-template>

  <ng-template mbsTableCell
               let-invoice>
    <a *ngIf="invoice.OrderId && invoice.OrderUrl"
       [href]="invoice.OrderUrl"
       target="_blank">
      {{ invoice.OrderId }}
    </a>
  </ng-template>

  <ng-template mbsTableCell
               let-invoice>

    <ng-container *ngIf="invoice.State !== InvoiceState.Paid">
      <!-- commented until fix on BE -->
<!--      <mbs-button *ngIf="invoice.PaymentType === PaymentType.SubscriptionUpdated"-->
<!--                  [type]="'secondary'"-->
<!--                  [icon]="'fa fa-shopping-cart text-base'"-->
<!--                  placement="top-right auto"-->
<!--                  container="body"-->
<!--                  [size]="MbsSize.sm"-->
<!--                  [ngbTooltip]="modulePayments + ':tooltip.subscriptionUpdated' | i18next: { format: 'title' }"-->
<!--                  (click)="handleSubscriptionUpdate(invoice)"></mbs-button>-->

      <a *ngIf="invoice.PaymentType === PaymentType.FirstSubscriptionActivated"
         [href]="invoice.InvoicePaymentUrl"
         target="_blank"
         class="btn btn-secondary btn-sm"
         placement="top-right auto"
         container="body"
         [ngbTooltip]="modulePayments + ':tooltip.purchase' | i18next: { format: 'title' }">
        <span class="fa fa-shopping-cart text-base"></span>
      </a>
    </ng-container>

  </ng-template>

</mbs-table-grid>

<ng-template #confirmSubscriptionMessageBodyTemplate>

  <p class="mb-1">
    {{ modulePayments + ':modal.body.confirmSubscriptionUpdate.yourAccount' | i18next: { format: 'capitalize' } }}
    <span class="font-weight-semibold">{{ invoiceAmount | priceToDisplay | currency: 'USD' }}</span>.
  </p>

  <p class="mb-0">
    {{ modulePayments + ':modal.body.confirmSubscriptionUpdate.areYouSure' | i18next: { format: 'capitalize' } }}
    <span class="font-weight-semibold">{{ (planInfo$ | async)?.ExpireDate | date: DateFormat.mediumDate }}</span>
    {{ modulePayments + ':modal.body.confirmSubscriptionUpdate.willBe' | i18next }}
    <span class="font-weight-semibold">{{ invoiceNextPayment | priceToDisplay | currency: 'USD' }}</span>
  </p>

</ng-template>
