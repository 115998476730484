import { ChangeDetectorRef, Component, ContentChild, Directive, Input, OnInit, Optional, Self, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MbsSize } from '../../utils';
import { InputBase } from '../input-base/input-base';

@Directive({
  selector: '[checkboxDescription]'
})
export class CheckboxDescription {
  @Input() hide = false;
  constructor(public template: TemplateRef<any>) {}
}

/**
 * Component that presents a `checkbox`
 */
@Component({
  selector: 'app-checkbox,mbs-checkbox',
  templateUrl: './checkbox.component.html'
})
export class CheckboxComponent extends InputBase<any> implements ControlValueAccessor, OnInit {
  public readonly MbsSize = MbsSize;

  private myIndeterminate = false;

  /**
   * Pin mode if true - not display checkbox, show fa-thumb-tack icon and label
   * @param {boolean} state
   */
  @Input() pinMode: boolean;

  @Input() subtitle: string;
  @Input() subtitleClasses: string;
  @Input() subtitleHide = false;
  @Input() subtitleOutside = true;

  /**
   * context for label template
   */
  @Input() labelContext: any;

  @Input() descriptionOutside = true;
  @Input() descriptionClasses: string;
  /**
   * Add font-weight-bold;
   */
  @Input() public boldLabel = false;

  /**
   * HTML native checkbox indeterminate state
   * @param {boolean} state
   */
  @Input()
  public set indeterminate(state: boolean) {
    this.myIndeterminate = state;

    // if you set this from outside of a component, like when you need to set "checked"
    // programmatically and get the checkbox component with viewChild and set "checked"
    // it should update native input props
    setTimeout(() => this.cd.detectChanges());
  }

  public get indeterminate() {
    return this.myIndeterminate;
  }

  @Input() centerCheckbox = true;

  @Input() public set value(value: string) {
    this.myValue = value;
  }
  public get value(): string {
    return String(this.myValue);
  }

  protected myChecked = false;
  /**
   * Native checked radio button
   * @param {boolean} state
   */
  @Input() public set checked(state: boolean) {
    this.myChecked = state;

    // if you set this outside of a component, for example when you need to set "checked"
    // programmatically and get the checkbox component with viewChild and set "checked"
    // it should update native input props
    this.cd.detectChanges();
  }
  public get checked(): boolean {
    return this.myChecked;
  }

  @ContentChild(CheckboxDescription, { static: true, read: CheckboxDescription }) checkboxDescription: CheckboxDescription;

  constructor(@Optional() @Self() ngControl: NgControl, @Optional() protected cd: ChangeDetectorRef) {
    super(ngControl, cd);
  }

  ngOnInit(): void {
    if (this.label && !this.id) {
      console.error('checkbox id must be specified if label in use');
    }
  }

  /**
   * Default change method. Can be replaced.
   * @param {Event} event - native DOM input event
   */
  handleChange(event: Event): void {
    event.stopPropagation();

    this.checked = (event.target as HTMLInputElement).checked;

    if (this.onChange) {
      this.onChange(this.checked);
    }

    this.change.emit(this.checked);
  }

  writeValue(obj: boolean): void {
    this.myChecked = Boolean(obj);
    this.cd.markForCheck();
  }
}
