import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[headerFor],[mbsTableHeader]'
})
export class TableHeaderNameDirective {
  public value: string;
  /**
   * @deprecated
   * @param {string} value
   */
  @Input('headerFor') set headerFor(value: string) {
    this.value = value;
  }
  @Input('mbsTableHeader') set mbsTableHeader(value: string) {
    this.value = value;
  }
  constructor(public template: TemplateRef<any>) {}
}
