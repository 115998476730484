export enum UserGroupsTagsEnum {
  Path = 1,
  Name,
  AutoActivation
}

export const UserGroupsTagsMap: Map<UserGroupsTagsEnum, { tagKey: string; prop: string }> = new Map([
  [UserGroupsTagsEnum.Path, { tagKey: ':grid.path', prop: 'Path' }],
  [UserGroupsTagsEnum.Name, { tagKey: ':grid.name', prop: 'Name' }],
  [
    UserGroupsTagsEnum.AutoActivation,
    {
      tagKey: ':grid.autoActivate',
      prop: 'AutoActivationMode'
    }
  ]
]);
