import { Injectable } from '@angular/core';
import { AuthUser, SystemInfo } from '@app/common/models';
import { ConfigurationService } from '@app/common/services/configuration.service';
import { getSentryEnvironment } from '@app/common/utils';
import { environment } from '@env/environment';
import * as Sentry from '@sentry/browser';
import { Integrations as TracingIntegrations } from '@sentry/tracing';

@Injectable({ providedIn: 'root' })
export class SentryService {
  constructor(private config: ConfigurationService) {}

  init(info: SystemInfo): void {
    if (!this.config.get('production')) return;

    Sentry.init({
      dsn: environment.sentry.dsn,
      release: info.AssemblyVersion,
      integrations: [
        new TracingIntegrations.BrowserTracing(),
        new Sentry.Integrations.Breadcrumbs({
          console: false,
          /** Add own breadcrumb for xhr */
          xhr: false
        })
      ],
      beforeBreadcrumb: (breadcrumb, hint?) => {
        if (breadcrumb.category === 'ui.click') {
          const innerText = hint?.event?.innerText;

          innerText && (breadcrumb.message = `${breadcrumb.message} ${innerText}`);
        }

        return breadcrumb;
      },
      tracesSampleRate: 1,
      environment: getSentryEnvironment()
    });
  }

  addUser(user: AuthUser): void {
    if (!this.config.get('production')) return;

    /** Add a user data for Sentry */
    Sentry.configureScope(function (scope) {
      scope.setUser({
        Email: user.Email,
        DomainId: user.DomainId,
        DomainName: user.DomainName,
        Id: user.Id,
        Name: user.Name,
        Provider: user.Provider,
        RestoreEnable: user.RestoreEnable,
        SignInEnable: user.SignInEnable
      });
    });
  }
}
