export enum COMPARISON_OPERATOR {
  gt = 'gt', // checks if a field is GREATER than a constant value.
  lt = 'lt', // checks if a field is LESS than a constant value.
  ge = 'ge', // checks if the field is GREATER THAN or EQUAL to a constant value.
  le = 'le', // checks if the field is LESS than or EQUAL to a constant value.
  eq = 'eq', // checks if a field is EQUAL to a constant value.
  ne = 'ne', // checks if the field is NOT EQUAL to a constant value.
  in = 'in' // checks if the array fields is EQUAL to a constant value.
}
export enum BOOLEAN_OPERATOR {
  and = 'and',
  or = 'or',
  not = 'not'
}
