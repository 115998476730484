<div class="btn-group mbs-btn-group"
     role="group"
     [ngClass]="[
       size ? 'mbs-btn-group_' + size : '',
       vertical ? 'btn-group-vertical mbs-btn-group_vertical' : '',
       type ? 'mbs-btn-group_' + type : '',
       customClasses
     ]">
  <ng-content></ng-content>
</div>
