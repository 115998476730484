import { CONDITION_CHARS, SmartSearchCharacters } from './constants';
import { ModelTemplateInternal, SmartSearchKeywordType, SmartSearchTag, SmartSearchTagField, SmartSearchWord } from './models';

export class SmartSearchParser {
  private static parseSingleWords(source: string, elements: Element[]): SmartSearchWord[] {
    const conditionChars = `(${[...CONDITION_CHARS.values()].join('|')})`;

    const wordModels: SmartSearchWord[] = [];

    const singleWordReg = new RegExp(conditionChars + '?(\\S+,?)', 'g');
    for (let match = singleWordReg.exec(source); match; match = singleWordReg.exec(source)) {
      const word = {
        type: 'word',
        value: match[2],
        conditionElements: match[1] ? elements.slice(match.index, match.index + match[1].length) : [],
        conditionValue: match[1],
        elements: [],
        isGrouped: true
      } as SmartSearchWord;

      word.elements = word.conditionElements.concat(
        elements.slice(match.index + word.conditionElements.length, match.index + word.value.length)
      );

      wordModels.push(word);
    }

    return wordModels;
  }

  private static parseGroupWords(source: string, elements: Element[]): SmartSearchWord[] {
    const conditionChars = `(${[...CONDITION_CHARS.values()].join('|')})`;

    const group = new RegExp(conditionChars + '?{([\\s\\S]+?)}', 'g');

    const allMatched: RegExpExecArray[] = [];
    for (let match = group.exec(source); match; match = group.exec(source)) {
      allMatched.push(match);
    }

    if (allMatched.length === 0) {
      return this.parseSingleWords(source, elements);
    }

    const wordModels: SmartSearchWord[] = [];

    for (let index = 0; index < allMatched.length; index++) {
      const match = allMatched[index];
      // first match and has left part
      if (match.index > 0 && wordModels.length === 0) {
        const wordLeftPart = source.substring(0, match.index - 1);
        const wordPartElements = elements.slice(0, wordLeftPart.length);

        wordModels.push(...this.parseSingleWords(wordLeftPart, wordPartElements));
      }

      const word = {
        type: 'word',
        value: match[2],
        conditionElements: match[1] ? elements.slice(match.index, match.index + match[1].length) : [],
        conditionValue: match[1],
        elements: [],
        isGrouped: true
      } as SmartSearchWord;

      word.elements = word.conditionElements.concat(
        elements.slice(match.index + word.conditionElements.length, match.index + word.value.length)
      );

      wordModels.push(word);

      // parse right part before next match or end line
      const nextMatch = allMatched[index + 1];
      const wordRightPart = source.substring(match.index + match[0].length, nextMatch ? nextMatch.index - 1 : source.length);
      const wordRightPartElements = elements.slice(match.index + match[0].length, nextMatch ? nextMatch.index - 1 : source.length);
      wordModels.push(...this.parseSingleWords(wordRightPart, wordRightPartElements));
    }

    return wordModels;
  }

  public static parseWords(source: string, elements: Element[]): SmartSearchWord[] {
    return this.parseGroupWords(source, elements);
  }

  public static parseTag(model: ModelTemplateInternal, source: string, elements: Element[]): SmartSearchKeywordType[] {
    const tagLower = model.tagFormatter(model).toLocaleLowerCase();

    const keywords: SmartSearchKeywordType[] = [];

    const searchTag = {
      type: model.isHashtag ? 'hashtag' : 'tag',
      tagElements: elements.slice(0, tagLower.length),
      tagValue: model.tag,
      tagTemplate: model,
      elements: [],
      fields: []
    } as SmartSearchTag;

    keywords.push(searchTag);

    const valueSource = source.substring(tagLower.length);
    const valueElements = elements.slice(tagLower.length);

    const getField = (fieldValue: string, match: RegExpExecArray): SmartSearchTagField => {
      const conditionIndex = valueSource.indexOf(match[1], match.index);
      const field = {
        fieldValue: fieldValue,
        conditionValue: match[1],
        conditionElements: match[1] ? valueElements.slice(conditionIndex, conditionIndex + match[1].length) : [],
        isGrouped: !!match[3]
      } as SmartSearchTagField;

      const fieldIndex = valueSource.indexOf(fieldValue, match.index);
      field.fieldElements = valueElements.slice(fieldIndex, fieldIndex + fieldValue.length);
      field.elements = field.conditionElements.concat(field.fieldElements);
      field.value = match[0].trim();

      return field;
    };

    const conditionChars = `(${[...CONDITION_CHARS.values()].join('|')})`;

    if (model.isMultiple === true || model.isSequenceMultiple === true) {
      const group = new RegExp(conditionChars + '?({([\\s\\S]+?)}|\\S+),?', 'g');

      for (let match = group.exec(valueSource); match; match = group.exec(valueSource)) {
        let fieldValue = match[3] || match[2];

        const hasEndComma = match[0][match[0].length - 1].endsWith(SmartSearchCharacters.SEQUENCE);
        if (fieldValue.endsWith(SmartSearchCharacters.SEQUENCE)) {
          // substring with remove last "comma"
          fieldValue = fieldValue.slice(0, -1);
        }
        const field = getField(fieldValue, match);

        searchTag.fields.push(field);

        searchTag.completed = !hasEndComma;

        // break current match and parse as word
        if (!hasEndComma) {
          const wordPart = valueSource.substring(match.index + match[0].length);
          if (wordPart) {
            const wordElements = valueElements.slice(match.index + match[0].length);
            keywords.push(...this.parseWords(wordPart, wordElements));
          }
          break;
        }
      }
    } else {
      const firstRegex = new RegExp('^\\s*' + conditionChars + '?({([\\s\\S]+?)}|\\S+)');
      const firstMatch = firstRegex.exec(valueSource);
      if (firstMatch) {
        const field = getField(firstMatch[3] || firstMatch[2], firstMatch);
        searchTag.fields = [field];

        searchTag.completed = true;

        const wordPart = valueSource.substring(firstMatch[0].length);
        if (wordPart) {
          const wordElements = valueElements.slice(firstMatch[0].length);
          keywords.push(...this.parseWords(wordPart, wordElements));
        }
      }
    }

    searchTag.elements = searchTag.tagElements.concat(...searchTag.fields.map(f => f.elements));

    return keywords;
  }
}
