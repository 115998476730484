export enum DateFormat {
  short = 'MMM d, y, h:mm a',
  shortTimeZone = 'MMM d, y, h:mm a z',
  medium = 'MMM d, y, h:mm:ss a',
  shortDate = 'M/d/yy',
  mediumDate = 'MMM d, y',
  shortTime = 'h:mm a',
  mediumTime = 'h:mm:ss a',
  shortMonth = 'MMM, y',
  longMonth = 'MMMM, y'
}
