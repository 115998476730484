import { TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';

// eslint-disable-next-line no-use-before-define
export interface ModelTemplateInternal extends ModelTemplate<any> {
  tagFormatter?: (value: any) => string;
  formatter?: (value: any) => string;
}

interface SmartSearchKeywordBase<T> {
  type: 'word' | 'tag' | 'hashtag';
  value: T;
  elements: Element[];
}

export interface SmartSearchHashtag extends SmartSearchKeywordBase<boolean> {
  hashtag: string;
  tagTemplate: ModelTemplateInternal;
}

export interface SmartSearchWord extends SmartSearchKeywordBase<string> {
  isGrouped?: boolean;
  conditionValue?: string;
  conditionElements?: Element[];
}

export interface SmartSearchTagField {
  value: string;
  elements: Element[];
  isGrouped?: boolean;
  conditionValue?: string;
  conditionElements?: Element[];
  fieldValue: string;
  fieldElements: Element[];
}

export interface SmartSearchTag extends SmartSearchKeywordBase<string> {
  tagValue: string;
  tagElements: Element[];
  fields: SmartSearchTagField[];
  completed?: boolean;
  tagTemplate: ModelTemplateInternal;
}

export type SmartSearchKeywordType = SmartSearchHashtag | SmartSearchWord | SmartSearchTag;

export interface SmartSearchState {
  leftCaretValue: string;
  leftCaretClearValue: string;
  leftCaretCondition: string;
  caretNode: Node;
  caretIndex: number;
  availableTags: ModelTemplateInternal[];
  usedTags: string[];
  model: SmartSearchKeywordType[];
  invalid: boolean;
  modelValidationState: SmartSearchValidationState;
}

export enum SmartSearchValidationState {
  Unknown,
  Valid,
  Invalid
}

export interface ModelTemplate<T> {
  tag: string;

  /**
   * if set `prop`, search box will NOT include values that already presented in search field into dropdown.
   * Attention! Now work with flat object (root level only)
   */
  filterByProp?: string;

  isHashtag?: boolean;

  /**
   * Get autocomplete items. Empty for hashtag.
   */
  items?: (state: SmartSearchState) => Observable<T[]>;

  /**
   * For default template. Empty for hashtag.
   */
  itemFormatter?: (value: T) => string;

  /**
   * Customize item template
   */
  template?: TemplateRef<any>;

  /**
   * if `true`, then the ability to reuse an already used tag is added
   * if `false` - search box will NOT include tags that already presented in search field into dropdown.
   * If user write duplicate tag manually it will be ignored;
   */
  isMultiple?: boolean;

  /**
   * if `true`, then special actions are added to the drop-down list, allowing you to list values separated by commas
   */
  isSequenceMultiple?: boolean;

  /**
   * if `true`, then special brackets '{' and '}' could be automatically added for values separated by space
   */
  addGroupBrackets?: boolean;

  /**
   * if `true`, then all selected cachedValues will be added to cachedValues storage. Only for tag values.
   */
  enableCacheStorage?: boolean;
}

export enum ActionType {
  /**
   * Separate list of values in isSequenceMultiple = 'true' mode
   */
  Comma = 1,
  /**
   * Switch to next tag in isSequenceMultiple = 'true' mode
   */
  Space
}

export interface CachedValueType {
  value;
  searchValue: string;
  tag: string;
}
