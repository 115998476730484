import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { WINDOW } from '@app/common';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(@Inject(WINDOW) private window: Window) {}

  handleError(error: { message: string }): void {
    const chunkFailedMessage = /Loading chunk \d+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      this.window.location.reload();
    }
  }
}
