<header class="mbs-page_header">
  <h3 class="mbs-page_title">
    <a [routerLink]="[prefix + RoutePath.Users]"
       class="d-inline-flex align-items-center text-dark">
      <span class="fa fa-arrow-left text-base mr-2"></span>
      {{
        isGoogle
          ? (moduleUsers + ':pageName.userUnitsGoogle' | i18next: { format: 'title' })
          : (moduleUsers + ':pageName.userGroupsOffice' | i18next: { format: 'title' })
      }}
    </a>
  </h3>
</header>

<form class="mbs-form mbs-form-filter">

  <div class="form-row">

    <div class="col pt-2"
         [style.max-width]="screenX | smartSearchMaxWidth"
         [style.min-width]="'300px'">

      <mbs-smart-search [accessUrlQuery]="false"
                        [disabled]="loading$ | async"
                        (search)="handleUpdateFilter($event)"
                        [data]="searchTemplates"></mbs-smart-search>
    </div>

    <div class="col-12 col-md-auto pt-2">

      <mbs-select [items]="modes"
                  [style.width]="screenX > 768 ? '160px' : 'auto'"
                  [(ngModel)]="filterModeModel"
                  (ngModelChange)="handleChangeMode($event)"
                  [ngModelOptions]="{ standalone: true }"
                  [placeholder]="moduleUsers + ':placeholder.autoActivation' | i18next: { format: 'title' }"
                  [disabledSelf]="loading$ | async"
                  class="d-block pb-2"
                  appendTo="body"
                  [searchable]="false"
                  [clearable]="true"
                  [id]="'AutoActivationMode'"></mbs-select>

    </div>

    <div class="col pt-2">
      <div class="form-row align-items-center justify-content-end">
        <div class="col-auto">
          <mbs-button [disabled]="loading$ | async"
                      [loading]="loadingNewGroup$ | async"
                      (click)="handleAutoActiveAction(AutoActiveActionType.All)">
            {{
              isGoogle
                ? (moduleButton + ':button.newUnitAutoActivation' | i18next: { format: 'title' })
                : (moduleButton + ':button.newGroupAutoActivation' | i18next: { format: 'title' })
            }}
          </mbs-button>
        </div>
      </div>
    </div>

  </div>

</form>

<mbs-table-grid bindSelected="Id"
                classesTable="mb-0"
                [showSelectAllHint]="true"
                [selectedItems]="selectedItems"
                [showTableCheckboxes]="true"
                [multipleSelect]="true"
                [selectable]="true"
                [stickyHeader]="true"
                [headers]="headers"
                [loading]="loading$ | async"
                [data]="data$ | async"
                [viewMode]="viewMode"
                [serverSidePagination]="true"
                [paginationOptions]="paginationOptions"
                [switcherView]="false"
                [changeSortState]="orderBy"
                [showRefreshButton]="true"
                [showNav]="true"
                [disableViewSwitch]="true"
                [maxHeight]="maxHeight$ | async"
                [myTrackBy]="trackBy"
                [selectAllButtonText]="paginationOptions.dataSize | selectGridAllButtonText : oneEntityKey : manyEntitiesKey"
                [selectedCountText]="selectedCount | selectGridButtonText : oneEntityKey : manyEntitiesKey : isSelectAllOnAllPages"
                (changeSelected)="handleSelect($event)"
                (pageChange)="handlePageChange($event)"
                (pageSizeChange)="handlePageSizeChange($event)"
                (refresh)="fetchData()"
                (sort)="handleSort($event)"
                (selectAllOnAllPages)="isSelectAllOnAllPages = $event"
                #mbsTableGridRef>

  <ng-container *ngIf="selectedItems.length !== 0; else NoSelected">

    <ng-template mbsTableFilter>
      <mbs-button [disabled]="loading$ | async"
                  [size]="MbsSize.sm"
                  [isCtrl]="true"
                  [loading]="loadingEnabledAllGroupMembers$ | async"
                  (click)="handleAutoActiveAction(AutoActiveActionType.EditAutoActivationMode)">
        {{ moduleUsers + ':grid-nav.editAutoActivationMode' | i18next: { format: 'title' } }}
      </mbs-button>
    </ng-template>

  </ng-container>

  <ng-template #NoSelected>
    <ng-template mbsTableFilter="label">
      {{
        isGoogle
          ? (moduleUsers + ':grid.allUnits' | i18next: { format: 'capitalize' })
          : (moduleUsers + ':grid.allGroups' | i18next: { format: 'capitalize' })
      }}: {{ paginationOptions.dataSize }}
    </ng-template>
  </ng-template>

  <ng-template mbsTableCell
               let-group>
    <mbs-text-ellipsis [tooltip]="group?.Name"
                       placement="top-left auto"
                       tooltipClass="tooltip-lg">
      {{ isGoogle ? group?.Path || group?.Name : group?.Name }}
    </mbs-text-ellipsis>
  </ng-template>

  <ng-template mbsTableCell
               let-group>

    {{ group.AutoActivationMode | autoActivationMode }}

  </ng-template>

  <ng-template mbsTableCell
               let-group>

    <mbs-button [icon]="'fa fa-pencil text-sm'"
                [type]="'secondary'"
                container="body"
                placement="top-right auto"
                triggers="mouseenter:mouseleave"
                [ngbTooltip]="moduleUsers + ':tooltip.editSetting' | i18next: { format: 'capitalize' }"
                (click)="handleGroupChange(group)"></mbs-button>

  </ng-template>

</mbs-table-grid>
