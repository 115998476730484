import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import IMask from 'imask';
import moment from 'moment';
import { MaskedOptions, MaskElement } from '../index';
import { isNil } from 'lodash';
import { TIME_12_FORMAT_WITHOUT_SECONDS_REGEXP } from '../../utils/constants';
import { InputBase } from '../input-base/input-base';
import { DOMEvent, InputClasses, ValidClasses } from '../input-base/input-base.model';
/**
 * Component that provides a timepicker. Based on
 * <a href="https://ng-bootstrap.github.io/#/components/timepicker/examples" target="_blank">NgbTimepicker</a>
 * and <a href="https://ng-bootstrap.github.io/#/components/dropdown/examples" target="_blank">NgbDropdown</a>
 */
@Component({
  selector: 'app-timepicker,mbs-timepicker',
  templateUrl: 'timepicker.component.html',
  host: {
    class: 'mbs-timepicker'
  }
})
export class TimepickerComponent extends InputBase<string> implements OnInit, OnDestroy, ControlValueAccessor {
  /**
   * @private time formats for moment.js
   */
  private momentFormat12 = 'hh:mm A';
  private momentFormat24 = 'HH:mm';
  private lastValidState = '12:00 AM';
  private testRegExp = new RegExp(TIME_12_FORMAT_WITHOUT_SECONDS_REGEXP);
  protected labelContentClassesDefault = 'mbs-timepicker_label-content';

  @Input() imaskOptions?: MaskedOptions;
  @Input() unmask?: boolean | 'typed';
  @Input() imaskElement: (elementRef: ElementRef<MaskElement>, directiveRef: any) => MaskElement = (elementRef) => elementRef.nativeElement;
  @ViewChild('dropdown', { static: false }) dropdown: NgbDropdown;
  /**
   * Whether to display 12H or 24H mode. 12H if `true`. <br>
   * Input always displays value in 24H mode.
   */
  @Input() meridian = false;
  /**
   * If `true`, it is possible to select seconds
   */
  @Input() showSeconds = false;
  /**
   *  CSS-Class to customize dropdown
   */
  @Input() dropdownClass: string;
  /**
   * The preferred placement of the dropdown.
   * See details in NgBootstrap <a href="https://ng-bootstrap.github.io/#/components/dropdown/api" target="_blank">docs</a>
   */
  @Input() placement: PlacementArray = 'bottom-left';
  /**
   * If `true`, the spinners above and below inputs are visible. (only for dropdown)
   */
  @Input() spinners = true;
  /**
   * The number of hours to add/subtract when clicking hours spinners.
   */
  @Input() hourStep = 1;
  /**
   * The number of minutes to add/subtract when clicking minutes spinners.
   */
  @Input() minuteStep = 1;
  /**
   * The number of seconds to add/subtract when clicking seconds spinners.
   */
  @Input() secondStep = 1;
  /**
   * Show clear button
   */
  @Input() clearButton = false;
  /**
   * Show spinner
   */
  @Input() loading = false;
  /**
   * if [isErrorsShow]="false" we hidden mbs-input-errors
   */
  @Input() isShowErrors = true;

  @Input() needChangeInvalid = false;
  /**
   * Add font-weight-bold;
   */
  @Input() public boldLabel = false;
  /**
   * @ignore
   */
  @Output() change = new EventEmitter();
  /**
   * @ignore
   */
  @Output() buttonClickPrepend = new EventEmitter();
  /**
   * @ignore
   */
  @Output() buttonClickAppend = new EventEmitter();

  /**
   * dynamic setting converted value in form
   * @param {string}time
   */
  public set value(time: string) {
    let newTime = 'invalid time format';
    if (this.testRegExp.test(time)) {
      newTime = this.convertTime(time, this.momentFormat12, this.momentFormat24);
      if (this.needChangeInvalid) this.lastValidState = time;
      this.myValue = time;
    } else {
      if (this.needChangeInvalid) this.myValue = this.lastValidState;
      else this.myValue = time;
      newTime = this.convertTime(this.myValue, this.momentFormat12, this.momentFormat24);
    }
    if (this.onChange) {
      this.onChange(newTime);
    }
    this.cd.markForCheck();
    this.change.emit(newTime);
  }

  /**
   * get converted time from input
   */
  public get value(): string {
    return this.myValue;
  }

  /**
   * @ignore
   */
  public get bindClasses(): string[] {
    const classesObject: InputClasses = Object.assign<Record<string, boolean>, ValidClasses>(
      { '-clearable': this.clearButton || this.loading },
      this.validClasses
    );
    return Object.entries(classesObject)
      .filter(([k, v]) => !!v)
      .map(([k]) => k)
      .concat([this.sizeClass]);
  }

  constructor(@Optional() @Self() ngControl: NgControl, protected cd: ChangeDetectorRef) {
    super(ngControl, cd);
    /**
     * The options of imask time format.
     * See details here https://imask.js.org/guide.html#masked-base
     */
    this.imaskOptions = {
      mask: this.momentFormat12,
      overwrite: true,
      autofix: true,
      lazy: false,
      blocks: {
        HH: {
          mask: IMask.MaskedRange,
          placeholderChar: '_',
          from: 0,
          to: 23,
          maxLength: 2
        } as IMask.AnyMaskedOptions,
        hh: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12,
          placeholderChar: '_'
        } as IMask.AnyMaskedOptions,
        mm: {
          mask: IMask.MaskedRange,
          placeholderChar: '_',
          from: 0,
          to: 59,
          maxLength: 2
        } as IMask.AnyMaskedOptions,
        A: {
          mask: IMask.MaskedEnum,
          enum: ['AM', 'am', 'PM', 'pm', 'aM', 'Am', 'pM', 'Pm']
        } as IMask.AnyMaskedOptions
      }
    };
  }

  ngOnInit(): void {
    if (this.label && !this.id) {
      console.error('Timepicker requires Id if label in use');
    }
  }

  ngOnDestroy(): void {
    if (this.dropdown) {
      this.dropdown.ngOnDestroy();
    }
  }

  timePickerBlurHandler(event: DOMEvent<HTMLInputElement>): void {
    if (this.needChangeInvalid) {
      event.target.value = this.lastValidState;
      this.value = this.lastValidState;
    }
    this.handleBlur(event);
  }

  /**
   * first time setting converted value in initialized input
   * @param {string}time
   */
  writeValue(time: string): void {
    if (!isNil(time)) {
      const newTime = this.convertTime(time, this.momentFormat24, this.momentFormat12);
      setTimeout(() => {
        if (this.needChangeInvalid && this.testRegExp.test(newTime)) this.lastValidState = newTime;
      });
      super.writeValue(newTime);
    } else super.writeValue(this.value);
  }

  /**
   * Convert time from current format to another format using moment.js
   * @param {string} time - current time
   * @param {string} from - current time format
   * @param {to} to - format in which the time will be converted
   * @return {string}
   */
  convertTime(time: string, from: string, to: string): string {
    return moment(time, from, false).format(to);
  }

  handleChange(event: DOMEvent<HTMLInputElement>): void {
    // replacing extra symbol from imask and making in upperCase am/pm to AM/PM
    this.value = event.target.value.replace('_', '').toUpperCase();
  }
}
