<mbs-modal [title]="modulePayments + ':modal.title.' + (isEditMode ? 'editPlan' : 'comparePlans') | i18next: { format: 'title' }">

  <ng-container modal-body
                *ngIf="planInfo$ | async as planInfo">

    <ng-container *ngIf="!loading$.value && isSelectMode">

      <ng-container *ngIf="hasUnsubscribedPlan; else elsePlanBlock">

        <mbs-alert [type]="MbsPopupType.warning"
                   [icon]="true"
                   class="mb-3">
          <span>
            {{ modulePayments + ':modal.youHaveUnsubscribed.before' | i18next: { format: 'capitalize' } }}
            <span class="font-weight-semibold">{{ planInfo.Name }}</span>
            {{ modulePayments + ':modal.youHaveUnsubscribed.after' | i18next }}
            <span class="font-weight-semibold">{{ planInfo.ExpireDate | date: DateFormat.mediumDate }}</span>.
          </span>
          <br>
          {{ modulePayments + ':modal.aNewSubscription' | i18next: { format: 'capitalize' } }}
        </mbs-alert>

        <p class="mb-3 text-md">
          {{ modulePayments + ':modal.hereYouCan' | i18next: { format: 'capitalize' } }}. <br>
          <span>
            {{ modulePayments + ':modal.afterExpire.before' | i18next: { format: 'capitalize' } }}
            <span class="font-weight-semibold">{{ planInfo.ExpireDate | date: DateFormat.mediumDate }}</span>
            {{ modulePayments + ':modal.afterExpire.after' | i18next }}
          </span>
        </p>

      </ng-container>

      <ng-template #elsePlanBlock>

        <ng-container *ngIf="planInfo.ActivatedPlanId; else elseHasActiveBlock">

          <mbs-alert [type]="MbsPopupType.warning"
                     [icon]="true"
                     class="mb-3">
            {{ modulePayments + ':modal.youAlreadyHaveASubscription' | i18next: { format: 'capitalize' } }}
            <span class="font-weight-semibold">{{ tariffDateConfirmModal | date: DateFormat.mediumDate }}</span>. <br>
            {{ modulePayments + ':modal.toPickUpAnother' | i18next: { format: 'capitalize' } }}. <br>
            {{ modulePayments + ':modal.newSubscriptionPlan' | i18next: { format: 'capitalize' } }}
          </mbs-alert>

          <p class="mb-3 text-md">
            {{ modulePayments + ':modal.hereYouCan' | i18next: { format: 'capitalize' } }}. <br>
            {{ modulePayments + ':modal.useEditSubscriptionButton.use' | i18next: { format: 'capitalize' } }}
            <mbs-button type="primary"
                        [size]="MbsSize.md"
                        [isCtrl]="true"
                        [disabled]="loading$ | async"
                        (click)="handleRedirectToEditSubscription()">
              {{ modulePayments + ':button.editSubscription' | i18next: { format: 'title' } }}
            </mbs-button>
            {{ modulePayments + ':modal.useEditSubscriptionButton.toEdit' | i18next }}
          </p>

        </ng-container>

        <ng-template #elseHasActiveBlock>
          <p class="mb-3 text-md">
            {{ modulePayments + ':modal.hereYouCan' | i18next: { format: 'capitalize' } }}. <br>
            {{ modulePayments + ':modal.pickUpASubscription' | i18next: { format: 'capitalize' } }}
          </p>
        </ng-template>

      </ng-template>

    </ng-container>

    <ng-container *ngIf="!loading$.value && isEditMode && hasUnsubscribedPlan">
      <mbs-alert [type]="MbsPopupType.warning"
                 [icon]="true"
                 class="mb-3">
        <span>
          This subscription is cancelled by <span class="font-weight-semibold">{{ (user$ | async).Name }}</span> and is valid only until <span class="font-weight-semibold">{{ planInfo.ExpireDate | date: DateFormat.mediumDate }}</span>. <br />
          During this period, you can add users to this subscription plan, if necessary
        </span>
      </mbs-alert>
    </ng-container>

    <mbs-table-grid class="mb-3"
                    bindSelected="Id"
                    [selectable]="false"
                    [data]="tariffs$ | async"
                    [loading]="loading$ | async"
                    [headers]="headers"
                    [showRefreshButton]="false"
                    [disableViewSwitch]="true"
                    [switcherView]="false"
                    [viewMode]="viewMode">

      <ng-template mbsTableCell
                   let-tariff>

        <span *ngIf="tariff.Subscribed; else elseBlockName"
              class="font-weight-semibold">
          {{ tariff.Name }}
        </span>

        <ng-template #elseBlockName>
          {{ tariff.Name }}
        </ng-template>

        <span *ngIf="tariff.Description"
              class="ico ico-InfoCircle cursor-help text-info text-xs"
              container="body"
              tooltipClass="tooltip-lg"
              placement="top-left auto"
              [ngbTooltip]="tariff.Description"></span>

      </ng-template>

      <ng-template *ngIf="!(isHomeUser$ | async)"
                   mbsTableCell
                   let-tariff>
        {{ tariff.CentPriceUserPerMonth | priceToDisplay | currency: 'USD' }}
      </ng-template>

      <ng-template *ngIf="!(isHomeUser$ | async)"
                   mbsTableCell
                   let-tariff>

        <ng-container *ngIf="isSelectMode">
          <mbs-number [min]="tariff.CurrentActivatedUsers"
                      [max]="999"
                      [disabled]="tariff.Subscribed"
                      [(ngModel)]="tariff.AdditionalUsers"
                      [autocomplete]="'off'"></mbs-number>
        </ng-container>

        <ng-container *ngIf="isEditMode">
          <mbs-number [min]="0"
                      [max]="999"
                      [(ngModel)]="tariff.AdditionalUsers"
                      [autocomplete]="'off'"></mbs-number>
        </ng-container>

      </ng-template>

      <ng-template *ngIf="!(isHomeUser$ | async)"
                   mbsTableCell
                   let-tariff>

        <ng-container *ngIf="isSelectMode">
          <mbs-checkbox [disabled]="tariff.Subscribed"
                        [(ngModel)]="tariff.ServicePaidTransformed"></mbs-checkbox>
        </ng-container>

        <ng-container *ngIf="isEditMode">
          <mbs-checkbox [disabled]="isServicePurchased(tariff)"
                        [(ngModel)]="tariff.ServicePaidTransformed"></mbs-checkbox>
        </ng-container>
      </ng-template>

      <ng-template mbsTableCell
                   let-tariff>

        <ng-container *ngIf="isSelectMode">
          {{ totalPrice(tariff, false) | priceToDisplay | currency: 'USD' }}
        </ng-container>

        <ng-container *ngIf="isEditMode">
          {{ totalPriceAdditionalPurchase(tariff, false) | priceToDisplay | currency: 'USD' }}
        </ng-container>

      </ng-template>

      <ng-template *ngIf="!(isHomeUser$ | async) && discount$ | async"
                   mbsTableCell
                   let-tariff>

        <ng-container *ngIf="isSelectMode">
          {{ totalPrice(tariff, true) | priceToDisplay | currency: 'USD' }}
        </ng-container>

        <ng-container *ngIf="isEditMode">
          {{ totalPriceAdditionalPurchase(tariff, true) | priceToDisplay | currency: 'USD' }}
        </ng-container>

      </ng-template>

      <ng-template mbsTableCell
                   let-tariff>

        <ng-container *ngIf="isSelectMode">

          <ng-container *ngIf="tariff.Subscribed; else elseUnsubscribeBlock">
            <mbs-button class="text-center"
                        customClasses="h-100"
                        [type]="'danger'"
                        [block]="true"
                        [size]="MbsSize.sm"
                        (click)="handleToggleSubscribe(tariff)">
              {{ modulePayments + ':modal.button.unsubscribe' | i18next: { format: 'title' } }}
            </mbs-button>
          </ng-container>

          <ng-template #elseUnsubscribeBlock>
            <mbs-button class="text-center"
                        [disabled]="hasSubscribedPlan(tariffs$ | async) || hasUnsubscribedPlan"
                        [type]="hasSubscribedPlan(tariffs$ | async) ? 'secondary' : 'success'"
                        [block]="true"
                        [size]="MbsSize.sm"
                        (click)="handleToggleSubscribe(tariff)">
              {{ modulePayments + ':modal.button.subscribe' | i18next: { format: 'title' } }}
            </mbs-button>
          </ng-template>

        </ng-container>

        <ng-container *ngIf="isEditMode">
          <mbs-button [type]="'success'"
                      [ngbTooltip]="modulePayments + ':modal.tooltip.purchase' | i18next: { format: 'title' }"
                      container="body"
                      placement="top-right auto"
                      [disabled]="disabledPurchaseButton(tariff)"
                      [icon]="'fa fa-shopping-cart'"
                      (click)="handleAdditionalPurchase(tariff)"></mbs-button>
        </ng-container>

      </ng-template>

    </mbs-table-grid>

    <div class="row">

      <div class="col-6">

        <p *ngIf="planInfo.CloudBerryDiscountState !== null"
           class="text-md mb-0">

          {{ modulePayments + ':modal.nonProfit' | i18next: { format: 'capitalize' } }}:

          <ng-container [ngSwitch]="true">

            <ng-template [ngSwitchCase]="planInfo.CloudBerryDiscountState === CloudBerryDiscountState.approved">
              <span *ngLet="discount$ | async as discount"
                    class="text-success">
                {{ modulePayments + ':modal.state.discountApproved' | i18next: { format: 'title', discount: discount ? '-'  + discount : 0 } }}
              </span>
            </ng-template>

            <ng-template [ngSwitchCase]="planInfo.CloudBerryDiscountState === CloudBerryDiscountState.notFound">
              <a [href]="planInfo.CloudBerryDiscountUrl"
                 target="_blank"
                 class="text-primary">
                {{ modulePayments + ':modal.button.submitRequest' | i18next: { format: 'capitalize' } }}
              </a>
            </ng-template>

            <ng-template [ngSwitchCase]="planInfo.CloudBerryDiscountState === CloudBerryDiscountState.pending">
              <span class="text-info">
                {{ modulePayments + ':modal.state.pending' | i18next: { format: 'capitalize' } }}
              </span>
            </ng-template>

            <ng-template [ngSwitchCase]="planInfo.CloudBerryDiscountState === CloudBerryDiscountState.notApproved">
              <span class="text-info">
                {{ modulePayments + ':modal.state.notApproved' | i18next: { format: 'capitalize' } }}
              </span>
            </ng-template>

          </ng-container>

        </p>

      </div>

      <div class="col-6">

        <p *ngIf="isEditMode"
           class="mb-0 text-md">
          {{ modulePayments + ':modal.userLimit' | i18next: { format: 'title' } }}
          <span class="ico ico-InfoCircle cursor-help text-info text-xs"
                container="body"
                tooltipClass="tooltip-lg"
                placement="top-left auto"
                [ngbTooltip]="modulePayments + ':modal.tooltip.numberOfUsers' | i18next: { format: 'capitalize' }"></span>:
          <span class="font-weight-semibold mr-1">{{ planInfo.UserLimit || 0 }}</span>
        </p>

        <p *ngIf="!loading$.value && isEditMode && !hasUnsubscribedPlan"
           class="mb-0 text-md">
          Next <span class="font-weight-semibold mr-1">{{ totalPriceNextPeriod(tariffs$ | async, !!discount$.value) | priceToDisplay | currency: 'USD' }}</span>
          <ng-container *ngIf="discount$.value">(with discount <span class="font-weight-semibold">{{ discount$.value }}%</span>)</ng-container>
          payment will be charged on
          <span class="font-weight-semibold text-nowrap">{{ planInfo.ExpireDate | date: DateFormat.mediumDate }}</span>
        </p>

      </div>

    </div>

  </ng-container>

</mbs-modal>

<ng-template #unsubscribeConfirmTemplate>

  <p class="text-md mb-3">
    {{ modulePayments + ':modal.body.confirmUnsubscribeRequest' | i18next: { format: 'capitalize' } }} <span class="font-weight-semibold">{{ tariffNameConfirmModal }}</span>?
  </p>

  <mbs-alert [type]="MbsPopupType.warning"
             [icon]="true"
             class="mb-0">
    {{ modulePayments + ':modal.alert.confirmUnsubscribeRequest.before' | i18next: { format: 'capitalize' } }}
    <span class="font-weight-semibold">{{ tariffDateConfirmModal | date: DateFormat.mediumDate }}</span>.
    {{ modulePayments + ':modal.alert.confirmUnsubscribeRequest.after' | i18next: { format: 'capitalize' } }}
  </mbs-alert>

</ng-template>
