import { DOCUMENT } from '@angular/common';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NgbDropdown, NgbDropdownMenu } from '@ng-bootstrap/ng-bootstrap';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CODE_DOWN, CODE_END, CODE_ENTER, CODE_ESCAPE, CODE_HOME, CODE_LEFT, CODE_RIGHT, CODE_UP, CODE_Z } from 'keycode-js';
import { assign, debounce, isNil, keyBy } from 'lodash';
import { asyncScheduler, BehaviorSubject, combineLatest, fromEvent, merge, Observable, of, scheduled, Subject, Subscription } from 'rxjs';
import { debounce as rxDebounce, debounceTime, distinctUntilChanged, filter, map, shareReplay } from 'rxjs/operators';
import { MbsSize } from '../utils';
import { INVALID_CLASS, SmartSearchCharacters, TAG_CLASS } from './constants';
import { HighlightHelper } from './highlight-helper';
import {
  ActionType,
  CachedValueType,
  ModelTemplate,
  ModelTemplateInternal,
  SmartSearchHashtag,
  SmartSearchKeywordType,
  SmartSearchModel,
  SmartSearchModelField,
  SmartSearchState,
  SmartSearchTag,
  SmartSearchValidationState,
  SmartSearchWord
} from './models';
import { SmartSearchConfig } from './smart-search-config';
import { SmartSearchKeywordDirective } from './smart-search-keyword.directive';
import { SmartSearchParser } from './smart-search-parser';
import { getClearTerm, getNormalizeTerm } from './utils';

export const ADVANCED_SEARCH_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line no-use-before-define
  useExisting: forwardRef(() => SmartSearchComponent),
  multi: true
};

export const highlightHelperFactory = (renderer: Renderer2) => new HighlightHelper(renderer);

export type AutocompleteDataType = {
  template?: TemplateRef<any>;
  value?: string | number;
  formatter: (value: any) => string;
  isKeyword?: boolean;
  actionType?: ActionType;
  addGroupBrackets?: boolean;
  skipSpaceAfterValue?: boolean;
};

const bottomLeftStr = 'bottom-left';

@UntilDestroy()
@Component({
  selector: 'mbs-smart-search',
  templateUrl: './smart-search.component.html',
  providers: [
    ADVANCED_SEARCH_VALUE_ACCESSOR,
    {
      provide: HighlightHelper,
      useFactory: highlightHelperFactory,
      deps: [Renderer2]
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmartSearchComponent implements OnInit, OnDestroy, ControlValueAccessor, AfterContentInit, OnChanges, AfterViewInit {
  @ViewChild('ngbDropdownMenuRef', { static: true, read: NgbDropdownMenu }) ngbDropdownMenu: NgbDropdownMenu;
  @ViewChild('ngbDropdownMenuFormRef', { static: true, read: NgbDropdownMenu }) ngbDropdownMenuForm: NgbDropdownMenu;
  @ViewChild('smartSearch', { static: true, read: ElementRef }) smartSearch: ElementRef;
  @ViewChild('formControlRef', { static: true, read: ElementRef }) formControlRef: ElementRef;
  @ViewChild('toggleButtonRef', { static: false, read: ElementRef }) toggleButtonRef: ElementRef;

  /**
   * Customization keyword templates
   */
  @ContentChildren(SmartSearchKeywordDirective, { descendants: false }) tagTemplates: QueryList<SmartSearchKeywordDirective>;

  @Input() disabled = false;

  /**
   * Debounce time handle events
   * Affected:
   * * input
   * * keyup
   */
  @Input() debounceTime = 300;

  /**
   * dropdown height value.
   */
  @Input() dropdownHeight: string | number;

  /**
   * `true` - read and edit url query
   * `false` - ignore url query
   */
  @Input() accessUrlQuery = true;

  /**
   * if `true` - search box will NOT include tags that already presented in search field into dropdown.
   * If user write duplicate tag manually it will be ignored;
   */
  @Input() disableMultiTag = true;

  /**
   * Search icon class
   */
  @Input() searchIcon = 'ico ico-Search';
  @Input() public size: MbsSize.xxs | MbsSize.xs | MbsSize.sm | MbsSize.md | MbsSize.lg = null;
  @Input() needClearOnDestroy = false;
  @Input() data: ModelTemplate<any>[] = [];
  @Input() searchFormTemplate: TemplateRef<any>;
  @Input() placeholder = 'Enter search request';
  @Input() updateOn: 'blur' | 'change' = 'blur';
  @Input() placementNgbDropdown: PlacementArray = bottomLeftStr;
  @Input() placementNgbDropdownForm: PlacementArray = bottomLeftStr;
  @Input() manualRouteHandling = false;

  /**
   * Loading state when autocomplete items fetching
   */
  public searching$ = new BehaviorSubject<boolean>(false);

  /**
   * Fire on `keypress.enter` and click `button[name="smartSearch-search"]`
   */
  @Output() search = new EventEmitter<SmartSearchModel>();
  @Output() searchingChange = this.searching$.asObservable();
  @Output() routeChanged = new EventEmitter<string>();

  public readonly MbsSize = MbsSize;
  public state: Partial<SmartSearchState> = {};
  public items$ = new BehaviorSubject<AutocompleteDataType[]>([]);
  public elementSelectors = {
    css: {
      input: 'mbs-smart-search_content',
      placeholder: 'mbs-smart-search_placeholder'
    }
  };

  /**
   * Current index for selected item in list of autocomplete
   */
  public selectedItemIndex = -1;

  /**
   * Show or hide
   */
  public showDropdown$: Observable<boolean>;

  public get caretNode(): Element {
    if (!this.state || Object.keys(this.state).length === 0) return null;

    const caret = this.state.caretNode as Element;

    return this.isPreviousContentEqualGroupBracket(caret) ? caret.previousElementSibling : caret;
  }

  public set value(value: SmartSearchModel) {
    this.myModel = value;
    this.notifyValueChange();
  }

  public get value(): SmartSearchModel {
    return this.myModel;
  }

  public get searchContent(): string {
    return this.searchDiv.textContent;
  }

  public set searchContent(newTextContent: string) {
    if (newTextContent || newTextContent === '') {
      const content = this.createFragment(newTextContent, null, false);

      const fragment = this.doc.createDocumentFragment();
      fragment.append(...Array.from(this.searchDiv.children));

      this.appendChild(content);
      this.setCaretAfterNode(this.searchDiv.lastElementChild);
      this.updateState();
      this.showAutocomplete();
      this.cdr.detectChanges();
    }
  }

  public set modelValidationState(state: SmartSearchValidationState) {
    this.state.modelValidationState = state;
    this.cdr.markForCheck();
  }

  public get cachedValues(): CachedValueType[] {
    return this.cachedValueState;
  }

  public get isFocus(): boolean {
    return this.doc.activeElement === this.searchDiv;
  }

  /**
   * Autocomplete items
   */
  public get items(): AutocompleteDataType[] {
    return this.myItems;
  }

  public set items(value: AutocompleteDataType[]) {
    this.items$.next(value);
    this.myItems = value;
  }

  /**
   * Required for cancel previous request before start next
   */
  public subscription: Subscription;

  /**
   * Flag for parsing textContent
   */
  public hasTextContentChanges = true;

  public get ngbDropdownAnchorLeftStyle(): string {
    if (this.placementNgbDropdownForm === bottomLeftStr) {
      /** -1 is shift to left.
       *   4 is sum borders width **/
      return (this.formControlRef?.nativeElement.offsetWidth - this.toggleButtonRef?.nativeElement.offsetWidth - 4) * -1 + 'px';
    } else {
      return '0px';
    }
  }

  /**
   * callback queue
   */
  private queueOnUpdateState$ = new Subject<{ type: string; callback: () => void }>();
  private dropdownsMenus: NgbDropdownMenu[];
  private mbsSelectOpenedNgOptions: HTMLElement[] = [];
  private cachedValueState: CachedValueType[] = [];
  private myModel: SmartSearchModel;
  private myItems: AutocompleteDataType[] = [];
  private focus$: Observable<Event>;
  private onUpdateState$ = new Subject<SmartSearchState>();
  private queueFocus$ = new Subject<boolean>();
  private onPasteValue$ = new Subject<number>();
  private keywordTemplates: ModelTemplateInternal[] = [];
  private history: Array<string> = [];
  private readonly sequenceMultipleActions: AutocompleteDataType[] = [
    {
      value: SmartSearchCharacters.SEQUENCE,
      formatter: () => SmartSearchCharacters.SEQUENCE,
      actionType: ActionType.Comma
    } as AutocompleteDataType,
    {
      value: SmartSearchCharacters.SPACE,
      formatter: () => SmartSearchCharacters.SPACE,
      actionType: ActionType.Space,
      skipSpaceAfterValue: true
    } as AutocompleteDataType
  ];

  private get searchDiv(): HTMLDivElement {
    return this.smartSearch.nativeElement as HTMLDivElement;
  }

  /**
   * For prevent update model and blur after mousedown on select item
   */
  private isMouseSelectValue = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    @Inject(DOCUMENT) private doc: Document,
    private renderer2: Renderer2,
    private config: SmartSearchConfig,
    private highlight: HighlightHelper,
    private cdr: ChangeDetectorRef
  ) {}

  onChange: (value) => void;
  onTouched: () => void;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  tagsFormatter = (val: any) => `${String(val.tag)}:`;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  hashtagsFormatter = (val: any) => String(val.tag);

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    if (!(event.target instanceof Window)) {
      return;
    }

    if (this.isOpenedDropdownMenu(this.ngbDropdownMenuForm)) {
      queueMicrotask(() => {
        this.cdr.markForCheck();
      });
    }
  }

  ngOnInit(): void {
    this.dropdownsMenus = [this.ngbDropdownMenu, this.ngbDropdownMenuForm];
    const callbacks: Observable<{ type: string; callback: () => void }>[] = [];

    callbacks.push(
      this.queueOnUpdateState$.pipe(
        filter((q) => q.type === 'handleUpdateModel'),
        rxDebounce(() => this.onUpdateState$)
      )
    );

    callbacks.push(
      this.queueOnUpdateState$.pipe(
        filter((q) => q.type === 'showAutocomplete'),
        rxDebounce(() => this.onUpdateState$)
      )
    );

    scheduled(merge(...callbacks), asyncScheduler)
      .pipe(untilDestroyed(this))
      .subscribe((q: { type: string; callback: () => void }) => q.callback());

    const sharePasteValue$ = this.onPasteValue$.pipe(shareReplay());

    scheduled(combineLatest([this.onPasteValue$, this.queueFocus$]), asyncScheduler)
      .pipe(untilDestroyed(this))
      .subscribe(([currentCaret]) => {
        this.isFocus || this.searchDiv.focus();
        this.setCaretAfterNode(currentCaret);
      });
  }

  ngOnDestroy(): void {
    this.needClearOnDestroy && this.handleClear();
  }

  ngAfterContentInit(): void {
    this.tagTemplates.changes
      .pipe(untilDestroyed(this))
      .subscribe((t: SmartSearchKeywordDirective[]) => this.mergeTagTemplates(this.data, t));

    this.mergeTagTemplates(this.data, this.tagTemplates.toArray());

    if (this.accessUrlQuery) {
      this.searchContent = this.route.snapshot.queryParamMap.get('search') || '';
      this.handleUpdateModel();
    } else {
      this.updateState();
    }
  }

  ngAfterViewInit(): void {
    const input$ = fromEvent(this.searchDiv, 'input');
    const keyup$ = fromEvent<KeyboardEvent>(this.searchDiv, 'keyup');
    const keydown$ = fromEvent<KeyboardEvent>(this.searchDiv, 'keydown');
    const keyupEnter$ = keyup$.pipe(filter((event) => event.key === CODE_ENTER));

    input$
      .pipe(
        filter((event: InputEvent) => event.inputType === 'insertText'),
        untilDestroyed(this)
      )
      .subscribe((event) => {
        this.insertText(event.data);
        this.hasTextContentChanges = true;
        this.cdr.markForCheck();
      });

    input$.pipe(debounceTime(this.debounceTime), untilDestroyed(this)).subscribe((event: InputEvent) => this.handleInput(event));

    keyup$.pipe(untilDestroyed(this)).subscribe((event) => {
      const text = this.searchDiv.textContent;

      if (event.key === CODE_ENTER) {
        event.preventDefault();
        event.stopPropagation();

        return void this.handleEnter(event);
      }

      if (event.key === CODE_ESCAPE) {
        return void this.searchDiv.blur();
      }

      if (event.key === CODE_UP || event.key === CODE_DOWN) {
        return void this.handleArrowUpDown(event);
      }

      if (event.code === CODE_Z && (event.ctrlKey || event.metaKey)) {
        return void queueMicrotask(() => {
          this.handleUndo();
        });
      }

      if (this.history[this.history.length - 1] !== text) {
        this.history.push(this.searchDiv.textContent);
      }
    });

    keyup$
      .pipe(
        debounceTime(this.debounceTime), // prettier
        untilDestroyed(this)
      )
      .subscribe((event) => this.handleKeyboardUp(event));

    keydown$
      .pipe(
        filter((event) => [CODE_UP, CODE_DOWN, CODE_ENTER].includes(event.key)),
        untilDestroyed(this)
      )
      .subscribe((event) => event.preventDefault());

    this.focus$ = fromEvent(this.searchDiv, 'focus');
    this.focus$.pipe(untilDestroyed(this)).subscribe(() => {
      this.updateState();
      this.showAutocomplete();
    });

    fromEvent<ClipboardEvent>(this.searchDiv, 'paste')
      .pipe(untilDestroyed(this))
      .subscribe((event) => {
        this.handlePaste(event);
        this.history.push(this.searchDiv.textContent);
      });

    fromEvent<ClipboardEvent>(this.searchDiv, 'cut')
      .pipe(untilDestroyed(this))
      .subscribe((event) => {
        this.handleCut(event);
        this.history.push(this.searchDiv.textContent);
      });

    this.showDropdown$ = merge(
      of(false), // prettier
      keyupEnter$.pipe(map(() => false)),
      fromEvent(this.searchDiv, 'blur').pipe(map(() => false)),
      this.items$.pipe(map((items) => items.length > 0))
    ).pipe(distinctUntilChanged());

    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data?.currentValue) {
      this.mergeTagTemplates(changes.data.currentValue as ModelTemplate<any>[], this.tagTemplates ? this.tagTemplates.toArray() : []);
    }
  }

  placeholderClick(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.ngbDropdownMenu && !this.isOpenedDropdownMenu(this.ngbDropdownMenu) && (this.ngbDropdownMenu.dropdown as NgbDropdown).toggle();
    this.searchDiv.focus();
  }

  isOpenedDropdownMenu(menu: NgbDropdownMenu): boolean {
    return menu && this.isOpenedNgbDropdownMenu(menu.dropdown as NgbDropdown);
  }

  notifyValueChange(): void {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  writeValue(model: SmartSearchModel): void {
    if (!model) return;

    this.myModel = model;

    this.searchContent = this.stringifySmartSearchModel(model);

    this.hasTextContentChanges = true;
  }

  registerOnChange(fn: (value) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  mergeTagTemplates(inputTemplates: ModelTemplate<any>[], contentTemplates: SmartSearchKeywordDirective[]): void {
    inputTemplates = inputTemplates.map((tag) => {
      const model = tag as ModelTemplateInternal;

      model.tagFormatter = model.isHashtag ? this.hashtagsFormatter : this.tagsFormatter;

      return model;
    });

    if (contentTemplates && contentTemplates.length > 0) {
      const templates = inputTemplates.concat(
        contentTemplates.map((tag) => {
          const model = tag.toModelTemplate() as ModelTemplateInternal;

          model.tagFormatter = model.isHashtag ? this.hashtagsFormatter : this.tagsFormatter;

          return model;
        })
      );

      // unique templates
      this.keywordTemplates = Object.values(keyBy(templates, 'tag'));
    } else {
      this.keywordTemplates = Array.from(inputTemplates);
    }
  }

  /**
   * Used for serialization modal to string
   * @param {SmartSearchModel} model
   * @return {string}
   */
  stringifySmartSearchModel(model: SmartSearchModel): string {
    return Object.keys(model)
      .map((key) => {
        if (key.startsWith('#')) return key;

        const data = model[key] as SmartSearchModelField[];
        const value = data
          .map((d) => {
            const value = d.value.split(/\s+/).length > 1 ? `{${d.value}}` : d.value;

            return (d.condition || '') + value;
          })
          .join(', ');

        return key ? `${key}: ${value}` : value;
      })
      .join(' ');
  }

  handleInput(event: InputEvent): void {
    switch (event.inputType) {
      case 'deleteContentBackward':
      case 'deleteContentForward':
        this.updateContenteditableDiv();
        this.hasTextContentChanges = true;
        break;
      default:
        break;
    }

    this.showAutocomplete();

    if (this.updateOn === 'change') {
      this.handleUpdateModel();
    }

    this.updateState();
  }

  handlePaste(event: ClipboardEvent): void {
    event.preventDefault();

    const paste = ((event.clipboardData || window['clipboardData']) as DataTransfer).getData('text');
    const selection = this.doc.getSelection();
    const range = selection.getRangeAt(0);

    if (range.toString()) {
      this.removeSelectedRangeElements(range.startContainer, range.endContainer);
    }

    const pasteFragment = this.createFragment(paste, null, false);
    const lastChild = pasteFragment.lastChild;
    const currentCaret = this.state.caretNode;

    if (currentCaret?.nextSibling) {
      this.insertBefore(pasteFragment, currentCaret.nextSibling);
    } else {
      this.appendChild(pasteFragment);
    }

    this.hasTextContentChanges = true;
    this.cdr.detectChanges();
    this.setCaretAfterNode(lastChild);
  }

  handleCut(event: ClipboardEvent): void {
    event.preventDefault();

    const selection = this.doc.getSelection();

    if (selection.rangeCount === 0) {
      return;
    }

    const range = selection.getRangeAt(0);

    if (!range.toString()) {
      return;
    }

    this.removeSelectedRangeElements(range.startContainer, range.endContainer);

    this.hasTextContentChanges = true;
  }

  updateContenteditableDiv(): void {
    Array.from(this.searchDiv.childNodes).forEach((node) => this.removeBlockElement(node));

    this.updateState();
    this.setCaretAfterNode(this.state.caretIndex);
  }

  removeBlockElement(n: ChildNode): Element[] {
    // extract all children
    if (n instanceof HTMLDivElement) {
      const onlySpanWithText = Array.from(n.children).filter((childNode) => {
        const firstChild = childNode.childNodes.item(0);

        return firstChild && firstChild.nodeName === '#text';
      });
      const fragment = this.doc.createDocumentFragment();

      fragment.append(...onlySpanWithText);
      this.insertBefore(fragment, n);
      this.removeChild(n);

      return onlySpanWithText;
    } else if (!(n instanceof HTMLSpanElement) || n.childNodes.item(0) instanceof HTMLBRElement) {
      this.removeChild(n);
    }

    return [];
  }

  getValidationStateClasses(): string {
    let result = '';

    if (this.state.modelValidationState === SmartSearchValidationState.Valid) {
      result = `ng-state-valid`;
    }

    if (this.state.modelValidationState === SmartSearchValidationState.Invalid) {
      result = `ng-state-invalid`;
    }

    return result;
  }

  /**
   * After keypress and before handle the input event the searchDiv has child nodes.
   * Get textContent from selected range.
   *
   * In current implementation ignore {data} parameters
   *
   * @param {string} data
   * @return {HTMLSpanElement} last inserted element
   */
  insertText(data: string): HTMLSpanElement {
    const range: Range = this.doc.getSelection().getRangeAt(0);
    // keep endOffset before Range update by ref
    const endOffset = range.endOffset - 1;

    const endElement = this.getParentSpan(range.endContainer) as Element;
    const span = this.createText(endElement.textContent);
    const spanArray = span instanceof DocumentFragment ? Array.from(span.children) : [span];

    this.insertBefore(span, endElement);
    this.removeChild(endElement);

    const lastChild = spanArray[endOffset];

    for (let i = 0; i < this.searchDiv.children.length; i++) {
      const element = this.searchDiv.children[i];

      this.removeBlockElement(element);
    }

    this.setCaretAfterNode(lastChild);

    return lastChild as HTMLSpanElement;
  }

  handleBlur(): void {
    this.highlight.highlightWords(this.state.model);

    if (!this.isMouseSelectValue) {
      this.handleUpdateModel();
    }

    this.isMouseSelectValue = false;
  }

  handleClear(): void {
    const fragment = this.doc.createDocumentFragment();

    fragment.append(...Array.from(this.searchDiv.children));

    this.hasTextContentChanges = true;
    this.handleUpdateModel();
    this.handleRoute(); // may be it's not need because inside handleUpdateModel(), method handleRoute() method will be called
  }

  handleDrop(event: DragEvent): void {
    event.preventDefault();

    const paste = event.dataTransfer.getData('text');

    if (!paste) {
      return;
    }

    const pasteFragment = this.createFragment(paste, null, false);
    const lastChild = pasteFragment.lastChild;
    const currentCaret = this.state.caretNode;

    if (currentCaret && currentCaret.nextSibling) {
      this.insertBefore(pasteFragment, currentCaret.nextSibling);
    } else {
      this.appendChild(pasteFragment);
    }

    this.hasTextContentChanges = true;
    this.queueFocus$.next(null);

    this.setCaretAfterNode(lastChild);
  }

  handleArrowUpDown(event: KeyboardEvent): void {
    switch (event.key) {
      case CODE_UP:
        if (Number.isNaN(this.selectedItemIndex)) {
          this.selectedItemIndex = -1;
        }

        event.preventDefault();
        this.selectedItemIndex = (this.items.length + this.selectedItemIndex - 1) % this.items.length;
        break;
      case CODE_DOWN:
        if (Number.isNaN(this.selectedItemIndex)) {
          this.selectedItemIndex = this.items.length - 1;
        }

        event.preventDefault();
        this.selectedItemIndex = (this.selectedItemIndex + 1) % this.items.length;
        break;
    }

    this.cdr.detectChanges();
  }

  private handleUndo(): void {
    this.history.pop(); // remove last item from history

    const newData = this.history[this.history.length - 1] || '';
    const pasteFragment = this.createFragment(newData, null, false);
    const lastChild = pasteFragment.lastChild;

    this.removeAllChild();
    this.appendChild(pasteFragment);
    this.hasTextContentChanges = true;
    this.queueFocus$.next(null);

    this.setCaretAfterNode(lastChild);

    this.cdr.detectChanges();
    this.updateState();

    if (this.items) {
      this.showAutocomplete();
    }
  }

  handleKeyboardUp(event: KeyboardEvent): void {
    if (![CODE_HOME, CODE_END, CODE_LEFT, CODE_RIGHT].includes(event.key)) return;

    switch (event.key) {
      case CODE_HOME:
        this.setCaretToTheBeginning();
        break;
      case CODE_END:
        this.setCaretToTheEnd();
        break;
    }

    this.showAutocomplete();
    this.updateState();
  }

  handleEnter(event: KeyboardEvent): void {
    // if selected some value
    if (this.items[this.selectedItemIndex] && event instanceof KeyboardEvent) {
      this.queueFocus$.next(null);
      this.pasteValue(this.items[this.selectedItemIndex]);
      this.hasTextContentChanges = true;
      this.showAutocomplete();
      this.updateState();
    } else {
      this.handleUpdateModel();
      this.searchDiv.blur();
    }
  }

  handleUpdateModel(): void {
    // put to queue before update state
    this.queueOnUpdateState$.next({
      type: 'handleUpdateModel',
      callback: () => {
        this.value = this.config.convertModel(this.state.model);

        this.handleRoute();
        this.search.emit(this.value);
      }
    });

    this.updateState();
  }

  handleRoute(): void {
    if (!this.accessUrlQuery) return;

    const search = this.searchDiv.textContent || null;
    // write url query
    const opts: NavigationExtras = {
      relativeTo: this.route,
      queryParamsHandling: 'merge',
      queryParams: { search }
    };

    this.routeChanged.emit(search);

    if (this.manualRouteHandling) return;

    void this.router.navigate([], opts);
  }

  private getParentSpan(node: Node): Node {
    let span = node;

    let counter = 0;
    while (span && span.parentElement !== this.searchDiv) {
      span = span.parentElement;

      counter++;
      if (counter > 100 || !span) {
        throw new Error('Failed find parent for current node');
      }
    }

    return span;
  }

  private updateState = debounce(this.updateStateImmediate.bind(this), 100);

  private updateStateImmediate(): void {
    const { element, index } = this.getCaretNodeInfo();

    this.state.caretNode = element;
    this.state.caretIndex = index;

    if (this.hasTextContentChanges) {
      this.state.model = this.parseSearchContent();
      this.checkActualCachedState();
      this.highlight.highlightNodes(this.state.model);
      this.hasTextContentChanges = false;
    }

    this.state.usedTags = this.state.model
      .filter((m) => m.type === 'tag') // prettier
      .map((m: SmartSearchTag) => m.tagTemplate.tag.toLowerCase());

    const [caretPart, condition] = this.getLeftCaretPart();

    this.state.leftCaretValue = getNormalizeTerm(caretPart);
    this.state.leftCaretClearValue = getClearTerm(this.state.leftCaretValue);
    this.state.leftCaretCondition = condition;

    this.state.availableTags = this.getAvailableTags();

    this.onUpdateState$.next(this.state as SmartSearchState);
  }

  private getLeftCaretPart(): string[] {
    const available = this.getAvailable();

    if (!available) return ['', ''];

    const caret = this.caretNode;
    let leftPart = '';
    let condition: string;

    if (available.type === 'tag') {
      const tagModel = available as SmartSearchTag;
      const tagIndex = tagModel.tagElements.indexOf(caret);

      if (tagIndex > -1 && tagIndex !== tagModel.tagElements.length - 1) {
        leftPart = tagModel.tagElements
          .slice(0, tagIndex + 1)
          .map((el) => el.textContent)
          .join('');
      }

      let elemIndex: number;
      const field = tagModel.fields.find((f) => {
        elemIndex = f.fieldElements.indexOf(caret);

        return elemIndex > -1;
      });

      if (field) {
        condition = field.conditionValue;

        leftPart = field.fieldElements
          .slice(0, elemIndex + 1)
          .map((el) => el.textContent)
          .join('');
      }
    }

    if (available.type === 'word') {
      const word = available as SmartSearchWord;
      const wordIndex = word.elements.indexOf(caret);

      if (wordIndex > -1) {
        condition = word.conditionValue;

        leftPart = available.elements
          .slice(0, wordIndex + 1)
          .map((el) => el.textContent)
          .join('');
      }
    }

    if (available.type === 'hashtag') {
      const hashtag = available as SmartSearchHashtag;
      const hashIndex = hashtag.elements.indexOf(caret);

      if (hashIndex > -1) {
        leftPart = available.elements
          .slice(0, hashIndex + 1)
          .map((el) => el.textContent)
          .join('');
      }
    }

    return [leftPart, condition];
  }

  private getAvailable(): SmartSearchKeywordType {
    return this.state.model.find((m) => m.elements.includes(this.caretNode));
  }

  /**
   * Find and split incorrect search string
   * @param {string} source
   * @return {string} correct part
   */
  private getCorrectSearchPart(source: string): string {
    let workSource = source;
    let invalidPart = '';

    const allGroupChars = Array.from(workSource)
      .map((char, idx) => (char === SmartSearchCharacters.GROUP_LEFT || char === SmartSearchCharacters.GROUP_RIGHT ? [char, idx] : null))
      .filter(Boolean);

    for (let i = 0; i < allGroupChars.length; i++) {
      const current: any[] = allGroupChars[i];
      const nextElement: any[] = allGroupChars[i + 1];

      if (current[0] === SmartSearchCharacters.GROUP_LEFT && (!nextElement || nextElement[0] !== SmartSearchCharacters.GROUP_RIGHT)) {
        // group isn`t closed. search string incorrect
        invalidPart = workSource.substring(current[1] as number);
        // substring correct part
        workSource = workSource.substring(0, current[1] - 1);

        break;
      }
    }

    if (invalidPart) {
      const invalidStart = source.indexOf(invalidPart);
      const invalidElements = this.getRangeElements(invalidStart, source.length);

      this.highlight.replaceClassInRange(invalidElements, INVALID_CLASS);
    }

    this.state.invalid = !!invalidPart;

    const correctElements = this.getRangeElements(0, workSource.length);

    this.highlight.removeClassInRange(correctElements, INVALID_CLASS);

    return workSource;
  }

  /**
   * Parse search content to SmartSearchModel and return it
   * @param {string} searchContent
   * @return {SmartSearchModel}
   */
  public getSearchModelByContent(searchContent: string): SmartSearchModel {
    const models = this.parseSearchContent(searchContent);

    return this.config.convertModel<SmartSearchModel>(models);
  }

  /**
   * Parse search content to internal model
   * @param {string} content
   * @return {SmartSearchKeywordType[]}
   */
  private parseSearchContent(content = this.searchDiv.textContent): SmartSearchKeywordType[] {
    const source = getNormalizeTerm(content);

    const correctSource = this.getCorrectSearchPart(source);

    const regRaw = '(^|\\s)(' + this.keywordTemplates.map((k) => k.tagFormatter(k)).join('|') + ')';
    const regRegex = new RegExp(regRaw, 'gi');

    const allMatched: RegExpExecArray[] = [];

    for (let match = regRegex.exec(correctSource); match; match = regRegex.exec(correctSource)) {
      allMatched.push(match);
    }

    const models: SmartSearchKeywordType[] = [];

    const correctElements = this.getRangeElements(0, correctSource.length);

    for (let index = 0; index < allMatched.length; index++) {
      const match = allMatched[index];

      // first match and has left part
      if (match.index > 0 && models.length === 0) {
        const wordPart = correctSource.substring(0, match.index);
        const wordPartElements = correctElements.slice(0, wordPart.length);
        models.push(...SmartSearchParser.parseWords(wordPart, wordPartElements));
      }

      const tagLower = match[2].toLocaleLowerCase();
      const model = this.keywordTemplates.find((k) => k.tagFormatter(k).toLocaleLowerCase() === tagLower);
      const previousSameModel = models.find((m) => m.type === 'tag' && (m as SmartSearchTag).tagTemplate === model);

      if (model.isMultiple !== true && previousSameModel) {
        continue;
      }

      const nextMatch = allMatched[index + 1];

      const tagIndex = correctSource.toLocaleLowerCase().indexOf(tagLower, match.index);
      const sourcePart = correctSource.substring(tagIndex, nextMatch ? nextMatch.index : correctSource.length);
      const sourceElements = correctElements.slice(tagIndex, tagIndex + sourcePart.length);

      models.push(...SmartSearchParser.parseTag(model, sourcePart, sourceElements));
    }

    if (correctSource && allMatched.length == 0) {
      models.push(...SmartSearchParser.parseWords(correctSource, correctElements));
    }

    return models;
  }

  /**
   * Get range of element in search div
   * @param {number} start start index
   * @param {number} end end index
   * @return {Element[]}
   */
  private getRangeElements(start: number, end: number): Element[] {
    const elements: Element[] = [];

    for (let i = start; i < end; i++) {
      elements.push(this.searchDiv.children.item(i));
    }

    return elements;
  }

  /**
   * Remove selection spans
   * @param {Node} start
   * @param {Node} end
   */
  private removeSelectedRangeElements(start: Node, end: Node): void {
    const startSpan = this.getParentSpan(start);
    const endSpan = this.getParentSpan(end);

    const removeSpan = [startSpan, endSpan];

    for (let node = startSpan.nextSibling; node && node != endSpan && node.nextSibling; node = node.nextSibling) {
      removeSpan.push(node);
    }

    removeSpan.forEach((span) => this.removeChild(span));
  }

  /**
   * Get available tags with ModelTemplateInternal.isMultiple value
   * @return {ModelTemplateInternal[]}
   */
  private getAvailableTags(): ModelTemplateInternal[] {
    const usedTagSet = this.state.usedTags;
    const leftPart = this.state.leftCaretValue;

    return this.keywordTemplates.filter((t) => {
      if (t.isMultiple !== true && usedTagSet.includes(t.tag.toLowerCase())) {
        return false;
      }

      return t.tag.toLowerCase().includes(leftPart.toLowerCase());
    });
  }

  /**
   * Get node under caret.
   * Return null if caret haven't previousElement
   * @return {{ element: Node, index: number }}
   */
  private getCaretNodeInfo(): { element: Node; index: number } {
    const selection = window.getSelection();

    if (selection.rangeCount === 0) {
      return { element: null, index: 0 };
    }

    const range = selection.getRangeAt(0);
    let span: Node = range.endContainer;

    if (this.searchDiv === span) {
      span = this.searchDiv.childNodes.item(range.endOffset);
    }

    let nodeIndex = Math.max(0, this.searchDiv.childElementCount - 1);

    const isNodeOrEmpty = !isNil(span?.nodeType) || isNil(span);

    if (isNodeOrEmpty && !this.searchDiv.contains(span)) {
      return { element: this.searchDiv.lastElementChild, index: nodeIndex };
    }

    let debugCounter = 0;

    while (span && this.searchDiv != span.parentElement) {
      span = span.parentElement;
      debugCounter++;

      if (debugCounter > 20 || !span) {
        throw new Error('Failed find parent for current node');
      }
    }

    nodeIndex = Array.from(this.searchDiv.childNodes).indexOf(span as ChildNode);

    return { element: range.endOffset === 0 ? null : span, index: nodeIndex };
  }

  /**
   * Push to queue
   */
  showAutocomplete(): void {
    this.queueOnUpdateState$.next({ type: 'showAutocomplete', callback: () => this.showAutocompleteImmediate() });
  }

  /**
   * Parse content and show autocomplete hints
   */
  showAutocompleteImmediate(): void {
    // We know that caretNode is HTMLSpanElement
    // caretNode have `Node` type for typecheck in other cases
    let caretElement: Element = this.state.caretNode as Element;

    this.items = [];
    this.searching$.next(false);

    const availableTags = () => {
      this.items = (this.state.availableTags || []).map((it) => {
        const item = it as AutocompleteDataType;

        item.formatter = it.tagFormatter;
        item.isKeyword = true;

        return item;
      });
      this.searching$.next(false);
    };

    // if nothing left
    if (!caretElement) {
      availableTags();

      return;
    }

    const matchedItems = (tagModel: SmartSearchTag): void => {
      this.searching$.next(true);

      // unsubscribe from last request
      if (this.subscription) {
        this.subscription.unsubscribe();
        this.subscription = undefined;
      }

      const tag = tagModel.tagTemplate;

      this.subscription = tag.items(this.state as SmartSearchState).subscribe({
        next: (items) => {
          const result: AutocompleteDataType[] = [];
          const model = this.state.model.find((m) => m.elements.includes(caretElement));

          if (tag.isSequenceMultiple && tagModel.completed) {
            result.push(...this.sequenceMultipleActions.map((item) => assign(item, { template: tag.template })));
          }

          result.push(
            ...items
              .filter((it: AutocompleteDataType) =>
                tagModel.tagTemplate.filterByProp
                  ? !model?.['fields'].some((i) => i.fieldValue === it[tagModel.tagTemplate.filterByProp])
                  : it
              )
              .map((it: AutocompleteDataType) => {
                if (typeof it !== 'object') {
                  const newIt = {
                    value: it,
                    formatter: (item: { value: string }) => tag.itemFormatter(item.value)
                  } as AutocompleteDataType;

                  if (!isNil(tag.addGroupBrackets)) {
                    newIt.addGroupBrackets = typeof it === 'string' ? this.getAddGroupBrackets(it, tag) : tag.addGroupBrackets;
                  }

                  if (!isNil(tag.template)) newIt.template = tag.template;
                  if (!isNil(tag.isSequenceMultiple)) newIt.skipSpaceAfterValue = !!tag.isSequenceMultiple;

                  return newIt;
                }

                it.formatter = tag.itemFormatter;
                it.template = tag.template;
                it.skipSpaceAfterValue = !!tag.isSequenceMultiple;
                const value = it.formatter(it);
                it.addGroupBrackets = this.getAddGroupBrackets(value, tag);

                return it;
              })
          );
          this.items = result;
          this.searching$.next(false);
        },
        error: () => {
          this.items = [];
          this.searching$.next(false);
        }
      });
    };

    let available = this.state.model.find((m) => m.elements.includes(caretElement));

    if (!available && this.isPreviousContentEqualGroupBracket(caretElement)) {
      caretElement = caretElement.previousElementSibling;
      available = this.state.model.find((m) => m.elements.includes(caretElement));
    }

    // first match
    if (available) {
      const tagModel = available as SmartSearchTag;

      if (tagModel && tagModel.type === 'tag') {
        const tagIndex = tagModel.tagElements.indexOf(caretElement);

        if (
          (tagIndex > -1 && tagIndex === tagModel.tagElements.length - 1) ||
          tagModel.fields.find((f) => f.elements.includes(caretElement))
        ) {
          tagModel.tagTemplate.items && matchedItems(tagModel);
        } else {
          availableTags();
        }
      } else {
        availableTags();
      }
    } else {
      // if caret value space
      // try find nearest neighbor to the left
      while (!available && caretElement.previousElementSibling) {
        caretElement = caretElement.previousElementSibling;
        available = this.state.model.find((m) => m.elements.includes(caretElement));
      }

      const tagModel = available as SmartSearchTag;

      if (tagModel && tagModel.type === 'tag') {
        const elementIndex = tagModel.fields.findIndex((f) => f.elements.includes(caretElement));

        // caret after last field
        if (elementIndex === tagModel.fields.length - 1) {
          if (tagModel.completed) {
            availableTags();
          } else {
            tagModel.tagTemplate.items && matchedItems(tagModel);
          }
        }
      } else {
        availableTags();
      }
    }
  }

  private getAddGroupBrackets = (value: string, tag: ModelTemplateInternal): boolean =>
    value && tag.addGroupBrackets && value.includes(SmartSearchCharacters.SPACE);

  /**
   * Create text fragment or span
   * @param {string} value tag value
   * @param {boolean} space add the space at end
   * @return {DocumentFragment | HTMLSpanElement}
   */
  private createText(value: string, space = false): DocumentFragment | HTMLSpanElement {
    return this.createFragment(value, null, space);
  }

  /**
   * Create tag fragment
   * @param {string} keyword tag value
   * @param {boolean} space add the space at end
   * @return {DocumentFragment}
   */
  private createTag(keyword: string, space = true): DocumentFragment {
    return this.createFragment(keyword, TAG_CLASS, space);
  }

  /**
   * Create fragment with class
   * @param {string} raw source string
   * @param {string} spanClass style class
   * @param {boolean} space add the space at end
   * @return {DocumentFragment}
   */
  private createFragment(raw: string, spanClass: string = null, space = true): DocumentFragment {
    const fragment = this.doc.createDocumentFragment();

    Array.from(raw).forEach((k) => {
      const span: HTMLSpanElement = this.renderer2.createElement('span') as HTMLSpanElement;

      if (/\s/.test(k)) {
        span.insertAdjacentHTML('afterbegin', '&nbsp;');
      } else {
        span.insertAdjacentText('afterbegin', k);
      }

      spanClass && this.renderer2.addClass(span, spanClass);
      fragment.appendChild(span);
    });

    if (space) {
      const spaceSpan: HTMLSpanElement = this.renderer2.createElement('span') as HTMLSpanElement;
      spaceSpan.insertAdjacentHTML('afterbegin', '&nbsp;');
      fragment.appendChild(spaceSpan);
    }

    return fragment;
  }

  private setCaretAfterNode(index: number): void;
  private setCaretAfterNode(element: Node): void;
  private setCaretAfterNode(...args: any[]): void {
    let element = args[0] as Node;

    if (typeof element == 'number') {
      element = this.searchDiv.childNodes.item(element);
    }

    if (!this.isFocus || !element?.parentElement) {
      return;
    }

    const rangeSelection = this.doc.createRange();

    rangeSelection.setStartAfter(element);
    rangeSelection.setEndAfter(element);

    window.getSelection().removeAllRanges();
    window.getSelection().addRange(rangeSelection);
  }

  private setCaretToTheEnd(): void {
    const elementIndex = this.searchDiv.childNodes.length && this.searchDiv.childNodes.length - 1;

    this.setCaretAfterNode(elementIndex);
  }

  private setCaretToTheBeginning(): void {
    const element = this.searchDiv.childNodes.length && this.searchDiv.childNodes.item(0);

    if (!this.isFocus || !element?.parentElement) {
      return;
    }

    const rangeSelection = this.doc.createRange();

    rangeSelection.setStartBefore(element);
    rangeSelection.setEndBefore(element);

    window.getSelection().removeAllRanges();
    window.getSelection().addRange(rangeSelection);
  }

  /**
   * Insert value around caret
   * Call onSelect from dropdown menu
   * @param value source item
   */

  pasteValue(item: AutocompleteDataType): void {
    let currentCaret: Element = this.state.caretNode as Element;

    let available = this.state.model.find((m) => m.elements.includes(currentCaret));
    let skipGroupSymbols = false;

    if (!available && this.isPreviousContentEqualGroupBracket(currentCaret)) {
      skipGroupSymbols = true;
      currentCaret = currentCaret.previousElementSibling;
      available = this.state.model.find((m) => m.elements.includes(currentCaret));
    }

    const value = this.needWrapToBranch(item, skipGroupSymbols) ? this.wrapToBranch(item) : item.formatter(item);
    const valueNodes = item.isKeyword === true ? this.createTag(value) : this.createText(value, !item.skipSpaceAfterValue);

    !item.isKeyword && !item.actionType && this.prepareCachedItem(item);

    let removeElements: Element[] = [];

    if (available && !item.actionType) {
      if (available.type === 'tag') {
        const tags = available as SmartSearchTag;
        const tagIndex = tags.tagElements.indexOf(currentCaret);

        if (tagIndex > -1 && tagIndex + 1 < tags.tagElements.length) {
          removeElements = tags.tagElements;
        }

        const field = tags.fields.find((f) => f.elements.includes(currentCaret));

        if (field) {
          removeElements = field.elements;
        }
      }
      if (available.type === 'word') {
        removeElements = available.elements;
      }
    }

    if (item.actionType && skipGroupSymbols && currentCaret.nextElementSibling) {
      currentCaret = currentCaret.nextElementSibling;
      skipGroupSymbols = false;
    }

    let nextCaret = valueNodes instanceof DocumentFragment ? valueNodes.lastElementChild : valueNodes;

    // before append new nodes
    if (currentCaret && currentCaret.nextSibling) {
      this.insertBefore(valueNodes, currentCaret.nextSibling);
    } else {
      this.appendChild(valueNodes);
    }

    // elements will be moved and cleaned by GC
    this.doc.createDocumentFragment().append(...removeElements);

    if (skipGroupSymbols && nextCaret.nextElementSibling) {
      nextCaret = nextCaret.nextElementSibling;
    }

    const children = Array.from(this.searchDiv.childNodes);
    let nextCaretIndex = 0;

    for (let i = 0; i < children.length; i++) {
      const element = this.searchDiv.children[i];
      element.className = '';
      if (element === nextCaret) {
        nextCaretIndex = i;
      }
    }

    this.selectedItemIndex = -1;

    this.hasTextContentChanges = true;

    this.state.caretIndex = nextCaretIndex;
    this.onPasteValue$.next(nextCaretIndex);
  }

  private insertBefore(newChild: DocumentFragment | HTMLElement, refChild: HTMLElement | ChildNode): void {
    this.renderer2.insertBefore(this.searchDiv, newChild, refChild);
  }

  private appendChild(newChild: DocumentFragment | HTMLElement): void {
    this.renderer2.appendChild(this.searchDiv, newChild);
  }

  private removeChild(oldChild: HTMLElement | ChildNode | Node): void {
    this.renderer2.removeChild(this.searchDiv, oldChild);
  }

  private removeAllChild(): void {
    Array.from(this.searchDiv.childNodes).forEach((item) => this.removeChild(item));
  }

  private isPreviousContentEqualGroupBracket = (element: Element): boolean =>
    element && element.previousElementSibling && element.textContent === SmartSearchCharacters.GROUP_RIGHT;

  private needWrapToBranch = (item: AutocompleteDataType, skipGroupSymbols: boolean): boolean =>
    !item.actionType && !skipGroupSymbols && item.addGroupBrackets && !item.isKeyword;

  private wrapToBranch = (item: AutocompleteDataType): string =>
    `${SmartSearchCharacters.GROUP_LEFT}${item.formatter(item)}${SmartSearchCharacters.GROUP_RIGHT}`;

  private prepareCachedItem(item: AutocompleteDataType): void {
    let currentCaret: Element = this.state.caretNode as Element;
    let available = this.state.model.find((m) => m.elements.includes(currentCaret));

    while (!available && currentCaret.previousElementSibling) {
      currentCaret = currentCaret.previousElementSibling;
      available = this.state.model.find((m) => m.elements.includes(currentCaret));
    }

    const tagModel = available as SmartSearchTag;

    tagModel &&
      tagModel.tagTemplate &&
      tagModel.tagTemplate.enableCacheStorage &&
      tagModel.type === 'tag' &&
      this.addItemToCache(tagModel.tagTemplate.tag, item.formatter(item), item);
  }

  private addItemToCache(tag: string, searchValue: string, value): void {
    if (
      tag &&
      searchValue &&
      value &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      value.cachedValue &&
      !this.cachedValueState.some((elem) => elem.tag === tag && elem.searchValue === searchValue)
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
      this.cachedValueState.push({ tag, searchValue, value: value.cachedValue });
    }
  }

  private checkActualCachedState(): void {
    const model = this.state.model;

    this.cachedValueState = this.cachedValueState.filter((value) =>
      model.some(
        (modelTag) =>
          modelTag.type === 'tag' &&
          (modelTag as SmartSearchTag).tagValue === value.tag &&
          (modelTag as SmartSearchTag).fields.some((field) => field.fieldValue === value.searchValue)
      )
    );
  }

  /**
   * Handle mousedown click on list of autocomplete value
   * @param {AutocompleteDataType} item selected value
   */
  handleClickSelectedValue(item: AutocompleteDataType): void {
    this.isMouseSelectValue = true;
    this.selectedItemIndex = -1;

    this.queueFocus$.next(null);
    this.pasteValue(item);
    this.updateState();

    this.history.push(this.searchDiv.textContent);

    this.showAutocomplete();
  }

  @HostListener('document:mousedown', ['$event.target'])
  closeDropdownMenu(target: HTMLElement): void {
    this.isClickedOutsideNgbDropdown(target) &&
      this.dropdownsMenus
        .filter((menu) => !!menu && this.isClickedOutsideNgbDropdownMenu(target, menu))
        .forEach((menu) => (menu.dropdown as NgbDropdown).close());
  }

  private isClickedOutsideNgbDropdown(target: HTMLElement): boolean {
    return (
      !this.isToggleButtonClicked(target) &&
      !this.isMbsDatepickerClicked(target) &&
      !this.isMbsTimepickerClicked(target) &&
      !this.isMbsSelectCloseCtrlClicked(target) &&
      !this.isPlaceholderClicked(target)
    );
  }

  private isToggleButtonClicked = (target: HTMLElement): boolean => !!target.closest('[ngbDropdownToggle]');

  private isMbsDatepickerClicked = (target: HTMLElement): boolean => target.closest('ngb-datepicker') && !target.closest('mbs-datepicker');

  private isMbsTimepickerClicked = (target: HTMLElement): boolean => target.closest('ngb-timepicker') && !target.closest('mbs-timepicker');

  private isMbsSelectCloseCtrlClicked = (target: HTMLElement): boolean => target.classList.contains('ng-clear-wrapper'); // the target haven't parent element

  private isPlaceholderClicked = (target: HTMLElement): boolean => !!target.closest('.mbs-smart-search_placeholder');

  private isClickedOutsideNgbDropdownMenu(target: HTMLElement, menu: NgbDropdownMenu): boolean {
    const dropdown = menu.dropdown as NgbDropdown;

    return (
      this.isOpenedNgbDropdownMenu(dropdown) && !this.isMbsSelectOptionClicked(target, dropdown) && !menu.nativeElement.contains(target)
    );
  }

  private isOpenedNgbDropdownMenu(dropdown: NgbDropdown): boolean {
    return dropdown._open;
  }

  private isMbsSelectOptionClicked(target: HTMLElement, dropdown: NgbDropdown): boolean {
    const ngOptions: HTMLElement[] = this.ngbDropdownMenuNgSelectOptions(dropdown);

    this.mbsSelectOpenedNgOptions = ngOptions.length > 0 ? ngOptions : this.mbsSelectOpenedNgOptions;

    return this.mbsSelectOpenedNgOptions.some((option) => option.contains(target));
  }

  private ngbDropdownMenuNgSelectOptions(dropdown: NgbDropdown): HTMLElement[] {
    const dropdownRef = dropdown['_elementRef'] as ElementRef<HTMLElement>;
    const container: string = dropdownRef.nativeElement.getAttribute('container');

    if (container === 'body') {
      return Array.from(this.doc.querySelectorAll('body > ng-dropdown-panel .ng-option'));
    } else {
      return Array.from(dropdownRef.nativeElement.querySelectorAll('ng-select.ng-select-opened ng-dropdown-panel .ng-option'));
    }
  }
}
