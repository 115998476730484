import { DOCUMENT } from '@angular/common';
import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  RendererFactory2
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { addBreadcrumb, SeverityLevel } from '@sentry/browser';
import { Observable } from 'rxjs';
import { ButtonType } from '../button';
import { isTemplate, MbsSize } from '../utils';
import { ModalFooterDirective } from './modal-footer.directive';

export type ModalSize = MbsSize.sm | MbsSize.md | MbsSize.lg | MbsSize.xl;

/**
 * Component that wrapping content into a `ngb-modal`
 */
@Component({
  selector: 'app-modal, mbs-modal',
  templateUrl: './modal.component.html'
})
export class ModalComponent implements OnInit, OnDestroy {
  private expandedClass = '-fullScreen';
  private modalDialog: HTMLElement;
  private readonly renderer: Renderer2;

  /**
   * Modal context
   */
  public context: any;
  /**
   * Expanded/collapsed modal state.
   */
  public isExpanded = false;

  /**
   * Text title in header modal
   */
  @Input()
  title: string;

  /**
   * Class for icon in header modal
   */
  @Input()
  titleIconClass: string;

  /**
   * Text for resolve button
   */
  @Input()
  okButtonText = 'Save';

  /**
   * Text for reject button
   */
  @Input()
  cancelButtonText = 'Close';

  /**
   * Color for resolve button
   */
  @Input()
  okButtonType: ButtonType = 'primary';

  /**
   * Disabled state resolve button
   */
  @Input()
  okButtonDisabled$: Observable<boolean>;

  /**
   * Loading state resolve button
   */
  @Input()
  okButtonLoading$: Observable<boolean>;

  /**
   * Will be inserted just as text to modal body. It's not templates context
   */
  @Input()
  message: string;

  /**
   * Header/string/HTML/Template<any>
   * 1) `string`: if set `HTML` or `string` from `modalSettings` in external component
   * 2) `Template<any>`: if set `TemplateRef` from `modalSettings` in external component
   * 3) `null/undefined`: it's possible if using custom template in modal. Will be use ng-content by [select]
   * 4) class Header: if `modalSettings` have prop `header`. It's default behavior
   */
  @Input()
  header: any;

  /**
   * Context for insert to template modal body
   */
  @Input()
  body: any;

  /**
   * Displaying footer modal
   */
  @Input()
  showFooter = true;

  /**
   * Displaying resolve button
   */
  @Input()
  showOkButton = true;

  /**
   * Displaying reject button
   */
  @Input()
  showCancelButton = true;

  /**
   * Modal size.
   * <br>
   * Possible values: `sm` | `md` | `lg` | `xl`.
   * <br>
   * You can use `enum` MbsSize
   */
  @Input()
  size: ModalSize = MbsSize.sm;

  /**
   * Header size by padding (will be set CSS styles)
   * <br>
   * Possible values: `sm` | `lg`.
   * <br>
   * You can use `enum` MbsSize
   */
  @Input()
  headerSize: MbsSize.sm | MbsSize.lg;

  /**
   * If `true` - modal title will be truncated and will take one line <br />
   * If `false` (default) modal title can take more than one line
   */
  @Input()
  titleTextOverflow = false;

  /**
   * If `true` - modal will be using responsive size
   */
  @Input()
  responsive = false;

  /**
   * If you need to get some data from an external component inside the modal, you can pass it using the `data` in modalSettings
   */
  @Input()
  data: any;

  /**
   * If `true` - will be hiding default HTML and you can insert custom HTML into `<mbs-modal>...</mbs-modal>`
   */
  @Input()
  isCustomModal = false;

  /**
   * if `true` - will be showing `mbs-loader` instead of modal content
   */
  @Input()
  loading = false;

  /**
   * Displaying close modal button
   */
  @Input()
  showCloseCross = true;

  /**
   * Displaying button for expanding/collapsing modal
   */
  @Input() showExpandedCross = false;

  @Input() canExpand = false;

  @Input() bodyClasses = '';

  /**
   * Emit modal state from `isExpanded` property
   */
  @Output() expandedChange = new EventEmitter<boolean>();

  /**
   * Directive for generating custom footer
   */
  @ContentChild(ModalFooterDirective, { static: true }) customFooter: any;
  collapsing: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private element: ElementRef<HTMLElement>,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  ngOnInit(): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
    this.context = this.data && this.data.context ? Object.assign({}, this.data.context, this.activeModal) : this.activeModal;

    if (this.isCustomModal) {
      this.element.nativeElement.parentElement.classList.add('modal-content-inner');
    }
    // TODO: need to understand why the .modal-dialog element was not found directly.
    if (this.canExpand || this.showExpandedCross) this.modalDialog = this.element.nativeElement.closest('.modal-content').parentElement;

    addBreadcrumb({
      category: 'modal',
      timestamp: new Date().getTime() / 1000,
      message: `Open modal '${this.title}'`,
      level: 'info' as SeverityLevel
    });
  }

  ngOnDestroy() {
    addBreadcrumb({
      category: 'modal',
      timestamp: new Date().getTime() / 1000,
      message: `Close modal '${this.title}'`,
      level: 'info' as SeverityLevel
    });
  }

  public get computedBodyClass() {
    return this.bodyClasses ? `${this.bodyClasses} modal-body` : 'modal-body';
  }

  /**
   * Closes current modal
   */
  public close(): void {
    this.renderer.removeAttribute(this.document.body, 'style');
    this.activeModal.dismiss(false);
  }

  /**
   * Closes the current modal after saving
   * @param {any} result
   */
  public save(result?: any): void {
    this.activeModal.close(result ? result : true);
  }

  /**
   * Get header size class with a hyphen added
   * @return {string}
   */
  getHeaderSizeClass(): string {
    return (this.headerSize ? '-' + this.headerSize : '') + ' ' + (this.header?.headerCustomClasses || '');
  }

  /**
   * Toggle expanding/collapsing modal and generating event emit
   */
  toggleExpand(): void {
    this.isExpanded = !this.isExpanded;
    this.renderer[this.isExpanded ? 'addClass' : 'removeClass'](this.modalDialog, this.expandedClass);

    const expandedOverflow = this.isExpanded ? 'overflow-y: hidden' : 'overflow-y: auto';
    this.renderer.setAttribute(this.document.body, 'style', expandedOverflow);

    this.expandedChange.emit(this.isExpanded);
  }

  /**
   * Check on `string` if set `HTML` or `string` from `modalSettings`
   * @return {boolean}
   */
  isHeaderSettingsString(): boolean {
    return typeof this.header === 'string';
  }

  /**
   * Check on `null/undefined` if set `TemplateRef<any>` from `modalSettings`
   * @return {boolean}
   */
  isHeaderSettingsTemplateRef(): boolean {
    return isTemplate(this.header);
  }
}
