import { Component } from '@angular/core';

@Component({
  selector: 'mbs-card-group',
  template: `
    <ng-content select="mbs-card-group-header"></ng-content>
    <ng-content></ng-content>
  `,
  host: {
    class: 'mbs-card_group'
  }
})
export class CardGroupComponent {}
