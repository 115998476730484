import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { APPEND_BUTTON_PASSWORD_STATE, PResolve } from '@app/common';
import { getAppendButtonsIcon, getAppendButtonsState, hasAppendButtonsPasswordType } from '@app/common/utils';
import { I18NextPipe } from 'angular-i18next';
import { ModalComponent } from 'mbs-ui-kit';
import { BehaviorSubject } from 'rxjs';

export interface ConfirmPasswordModalSettings {
  type: ConfirmPasswordModalType;
  beforeSave: (password: string) => Promise<PResolve>;
}

export enum ConfirmPasswordModalType {
  save = 'save',
  delete = 'delete'
}

@Component({
  selector: 'app-confirm-password-modal',
  templateUrl: './confirm-password-modal.component.html'
})
export class ConfirmPasswordModalComponent implements OnInit {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  public passwordType$: BehaviorSubject<string> = new BehaviorSubject(APPEND_BUTTON_PASSWORD_STATE.hidden.type);

  readonly #deleteText: string;
  readonly #saveText: string;

  private beforeSave: (password: string) => Promise<PResolve>;
  private type: ConfirmPasswordModalType;

  public readonly getAppendButtonsIcon = getAppendButtonsIcon;

  public title = '';
  public confirmForm = new UntypedFormGroup({
    password: new FormControl('', [Validators.required, Validators.maxLength(20)])
  });

  get saveButtonType(): 'primary' | 'danger' {
    return this.type === ConfirmPasswordModalType.delete ? 'danger' : 'primary';
  }

  get saveButtonText(): string {
    return this.type === ConfirmPasswordModalType.delete ? this.#deleteText : this.#saveText;
  }

  get passwordControl(): AbstractControl {
    return this.confirmForm.get('password');
  }

  constructor(private i18nPipe: I18NextPipe) {
    this.#deleteText = i18nPipe.transform('common.delete', { format: 'title' });
    this.#saveText = i18nPipe.transform('common.save', { format: 'title' });
  }

  ngOnInit(): void {
    const settings = this.baseModal.data as ConfirmPasswordModalSettings;
    const title = (this.baseModal.header as { title: string }).title;

    this.confirmForm.reset();
    this.beforeSave = settings?.beforeSave || (() => Promise.resolve({ resolve: true }));
    this.type = settings?.type || ConfirmPasswordModalType.save;

    if (title) {
      this.title = title;
    } else {
      this.title = this.type === ConfirmPasswordModalType.delete ? this.#deleteText : this.#saveText;
    }
  }

  async saveButtonClick(): Promise<void> {
    const save = await this.beforeSave(this.passwordControl.value);

    if (!save.resolve) {
      const ERROR_TEXT = save.error || this.i18nPipe.transform('common.validation.somethingWrong', { format: 'capitalize' });

      this.passwordControl.setValue('');
      this.passwordControl.setErrors({ passwordIncorrect: { message: ERROR_TEXT } });
    } else {
      this.baseModal.save();
    }
  }

  cancelButtonClick(): void {
    this.baseModal.close();
  }

  handleChangePasswordType(): void {
    const isPasswordType: boolean = hasAppendButtonsPasswordType(this.passwordType$.value);

    this.passwordType$.next(APPEND_BUTTON_PASSWORD_STATE[getAppendButtonsState(isPasswordType)].type);
  }
}
