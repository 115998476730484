import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { get, isNil } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { MbsSize } from '../utils';

export enum StatusIconType {
  'default' = 'fa fa-circle',
  'info' = 'fa fa-info-circle',
  'success' = 'fa fa-check-circle',
  'warning' = 'fa fa-exclamation-triangle',
  'danger' = 'fa fa-exclamation-circle'
}
@Component({
  selector: 'mbs-status',
  templateUrl: './status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': 'getCustomClasses'
  }
})
export class StatusComponent implements OnChanges, OnInit, AfterContentInit {
  @ViewChild('ngContentWrapperRef', { static: false, read: ElementRef }) ngContentWrapperRef: ElementRef<HTMLSpanElement>;
  @Input() disabled = false;
  @Input() clickable = true;
  @Input() size: MbsSize.sm | MbsSize.md | MbsSize.lg | '' = '';
  @Input() iconPosition: 'left' | 'right' = 'left';
  @Input() icon: StatusIconType | string = StatusIconType.default;
  @Input() type: 'success' | 'info' | 'warning' | 'danger' | 'muted' | '' = 'success';
  @Input() textCustomClasses: string | string[] = '';
  @Input() textSize: MbsSize.sm | MbsSize.md | MbsSize.lg | '' = '';
  @Input('class') hostClasses = '';

  @Output() mbsClick = new EventEmitter<Event>();

  public iconClasses$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public textClasses$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public showStatusText$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(private cdRef: ChangeDetectorRef) {}

  @HostListener('click', ['$event'])
  handleClick($event: Event) {
    if (this.clickable) {
      this.mbsClick.emit($event);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.needUpdateIconClasses(changes)) this.updateIconClasses();
    if (this.needUpdateTextClasses(changes)) this.updateTextClasses();
  }

  private needUpdateIconClasses = (changes: SimpleChanges): boolean =>
    (changes.size && !changes.size.firstChange) ||
    (changes.icon && !changes.icon.firstChange) ||
    (changes.type && !changes.type.firstChange) ||
    (changes.disabled && !changes.disabled.firstChange);

  private needUpdateTextClasses = (changes: SimpleChanges): boolean =>
    (changes.textSize && !changes.textSize.firstChange) ||
    (changes.textCustomClasses && !changes.textCustomClasses.firstChange) ||
    (changes.disabled && !changes.disabled.firstChange);

  ngOnInit(): void {
    this.updateIconClasses();
    this.updateTextClasses();
    this.cdRef.detectChanges();
  }

  private updateIconClasses(): void {
    const iconClasses: string = this.getIconClasses();
    this.iconClasses$.next(iconClasses);
  }

  private updateTextClasses(): void {
    const textClasses: string = this.getTextClasses();
    this.textClasses$.next(textClasses);
  }

  private getIconClasses = (): string => {
    const classList: string[] = [];
    const sizeClass: string = this.getSizeClass();

    if (sizeClass) classList.push('-' + sizeClass);
    if (this.icon) classList.push(this.icon);
    if (this.type) classList.push('text-' + this.type);

    return classList.join(' ');
  };

  private getTextClasses = (): string => {
    const classList: string[] = [];

    if (this.textSize) classList.push('text-' + this.textSize);
    if (this.textCustomClasses) classList.push(this.getClassesAsString(this.textCustomClasses));

    return classList.join(' ');
  };

  private getSizeClass(): string {
    if (!this.validateSizeClass() || !this.size) return '';

    const key: string | undefined = Object.keys(MbsSize).find((key: string) => key === this.size);
    return isNil(key) ? '' : String(MbsSize[key]);
  }

  private validateSizeClass(): boolean {
    return !isNil(this.size) && typeof this.size === 'string' && this.size.length > 0;
  }

  private getClassesAsString = (classList: string | string[]): string => {
    if (classList instanceof Array || Array.isArray(classList)) {
      const parsedClassList: string = classList.join(' ');
      return this.getClassesWithoutJustEmptySpace(parsedClassList);
    } else if (typeof classList === 'string') {
      return this.getClassesWithoutJustEmptySpace(classList);
    } else {
      return '';
    }
  };

  private getClassesWithoutJustEmptySpace = (str: string): string => {
    return this.isJustEmptySpace(str) ? '' : str;
  };

  private isJustEmptySpace = (str: string): boolean => {
    return new RegExp(/^\s+$/).test(str);
  };

  public get getCustomClasses(): string {
    const classList: string[] = ['mbs-status'];
    const sizeClass: string = this.getSizeClass();

    this.updateShowStatusText();

    if (this.hostClasses) classList.push(this.getClassesAsString(this.hostClasses));
    if (sizeClass) classList.push('-' + sizeClass);
    if (this.iconPosition === 'right' && this.showStatusText$.getValue()) classList.push('-reverse');
    if (this.disabled) classList.push('-disabled');
    if (this.clickable) classList.push('-clickable');
    if (this.type && !this.disabled) classList.push('-' + this.type);

    return classList.join(' ');
  }

  ngAfterContentInit(): void {
    this.updateShowStatusText();
    this.cdRef.detectChanges();
  }

  private updateShowStatusText(): void {
    const isShow: boolean = this.checkNgContentStatusText();
    this.showStatusText$.next(isShow);
  }

  private checkNgContentStatusText(): boolean {
    return (get(this.ngContentWrapperRef, 'nativeElement.childNodes', []) as Node[]).length > 0;
  }
}
