import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { TableHeader } from '../table-grid/models/table-header';
import { isNil } from 'lodash';

@Component({
  selector: 'mbs-tile',
  templateUrl: './tile.component.html'
})
export class TileComponent {
  @Input() statusClass: string;
  @Input() data: any;
  @Input() disabled = false;

  private gridHeaders: TableHeader[];
  @Input()
  set headers(value: TableHeader[]) {
    this.gridHeaders = value.map((h, index) => Object.assign({ id: index }, h)).filter((h) => h.isGridColumn !== false);
  }

  get headers(): TableHeader[] {
    return this.gridHeaders;
  }

  @Input() showBody = true;
  @Input() icon: string;
  @Input() title: string;
  @Input() headerTemplate: ElementRef;
  @Input() tileTemplate: ElementRef;
  @Input() templates: ElementRef[];
  @Input() selected = false;
  @Input() selectable = false;
  @Input() selectedClass = '-selected';

  @Output() click = new EventEmitter();

  handleClick(event: MouseEvent): void {
    event.stopPropagation();
    if (this.selectable && !this.disabled) {
      this.selected = !this.selected;
    }
    this.click.emit({ ...this.data, event });
  }

  getTileClasses(): string {
    const classes = [];
    if (!isNil(this.statusClass)) {
      classes.push(this.statusClass);
    }
    if (this.selectable) {
      classes.push('-selectable');
    }
    if (this.disabled) {
      classes.push('-disabled');
    }
    if (this.selectable && this.selected) {
      classes.push(this.selectedClass);
    }
    return classes.join(' ');
  }
}
