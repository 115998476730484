<ng-template [ngTemplateOutlet]="errorsTemplate ? errorsTemplate : errorsDefault"
             [ngTemplateOutletContext]="{ $implicit: errors }"></ng-template>

<ng-template #errorsDefault
             let-errors>

  <ng-container *ngIf="errors.length > 0">

    <div class="invalid-feedback"
         *ngFor="let error of errors; trackBy: trackByMessage">
      {{ error.message }}
    </div>

  </ng-container>

</ng-template>
