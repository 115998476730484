import { HttpStatusCode } from '@angular/common/http';

export enum ErrorsEnum {
  // [HttpStatusCode(HttpStatusCode.InternalServerError)]
  // [Description("This kind of error is not classified. Probably the problem occurred because of the bad written code base.")]
  Uknown = 0,
  // [HttpStatusCode(HttpStatusCode.Forbidden)]
  // [Description("Two-factor authentication enforced by the provider is not configured for a SubAdmin")]
  Forced2FANotConfigured = 1,
  // [HttpStatusCode(HttpStatusCode.ServiceUnavailable)]
  // [Description("Error ocurred while recording a new entity to the database")]
  DatabaseAccessLayerCreatingError = 2,
  // [HttpStatusCode(HttpStatusCode.ServiceUnavailable)]
  // [Description("Error ocurred while reading an entity from the database")]
  DatabaseAccessLayerReadingError = 3,
  // [HttpStatusCode(HttpStatusCode.ServiceUnavailable)]
  // [Description("Error ocurred while updating an entity in the database")]
  DatabaseAccessLayerUpdatingError = 4,
  // [HttpStatusCode(HttpStatusCode.ServiceUnavailable)]
  // [Description("Error ocurred while deleting an entity from the database")]
  DatabaseAccessLayerDeletingError = 5,
  // [HttpStatusCode(HttpStatusCode.BadRequest)]
  // [Description("Invalid Model")]
  InvalidModelError = 6,
  // [HttpStatusCode(HttpStatusCode.PreconditionFailed)]
  // [Description("Email not sent because notification service is not configured properly")]
  EmailNotSentBecauseServiceNotConfiguredError = 7,
  // [HttpStatusCode(HttpStatusCode.PreconditionFailed)]
  // [Description("Entity was not found with given search parameters. Most probably the entity was removed or its parameters changed.")]
  EntityNotFoundError = 8,
  // [HttpStatusCode(HttpStatusCode.BadRequest)]
  // [Description("This operation can only be executed once with these request parameters.")]
  OperationCanBeDoneOnceError = 9,
  // [HttpStatusCode(HttpStatusCode.BadRequest)]
  // [Description("Settings are not configured for the requested operation.")]
  SettingsNotConfiguredError = 10,
  // [Description("Web manager is not authorized with this permission.")]
  WebManagerNotAuthorizedWithPermission = 11,
  // [Description("Enity can not be created with a not unique property.")]
  NotUniqueProperty = 12,
  // [Description("Web manager is not authorized to change this company information.")]
  WebManagerNotAuthorizedChangeCompanyInfo = 13,
  // [Description("Operation can be completed.")]
  ImpossibleOperation = 14,

  // RMM agent section
  //  DESCRIPTION: Counters initialization error
  RmmSummaryCounter = 5001,
  //  DESCRIPTION: Parameter not found
  RmmParameterNotFound = 5002,
  //  DESCRIPTION: Counters recovery error
  RmmCountersRecoveryError = 5003,
  //  DESCRIPTION: Folder creating or access error
  RmmFolderCreatingOr = 5004,
  //  DESCRIPTION: File operation error
  RmmFileOperationError = 5005,
  //  DESCRIPTION: Process error
  RmmProcessError = 5006,
  //  DESCRIPTION: TimeOut
  RmmTimeOut = 5007,
  //  DESCRIPTION: The input parameter string is not in the correct format and the command cannot be recognized
  RmmTheInputParameter = 5008,
  //  DESCRIPTION: Previous operation not completed, plugin is busy
  RmmPreviousOperationNot = 5009,
  //  DESCRIPTION: File not exists
  RmmFileNotExists = 5010,
  //  DESCRIPTION: Path not exists
  RmmPathNotExists = 5011,
  //  DESCRIPTION: The remote management agent is in read-only mode. This command is blocked
  RmmReadonlyMode = 5012,
  //  DESCRIPTION: PowerShell scripts are not allowed to run
  RmmPowerShellScriptsAre = 5013,
  //  DESCRIPTION: An cancel request was received
  RmmAnCancelRequest = 5014,

  RmmHyperVNotFound = 5051,
  RmmHyperVServiceNotFound = 5052,
  RmmHyperVServiceNotRunning = 5053,

  RmmStartProcessAs = 5051,

  RmmUpdateDownloadError = 5051,
  RmmUpdateInstallationError = 5052,
  RmmUpdatesNotFound = 5053,
  RmmUpdatesListCorrupted = 5054,
  RmmThereMayBe = 5055,
  RmmInstalledUpdatesNot = 5056,
  RmmAvailableUpdatesNot = 5057,
  RmmUpdateNotInstalled = 5058,

  RmmHddErrorRetrievingSmart = 5051,
  RmmHddGetSmartRegisters = 5052,
  RmmHddConvertingHexValue = 5053,

  // RMM api section
  //  RMM Access denied
  RmmAccessDenied = 5102,
  // Timeout error occurred while getting the list of computers.
  RmmTimeoutGettingComputers = 5100,
  // Timeout error occurred or client is not connected.
  RmmTimeoutClientConnect = 5101
}

export enum ErrorPriority {
  Info = 1,
  Warning = 2,
  Minor = 3,
  Major = 4,
  Critical = 5
}

export class ResponseError {
  code: ErrorsEnum | HttpStatusCode;
  detail: string;
  knownType: number;
  priority: ErrorPriority;
  title: string;
  type: string;
  ErrorMessage: string;

  constructor(init?: Partial<ResponseError>) {
    if (init) {
      Object.assign(this, init);
    }
  }
}
