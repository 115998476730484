import { Component, ContentChild, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { ValidationErrorType } from '../input-base.types';

@Component({
  selector: 'mbs-input-errors',
  templateUrl: './input-errors.component.html',
  host: {
    class: 'mbs-form-feedback'
  }
})
export class InputErrorsComponent implements OnChanges {
  @Input() errors: Partial<ValidationErrorType>[] = [];
  @Input('errorsTemplate') set myErrorsTemplate(template: TemplateRef<any>) {
    this.errorsTemplate = template;
  }
  @ContentChild(TemplateRef, { read: TemplateRef, static: true }) errorsTemplate: TemplateRef<any>;

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.errors.currentValue) {
      this.errors = [];
    }
  }

  trackByMessage(index, item: ValidationErrorType): string {
    return item.message;
  }
}
