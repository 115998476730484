import { Directive, Input, TemplateRef } from '@angular/core';
import { MbsSize } from '../utils';

@Directive({
  selector: '[mbsButtonDropdown]'
})
export class ButtonDropdownDirective {
  @Input() showArrow = false;
  @Input() container = 'body';
  @Input() size: MbsSize.sm | MbsSize.lg;
  @Input() display: 'dynamic' | 'static' = 'dynamic';

  constructor(public template: TemplateRef<any>) {}

  getTemplate() {
    return this.template;
  }
}
