import { Injectable } from '@angular/core';
import { AzureGroup, OrganizationalUnit } from '@app/common/models';
import { SortOrderByPipe } from '@app/common/pipes';
import { AuthService, AzureGroupsService } from '@app/common/services';
import { OrganizationalUnitsService } from '@app/common/services/organizational-units.service';
import { getUniqueListByProp } from '@app/common/services/smart-search';
import SmartSearchTemplatesBase from '@app/common/services/smart-search/smart-search-template-base';
import { UserGroupsTagsEnum, UserGroupsTagsMap } from '@app/common/services/smart-search/user-groups/smart-search-tags-user-groups.model';
import { I18_NAMESPACE_MODULE } from '@app/i18';
import { I18NextPipe } from 'angular-i18next';
import { isNil } from 'lodash';
import { ModelTemplate, SmartSearchState } from 'mbs-ui-kit';
import { Observable, asapScheduler, scheduled } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SmartSearchModelTemplateUserGroupsService extends SmartSearchTemplatesBase {
  readonly #name = this.isGoogle ? UserGroupsTagsMap.get(UserGroupsTagsEnum.Path) : UserGroupsTagsMap.get(UserGroupsTagsEnum.Name);

  public readonly moduleUsers = I18_NAMESPACE_MODULE.users;

  get isGoogle(): boolean {
    return this.authService.isGoogle;
  }

  constructor(
    private authService: AuthService,
    private i18nPipe: I18NextPipe,
    private sortOrderByPipe: SortOrderByPipe<AzureGroup | OrganizationalUnit>,
    private azureGroupsService: AzureGroupsService,
    private orgUnitsService: OrganizationalUnitsService
  ) {
    super();
  }

  public readonly NameTag: ModelTemplate<AzureGroup | OrganizationalUnit> = {
    tag: this.i18nPipe.transform(this.moduleUsers + this.#name.tagKey, { format: 'title' }),
    items: (state: SmartSearchState): Observable<AzureGroup[] | OrganizationalUnit[]> => {
      const term = state.leftCaretValue;

      this.groupService().filter = term || '';

      return scheduled(
        this.groupService()
          .fetchGroups()
          .pipe(
            map((res) => res.data),
            map((groups: AzureGroup[] | OrganizationalUnit[]) => {
              const filtered = groups.filter((group) =>
                this.isGoogle
                  ? !isNil(group['Path']) && group['Path'].includes(term)
                  : !isNil(group['Name']) && group['Name'].includes(term)
              );
              const uniqueList = getUniqueListByProp(filtered, this.#name.prop).slice(0, 10);

              return this.sortOrderByPipe.transform(uniqueList, this.#name.prop);
            })
          ),
        asapScheduler
      );
    },
    itemFormatter: (value): string => (this.isGoogle ? value['Path'] : value['Name']),
    addGroupBrackets: true
  };

  private groupService(): OrganizationalUnitsService | AzureGroupsService {
    return this.isGoogle ? this.orgUnitsService : this.azureGroupsService;
  }
}
