import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MbsSize } from '../utils';
import { BreadcrumbsItem } from './breadcrumbs.model';

@Component({
  selector: 'mbs-breadcrumbs',
  templateUrl: 'breadcrumbs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbsComponent<T> {
  @Input() list: T[];
  /**
   * Optional parameter to specify display property of item
   */
  @Input() bindLabel: string;
  @Input() delimiterIcon: string;
  @Output() navigate = new EventEmitter<BreadcrumbsItem<any>>();

  public readonly MbsSize = MbsSize;

  itemDisplayValue<T>(item: T): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bindLabel ? item[this.bindLabel] : item;
  }

  handleItemClick<T>(item: T, index: number): void {
    const label = this.itemDisplayValue(item);

    this.navigate.emit({ item, label, level: index });
  }
}
