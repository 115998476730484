enum SmartSearchCharacters {
  GROUP_LEFT = '{',
  GROUP_RIGHT = '}',
  NEGATIVE = '-',
  GREATER = '>',
  GREATER_EQUAL = '>=',
  LESS = '<',
  LESS_EQUAL = '<=',
  SPACE = ' ',
  MASK = '*',
  SEQUENCE = ','
}

const DEFAULT_CHARS = new Set(Object.values(SmartSearchCharacters));
const CONDITION_CHARS = new Set<string>([
  SmartSearchCharacters.NEGATIVE,
  SmartSearchCharacters.GREATER_EQUAL,
  SmartSearchCharacters.LESS_EQUAL,
  SmartSearchCharacters.GREATER,
  SmartSearchCharacters.LESS
]);

const DEFAULT_CLASS = 'mbs-smart-search-default';
const INVALID_CLASS = 'mbs-smart-search-invalid';
const TAG_CLASS = 'mbs-smart-search-tag';
const FIELD_CLASS = 'mbs-smart-search-field';
const PLAIN_CLASS = 'mbs-smart-search-plain';
const GROUP_START_CLASS = 'mbs-smart-search-group-start';
const GROUP_END_CLASS = 'mbs-smart-search-group-end';

const SMART_SEARCH_CLASSES = [DEFAULT_CLASS, INVALID_CLASS, TAG_CLASS, FIELD_CLASS, PLAIN_CLASS, GROUP_START_CLASS, GROUP_END_CLASS];

export {
  SmartSearchCharacters,
  DEFAULT_CHARS,
  CONDITION_CHARS,
  SMART_SEARCH_CLASSES,
  DEFAULT_CLASS,
  INVALID_CLASS,
  TAG_CLASS,
  FIELD_CLASS,
  PLAIN_CLASS,
  GROUP_START_CLASS,
  GROUP_END_CLASS
};
