export enum MbsPopupType {
  success = 'success',
  danger = 'danger',
  info = 'info',
  warning = 'warning'
}

export enum MbsPopupIcon {
  danger = 'fa fa-exclamation-circle',
  info = 'fa fa-info-circle',
  success = 'fa fa-check-circle',
  warning = 'fa fa-exclamation-triangle'
}
