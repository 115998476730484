import { Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'mbs-card-header',
  template: `
    <header class="mbs-card_header">
      <ng-container *ngIf="!headerTemplate; else headerTemplate">
        <div class="mbs-card_ico" *ngIf="headerIco">
          <span [ngClass]="[headerIco]"></span>
        </div>
        <div class="mbs-card_title">
          {{ headerTitle }}
        </div>
      </ng-container>
    </header>
  `
})
export class CardHeaderComponent {
  @Input('ico') headerIco: string;
  @Input('title') headerTitle: string;
  @ContentChild(TemplateRef, { read: TemplateRef, static: true }) headerTemplate;
}
