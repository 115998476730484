<header class="modal-header mbs-modal_header">
  <div class="form-row">
    <div class="col overflow-hidden">
      <h5 *ngIf="!wizardTitle"
          class="modal-title mbs-modal_title text-overflow">
        {{ title }}
      </h5>
      <ng-template *ngIf="wizardTitle"
                   [ngTemplateOutlet]="wizardTitle.template"></ng-template>
    </div>
    <div class="col-auto"
         *ngIf="!hideCrossButton">
      <mbs-button [isCtrl]="true"
                  type="dark"
                  icon="ico ico-Close"
                  (click)="closeWithConfirm('Cross click')">
      </mbs-button>
    </div>
  </div>
</header>

<div class="modal-body mbs-wizard_body"
     [style.height]="sanitizedHeight || defaultWizardHeight"
     [class.overflow-hidden]="scrollableStepContent">
  <ng-container *ngIf="!initialLoading; else initialLoader">
    <div class="row no-gutters h-100">
      <ng-container *ngIf="stepsOrientation === 'vertical' && showSteps">
        <div class="col-3">
          <ul class="nav nav-tabs flex-column h-100">
            <li *ngFor="let step of steps; let i=index"
                class="nav-item">
              <button (click)="selectManualStep($event, step)"
                      [disabled]="!step.completed && !step.canOpen && !isOpenSteps"
                      [ngClass]="stepClasses(step)"
                      class="nav-link mbs-wizard_nav-tabs">
                <span class="nav-item_index">{{i + 1}}</span>
                <span class="nav-item_text">{{step.title}}</span>
              </button>
            </li>
          </ul>
        </div>
      </ng-container>
      <div [ngClass]="stepContainerClasses()"
           [class.h-100]="scrollableStepContent">
        <ng-content select="[prependStep]"
                    ngProjectAs="[prependStep]"></ng-content>
        <div class="mbs-wizard-steps_container"
             [class.overflow-auto]="scrollableStepContent"
             (scroll)="handleStepScroll()">
          <ng-container *ngIf="stepsOrientation === 'horizontal' && showSteps">
            <ul class="mbs-wizard-steps">
              <li (click)="step.completed ? selectManualStep($event, step) : ''"
                  *ngFor="let step of steps"
                  [ngClass]="stepClasses(step)"
                  class="mbs-wizard-steps_item col-auto">
                <div class="mbs-wizard-steps_link">{{step.title}}</div>
              </li>
            </ul>
          </ng-container>
          <mbs-loader *ngIf="loading"></mbs-loader>
          <ng-container *ngIf="!loading">
            <ng-content></ng-content>
          </ng-container>
        </div>

      </div>
    </div>
  </ng-container>

  <ng-template #initialLoader>
    <mbs-loader></mbs-loader>
  </ng-template>
</div>

<footer class="modal-footer mbs-modal_footer"
        *ngIf="showFooter">
  <div class="mr-auto"
       *ngIf="prepend && !prepend.hide">
    <ng-container *ngTemplateOutlet="prepend.template"></ng-container>
  </div>
  <mbs-button-group *ngIf="!hideNextAndBackButtons"
                    [type]="nextAndBackButtonType"
                    class="mr-3">
    <mbs-button *ngIf="!backButton.hide"
                [id]="'back_' + currentStep.title?.replaceAll(' ', '_')"
                [type]="nextAndBackButtonType"
                [size]="backButton.size"
                [loading]="(buttonLoadingMode && loading) || backButton.loading"
                [disabled]="backButton.loading || backButton.disabled || (buttonLoadingMode && loading) || steps?.first === currentStep"
                (click)="changeStep(-1)">
      <span *ngIf="backButton.icon"
            [ngClass]="[backButton.icon]"></span>
      {{ backButton.text }}
    </mbs-button>
    <mbs-button *ngIf="!nextButton.hide"
                [id]="'next_' + currentStep.title?.replaceAll(' ', '_')"
                [type]="nextAndBackButtonType"
                [size]="nextButton.size"
                [loading]="(buttonLoadingMode && loading) || nextButton.loading"
                [disabled]="nextButton.loading || nextButton.disabled || (buttonLoadingMode && loading) || steps?.last === currentStep"
                (click)="changeStep(1)">
      <span *ngIf="nextButton.icon"
            [ngClass]="[nextButton.icon]"></span>
      {{ nextButton.text }}
    </mbs-button>
  </mbs-button-group>

  <mbs-button *ngIf="!saveButton.hide && !saveButtonGroup?.length"
              [id]="'save_' + currentStep.title?.replaceAll(' ', '_')"
              [type]="saveButton.type ? saveButton.type : 'success'"
              [size]="saveButton.size"
              [loading]="(!disableSpinner && spinnerShow) || (buttonLoadingMode && loading) || saveButton.loading"
              [disabled]="saveButtonDisabled"
              [tooltipClass]="'tooltip-lg'"
              [tooltipData]="saveButtonTooltip"
              [tooltipAlways]="true"
              [tooltipPlacement]="['top', 'auto']"
              [tooltipTriggers]="'hover focus'"
              (click)="handleSave()">
    <span *ngIf="saveButton.icon"
          [ngClass]="[saveButton.icon]"></span>
    {{ saveButton.text }}
  </mbs-button>

  <mbs-button *ngFor="let saveButtonGroupItem of (saveButtonGroup || [])"
              (click)="handleSave(saveButtonGroupItem.action)"
              [id]="saveButtonGroupItem.text + '_' + currentStep.title?.replaceAll(' ', '_')"
              [type]="saveButtonGroupItem.type ? saveButtonGroupItem.type : 'success'"
              [size]="saveButtonGroupItem.size"
              [loading]="(!disableSpinner && spinnerShow) || (buttonLoadingMode && loading) || saveButtonGroupItem.loading"
              [disabled]="saveButtonGroupItem.disabled || saveButtonGroupItem.loading || (!disableSpinner && spinnerShow) || (buttonLoadingMode && loading) || (!isOpenSteps && steps?.last !== currentStep && !disableSpinner)">
    <span *ngIf="saveButtonGroupItem.icon"
          [ngClass]="[saveButtonGroupItem.icon]"></span>
    {{ saveButtonGroupItem.text }}
  </mbs-button>

  <mbs-button *ngIf="!cancelButton.hide"
              [id]="'close_' + currentStep.title?.replaceAll(' ', '_')"
              [type]="cancelButton.type ? cancelButton.type : 'secondary'"
              [size]="cancelButton.size"
              [loading]="(!disableSpinner && spinnerShow) || (buttonLoadingMode && loading) || cancelButton.loading"
              [disabled]="cancelButton.loading || cancelButton.disabled || (!disableSpinner && spinnerShow) || (buttonLoadingMode && loading)"
              (click)="handleClose()">
    <span *ngIf="cancelButton.icon"
          [ngClass]="[cancelButton.icon]"></span>
    {{ cancelButton.text }}
  </mbs-button>
</footer>
