import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DeleteUserBackupModule } from '@app/common/components/delete-user-backup/delete-user-backup.module';
import { ExportToPstModule } from '@app/common/components/export-to-pst/export-to-pst.module';
import { AutoActivationModePipe, SelectGridAllButtonTextPipe, SelectGridButtonTextPipe } from '@app/common/pipes';
import { PipesModule } from '@app/common/pipes/pipes.module';
import { AzureGroupsService } from '@app/common/services';
import { OrganizationalUnitsService } from '@app/common/services/organizational-units.service';
import { SmartSearchModelTemplateUserGroupsService } from '@app/common/services/smart-search/user-groups';
import { ChangeEmailModalComponent } from '@app/components/modals/change-email-modal/change-email-modal.component';
import { ChangePasswordModalComponent } from '@app/components/modals/change-password-modal/change-password-modal.component';
import { ConfirmPasswordModalComponent } from '@app/components/modals/confirm-password-modal/confirm-password-modal.component';
import { EditCreateRetentionPolicyModalModule } from '@app/components/modals/edit-create-retention-policy-modal/edit-create-retention-policy-modal.module';
import { I18_NAMESPACE_MODULE } from '@app/i18';
import { BackupConfigControlComponent } from '@app/pages/users/backup-config-control';
import { UsersPipesModule } from '@app/pages/users/pipe/pipes.module';
import { EditGroupSettingModalComponent } from '@app/pages/users/user-groups/modals/edit-group-setting/edit-group-setting-modal.component';
import { NewGroupSettingsModalComponent } from '@app/pages/users/user-groups/modals/new-group-settings/new-group-settings-modal.component';
import { UserRoutingModule } from '@app/pages/users/user-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { I18NEXT_NAMESPACE, I18NextModule } from 'angular-i18next';
import { QRCodeModule } from 'angularx-qrcode';
import { MbsUiKitModule } from 'mbs-ui-kit';
import { NgLetModule } from 'ng-let';
import { AlternateAccountSettingsComponent } from './alternate-account-settings/alternate-account-settings.component';
import { UsersComponent } from './component/users.component';
import { UserGroupActionSidepanelComponent } from './user-group-action-sidepanel/user-group-action-sidepanel.component';
import { UserGroupsComponent } from './user-groups/user-groups.component';
import { UserInformationControlComponent } from './user-information-control/user-information-control.component';
import { UserPermissionsControlComponent } from './user-permissions-control/user-permissions-control.component';
import { UserRetentionPoliciesControlComponent } from './user-retention-policies-control/user-retention-policies-control.component';

const modules = [
  UserRoutingModule,
  I18NextModule,
  CommonModule,
  NgbModule,
  NgSelectModule,
  MbsUiKitModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  EditCreateRetentionPolicyModalModule,
  DeleteUserBackupModule,
  ExportToPstModule,
  NgLetModule,
  UsersPipesModule,
  QRCodeModule,
  AutoActivationModePipe,
  SelectGridButtonTextPipe,
  SelectGridAllButtonTextPipe
];
@NgModule({
  imports: [modules, PipesModule],
  declarations: [
    UsersComponent,
    AlternateAccountSettingsComponent,
    BackupConfigControlComponent,
    UserGroupActionSidepanelComponent,
    UserGroupsComponent,
    UserInformationControlComponent,
    UserPermissionsControlComponent,
    UserRetentionPoliciesControlComponent,
    ConfirmPasswordModalComponent,
    ChangePasswordModalComponent,
    ChangeEmailModalComponent,
    NewGroupSettingsModalComponent,
    EditGroupSettingModalComponent
  ],
  providers: [
    AzureGroupsService,
    OrganizationalUnitsService,
    SmartSearchModelTemplateUserGroupsService,
    {
      provide: I18NEXT_NAMESPACE,
      useValue: [I18_NAMESPACE_MODULE.users, I18_NAMESPACE_MODULE.policy]
    }
  ]
})
export class UserModule {}
