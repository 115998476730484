<div class="mbs-advanced-select input-group">

  <button class="btn btn-secondary mbs-advanced-select_btn"
          [ngClass]="buttonSizeClass"
          (click)="handleLeftBtnClick()"
          [disabled]="isLeftButtonDisabled || disabled">
    <span class="ico ico-arrLeft"></span>
  </button>

  <div class="mbs-select flex-grow-1 overflow-hidden">
    <ng-select [items]="items"
               [appendTo]="appendTo"
               [loading]="loading"
               [disabled]="disabled"
               (change)="onChange($event)"
               [clearable]="false"
               [bindValue]="bindValue"
               [bindLabel]="bindLabel"
               [(ngModel)]="value"
               class="mbs-advanced-select_select mbs-select_ng-select"></ng-select>
  </div>

  <button class="btn btn-secondary mbs-advanced-select_btn"
          [ngClass]="buttonSizeClass"
          (click)="handleRightBtnClick()"
          [disabled]="isRightButtonDisabled || disabled">
    <span class="ico ico-arrRight"></span>
  </button>

</div>
