import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18NextModule } from 'angular-i18next';
import { MbsUiKitModule } from 'mbs-ui-kit';
import { DontHavePermissionComponent } from './dont-have-permission.component';

@NgModule({
  imports: [CommonModule, MbsUiKitModule, I18NextModule],
  exports: [DontHavePermissionComponent],
  declarations: [DontHavePermissionComponent]
})
export class DontHavePermissionModule {}
