import { Attribute, Directive, TemplateRef } from '@angular/core';

/**
 * Common table cell by group
 */
@Directive({
  selector: '[mbsTableCell]'
})
export class TableCellDirective {
  constructor(@Attribute('group') public group: string, public template: TemplateRef<any>) {}
}
