import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'resizeColumnLayout',
  pure: true
})
export class ResizeColumnLayoutPipe implements PipeTransform {
  transform(screenX: number): string {
    switch (true) {
      case screenX < 1600 && screenX >= 1440:
        return '20%';
      case screenX < 1440 && screenX >= 992:
        return '25%';
      default:
        return '16.66666667%';
    }
  }
}
