import { EventEmitter } from '@angular/core';
import { merge } from 'lodash';

function create<T>(type: new () => T): T {
  return new type();
}

export abstract class PersistentStateServiceBase<T> {
  public data: T;
  private myChange = new EventEmitter<T>(true);
  private namespace = 'global';
  public change = this.myChange.asObservable();
  constructor(private type: new () => T, private namespace$: EventEmitter<string> | string) {
    this.data = this.readData();

    if (typeof this.namespace$ === 'string') {
      this.namespace = this.namespace$;
    } else {
      this.namespace$.subscribe(userId => {
        this.namespace = userId || 'global';
        this.data = this.readData();
      });
    }
  }

  protected getEmitterInstance(): EventEmitter<string> {
    return this.namespace$ as EventEmitter<string>;
  }

  protected listenStorageChange(): void {
    window.addEventListener('storage', (event: StorageEvent) => {
      if (event.storageArea === localStorage && event.key === this.namespace) {
        this.data = this.readData();
        this.myChange.emit(this.data);
      }
    });
  }

  protected get<T = any>(obj: Record<string, any>, key: string): T {
    return obj['_' + key] as T;
  }

  protected set(obj: Record<string, any>, key: string, value: any): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    obj['_' + key] = value;
    this.myChange.emit(this.data);
    localStorage.setItem(this.namespace, JSON.stringify(this.data));
  }

  protected readData(): T {
    const storageData = JSON.parse(localStorage.getItem(this.namespace)) as T;
    const clearData = create<T>(this.type);
    const myData = merge(clearData, storageData);

    this.recursiveDefineReactiveProperties(myData);

    return myData;
  }

  protected recursiveDefineReactiveProperties(sourceObj: any): void {
    function getPropertiesOptions(k: string) {
      return {
        ['_' + k]: {
          writable: true,
          enumerable: false,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
          value: sourceObj[k]
        },
        [k]: {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
          get: this.get.bind(this, sourceObj, k),
          // eslint-disable-next-line
          set: (value => this.set(sourceObj, k, value)).bind(this),
          enumerable: true
        }
      };
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-argument
    const properties: PropertyDescriptorMap & ThisType<any> = Object.keys(sourceObj)
      .map(k => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (sourceObj[k] instanceof Object && !(sourceObj[k] instanceof Date)) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          this.recursiveDefineReactiveProperties(sourceObj[k]);
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return getPropertiesOptions.call(this, k);
      })
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      .reduce((prev, next) => Object.assign(prev, next), {});

    Object.defineProperties(sourceObj, properties);
  }
}
