export function naturalSort(as, bs): number {
  let a: string | RegExpMatchArray;
  let b: string | RegExpMatchArray;
  let a1: string;
  let b1: string;

  const rx = /(\d+)|(\D+)/g;
  const rd = /\d/;
  const rz = /^0/;

  if (typeof as == 'number' || typeof bs == 'number') {
    if (isNaN(as as number)) return 1;
    if (isNaN(bs as number)) return -1;

    return as - bs;
  }

  a = String(as).toLowerCase();
  b = String(bs).toLowerCase();

  if (a === b) return 0;
  if (!(rd.test(a) && rd.test(b))) return a > b ? 1 : -1;

  a = a.match(rx);
  b = b.match(rx);

  while (a.length && b.length) {
    a1 = a.shift();
    b1 = b.shift();

    if (a1 !== b1) {
      if (rd.test(a1) && rd.test(b1)) {
        return Number(a1.replace(rz, '.0')) - Number(b1.replace(rz, '.0'));
      } else return a1 > b1 ? 1 : -1;
    }
  }

  return a.length - b.length;
}
