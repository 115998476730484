import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthService, DomainService } from '@app/common/services';
import { isNil } from 'lodash';
import { ModalService } from 'mbs-ui-kit';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private domainService: DomainService, private authService: AuthService, private modalService: ModalService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (isNil(this.authService.loggedIn$.value)) {
      this.authService.checkTokenInLocalStorage();
    }

    if (this.authService.loggedIn$.value) {
      return this.authService.initAuthGuard().pipe(
        switchMap(() =>
          this.domainService.initAuthGuard().pipe(
            map(() => state.url.includes(this.authService.prefix)),
            tap(() => this.modalService.dismissAll()),
            catchError(() => of(false))
          )
        )
      );
    } else {
      this.authService.logout();

      return of(false);
    }
  }
}
