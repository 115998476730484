import { formatDate as ngFormatDate } from '@angular/common';
import { DateFormat } from 'mbs-ui-kit';

export function formatDate(value: string | Date): string {
  try {
    if (typeof value === 'string' || value instanceof Date) {
      return ngFormatDate(value, DateFormat.mediumDate, 'en-US');
    } else {
      // eslint-disable-next-line no-throw-literal
      throw 'non-data and non-string';
    }
  } catch (error) {
    console.error('Caught error manually: Error formatting date', error);

    return '';
  }
}
