import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[disableControl]'
})
export class DisableControlDirective implements OnChanges {
  @Input() disableControl: boolean;

  @Input() disableControlOptions: { emitEvent?: boolean; onlySelf?: boolean };

  constructor(private ngControl: NgControl) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disableControl) this.toggleDisabledState(changes.disableControl.currentValue as boolean);
  }

  toggleDisabledState(condition: boolean) {
    const action = condition ? 'disable' : 'enable';
    this.ngControl && this.ngControl.control[action](this.disableControlOptions);
  }
}
