<mbs-modal [title]="moduleAccount + ':modal.title.resetAlternatePassword' | i18next: { format: 'title' }">

  <ng-container modal-body
                [formGroup]="formGroup">

    <mbs-alert [type]="MbsPopupType.warning"
               [icon]="true">
      {{ moduleAccount + ':modal.body.resetAlternatePassword.toResetYour' | i18next: { format: 'capitalize' } }}
    </mbs-alert>

    <mbs-form-group class="mb-0">
      <mbs-input formControlName="alternateEmail"
                 mbsAutoFocus
                 [label]="moduleAccount + ':modal.label.alternateEmail' | i18next: { format: 'title' }">
      </mbs-input>
    </mbs-form-group>

  </ng-container>

  <ng-container modalFooter>

    <mbs-button (click)="handleSend()"
                [loading]="loading | async"
                [disabled]="formGroup.invalid"
                type="primary">
      {{ moduleAccount + ':modal.button.reset' | i18next: { format: 'title' } }}
    </mbs-button>

    <mbs-button (click)="handleClose()">
      {{ moduleAccount + ':modal.button.cancel' | i18next: { format: 'title' } }}
    </mbs-button>

  </ng-container>

</mbs-modal>
