<mbs-modal [title]="'login.signInAlternateEmail' | i18next: { format: 'title' }">

  <ng-container modal-body>

    <mbs-alert *ngIf="formGroup.errors?.validation as errorText"
               [icon]="true"
               [type]="MbsPopupType.danger"
               class="mb-4">
      {{ errorText }}
    </mbs-alert>

    <form [formGroup]="formGroup"
          (ngSubmit)="handleSignIn()">

      <mbs-form-group>

        <mbs-input [type]="'email'"
                   [id]="'email-id'"
                   [placeholder]="'common.placeholders.primaryEmail' | i18next: { format: 'title' }"
                   [prependButtons]="[
                     {
                      disabled: true,
                      id: 'prepend-primary-email-id',
                      type: 'secondary',
                      icon: 'fa fa-envelope'
                     }
                   ]"
                   name="providerEmail"
                   formControlName="providerEmail"
                   autocomplete="off"
                   mbsAutoFocus></mbs-input>

      </mbs-form-group>

      <mbs-form-group>

        <mbs-input [type]="'email'"
                   [id]="'alternate-email-id'"
                   [placeholder]="'common.placeholders.alternativeEmail' | i18next: { format: 'title' }"
                   [prependButtons]="[
                     {
                      disabled: true,
                      id: 'prepend-alternate-email-id',
                      type: 'secondary',
                      icon: 'fa fa-envelope'
                     }
                   ]"
                   name="alternativeEmail"
                   formControlName="alternativeEmail"
                   autocomplete="off"></mbs-input>

      </mbs-form-group>

      <mbs-form-group [class.mb-0]="env.production && !tfaCtrl">

        <mbs-input [type]="'password'"
                   [id]="'password-id'"
                   [placeholder]="'common.placeholders.password' | i18next: { format: 'title' }"
                   [prependButtons]="[
                     {
                      disabled: true,
                      id: 'prepend-password-id',
                      type: 'secondary',
                      icon: 'fa fa-key'
                     }
                   ]"
                   name="password"
                   formControlName="password"
                   autocomplete="off"></mbs-input>

      </mbs-form-group>

      <mbs-form-group *ngIf="tfaCtrl"
                      [class.mb-0]="env.production">

        <mbs-input [type]="'text'"
                   [id]="'2fa-password-id'"
                   [placeholder]="'common.placeholders.code' | i18next: { format: 'title' }"
                   [prependButtons]="[
                     {
                      disabled: true,
                      id: 'prepend-2fa-password-id',
                      type: 'secondary',
                      icon: 'fa fa-lock'
                     }
                   ]"
                   name="tfa"
                   formControlName="tfa"></mbs-input>

      </mbs-form-group>

      <mbs-form-group *ngIf="!env.production"
                      [class.mb-0]="!env.production">

        <mbs-checkbox formControlName="providerSignIn"
                      [label]="'common.labels.providerSignIn' | i18next: { format: 'title' }"
                      [id]="'provider-sign-in'"></mbs-checkbox>

      </mbs-form-group>

    </form>

  </ng-container>

  <ng-container modalFooter>

    <mbs-button type="primary"
                [icon]="'fa fa-sign-in text-base'"
                [loading]="loading$ | async"
                [disabled]="!formGroup.valid && formGroup.touched"
                [buttonType]="'submit'"
                (keyup.enter)="handleSignIn()"
                (click)="handleSignIn()">
      {{ 'common.signIn' | i18next: { format: 'title' } }}
    </mbs-button>

    <mbs-button (keyup.escape)="handleClose()"
                (click)="handleClose()">
      {{ 'common.cancel' | i18next: { format: 'title' } }}
    </mbs-button>

  </ng-container>

</mbs-modal>
