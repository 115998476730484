<mbs-modal id="assign-retention-policy-modal"
           [loading]="loading$ | async"
           [title]="'retentionPolicy.modal.title.assign' | i18next: { format: 'title' }">

  <ng-container *ngIf="attachedPolicies$ | async as policies"
                modal-body>

    <mbs-form-group>

      <div class="row align-items-center">

        <div class="col">
          <mbs-button *ngIf="isAvailableCreatePolicy$ | async"
                      type="primary"
                      (click)="handleShowCreateRetention()">
            {{ 'retentionPolicy.button.createPolicy' | i18next: { format: 'title' } }}
          </mbs-button>
        </div>

        <div class="col-auto">
          <mbs-button (click)="handleRefresh()"
                      type="primary"
                      ngbTooltip="Refresh"
                      container="body"
                      placement="top-right"
                      tooltipClass="-no-arrow"
                      [icon]="'ico ico-Refresh'"
                      [isCtrl]="true"
                      [size]="MbsSize.sm"></mbs-button>
        </div>

      </div>

    </mbs-form-group>

    <mbs-form-group class="mb-0">
      <div class="border background-light">

        <div class="form-row align-items-center py-2 px-3 border-bottom">

          <div class="col-5"
               [ngClass]="{ 'text-muted': policies.EmailPolicies?.length === 0 }">

            <span class="fa fa-envelope text-lg mr-2"></span>

            <span class="font-weight-semibold">
              {{ 'retentionPolicy.label.mail' | i18next: { format: 'title' } }}
            </span>

          </div>

          <div class="col-7">

            <mbs-select [items]="policies.EmailPolicies"
                        [labelClasses]="'-' + MbsSize.sm"
                        [size]="MbsSize.sm"
                        [disabled]="policies.EmailPolicies?.length === 0"
                        [placeholder]="'retentionPolicy.placeholder.selectPolicy' | i18next: { format: 'title' }"
                        appendTo="#assign-retention-policy-modal"
                        bindLabel="Text"
                        bindValue="Value"
                        [clearable]="false"
                        [(ngModel)]="selectedPolicies.emailPolicyId"
                        name="mailPolicy">

              <ng-template ng-option-tmp
                           let-policy="item">

                <mbs-text-ellipsis class="text-dark"
                                   container="body"
                                   placement="top-left auto"
                                   tooltipClass="tooltip-lg"
                                   [tooltip]="policy.Text">
                  {{ policy.Text }}
                </mbs-text-ellipsis>

              </ng-template>

            </mbs-select>

          </div>

        </div>

        <div class="form-row align-items-center py-2 px-3 border-bottom">

          <div class="col-5"
               [ngClass]="{ 'text-muted': policies.DrivePolicies?.length === 0 }">

            <span class="ico ico-Drive text-lg mr-2"></span>

            <span class="font-weight-semibold">
              {{ 'retentionPolicy.label.drive' | i18next: { format: 'title' } }}
            </span>

          </div>

          <div class="col-7">

            <mbs-select [items]="policies.DrivePolicies"
                        [labelClasses]="'-' + MbsSize.sm"
                        [size]="MbsSize.sm"
                        [disabled]="policies.DrivePolicies?.length === 0"
                        [placeholder]="'retentionPolicy.placeholder.selectPolicy' | i18next: { format: 'title' }"
                        appendTo="#assign-retention-policy-modal"
                        bindLabel="Text"
                        bindValue="Value"
                        [clearable]="false"
                        [(ngModel)]="selectedPolicies.drivePolicyId"
                        name="divePolicy">

              <ng-template ng-option-tmp
                           let-policy="item">

                <mbs-text-ellipsis class="text-dark"
                                   container="body"
                                   placement="top-left auto"
                                   tooltipClass="tooltip-lg"
                                   [tooltip]="policy.Text">
                  {{ policy.Text }}
                </mbs-text-ellipsis>

              </ng-template>

            </mbs-select>

          </div>

        </div>

        <div class="form-row align-items-center py-2 px-3 border-bottom">

          <div class="col-5"
               [ngClass]="{ 'text-muted': policies.ContactPolicies?.length === 0 }">

            <span class="fa fa-address-book-o text-lg mr-2"></span>

            <span class="font-weight-semibold">
              {{ 'retentionPolicy.label.contacts' | i18next: { format: 'title' } }}
            </span>

          </div>

          <div class="col-7">

            <mbs-select [items]="policies.ContactPolicies"
                        [labelClasses]="'-' + MbsSize.sm"
                        [size]="MbsSize.sm"
                        [disabled]="policies.ContactPolicies?.length === 0"
                        [placeholder]="'retentionPolicy.placeholder.selectPolicy' | i18next: { format: 'title' }"
                        appendTo="#assign-retention-policy-modal"
                        bindLabel="Text"
                        bindValue="Value"
                        [clearable]="false"
                        [(ngModel)]="selectedPolicies.contactPolicyId"
                        name="contactPolicy">

              <ng-template ng-option-tmp
                           let-policy="item">

                <mbs-text-ellipsis class="text-dark"
                                   container="body"
                                   placement="top-left auto"
                                   tooltipClass="tooltip-lg"
                                   [tooltip]="policy.Text">
                  {{ policy.Text }}
                </mbs-text-ellipsis>

              </ng-template>

            </mbs-select>

          </div>

        </div>

        <div class="form-row align-items-center py-2 px-3 border-bottom">

          <div class="col-5"
               [ngClass]="{ 'text-muted': policies.CalendarPolicies?.length === 0 }">

            <span class="ico ico-Calendar text-lg mr-2"></span>

            <span class="font-weight-semibold">
              {{ 'retentionPolicy.label.calendar' | i18next: { format: 'title' } }}
            </span>

          </div>

          <div class="col-7">

            <mbs-select [items]="policies.CalendarPolicies"
                        [labelClasses]="'-' + MbsSize.sm"
                        [size]="MbsSize.sm"
                        [disabled]="policies.CalendarPolicies?.length === 0"
                        [placeholder]="'retentionPolicy.placeholder.selectPolicy' | i18next: { format: 'title' }"
                        appendTo="#assign-retention-policy-modal"
                        bindLabel="Text"
                        bindValue="Value"
                        [clearable]="false"
                        [(ngModel)]="selectedPolicies.calendarPolicyId"
                        name="calendarPolicy">

              <ng-template ng-option-tmp
                           let-policy="item">

                <mbs-text-ellipsis class="text-dark"
                                   container="body"
                                   placement="top-left auto"
                                   tooltipClass="tooltip-lg"
                                   [tooltip]="policy.Text">
                  {{ policy.Text }}
                </mbs-text-ellipsis>

              </ng-template>

            </mbs-select>

          </div>

        </div>

        <div *ngIf="!(isHomeUser$ | async)"
             class="form-row align-items-center py-2 px-3 border-bottom">

          <div class="col-5"
               [ngClass]="{ 'text-muted': getDriveItems(policies)?.length === 0 }">

            <span class="ico ico-SquareS text-lg mr-2"></span>

            <span class="font-weight-semibold">
              {{ driveTitle }}
            </span>

          </div>

          <div class="col-7">

            <mbs-select [items]="getDriveItems(policies)"
                        [labelClasses]="'-' + MbsSize.sm"
                        [size]="MbsSize.sm"
                        [disabled]="getDriveItems(policies)?.length === 0"
                        [placeholder]="'retentionPolicy.placeholder.selectPolicy' | i18next: { format: 'title' }"
                        appendTo="#assign-retention-policy-modal"
                        bindLabel="Text"
                        bindValue="Value"
                        [clearable]="false"
                        [(ngModel)]="filePolicyId"
                        name="sitePolicy">

              <ng-template ng-option-tmp
                           let-policy="item">

                <mbs-text-ellipsis class="text-dark"
                                   container="body"
                                   placement="top-left auto"
                                   tooltipClass="tooltip-lg"
                                   [tooltip]="policy.Text">
                  {{ policy.Text }}
                </mbs-text-ellipsis>

              </ng-template>

            </mbs-select>

          </div>

        </div>

        <div *ngIf="!(isHomeUser$ | async) && isOffice"
             class="form-row align-items-center py-2 px-3">

          <div class="col-5"
               [ngClass]="{ 'text-muted': policies.TeamsPolicies?.length === 0 }">

            <span class="ico ico-SquareT text-lg mr-2"></span>

            <span class="font-weight-semibold">
              {{ 'retentionPolicy.label.teams' | i18next: { format: 'title' } }}
            </span>

          </div>

          <div class="col-7">

            <mbs-select [items]="policies.TeamsPolicies"
                        [labelClasses]="'-' + MbsSize.sm"
                        [size]="MbsSize.sm"
                        [disabled]="policies.TeamsPolicies?.length === 0"
                        [placeholder]="'retentionPolicy.placeholder.selectPolicy' | i18next: { format: 'title' }"
                        appendTo="#assign-retention-policy-modal"
                        bindLabel="Text"
                        bindValue="Value"
                        [clearable]="false"
                        [(ngModel)]="selectedPolicies.teamsPolicyId"
                        name="teamsPolicy">

              <ng-template ng-option-tmp
                           let-policy="item">

                <mbs-text-ellipsis class="text-dark"
                                   container="body"
                                   placement="top-left auto"
                                   tooltipClass="tooltip-lg"
                                   [tooltip]="policy.Text">
                  {{ policy.Text }}
                </mbs-text-ellipsis>

              </ng-template>

            </mbs-select>

          </div>

        </div>

      </div>

    </mbs-form-group>

  </ng-container>

  <ng-container modalFooter>

    <mbs-button (click)="handleAssign()"
                [disabled]="!policiesChanged"
                type="primary">
      {{ 'retentionPolicy.modal.button.assign' | i18next: { format: 'title' } }}
    </mbs-button>

    <mbs-button (click)="handleClose()">
      {{ 'retentionPolicy.modal.button.cancel' | i18next: { format: 'title' } }}
    </mbs-button>

  </ng-container>

</mbs-modal>
