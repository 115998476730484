export enum InvoiceState {
  Expose = 'Expose',
  Paid = 'Paid',
  Error = 'Error',
  WaitPayment = 'WaitPayment',
  SendErrorEmail = 'SendErrorEmail',
  WrongPrice = 'WrongPrice'
}

export const InvoiceStateByAPI = {
  [InvoiceState.Expose]: {
    property: InvoiceState.Expose,
    displayName: 'Expose'
  },
  [InvoiceState.Paid]: {
    property: InvoiceState.Paid,
    displayName: 'Paid'
  },
  [InvoiceState.Error]: {
    property: InvoiceState.Error,
    displayName: 'Error'
  },
  [InvoiceState.WaitPayment]: {
    property: InvoiceState.WaitPayment,
    displayName: 'Wait Payment'
  },
  [InvoiceState.SendErrorEmail]: {
    property: InvoiceState.SendErrorEmail,
    displayName: 'Send Error Email'
  },
  [InvoiceState.WrongPrice]: {
    property: InvoiceState.WrongPrice,
    displayName: 'Wrong Price'
  }
};
