<mbs-modal [title]="title">

  <ng-container modal-body>

    <form [formGroup]="confirmForm">

      <mbs-input [type]="passwordType$ | async"
                 [appendButtons]="[
                   {
                     id: 'append-password',
                     type: 'secondary',
                     icon: getAppendButtonsIcon(passwordType$ | async),
                     btnType: 'button',
                     loading: false,
                     disabled: false
                   }
                 ]"
                 (buttonClick)="handleChangePasswordType()"
                 formControlName="password"
                 [placeholder]="'common.placeholders.password' | i18next"
                 [label]="'common.labels.alternatePassword' | i18next: { format: 'title' }"
                 autocomplete="off"
                 mbsAutoFocus></mbs-input>

    </form>

  </ng-container>

  <ng-container modalFooter>

    <mbs-button [type]="saveButtonType"
                [disabled]="confirmForm.invalid"
                (click)="saveButtonClick()">
      {{ saveButtonText }}
    </mbs-button>

    <mbs-button (click)="cancelButtonClick()">
      {{ 'common.cancel' | i18next: { format: 'title' } }}
    </mbs-button>

  </ng-container>

</mbs-modal>
