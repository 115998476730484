export class HeaderItem {
  private readonly _linkFunction: () => string = null;

  public readonly displayName: string;
  public readonly icon: string;
  public readonly needChangeDetectionHack: boolean;

  public get link(): string {
    return this._linkFunction();
  }

  constructor(displayName: string, icon: string, link: string | (() => string), needChangeDetectionHack = false) {
    this.displayName = displayName;
    this.icon = icon;

    if (typeof link === 'string') {
      this._linkFunction = () => link;
    } else {
      this._linkFunction = link;
    }

    this.needChangeDetectionHack = needChangeDetectionHack;
  }
}

export interface MenuItemType {
  name: string;
  iconClass?: string;
  routeUrl?: string;
  queryParams?: { [key: string]: string };
  externalUrl?: string;
  target?: '_blank' | '_self';
  action?: () => void;
  children?: Array<MenuItemType>;
  can?: boolean;
  title?: string;
  id: string;
}
