import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InvoiceStateToDisplayNamePipe, SortOrderByPipe } from '@app/common/pipes';
import { PipesModule } from '@app/common/pipes/pipes.module';
import { InvoicesService, TariffsService } from '@app/common/services';
import { SmartSearchModelTemplatePaymentsService } from '@app/common/services/smart-search';
import { I18_NAMESPACE_MODULE } from '@app/i18';
import { ComparePlansModalComponent } from '@app/pages/payments/compare-plans-modal/compare-plans-modal.component';
import { PaymentsComponent } from '@app/pages/payments/component/payments.component';
import { InvoicePlanComponent } from '@app/pages/payments/invoice-plan/invoice-plan.component';
import { PaymentsRoutingModule } from '@app/pages/payments/payments-routing.module';
import { NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { I18NEXT_NAMESPACE, I18NextModule } from 'angular-i18next';
import { MbsUiKitModule } from 'mbs-ui-kit';
import { NgLetModule } from 'ng-let';

const modules = [
  PaymentsRoutingModule,
  I18NextModule,
  MbsUiKitModule,
  NgbTooltipModule,
  CommonModule,
  PipesModule,
  FormsModule,
  NgbPopoverModule,
  NgLetModule
];
@NgModule({
  imports: modules,
  declarations: [PaymentsComponent, InvoicePlanComponent, ComparePlansModalComponent],
  exports: [PaymentsComponent, InvoicePlanComponent, ComparePlansModalComponent],
  providers: [
    CurrencyPipe,
    DatePipe,
    SortOrderByPipe,
    InvoiceStateToDisplayNamePipe,
    InvoicesService,
    TariffsService,
    SmartSearchModelTemplatePaymentsService,
    {
      provide: I18NEXT_NAMESPACE,
      useValue: [I18_NAMESPACE_MODULE.payments]
    }
  ]
})
export class PaymentsModule {}
