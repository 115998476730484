import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, forwardRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, NG_VALUE_ACCESSOR, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomCVAccessor, PHONE_REGEX, PHONE_REGEX_SAFARI, RoutePath, SECRET_KEY_MASK } from '@app/common';
import {
  DateUnitEnum,
  DeleteBackup,
  ExportToPST,
  hasDomainAdminRole,
  hasDomainUserRole,
  hasLimitedAdminRole,
  hasSingleUserRole,
  ProviderRole,
  Role
} from '@app/common/models';
import { AuthService, ExportToPSTService, UserOdataService } from '@app/common/services';
import { BrowserService } from '@app/common/services/browser.service';
import { isHomeUser } from '@app/common/utils';
import { GeneralTabForm } from '@app/components/my-account/my-account-sidepanel/my-account-sidepanel.model';
import { MyAccountSidepanelService } from '@app/components/my-account/my-account-sidepanel/my-account-sidepanel.service';
import { AllowedModalComponent } from '@app/components/my-account/tabs/general-tab/allowed-modal/allowed-modal.component';
import { AssignRetentionPolicyComponent } from '@app/components/my-account/tabs/general-tab/assign-retention-policy-modal/assign-retention-policy-modal.component';
import { SaveEmitAttachedPolicies } from '@app/components/my-account/tabs/general-tab/assign-retention-policy-modal/assign-retention-policy-modal.model';
import { DeleteBackupModalComponent } from '@app/components/my-account/tabs/general-tab/delete-backup-modal/delete-backup-modal.component';
import { ExportToPstModalComponent } from '@app/components/my-account/tabs/general-tab/export-to-pst-modal/export-to-pst-modal.component';
import { I18_NAMESPACE_MODULE } from '@app/i18';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { isEqual, noop } from 'lodash';
import { MbsPopupType, MbsSize, ModalService, ModalSettings, TabBase, TabsetItemDirective, TabsService, ToastService } from 'mbs-ui-kit';
import { BehaviorSubject, EMPTY, forkJoin, from, Observable, of, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, finalize, first, map, shareReplay, switchMap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-my-account-general-tab',
  templateUrl: './general-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MyAccountGeneralTabComponent),
      multi: true
    }
  ]
})
export class MyAccountGeneralTabComponent extends TabBase implements ControlValueAccessor, OnInit {
  @ViewChild('deleteJobTransferredTemplateRef', { static: false, read: TemplateRef }) deleteJobTransferredRef: TemplateRef<any>;
  @ViewChild('exportJobTransferredTemplateRef', { static: false, read: TemplateRef }) exportJobTransferredRef: TemplateRef<any>;

  #settings: GeneralTabForm;

  readonly #toastTitleSuccess = this.i18nPipe.transform('toast.success.title', { format: 'title' });
  readonly #toastTitleError = this.i18nPipe.transform('toast.error.title', { format: 'title' });

  private initialSettings: GeneralTabForm;

  private readonly close$: Observable<boolean>;
  private readonly roles$: Observable<Role[]>;
  private readonly providerRoles$: Observable<ProviderRole[]>;

  public readonly moduleAccount = I18_NAMESPACE_MODULE.account;
  public readonly modulePolicy = I18_NAMESPACE_MODULE.policy;
  public readonly MbsSize = MbsSize;
  public readonly MbsPopupType = MbsPopupType;
  public readonly RoutePath = RoutePath;

  public assignLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public exportLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public deleteLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public $isDomainUser$: Observable<boolean>;
  public isAvailableExportToPST$: Observable<boolean>;
  public isAvailableExportSettings$: Observable<boolean>;

  public formGroup: UntypedFormGroup;
  public periodFilter = Object.keys(DateUnitEnum);

  private onChange: (value: CustomCVAccessor<GeneralTabForm>) => void;
  private onTouched: () => void;

  private set settings(settings: GeneralTabForm) {
    this.#settings = settings;
    this.notifyValueChange();
  }

  private get settings(): GeneralTabForm {
    return this.#settings;
  }

  get userId(): string {
    return this.authService.id;
  }

  get phoneControl(): FormControl {
    return <FormControl>this.formGroup.get('phone');
  }

  get keepAllowedControl(): FormControl {
    return <FormControl>this.formGroup.get('exportSettings.keepAllowed');
  }

  get keepCntUnitsControl(): FormControl {
    return <FormControl>this.formGroup.get('exportSettings.keepCntUnits');
  }

  get prefix(): string {
    return this.authService?.prefix;
  }

  get isImmutability(): boolean {
    return this.authService?.isImmutability;
  }

  constructor(
    private fb: FormBuilder,
    private i18nPipe: I18NextPipe,
    private toastService: ToastService,
    private modalService: ModalService,
    private userService: UserOdataService,
    private authService: AuthService,
    private exportToPSTService: ExportToPSTService,
    private myAccountSidepanelService: MyAccountSidepanelService,
    private browserService: BrowserService,
    tabService: TabsService,
    tabsetItem: TabsetItemDirective
  ) {
    super(tabService, tabsetItem);
    this.close$ = myAccountSidepanelService.sidepanel.close;
    this.roles$ = authService.getRoles().pipe(shareReplay(1));
    this.providerRoles$ = authService.getProviderRoles();

    this.$isDomainUser$ = this.roles$.pipe(map((roles) => hasDomainUserRole(roles)));
    this.isAvailableExportToPST$ = forkJoin([authService.getAuthUser(), this.roles$]).pipe(
      switchMap(([user, roles]) => {
        const isDomainUser = hasDomainUserRole(roles);
        const hasRestorePermission = user.RestoreEnable;

        return of(hasRestorePermission && (isHomeUser(user) || isDomainUser));
      })
    );
    this.isAvailableExportSettings$ = forkJoin([this.roles$, this.providerRoles$]).pipe(
      switchMap(([roles, providerRoles]) => {
        const isDomainAdmin = hasDomainAdminRole(roles);
        const isSingleUser = hasSingleUserRole(roles);
        const isLimitedAdmin = hasLimitedAdminRole(roles, providerRoles);

        return of(isDomainAdmin || isSingleUser || isLimitedAdmin);
      }),
      shareReplay(1)
    );
  }

  ngOnInit(): void {
    this.initForm();

    this.formGroup.valueChanges
      .pipe(
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
        untilDestroyed(this)
      )
      .subscribe((settings: GeneralTabForm) => {
        this.settings = settings;
        this.valid = this.formGroup.valid;
      });
    this.close$.pipe(untilDestroyed(this)).subscribe(() => this.resetFormGroup());
  }

  initForm(): void {
    const { name, version } = this.browserService.detectBrowser();
    const isSafari = name.toLowerCase().includes('safari') && version < '16.4';
    const phonePattern = new RegExp(isSafari ? PHONE_REGEX_SAFARI : PHONE_REGEX);

    this.formGroup = this.fb.group({
      email: [''],
      phone: ['', [Validators.pattern(phonePattern), Validators.maxLength(40)]],
      version: [''],
      exportSettings: this.fb.group({
        keepAllowed: this.fb.group({
          checkbox: [false],
          password: ['']
        }),
        keepCntUnits: this.fb.group({
          checkbox: [true],
          number: [30],
          filter: [DateUnitEnum.Days]
        })
      })
    });
    this.form = this.formGroup;
  }

  registerOnChange(fn: (value: CustomCVAccessor<GeneralTabForm>) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(settings: CustomCVAccessor<GeneralTabForm>): void {
    if (!settings) return;

    this.updateFormValue(settings.data);
    this.settings = settings.data;
    this.initialSettings = settings.data;
  }

  notifyValueChange(): void {
    const value: CustomCVAccessor<GeneralTabForm> = {
      valid: this.formGroup.valid,
      data: this.settings
    };

    this.onChange && this.onChange(value);
    this.onTouched && this.onTouched();
  }

  updateFormValue(settings: GeneralTabForm): void {
    this.resetFormGroup();

    if (settings.email) this.formGroup.get('email').setValue(settings.email, { emitEvent: false });
    if (settings.phone) this.formGroup.get('phone').setValue(settings.phone, { emitEvent: false });
    if (settings.version) this.formGroup.get('version').setValue(settings.version, { emitEvent: false });
    if (settings.exportSettings) {
      if (settings.exportSettings.keepAllowed) {
        this.keepAllowedControl.get('checkbox').setValue(settings.exportSettings.keepAllowed.checkbox, { emitEvent: false });
        this.keepAllowedControl.get('password').setValue(settings.exportSettings.keepAllowed.password, { emitEvent: false });
      }
      if (settings.exportSettings.keepCntUnits) {
        this.keepCntUnitsControl.get('checkbox').setValue(settings.exportSettings.keepCntUnits.checkbox, { emitEvent: false });
        this.keepCntUnitsControl.get('number').setValue(settings.exportSettings.keepCntUnits.number, { emitEvent: false });
        this.keepCntUnitsControl.get('filter').setValue(settings.exportSettings.keepCntUnits.filter, { emitEvent: false });
      }
    }

    this.afterFormInit();
  }

  private resetFormGroup(): void {
    this.formGroup.reset(this.defaultFormValue(), { emitEvent: false });
  }

  private defaultFormValue = (): GeneralTabForm => ({
    email: '',
    phone: '',
    version: '',
    exportSettings: {
      keepAllowed: {
        checkbox: false,
        password: ''
      },
      keepCntUnits: {
        checkbox: false,
        number: 30,
        filter: DateUnitEnum.Days
      }
    }
  });

  handleAssignRetentionPolicy(): void {
    const bodyUpdated = this.i18nPipe.transform(this.modulePolicy + ':toast.body.assign', { format: 'capitalize' });
    const bodyError = this.i18nPipe.transform(this.modulePolicy + ':toast.error.assign', { format: 'capitalize' });
    const settings: Partial<ModalSettings> = {
      responsive: true,
      size: MbsSize.sm
    };

    from(this.modalService.openCustom(AssignRetentionPolicyComponent, settings))
      .pipe(
        switchMap((saveResult: SaveEmitAttachedPolicies) => {
          if (saveResult?.attachedPolicies) {
            const { userIds, attachedPolicies } = saveResult;

            this.assignLoading$.next(true);
            return this.userService.updateAttachedPolicies(userIds, attachedPolicies).pipe(
              map(() => true),
              catchError((err: HttpErrorResponse) => throwError(() => err)),
              finalize(() => this.assignLoading$.next(false))
            );
          }

          return of(false);
        }),
        catchError(() => of(false)),
        first()
      )
      .subscribe({
        next: (result) => result && this.toastService.success(bodyUpdated, this.#toastTitleSuccess),
        error: (res: HttpErrorResponse) =>
          this.toastService.error(res.status !== 500 && (res.error?.value || bodyError), this.#toastTitleError)
      });
  }

  handleDeleteBackup(): void {
    const settings: Partial<ModalSettings> = {
      responsive: true,
      size: MbsSize.sm
    };

    from(this.modalService.openCustom(DeleteBackupModalComponent, settings))
      .pipe(
        switchMap((payloadDeleteBackup: DeleteBackup) => {
          if (payloadDeleteBackup) {
            this.deleteLoading$.next(true);
            return this.userService.deleteBackup(payloadDeleteBackup).pipe(finalize(() => this.deleteLoading$.next(false)));
          }

          return of(false);
        }),
        catchError(() => of(false)),
        first()
      )
      .subscribe({
        next: (result) => result && this.toastService.success(this.deleteJobTransferredRef, this.#toastTitleSuccess)
        // error: (res: HttpErrorResponse) => this.toastService.error(res.status !== 500 && res.error?.value, this.toastTitleError) // catching error interceptor
      });
  }

  handleExportToPST(): void {
    const settings: Partial<ModalSettings> = {
      responsive: true,
      size: MbsSize.sm
    };

    from(this.modalService.openCustom(ExportToPstModalComponent, settings))
      .pipe(
        switchMap((payloadForExportToPST: ExportToPST) => {
          if (payloadForExportToPST) {
            this.exportLoading$.next(true);
            return this.exportToPSTService.exportUserData(payloadForExportToPST).pipe(
              map(() => true),
              catchError((err: HttpErrorResponse) => (err.status !== 403 ? throwError(() => err) : EMPTY)),
              finalize(() => this.exportLoading$.next(false))
            );
          }

          return of(false);
        }),
        catchError(() => of(false)),
        first()
      )
      .subscribe({
        next: (result) => result && this.toastService.success(this.exportJobTransferredRef, this.#toastTitleSuccess),
        error: (res: HttpErrorResponse) => this.toastService.error(res.status !== 500 && res.error?.value, this.#toastTitleError)
      });
  }

  handleBlurPhoneInput(): void {
    if (this.phoneControl.value === '' && this.phoneControl.valid) {
      this.phoneControl.markAsUntouched({ onlySelf: true });
    }
  }

  handleChange(state: boolean): void {
    if (state) {
      if (this.initialSettings && this.initialSettings.exportSettings.keepAllowed.checkbox) {
        this.keepAllowedControl.get('password').setValue(SECRET_KEY_MASK);
        return;
      }

      const settings: ModalSettings = {
        size: MbsSize.sm
      };

      this.modalService
        .openCustom(AllowedModalComponent, settings)
        .then((res: { password: string }) => {
          this.keepAllowedControl.get('checkbox').setValue(true);
          this.keepAllowedControl.get('password').setValue(res.password);
        })
        .catch((err) => {
          this.keepAllowedControl.get('checkbox').setValue(false);
          this.keepAllowedControl.get('password').setValue('');
        });
    } else {
      this.keepAllowedControl.get('password').setValue('');
    }
  }

  handlePassword(): void {
    const settings: ModalSettings = {
      size: MbsSize.sm
    };

    this.modalService
      .openCustom(AllowedModalComponent, settings)
      .then((res: { password: string }) => this.keepAllowedControl.get('password').setValue(res.password))
      .catch(noop);
  }
}
