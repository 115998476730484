export enum AuditLogTagsEnum {
  event = 'event',
  action = 'action',
  userName = 'userName',
  from = 'from',
  to = 'to'
}

export const AuditLogTagsMap: Map<AuditLogTagsEnum, { tag: string; prop: string }> = new Map([
  [
    AuditLogTagsEnum.event,
    {
      tag: 'Event',
      prop: 'UserActionTypeArea'
    }
  ],
  [
    AuditLogTagsEnum.action,
    {
      tag: 'Action',
      prop: 'UserActionType'
    }
  ],
  [
    AuditLogTagsEnum.userName,
    {
      tag: 'User Name',
      prop: 'UserName'
    }
  ],
  [
    AuditLogTagsEnum.from,
    {
      tag: 'Date From',
      prop: 'Date'
    }
  ],
  [
    AuditLogTagsEnum.to,
    {
      tag: 'To Date',
      prop: 'Date'
    }
  ]
]);
