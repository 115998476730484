/*
 * oData cannot parse single quotes in query param
 * https://stackoverflow.com/questions/3979367/how-to-escape-a-single-quote-to-be-used-in-an-odata-query
 * @constructor
 * @param term
 */
export function quoteODataEncoding(term: string): string {
  const STR_QUOTES = /'/g;
  return term ? term.replace(STR_QUOTES, "''") : '';
}
