export enum MailTagsEnum {
  from = 'From',
  to = 'To',
  subject = 'Subject',
  size = 'Size',
  sizeFilter = 'Filter',
  sizeValue = 'Value',
  sizeMagnitude = 'Magnitude',
  dateTo = 'Date To',
  dateFrom = 'Date From',
  sizeMore = 'Size More',
  sizeLess = 'Size Less',
  withAttachment = 'With Attachments',
  backupArchive = 'Backup Archive'
}

export const MailTagsMap: Map<MailTagsEnum, { tag: string; prop: string }> = new Map([
  [
    MailTagsEnum.from,
    {
      tag: 'From',
      prop: 'From'
    }
  ],
  [
    MailTagsEnum.to,
    {
      tag: 'To',
      prop: 'To'
    }
  ],
  [
    MailTagsEnum.sizeMore,
    {
      tag: 'Size More Than',
      prop: 'Size'
    }
  ],
  [
    MailTagsEnum.sizeLess,
    {
      tag: 'Size Less Than',
      prop: 'Size'
    }
  ],
  [
    MailTagsEnum.subject,
    {
      tag: 'Subject',
      prop: 'Subject'
    }
  ],
  [
    MailTagsEnum.withAttachment,
    {
      tag: 'With Attachments',
      prop: 'HasAttach'
    }
  ],
  [
    MailTagsEnum.backupArchive,
    {
      tag: 'Backup Archive',
      prop: 'IsArchived'
    }
  ],
  [
    MailTagsEnum.dateTo,
    {
      tag: 'To Date',
      prop: 'ReceiveDate'
    }
  ],
  [
    MailTagsEnum.dateFrom,
    {
      tag: 'Date From',
      prop: 'ReceiveDate'
    }
  ]
]);
