import { MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeElement } from '../models/flatTreeElement.model';
import { TreeElement } from '../models/treeElement.model';
import { getIsNodeExpandable } from './getIsNodeExpandable';
import { getNodeChildren } from './getNodeChildren';
import { getNodeLevel } from './getNodeLevel';
import { nodeTransformer } from './nodeTransformer';

// Tells tree data source builder how to flatten our nested node data into flat nodes
export const treeFlattener = new MatTreeFlattener<TreeElement, FlatTreeElement>(
  nodeTransformer,
  getNodeLevel,
  getIsNodeExpandable,
  getNodeChildren
);
