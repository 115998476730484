import { Injectable } from '@angular/core';
import { SearchODataOptions, SiteItemODataModel } from '@app/common/models';
import { ODataPagedResult } from '@app/common/odata';
import { SortOrderByPipe } from '@app/common/pipes';
import { SiteItemsODataService } from '@app/common/services';
import { getUniqueListByProp } from '@app/common/services/smart-search';
import { SharePointTagsEnum, SharePointTagsMap } from '@app/common/services/smart-search/sharePoint/smart-search-tags-sharepoint.model';
import SmartSearchTemplatesBase from '@app/common/services/smart-search/smart-search-template-base';
import { smartSearchModelFactory } from '@app/common/utils/functions/search';
import { isNil } from 'lodash';
import { ModelTemplate, SmartSearchState } from 'mbs-ui-kit';
import { Observable, asapScheduler, scheduled } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable()
export class SmartSearchModelTemplateSharePointService extends SmartSearchTemplatesBase {
  readonly #siteName = SharePointTagsMap.get(SharePointTagsEnum.SiteName);
  readonly #fileName = SharePointTagsMap.get(SharePointTagsEnum.FileName);
  readonly #mixinName = SharePointTagsMap.get(SharePointTagsEnum.Mixin);

  constructor(private siteItemsService: SiteItemsODataService, private sortOrderByPipe: SortOrderByPipe<SiteItemODataModel>) {
    super();
  }

  public readonly SiteNameTag: ModelTemplate<SiteItemODataModel> = {
    tag: this.#siteName.tag,
    items: (state: SmartSearchState): Observable<SiteItemODataModel[]> => {
      const term = state.leftCaretValue;
      const options: SearchODataOptions = this.getSearchODataOptions({
        filter: this.siteItemsService.updateFilter(smartSearchModelFactory(this.#siteName.tag, term)) || '',
        orderBy: ''
      });

      return scheduled(
        this.siteItemsService.siteItems(options).pipe(
          take(1),
          map((res: ODataPagedResult<SiteItemODataModel>) => res.data),
          map((sites: SiteItemODataModel[]) => {
            const filtered = sites.filter((site) => !isNil(site.Name) && site.Name.toLowerCase().includes(term));
            const uniqueList = getUniqueListByProp(filtered, this.#siteName.prop).slice(0, 10);

            return this.sortOrderByPipe.transform(uniqueList, this.#siteName.prop);
          })
        ),
        asapScheduler
      );
    },
    itemFormatter: ({ Name }): string => Name,
    addGroupBrackets: true
  };

  public readonly FileNameTag: ModelTemplate<SiteItemODataModel> = {
    tag: this.#fileName.tag,
    items: (state: SmartSearchState): Observable<SiteItemODataModel[]> => {
      const term = state.leftCaretValue;
      const options: SearchODataOptions = this.getSearchODataOptions({
        filter: this.getFileFilter(state, term) || '',
        orderBy: 'Type asc'
      });

      return scheduled(
        this.siteItemsService.findDriveItemsOfSiteList(options).pipe(
          take(1),
          map((res: ODataPagedResult<SiteItemODataModel>) => res.data),
          map((files: SiteItemODataModel[]) => {
            const filtered = files.filter((file) => !isNil(file.Name) && file.Name.toLowerCase().includes(term));
            const uniqueList = getUniqueListByProp(filtered, this.#fileName.prop).slice(0, 10);

            return this.sortOrderByPipe.transform(uniqueList, this.#fileName.prop);
          })
        ),
        asapScheduler
      );
    },
    itemFormatter: ({ Name }): string => Name,
    addGroupBrackets: true
  };

  private getFileFilter(state: SmartSearchState, term: string): string {
    if (state.usedTags[0] === this.#siteName.tag.toLowerCase()) {
      // we need check first position `SiteName` tag for search file in site
      const smartSearchObjs = [];
      const value = state.model.find((i) => i['tagValue'] === this.#siteName.tag)['fields'][0].value;

      smartSearchObjs.push(smartSearchModelFactory(this.#mixinName.tag, value));
      smartSearchObjs.push(smartSearchModelFactory(this.#fileName.tag, term));

      return this.siteItemsService.updateFilter(smartSearchObjs);
    } else {
      return this.siteItemsService.updateFilter(smartSearchModelFactory(this.#fileName.tag, term));
    }
  }

  private getSearchODataOptions(options: { filter: string; orderBy: string }): SearchODataOptions {
    const { filter, orderBy } = options || {};

    return {
      filter,
      orderBy,
      page: 1,
      pageSize: 50
    };
  }
}
