import { Pipe, PipeTransform } from '@angular/core';
import { ReportStatus } from '@app/common';

@Pipe({
  name: 'userStatus',
  pure: true
})
export class UserStatusPipe implements PipeTransform {
  private readonly iconMap: Map<ReportStatus, string> = new Map([
    [ReportStatus.issue, 'fa fa-exclamation-circle'],
    [ReportStatus.success, 'fa fa-check-circle'],
    [ReportStatus.warning, 'fa fa-exclamation-triangle'],
    [ReportStatus.disabled, 'fa fa-circle'],
    [ReportStatus.overdue, 'fa fa-clock-o'],
    [ReportStatus.unknown, 'fa fa-question']
  ]);

  private readonly typeMap: Map<ReportStatus, string> = new Map([
    [ReportStatus.issue, 'danger'],
    [ReportStatus.success, 'success'],
    [ReportStatus.warning, 'warning'],
    [ReportStatus.disabled, 'disabled'],
    [ReportStatus.overdue, 'yellow'],
    [ReportStatus.unknown, 'muted']
  ]);

  transform(data: [ReportStatus, string], param: 'icon' | 'type' = 'icon'): string {
    const [state, reason] = data || [];

    if (!state) return '';

    switch (param) {
      case 'icon': {
        const classes = [];

        classes.push(this.iconMap.get(state));

        return classes.join(' ');
      }
      case 'type':
        return this.typeMap.get(this.checkUnknownState(state, reason));
      default:
        throw new Error('Unknown user status. UserStatusPipe');
    }
  }

  private readonly checkUnknownState = (state: ReportStatus, reason: string): ReportStatus => {
    const unknownState = state === ReportStatus.disabled && reason;
    return unknownState ? ReportStatus.unknown : state;
  };
}
