<div *ngIf="needToShowPaginationColumn || myFilters?.length > 0 || myContent || filtersGroup"
     class="row mbs-table_nav">

  <div *ngIf="myFilters?.length > 0 || myContent || filtersGroup"
       [ngClass]="{
         'col-lg-7 col-xl-8': !fullContentWidth && !wideContentMode,
         'col-lg-6': !fullContentWidth && wideContentMode,
         'col-12': true
       }">

    <ng-container *ngIf="filtersGroup">
      <ng-container [ngTemplateOutlet]="filtersGroup?.template"></ng-container>
    </ng-container>

    <div *ngIf="myFilters?.length > 0"
         class="row mbs-table_filter">

      <div *ngFor="let filter of myFilters"
           [class.mbs-table_filter-divider]="filter.mode === 'divider'"
           [class.mbs-table_filter-label]="filter.mode === 'label'"
           class="col-auto mbs-table_filter-item">
        <ng-container [ngTemplateOutlet]="filter.template"></ng-container>
      </div>

    </div>

    <div *ngIf="myContent && (!myFilters || myFilters?.length === 0 || !filtersGroup)"
         class="mbs-table-nav_custom-content">
      <ng-container *ngTemplateOutlet="myContent.template; context: { $implicit: myContent.context }"></ng-container>
    </div>

  </div>

  <div *ngIf="needToShowPaginationColumn"
       [ngClass]="{
         'col-lg-5 col-xl-4 mt-lg-0 mt-2' : !fullContentWidth && !wideContentMode && (myFilters?.length > 0 || myContent || filtersGroup),
         'col-lg-6' : !fullContentWidth && wideContentMode,
         'col-12': true
       }">

    <div class="form-row align-items-center justify-content-lg-end">

      <div *ngIf="customPrependControls.length > 0"
           class="col-auto">

        <ng-container *ngFor="let control of customPrependControls">
          <ng-container *ngTemplateOutlet="control.template"></ng-container>
        </ng-container>

      </div>

      <div *ngIf="showPagination"
           class="col-auto">

        <ngb-pagination (pageChange)="onPageChange($event)"
                        [collectionSize]="paginationOptionsInternal.dataSize"
                        [maxSize]="paginationOptionsInternal.maxSize"
                        [pageSize]="paginationOptionsInternal.pageSize"
                        [page]="paginationOptionsInternal.page"
                        [rotate]="paginationOptionsInternal.rotate"
                        class="mbs-table_pagination">

          <ng-template ngbPaginationPrevious>
            <span class="fa fa-long-arrow-left arrow"
                  ngbTooltip="Previous page"
                  container="auto"
                  placement="top-right"
                  tooltipClass="text-nowrap -no-arrow"></span>
          </ng-template>

          <ng-template ngbPaginationNext>
            <span class="fa fa-long-arrow-right arrow"
                  ngbTooltip="Next page"
                  container="auto"
                  placement="top-right"
                  tooltipClass="text-nowrap -no-arrow"></span>
          </ng-template>

        </ngb-pagination>

      </div>

      <div *ngIf="showExportToCSVButton"
           class="col-auto lh-1">
        <mbs-button (click)="export.emit()"
                    ngbTooltip="Export to CSV"
                    container="auto"
                    placement="top-right"
                    tooltipClass="text-nowrap -no-arrow"
                    class="mbs-pagination-btn_export_to_csv">
          {{ exportButtonName }}
        </mbs-button>
      </div>

      <div *ngIf="showRefreshButton"
           class="col-auto lh-1">
        <mbs-button (click)="refresh.emit()"
                    ngbTooltip="Refresh"
                    container="auto"
                    placement="top-right"
                    tooltipClass="-no-arrow"
                    [icon]="'ico ico-Refresh'"
                    class="mbs-pagination-btn_refresh"></mbs-button>
      </div>

      <div *ngIf="paginationOptionsInternal.pageSizeList &&
                  paginationOptionsInternal.dataSize > 0 &&
                  paginationOptionsInternal.pageSizeList[0] < paginationOptionsInternal.dataSize"
           class="col-auto d-flex align-self-stretch align-items-center">

        <mbs-button class="mbs-pagination-btn_perpage"
                    dropdownPlacement="bottom-right">{{ getPageSizeText(paginationOptionsInternal.pageSize) }}

          <ng-template mbsButtonDropdown
                       container=".mbs-pagination-btn_perpage">
            <button *ngFor="let size of paginationOptionsInternal.pageSizeList"
                    (click)="handleChangePageSize(size)"
                    class="-sm"
                    ngbDropdownItem>
              {{ getPageSizeMenuText(size) }}
            </button>
          </ng-template>

        </mbs-button>

      </div>

      <div *ngIf="enableSwitcher && switcherView"
           class="col-auto">

        <div class="mbs-table_switcher">

          <div class="mbs-table_switcher-item">
            <button (click)="onViewChange(sharedPersistentStateEnum.table)"
                    [ngClass]="{ 'active': myViewMode === sharedPersistentStateEnum.table }"
                    class="ctrl ctrl-sm mbs-table_switcher-ctrl"
                    ngbTooltip="Table view"
                    container="auto"
                    placement="top-right"
                    tooltipClass="text-nowrap -no-arrow">
              <span class="ico ico-ListView"></span>
            </button>
          </div>

          <div class="mbs-table_switcher-item">
            <button (click)="onViewChange(sharedPersistentStateEnum.grid)"
                    [ngClass]="{ 'active': myViewMode === sharedPersistentStateEnum.grid }"
                    class="ctrl ctrl-sm mbs-table_switcher-ctrl"
                    ngbTooltip="Tile view"
                    container="auto"
                    placement="top-right"
                    tooltipClass="text-nowrap -no-arrow">
              <span class="ico ico-GridView"></span>
            </button>
          </div>

        </div>

      </div>

      <div *ngIf="customAppendControls.length > 0"
           class="col-auto">

        <ng-container *ngFor="let control of customAppendControls">
          <ng-container *ngTemplateOutlet="control.template"></ng-container>
        </ng-container>

      </div>

    </div>

  </div>

</div>
