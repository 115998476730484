import { ApplicationRef, enableProdMode } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '@app/app.module';
import { environment } from '@env/environment';
import { MBS_APP_CONFIG, loadConfigurations } from './config.loader';

if (environment.production) {
  enableProdMode();
}

function mountApp(config: string) {
  platformBrowserDynamic([
    {
      provide: MBS_APP_CONFIG,
      useValue: config
    }
  ])
    .bootstrapModule(AppModule)
    .then((module) => {
      if (!environment.production) {
        const applicationRef = module.injector.get(ApplicationRef);
        const appComponent = applicationRef.components[0];

        enableDebugTools(appComponent);
      }
    })
    .catch((err) => console.error(err));
}

loadConfigurations('assets/config/config.json')
  .then((res: string) => {
    try {
      mountApp(JSON.parse(res));
    } catch (err) {
      console.error(err);
    }
  })
  .catch((err) => console.error(err.message));
