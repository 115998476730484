import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ButtonModule } from '../../button';
import { TagModule } from '../../tag';
import { InputBaseModule } from '../input-base/input-base.module';
import { TagsInputComponent } from './tags-input.component';

@NgModule({
  imports: [CommonModule, InputBaseModule, ReactiveFormsModule, TagModule, ButtonModule, NgScrollbarModule, NgbDropdownModule],
  declarations: [TagsInputComponent],
  exports: [TagsInputComponent]
})
export class TagsInputModule {}
