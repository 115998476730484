import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DeleteUserBackupModule } from '@app/common/components/delete-user-backup/delete-user-backup.module';
import { ExportToPstModule } from '@app/common/components/export-to-pst/export-to-pst.module';
import { PipesModule } from '@app/common/pipes/pipes.module';
import { MyAccountAlternateAccountTabComponent } from '@app/components/my-account/tabs/alternate-account-tab/alternate-account-tab.component';
import { DeleteAlternateAccountModalComponent } from '@app/components/my-account/tabs/alternate-account-tab/delete-alternate-account-modal/delete-alternate-account-modal.component';
import { ResetPasswordModalComponent } from '@app/components/my-account/tabs/alternate-account-tab/reset-password-modal/reset-password-modal.component';
import { TwoFactorAuthenticationComponent } from '@app/components/my-account/tabs/alternate-account-tab/two-factor-authentication/two-factor-authentication.component';
import { TwoFactorDisableComponent } from '@app/components/my-account/tabs/alternate-account-tab/two-factor-disable/two-factor-disable.component';
import { MyAccountBackupDestinationTabComponent } from '@app/components/my-account/tabs/backup-destination-tab/backup-destination-tab.component';
import { AllowedModalComponent } from '@app/components/my-account/tabs/general-tab/allowed-modal/allowed-modal.component';
import { AssignRetentionPolicyComponent } from '@app/components/my-account/tabs/general-tab/assign-retention-policy-modal/assign-retention-policy-modal.component';
import { DeleteBackupModalComponent } from '@app/components/my-account/tabs/general-tab/delete-backup-modal/delete-backup-modal.component';
import { ExportToPstModalComponent } from '@app/components/my-account/tabs/general-tab/export-to-pst-modal/export-to-pst-modal.component';
import { MyAccountGeneralTabComponent } from '@app/components/my-account/tabs/general-tab/general-tab.component';
import { I18_NAMESPACE_MODULE } from '@app/i18';
import { UserModule } from '@app/pages/users/user.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { I18NextModule, I18NEXT_NAMESPACE } from 'angular-i18next';
import { QRCodeModule } from 'angularx-qrcode';
import { MbsUiKitModule } from 'mbs-ui-kit';
import { MyAccountSidepanelComponent } from './my-account-sidepanel/my-account-sidepanel.component';

@NgModule({
  imports: [
    I18NextModule,
    MbsUiKitModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    NgbTooltipModule,
    ExportToPstModule,
    UserModule,
    DeleteUserBackupModule,
    RouterModule,
    PipesModule,
    QRCodeModule
  ],
  declarations: [
    MyAccountSidepanelComponent,
    MyAccountGeneralTabComponent,
    MyAccountAlternateAccountTabComponent,
    MyAccountBackupDestinationTabComponent,
    AssignRetentionPolicyComponent,
    ExportToPstModalComponent,
    DeleteBackupModalComponent,
    DeleteAlternateAccountModalComponent,
    ResetPasswordModalComponent,
    TwoFactorAuthenticationComponent,
    TwoFactorDisableComponent,
    AllowedModalComponent
  ],
  exports: [MyAccountSidepanelComponent],
  providers: [
    {
      provide: I18NEXT_NAMESPACE,
      useValue: [I18_NAMESPACE_MODULE.account, I18_NAMESPACE_MODULE.policy]
    }
  ]
})
export class MyAccountModule {}
