import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import { APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { FORMAT_TOKEN, formatTitle } from '@app/common/utils/helper/i18-format-title';
import { environment } from '@env/environment';
import { I18NEXT_SERVICE, ITranslationService, defaultInterpolationFormat } from 'angular-i18next';
import Backend from 'i18next-http-backend';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { startWith } from 'rxjs/operators';

export enum I18_NAMESPACE_MODULE {
  account = 'my-account.module',
  app = 'app',
  audit = 'audit.module',
  backupHistory = 'backup-history.module',
  button = 'button',
  calendar = 'calendar.module',
  chart = 'chart.module',
  contacts = 'contacts.module',
  dashboard = 'dashboard.module',
  dontHavePermission = 'dont-have-permission.module',
  drive = 'drive.module', // base module for Drive, SharePoint, Shared Drives, Teams pages
  history = 'history.module',
  mail = 'mail.module',
  payments = 'payments.module',
  policy = 'retention-policy.module',
  reports = 'reports.module',
  results = 'export-results.module',
  skipItems = 'skipped-items-history.module',
  tasks = 'task-manager.module',
  users = 'users.module'
}

const LOCALE_DATA = {
  en: localeEn
};

/*
 * Detect lang be html lang="" attr async
 * @returns Observable<string>
 */
export function documentLangObserver(): Observable<string> {
  const subject: Subject<string> = new Subject();
  const mutationObserver = new MutationObserver((mutationsList, _observer) => {
    for (const mutation of mutationsList) {
      if (mutation.type === 'attributes' && mutation.attributeName === 'lang') {
        subject.next((mutation.target as HTMLElement).getAttribute('lang'));
      }
    }
  });

  mutationObserver.observe(document.documentElement, { attributes: true });
  return subject.pipe(startWith(document.documentElement.getAttribute('lang')));
}

/**
 * Lang detect ngApp integration
 */
const LANGUAGE_DETECTOR = {
  type: 'languageDetector',
  async: true,
  init: function (services, detectorOptions, i18nextOptions) {
    /* use services and options */
  },
  detect: (callback) => {
    documentLangObserver().subscribe((lang: string) => {
      const data = LOCALE_DATA[lang] || LOCALE_DATA.en;

      registerLocaleData(data, lang);
      callback(lang);
    });
  },
  cacheUserLanguage: function (lng) {
    /* cache language */
  }
};

/**
 * i18next default options
 */
const i18nextOptions = {
  whitelist: ['en'],
  fallbackLng: 'en',
  debug: false,
  returnEmptyString: true,
  defaultNS: I18_NAMESPACE_MODULE.app,
  interpolation: {
    format: (value, format, lng) => {
      if (format === FORMAT_TOKEN) return formatTitle(value, lng);
      if (value instanceof Date) return moment(value).format(format);
      return defaultInterpolationFormat(value, format, lng);
    }
  },
  backend: {
    loadPath: environment.localePath,
    addPath: environment.localePath
  },
  ns: [
    I18_NAMESPACE_MODULE.app,
    I18_NAMESPACE_MODULE.account,
    I18_NAMESPACE_MODULE.button,
    I18_NAMESPACE_MODULE.dontHavePermission,
    I18_NAMESPACE_MODULE.policy
  ]
};

/*
 * Before ngApp init
 * @param i18next
 * @returns
 */
export function appInit(i18next: ITranslationService) {
  return () => {
    return i18next.use<any>(Backend).use<any>(LANGUAGE_DETECTOR).init(i18nextOptions);
  };
}

/*
 * get i18next instance ID
 * @param i18next
 * @returns
 */
export function localeIdFactory(i18next: ITranslationService): string {
  return i18next.language;
}

/**
 * ngApp integration
 */
export const I18N_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [I18NEXT_SERVICE],
    multi: true
  },
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: localeIdFactory
  }
];
