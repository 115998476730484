import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CardFooterComponent } from './card-footer.component';
import { CardGroupHeaderComponent } from './card-group-header.component';
import { CardGroupComponent } from './card-group.component';
import { CardHeaderComponent } from './card-header.component';
import { CardComponent } from './card.component';

const components = [CardComponent, CardHeaderComponent, CardGroupComponent, CardGroupHeaderComponent, CardFooterComponent];

@NgModule({
  imports: [CommonModule, NgbTooltipModule],
  declarations: components,
  exports: components
})
export class CardModule {}
