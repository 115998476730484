export enum SharePointTagsEnum {
  Mixin,
  SiteName,
  FileName
}

export const SharePointTagsMap: Map<SharePointTagsEnum, { tag: string; prop: string; value?: Record<string, string> }> = new Map([
  [SharePointTagsEnum.Mixin, { tag: 'Folder/File of Site', prop: 'SiteName' }],
  [SharePointTagsEnum.SiteName, { tag: 'Site Name', prop: 'Name' }],
  [SharePointTagsEnum.FileName, { tag: 'Folder/File Name', prop: 'Name' }]
]);
