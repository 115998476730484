import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[resizeColumn]'
})
export class ResizeColumnDirective implements OnInit {
  @Input('resizeColumn') resizable: boolean;

  @Input('resizeColumnMax') max = '30%';
  @Input('resizeColumnMin') min = '16.66666667%';

  @Input() reverse: boolean;

  private column: HTMLElement;
  private grid: HTMLElement;
  private resizer: HTMLSpanElement;

  private pressed: boolean;
  private startX: number;
  private startWidth: number;

  private readonly holderClass = 'mbs-resize-holder';

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    if (!(event.target instanceof Window)) return;

    this.updateClasses();
    this.updateStyles();
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    if (this.resizable) {
      this.initInstances();

      this.insertResizer();
      this.initStyles();
      this.initClasses();

      this.initListeners();
    }
  }

  private initInstances(): void {
    this.column = this.el.nativeElement;
    this.grid = this.renderer.parentNode(this.column);

    this.resizer = this.renderer.createElement('span');
  }

  private insertResizer(): void {
    this.renderer.addClass(this.resizer, this.holderClass);
    this.renderer.appendChild(this.column, this.resizer);
  }

  private initStyles(): void {
    this.renderer.setStyle(this.column, 'width', this.min);
    this.renderer.setStyle(this.column, 'min-width', this.min);
    this.renderer.setStyle(this.column, 'max-width', this.max);
  }

  private initClasses(): void {
    this.reverse && this.renderer.addClass(this.resizer, '--reverse');
  }

  private initListeners(): void {
    this.renderer.listen(this.resizer, 'mousedown', this.onMouseDown.bind(this));
    this.renderer.listen(this.grid, 'mousemove', this.onMouseMove.bind(this));
    this.renderer.listen('document', 'mouseup', this.onMouseUp.bind(this));
  }

  private updateClasses(): void {
    if (this.resizer) {
      this.resizable ? this.renderer.addClass(this.resizer, this.holderClass) : this.renderer.removeClass(this.resizer, this.holderClass);
    }
  }

  private updateStyles(): void {
    if (this.column) {
      this.resizable ? this.renderer.setStyle(this.column, 'width', this.min) : this.renderer.removeStyle(this.column, 'width');
      this.resizable ? this.renderer.setStyle(this.column, 'min-width', this.min) : this.renderer.removeStyle(this.column, 'min-width');
      this.resizable ? this.renderer.setStyle(this.column, 'max-width', this.max) : this.renderer.removeStyle(this.column, 'max-width');
    }
  }

  private onMouseDown(e: MouseEvent): void {
    this.pressed = true;
    this.startX = e.pageX;
    this.startWidth = this.column.offsetWidth;
  }

  private onMouseMove(e: MouseEvent): void {
    if (this.pressed && e.buttons) {
      e.preventDefault();

      const width = this.reverse ? this.startWidth + (this.startX - e.pageX) : this.startWidth + (e.pageX - this.startX);

      this.renderer.addClass(this.grid, 'resizing');
      this.renderer.setStyle(this.column, 'width', `${width}px`);
    }
  }

  private onMouseUp(): void {
    if (this.pressed) {
      this.pressed = false;
      this.renderer.removeClass(this.grid, 'resizing');
    }
  }
}
