import { Injectable } from '@angular/core';
import { NgbTimeAdapter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { pad } from '../../../utils/constants';

/**
 * Example of a String Time adapter
 */
@Injectable()
export class NgbTimeStringAdapter extends NgbTimeAdapter<string> {
  fromModel(value: string | null): NgbTimeStruct | null {
    if (!value) {
      return null;
    }

    if (typeof value == 'string') {
      const split = value.split(':');
      return {
        hour: parseInt(split[0], 10),
        minute: parseInt(split[1], 10),
        second: split[2] ? parseInt(split[2], 10) : null
      };
    } else {
      return value;
    }
  }

  toModel(time: NgbTimeStruct | null): string | null {
    return time != null ? `${pad(time.hour)}:${pad(time.minute)}:${pad(time.second)}` : null;
  }
}
