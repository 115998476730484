import { Injectable } from '@angular/core';
import { ServiceTypeByAPIEnum, TaskManagerOdata } from '@app/common/models';
import { ODataPagedResult } from '@app/common/odata';
import { OperationTypeToDisplayNamePipe, ServiceTypeToDisplayNamePipe } from '@app/common/pipes';
import { TaskManagerService } from '@app/common/services';
import { getUniqueListByProp, TaskManagerTagsEnum, TaskManagerTagsMap } from '@app/common/services/smart-search';
import SmartSearchTemplatesBase from '@app/common/services/smart-search/smart-search-template-base';
import { FilterOptions, getFilterByContains, getFilterByEq, getFilterByEqFromEnum } from '@app/common/utils/functions/search';
import { ModelTemplate, SmartSearchState } from 'mbs-ui-kit';
import { asapScheduler, Observable, scheduled } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class SmartSearchModelTemplateTaskManagerService extends SmartSearchTemplatesBase {
  readonly #userName = TaskManagerTagsMap.get(TaskManagerTagsEnum.userName);
  readonly #serviceType = TaskManagerTagsMap.get(TaskManagerTagsEnum.serviceType);
  readonly #task = TaskManagerTagsMap.get(TaskManagerTagsEnum.task);
  readonly #status = TaskManagerTagsMap.get(TaskManagerTagsEnum.status);

  private keyCache = 'task-manger-name_';

  private readonly taskManagerService$: Observable<ODataPagedResult<TaskManagerOdata>>;
  private readonly serviceTypeToDisplayNamePipe: ServiceTypeToDisplayNamePipe = new ServiceTypeToDisplayNamePipe();
  private readonly operationTypeToDisplayNamePipe: OperationTypeToDisplayNamePipe = new OperationTypeToDisplayNamePipe();

  constructor(private taskManagerService: TaskManagerService) {
    super();
    this.taskManagerService$ = taskManagerService.getTasks();
  }

  public readonly UserNameTag: ModelTemplate<TaskManagerOdata> = {
    tag: this.#userName.tag,
    items: (state: SmartSearchState): Observable<TaskManagerOdata[]> => {
      const term = state.leftCaretValue;
      const options: FilterOptions = {
        model: [{ value: term }],
        prop: this.#userName.prop
      };

      this.taskManagerService.filter = term ? getFilterByContains(options) : '';

      // if (this.cache[this.keyCache + term]) {
      //   return of(this.cache[this.keyCache + term]) as Observable<TaskManagerOdata[]>;
      // }

      return scheduled(
        this.taskManagerService$.pipe(
          map((res) => res.data),
          map((tasks: TaskManagerOdata[]) => {
            const filtered = tasks.filter((task) => task.UserName.toLowerCase().includes(term));

            return getUniqueListByProp(filtered, this.#userName.prop).slice(0, 10);
          }),
          tap(this.writeToCache(this.keyCache + term))
        ),
        asapScheduler
      ) as Observable<TaskManagerOdata[]>;
    },
    itemFormatter: ({ UserName }): string => UserName,
    addGroupBrackets: true
  };

  public readonly ServiceTypeTag: ModelTemplate<TaskManagerOdata> = {
    tag: this.#serviceType.tag,
    items: (state: SmartSearchState): Observable<TaskManagerOdata[]> => {
      const term = state.leftCaretValue;
      const options: FilterOptions = {
        model: [{ value: term }],
        prop: this.#serviceType.prop,
        enumItems: ServiceTypeByAPIEnum
      };

      this.taskManagerService.filter = term ? getFilterByEqFromEnum(options) : '';

      // if (this.cache[this.keyCache + term]) {
      //   return of(this.cache[this.keyCache + term]) as Observable<TaskManagerOdata[]>;
      // }

      return scheduled(
        this.taskManagerService$.pipe(
          map((res) => res.data),
          map((tasks: TaskManagerOdata[]) => {
            const filtered = tasks.filter((task) => task.ServiceType.toLowerCase().includes(term));

            return getUniqueListByProp(filtered, this.#serviceType.prop).slice(0, 10);
          }),
          tap(this.writeToCache(this.keyCache + term))
        ),
        asapScheduler
      ) as Observable<TaskManagerOdata[]>;
    },
    itemFormatter: ({ ServiceType }): string => this.serviceTypeToDisplayNamePipe.transform(ServiceType),
    addGroupBrackets: true
  };

  public readonly TaskTag: ModelTemplate<TaskManagerOdata> = {
    tag: this.#task.tag,
    items: (state: SmartSearchState): Observable<TaskManagerOdata[]> => {
      const term = state.leftCaretValue;
      const options: FilterOptions = {
        model: [{ value: term }],
        prop: this.#task.prop
      };

      this.taskManagerService.filter = term ? getFilterByEq(options) : '';

      // if (this.cache[this.keyCache + term]) {
      //   return of(this.cache[this.keyCache + term]) as Observable<TaskManagerOdata[]>;
      // }

      return scheduled(
        this.taskManagerService$.pipe(
          map((res) => res.data),
          map((tasks: TaskManagerOdata[]) => {
            const filtered = tasks.filter((task) => task.OperationType.toLowerCase().includes(term));

            return getUniqueListByProp(filtered, this.#task.prop).slice(0, 10);
          }),
          tap(this.writeToCache(this.keyCache + term))
        ),
        asapScheduler
      ) as Observable<TaskManagerOdata[]>;
    },
    itemFormatter: ({ OperationType }): string => this.operationTypeToDisplayNamePipe.transform(OperationType),
    addGroupBrackets: true
  };

  public readonly StatusTag: ModelTemplate<TaskManagerOdata> = {
    tag: this.#status.tag,
    items: (state: SmartSearchState): Observable<TaskManagerOdata[]> => {
      const term = state.leftCaretValue;
      const options: FilterOptions = {
        model: [{ value: term }],
        prop: this.#status.prop
      };

      this.taskManagerService.filter = term ? getFilterByEq(options) : '';

      // if (this.cache[this.keyCache + term]) {
      //   return of(this.cache[this.keyCache + term]) as Observable<TaskManagerOdata[]>;
      // }

      return scheduled(
        this.taskManagerService$.pipe(
          map((res) => res.data),
          map((tasks: TaskManagerOdata[]) => {
            const filtered = tasks.filter((task) => task.State.toLowerCase().includes(term));

            return getUniqueListByProp(filtered, this.#status.prop).slice(0, 10);
          }),
          tap(this.writeToCache(this.keyCache + term))
        ),
        asapScheduler
      ) as Observable<TaskManagerOdata[]>;
    },
    itemFormatter: ({ State }): string => State,
    addGroupBrackets: true
  };
}
