export enum ServiceTypeStr {
  All = 'All',
  CalculateProviderSize = 'CalculateProviderSize',
  Calendar = 'Calendar',
  Contacts = 'Contacts',
  Drive = 'Drive',
  Export = 'Export',
  History = 'History',
  Mail = 'Mail',
  Payments = 'Payments',
  PersonalSite = 'PersonalSite',
  PublicFolder = 'PublicFolder',
  PublicFolderCalendar = 'PublicFolderCalendar',
  PublicFolderContact = 'PublicFolderContact',
  PublicFolderMail = 'PublicFolderMail',
  SharePoint = 'SharePoint',
  TeamDrives = 'TeamDrives',
  Teams = 'Teams',
  Users = 'Users'
}
