import {
  AfterContentInit,
  ChangeDetectorRef,
  ContentChildren,
  Directive,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  QueryList
} from '@angular/core';
import { isNil } from 'lodash';
import { TabsetDirectiveToken, TabsetDirectiveType } from '../tokens/tabset.directive.token';
import { TabsetGroupDirective } from './tabset-group.directive';
import { TabsetItemDirective } from './tabset-item.directive';

@Directive({
  selector: '[mbsTabset]',
  exportAs: 'mbsTabset',
  host: {
    '[class.mbs-tabset]': 'true',
    '[class.-horizontal]': 'horizontal',
    '[class.-vertical]': 'vertical',
    '[class.-inline]': 'inline'
  },
  providers: [{ provide: TabsetDirectiveToken, useExisting: forwardRef(() => TabsetDirective) }]
})
export class TabsetDirective implements AfterContentInit, TabsetDirectiveType {
  @Input() public activeId: string;
  @Input() loadOnAppearance = true;
  @Input() public mode: 'vertical' | 'horizontal' | 'inline' = 'horizontal';

  get horizontal(): boolean {
    return this.mode === 'horizontal';
  }

  get vertical(): boolean {
    return this.mode === 'vertical';
  }

  get inline(): boolean {
    return this.mode === 'inline';
  }

  @Output() beforeUpdate = new EventEmitter();
  @Output() afterUpdate = new EventEmitter();
  @ContentChildren(TabsetItemDirective, { descendants: true }) items: QueryList<TabsetItemDirective>;
  @ContentChildren(TabsetGroupDirective, { descendants: true }) groups: QueryList<TabsetGroupDirective>;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngAfterContentInit(): void {
    if (isNil(this.activeId)) {
      const nextId = this.items.first ? this.items.first.id : null;
      this.updateActiveId(nextId, false);
      this.cdRef.detectChanges();
    }
  }

  public activateGroup(groupId: string): void {
    const group = this.groups.toArray().find((group: TabsetGroupDirective) => group.id === groupId);
    if (!group.isDisabled) {
      group.isActive = true;
    }
  }

  public click(item: TabsetItemDirective): void {
    if (!item.isDisabled) {
      this.updateActiveId(item.id);
    }
  }

  public select(itemId: string): void {
    this.updateActiveId(itemId, false);
  }

  private updateActiveId(nextId: string, emitEvent = true): void {
    if (this.activeId !== nextId) {
      let performUpdate = true;

      this.beforeUpdate.emit({
        nextId: nextId,
        currentId: this.activeId,
        stop: () => {
          performUpdate = false;
        }
      });

      if (performUpdate) {
        this.activeId = nextId;

        if (emitEvent) {
          this.afterUpdate.emit(nextId);
        }
      }
    }
  }
}
