import { Injectable } from '@angular/core';
import { NgbTimeAdapter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { pad } from '../../../utils/constants';

@Injectable()
export class NgbTimeStringAdapterFormat12 extends NgbTimeAdapter<string> {
  private readonly meridian = 12;

  fromModel(value: string | null): NgbTimeStruct | null {
    if (!value) return null;

    if (typeof value == 'string') {
      const parseTime = /[ :]/;
      const [hours, minutes, meridian] = value.split(parseTime);

      return {
        hour: this.setHours(hours, meridian),
        minute: parseInt(minutes, 10),
        second: null
      };
    } else {
      return value;
    }
  }

  toModel(time: NgbTimeStruct | null): string | null {
    if (!time) return null;

    const { hour, minute } = time;
    const isNoon = hour == this.meridian;
    const isMidnight = hour == 0;
    const isPm = hour >= this.meridian;
    const isAm = hour < this.meridian;

    if (isPm) return `${!isNoon ? pad(hour - this.meridian) : this.meridian}:${pad(minute)} PM`;
    if (isAm) return `${!isMidnight ? pad(hour) : this.meridian}:${pad(minute)} AM`;

    return null;
  }

  private setHours(hours: string, meridian: string): number {
    if (!hours || !meridian) return null;

    const m = meridian.toLowerCase();
    const h = +hours;

    if (h == this.meridian && m == 'am') return 0;
    if (h != this.meridian && m == 'am') return parseInt(hours, 10);
    if (h == this.meridian && m == 'pm') return this.meridian;
    if (h != this.meridian && m == 'pm') return parseInt(hours, 10) + this.meridian;

    return 0;
  }
}
