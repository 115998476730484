import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { IMaskModule } from 'angular-imask';
import { InputBaseModule } from '../input-base/input-base.module';
import { IPAddressComponent } from './ip-address.component';

@NgModule({
  declarations: [IPAddressComponent],
  imports: [CommonModule, FormsModule, NgbTooltipModule, IMaskModule, InputBaseModule],
  exports: [IPAddressComponent],
  providers: []
})
export class IPAddressModule {
  constructor() {
    // empty
  }
}
