import { SortDirection } from 'mbs-ui-kit';

export function getOdataOrderBy(column: string, direction: SortDirection, extra?: string): string {
  if (direction) {
    const orderBy = `${column} ${direction}`;

    return extra?.trim().length ? extra + ', ' + orderBy : orderBy;
  } else {
    return null;
  }
}
