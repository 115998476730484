<mbs-modal [title]="moduleUsers + ':modal.title.editSetting' | i18next: { format: 'title' }">

  <ng-container [formGroup]="formGroup"
                modal-body>

    <mbs-form-group [title]="isGoogle
                     ? (moduleUsers + ':modal.subTitle.unit' | i18next: { format: 'capitalize' })
                     : (moduleUsers + ':modal.subTitle.group' | i18next: { format: 'capitalize' })"
                    class="mb-4">
      {{ names }} <span *ngIf="isGroupAction">selected</span>
    </mbs-form-group>

    <mbs-form-group class="mb-0">

      <h5 class="mb-0">
        {{ moduleUsers + ':modal.subTitle.autoActivate' | i18next: { format: 'title' } }}
      </h5>

      <p class="text-muted text-sm mb-3">
        {{ moduleUsers + ':modal.body.newGroupSettings' | i18next: { format: 'capitalize' } }}
      </p>

      <mbs-radio [label]="moduleUsers + ':modal.label.allUsers' | i18next: { format: 'capitalize' }"
                 [descriptionOutside]="false"
                 [disabledSelf]="loading$ | async"
                 [labelPosition]="'right'"
                 [value]="AutoActivationMode.AllUsers"
                 name="autoActivationMode"
                 formControlName="autoActivationMode">

        <ng-template radioDescription>
          <div class="mbs-form-text small mt-0">
            {{
              isGoogle
                ? (moduleUsers + ':modal.description.allUsersGoogle' | i18next: { format: 'capitalize' })
                : (moduleUsers + ':modal.description.allUsersOffice' | i18next: { format: 'capitalize' })
            }}
          </div>
        </ng-template>

      </mbs-radio>

      <mbs-radio [label]="moduleUsers + ':modal.label.newUsersOnly' | i18next: { format: 'capitalize' }"
                 [descriptionOutside]="false"
                 [disabledSelf]="loading$ | async"
                 [labelPosition]="'right'"
                 [value]="AutoActivationMode.NewUsers"
                 name="autoActivationMode"
                 formControlName="autoActivationMode">

        <ng-template radioDescription>
          <div class="mbs-form-text small mt-0">
            {{
              isGoogle
                ? (moduleUsers + ':modal.description.newUsersOnlyGoogle' | i18next: { format: 'capitalize' })
                : (moduleUsers + ':modal.description.newUsersOnlyOffice' | i18next: { format: 'capitalize' })
            }}
          </div>
        </ng-template>

      </mbs-radio>

      <mbs-radio [label]="moduleUsers + ':modal.label.disabled' | i18next: { format: 'capitalize' }"
                 [descriptionOutside]="false"
                 [disabledSelf]="loading$ | async"
                 [labelPosition]="'right'"
                 [value]="AutoActivationMode.Disabled"
                 name="autoActivationMode"
                 formControlName="autoActivationMode">

        <ng-template radioDescription>
          <div class="mbs-form-text small mt-0">
            {{
              isGoogle
                ? (moduleUsers + ':modal.description.disabledGoogle' | i18next: { format: 'capitalize' })
                : (moduleUsers + ':modal.description.disabledOffice' | i18next: { format: 'capitalize' })
            }}
          </div>
        </ng-template>

      </mbs-radio>

    </mbs-form-group>

  </ng-container>

  <ng-container modalFooter>

    <mbs-button type="primary"
                [loading]="saveLoading$ | async"
                [disabled]="!formGroup.valid || isNotChanged"
                (click)="handleSave()">
      {{ moduleButton + ':button.apply' | i18next: { format: 'title' } }}
    </mbs-button>

    <mbs-button (click)="handleCancel()">
      {{ moduleButton + ':button.cancel' | i18next: { format: 'title' } }}
    </mbs-button>

  </ng-container>

</mbs-modal>
