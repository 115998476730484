import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ExportToPstComponent } from '@app/common/components/export-to-pst/export-to-pst.component';
import { I18NextModule } from 'angular-i18next';
import { MbsUiKitModule } from 'mbs-ui-kit';

@NgModule({
  imports: [ReactiveFormsModule, MbsUiKitModule, CommonModule, FormsModule, I18NextModule],
  declarations: [ExportToPstComponent],
  exports: [ExportToPstComponent]
})
export class ExportToPstModule {}
