import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[mbsFocus]'
})
export class FocusDirective implements OnChanges {
  @Input() isFocused: boolean;

  constructor(private el: ElementRef) {}

  ngOnChanges() {
    if (this.isFocused) {
      queueMicrotask(() => this.el.nativeElement.querySelectorAll('[tabindex]')[0].focus());
    }
  }
}
