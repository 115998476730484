<mbs-sidepanel id="group_actions"
               [tabs]="true"
               [showFooter]="true"
               innerContentType="tabs">

  <div header-title
       class="mbs-sidepanel_title">

    <div class="group-action-sidepanel_title-ico mbs-sidepanel_title-ico">

      <ng-container *ngIf="isUserDeleted || isGlobalAdmin; else defaultUserIcon">

        <span class="mbs-user_ico">

          <ng-container *ngTemplateOutlet="singleUserIcon"></ng-container>

          <span *ngIf="isUserDeleted"
                class="fa fa-times"></span>

          <span *ngIf="isGlobalAdmin"
                class="fa fa-star text-purple"></span>

        </span>

      </ng-container>

      <ng-template #defaultUserIcon>
        <ng-container *ngTemplateOutlet="singleUserIcon"></ng-container>
      </ng-template>

      <ng-template #singleUserIcon>
        <span [ngClass]="[userIcon, userIconCssClass]"></span>
      </ng-template>

    </div>

    <div class="mbs-sidepanel_title-name text-base overflow-hidden"
         *ngIf="!singleUser; else userInfo">
      {{ moduleUsers + ':sidepanel.title.selectedLengthUsers' | i18next: { format: 'title', length: userIds.length } }}
    </div>

    <ng-template #userInfo>

      <div class="overflow-hidden">

        <div class="mbs-sidepanel_title-name text-base">
          {{ user.Name }}
        </div>

        <mbs-text-ellipsis [tooltip]="user.Email"
                           *ngIf="user.Email"
                           class="text-sm"
                           tooltipClass="tooltip-lg">
          {{ user.Email }}
        </mbs-text-ellipsis>
      </div>

    </ng-template>

  </div>

  <div body
       class="mbs-sidepanel_tabset">

    <mbs-loader *ngIf="loading"></mbs-loader>

    <ul mbsTabset
        [loadOnAppearance]="false"
        #activeContent="mbsTabset">

      <!--General Tab-->
      <li *ngIf="!deleteMode && !exportMode"
          mbsTabsetItem="general">

        <button mbsTabsetLink
                [title]="moduleUsers + ':sidepanel.tab.generalOptions' | i18next: { format: 'title' }">
          <span class="ico ico-Gear mbs-tabset_link-ico"></span>
        </button>

        <ng-template mbsTabsetContent>

          <header class="mbs-sidepanel_content-header">
            <h2 class="mbs-sidepanel_content-title">
              {{ moduleUsers + ':sidepanel.tab.generalOptions' | i18next: { format: 'title' } }}
            </h2>
          </header>

          <div class="mbs-sidepanel_content-scrolling tab-pane">
            <app-backup-config-control [hidden]="loading"
                                       [backupEnabled]="user.UserProviderState !== 'NotInDomain'"
                                       [IsPublicFolder]="user.IsPublicFolder"
                                       class="mbs-form_content"
                                       [class.pt-3]="disabledServices.length !== 0"
                                       [userIdForLink]="userIdForLinks"
                                       [settings]="backupConfigSettings"
                                       [disabledServices]="disabledServices"
                                       (mbsChange)="backupConfigChanged($event)">
            </app-backup-config-control>
          </div>

        </ng-template>

      </li>
      <!--/General Tab-->

      <!--Permissions Tab-->
      <li *ngIf="!deleteMode && !exportMode && !user.IsPublicFolder"
          mbsTabsetItem="permissions">

        <button mbsTabsetLink
                [title]="moduleUsers + ':sidepanel.tab.permissions' | i18next: { format: 'title' }">
          <span class="ico ico-Lock2 mbs-tabset_link-ico"></span>
        </button>

        <ng-template mbsTabsetContent>

          <header class="mbs-sidepanel_content-header">
            <h2 class="mbs-sidepanel_content-title">
              {{ moduleUsers + ':sidepanel.tab.permissions' | i18next: { format: 'title' } }}
            </h2>
          </header>

          <div class="mbs-sidepanel_content-scrolling tab-pane">
            <app-user-permissions-control [hidden]="loading"
                                          class="mbs-form_content"
                                          [user]="user"
                                          [forManyUsers]="userIds.length > 1"
                                          [alternateEmailInfo]="alternateEmailInfo"
                                          [(alternateSelected)]="alternateSelected"
                                          (formsInvalid)="invalidForms($event)"
                                          (editAlternateEmailModel)="changeEditAlternateModel($event)"
                                          (newAlternateEmailModel)="changeNewAlternateEmailModel($event)">
            </app-user-permissions-control>
          </div>

        </ng-template>

      </li>
      <!--/Permissions Tab-->

      <!-- Export to PST Tab -->
      <li *ngIf="exportMode"
          mbsTabsetItem="exportToPST">

        <button mbsTabsetLink
                [title]="moduleUsers + ':sidepanel.tab.exportToPST' | i18next: { format: 'title' }">
          <span class="ico ico-file mbs-tabset_link-ico"></span>
        </button>

        <ng-template mbsTabsetContent>

          <header class="mbs-sidepanel_content-header">
            <h2 class="mbs-sidepanel_content-title">
              {{ moduleUsers + ':sidepanel.tab.exportToPST' | i18next: { format: 'title' } }}
            </h2>
          </header>

          <div class="mbs-sidepanel_content-scrolling tab-pane">
            <app-export-to-PST-control [hidden]="loading"
                                       [needResetForm]="close | async"
                                       (exportServices)="handleUpdatePayloadForExportToPST($event)"
                                       (formValid)="invalidForms($event)"
                                       class="mbs-form_content">
            </app-export-to-PST-control>
          </div>

        </ng-template>

      </li>
      <!-- / Export to PST Tab -->

      <!--Policies Tab-->
      <li *ngIf="!deleteMode && !exportMode"
          mbsTabsetItem="policies">

        <button mbsTabsetLink
                [title]="moduleUsers + ':sidepanel.tab.retentionPolicy' | i18next: { format: 'title' }">
          <span class="ico ico-boxClock mbs-tabset_link-ico"></span>
        </button>

        <ng-template mbsTabsetContent>
          <app-user-retention-policies-control [hidden]="loading"
                                               [userIds]="userIds">
          </app-user-retention-policies-control>
        </ng-template>

      </li>
      <!--/Policies Tab-->

      <!--Info Tab-->
      <li *ngIf="singleUser && !deleteMode && !exportMode"
          mbsTabsetItem="info">

        <button mbsTabsetLink
                [title]="moduleUsers + ':sidepanel.tab.information' | i18next: { format: 'title' }">
          <span class="ico ico-Info mbs-tabset_link-ico"></span>
        </button>

        <ng-template mbsTabsetContent>

          <header class="mbs-sidepanel_content-header">
            <h2 class="mbs-sidepanel_content-title">
              {{ moduleUsers + ':sidepanel.tab.information' | i18next: { format: 'title' } }}
            </h2>
          </header>

          <div class="mbs-sidepanel_content-scrolling tab-pane">
            <app-user-information-control class="mbs-form_content"
                                          [user]="user">
            </app-user-information-control>
          </div>

        </ng-template>

      </li>
      <!--/Info Tab-->

      <!--Delete Tab-->
      <li *ngIf="deleteMode && !exportMode"
          mbsTabsetItem="delete">

        <button mbsTabsetLink
                [title]="moduleUsers + ':sidepanel.tab.deleteBackup' | i18next: { format: 'title' }">
          <span class="ico ico-Lock2 mbs-tabset_link-ico"></span>
        </button>

        <ng-template mbsTabsetContent>
          <div class="mbs-sidepanel_content-scrolling tab-pane">

            <header class="mbs-sidepanel_content-header">
              <h2 class="mbs-sidepanel_content-title">
                {{ moduleUsers + ':sidepanel.tab.deleteBackup' | i18next: { format: 'title' } }}
              </h2>
            </header>

            <div class="mbs-sidepanel_content-scrolling tab-pane">
              <app-delete-user-backup [hidden]="loading"
                                      class="mbs-form_content"
                                      [statistics]="backupStatistics$ | async"
                                      [formError]="deleteBackupFormError"
                                      (deleteBackupChange)="deleteBackupChange($event)"
                                      (deleteBackupFormValid)="invalidForms($event)">
              </app-delete-user-backup>
            </div>

          </div>
        </ng-template>

      </li>
      <!--/Delete Tab-->

    </ul>

    <div [mbsTabsetOutlet]="activeContent"></div>

  </div>

  <ng-template #footerTemplate>

    <div *ngIf="deleteMode"
         class="mbs-sidepanel_footer-col">
      <mbs-button type="danger"
                  [disabled]="disabledDelete || loading"
                  [loading]="loading"
                  (click)="genericPanel.deleteEvent.emit()">
        {{ moduleUsers + ':sidepanel.button.delete' | i18next: { format: 'title' } }}
      </mbs-button>
    </div>

    <div *ngIf="exportMode"
         class="mbs-sidepanel_footer-col">
      <mbs-button type="primary"
                  [disabled]="disabledExport || loading"
                  [loading]="loading"
                  (click)="handleExportToPST()">
        {{ moduleUsers + ':sidepanel.button.export' | i18next: { format: 'title' } }}
      </mbs-button>
    </div>

    <div *ngIf="!exportMode && !deleteMode"
         class="mbs-sidepanel_footer-col">
      <mbs-button type="primary"
                  [disabled]="disabledSave || loading"
                  [loading]="loading"
                  (click)="genericPanel.saveEvent.emit()">
        {{ moduleUsers + ':sidepanel.button.save' | i18next: { format: 'title' } }}
      </mbs-button>
    </div>

    <div class="mbs-sidepanel_footer-col">
      <mbs-button type="secondary"
                  (click)="close.emit()">
        {{ moduleUsers + ':sidepanel.button.close' | i18next: { format: 'title' } }}
      </mbs-button>
    </div>

  </ng-template>

</mbs-sidepanel>

<ng-template #exportJobTransferredTemplateRef>
  {{ moduleUsers + ':toast.body.exportJobTransferred' | i18next: { format: 'capitalize' } }}
  <a [routerLink]="[prefix + RoutePath.TaskManager]"
     class="d-inline">{{ moduleUsers + ':toast.body.taskManager' | i18next: { format: 'title' } }}</a>
</ng-template>
