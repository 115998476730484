import { Pipe, PipeTransform } from '@angular/core';
import { UserActionTypeArea } from '@app/common/models';

@Pipe({
  name: 'userNameTypeArea'
})
export class UserActionTypeAreaPipe implements PipeTransform {
  transform(key: string): string {
    return UserActionTypeArea[key] ? UserActionTypeArea[key].displayName : '';
  }
}
