import {
  AfterContentInit,
  Component,
  ContentChildren,
  Input,
  QueryList
} from '@angular/core';
import { StepComponent } from './step/step.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'mbs-steps',
  templateUrl: './steps.component.html'
})
export class StepsComponent implements AfterContentInit {
  @ContentChildren(StepComponent) steps: QueryList<StepComponent>;
  myActiveStep: number | string;
  @Input() set activeStep(value: number | string) {
    this.myActiveStep = value;
    this.handleActiveStep(value);
  }

  ngAfterContentInit(): void {
    if (this.steps.length === 0) {
      return;
    }
    this.handleActiveStep(this.myActiveStep || this.steps.first.number);
    this.steps.changes.pipe(untilDestroyed(this)).subscribe(() => {
      this.steps.forEach((step, index) => {
        step.lastStep = index === this.steps.length - 1;
      })
    })
  }

  handleActiveStep(value: number | string): void {
    setTimeout(() => {
      if (!this.steps) {
        return;
      }
      this.steps.first.singleStep = this.steps.length == 1;
      this.steps.last.lastStep = true;
      let activeStepIndex = this.steps.length;
      this.steps.forEach((s, index) => {
        if (index < activeStepIndex) {
          s.complete();
        }
        if (s.number == value) {
          activeStepIndex = index;
          s.activate();
        }
        if (index > activeStepIndex) {
          s.pending();
        }
      });
    });
  }
}
