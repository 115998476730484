import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

@Injectable()
export class FormsUtil {
  public static triggerValidation(control: AbstractControl) {
    if (control instanceof FormGroup) {
      for (const field in control.controls) {
        if(!Object.prototype.hasOwnProperty.call(control.controls, field)) continue;
        const c = control.controls[field];

        this.triggerValidation(c);
      }
    } else if (control instanceof FormArray) {
      for (const items of control.controls) {
        this.triggerValidation(items);
      }
    }

    if (control.validator) {
      const errors = control.validator(control);
      if (errors && Object.getOwnPropertyNames(errors).length > 0) {
        control.updateValueAndValidity({ onlySelf: false });
        control.markAsDirty();
        control.markAsTouched();
      }
    }
  }
}
