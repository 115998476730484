import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { APPEND_BUTTON_PASSWORD_STATE, ODataError } from '@app/common';
import { UserOdataService } from '@app/common/services';
import { getAppendButtonsIcon, getAppendButtonsState, hasAppendButtonsPasswordType } from '@app/common/utils';
import { I18_NAMESPACE_MODULE } from '@app/i18';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MbsValidators, ModalComponent } from 'mbs-ui-kit';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

type PartialUserData = { id: string; email: string };

@UntilDestroy()
@Component({
  templateUrl: './two-factor-authentication.component.html',
  styleUrls: ['./two-factor-authentication.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TwoFactorAuthenticationComponent implements OnInit {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  #user: PartialUserData;

  public validateLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public disabled$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  public passwordType$: BehaviorSubject<string> = new BehaviorSubject(APPEND_BUTTON_PASSWORD_STATE.hidden.type);

  public formGroup: FormGroup;
  public secret = '';

  public twoStepQRURL = '';
  public readonly moduleAccount = I18_NAMESPACE_MODULE.account;

  public readonly getAppendButtonsIcon = getAppendButtonsIcon;
  set user(value: PartialUserData) {
    this.#user = value;
  }

  get user(): PartialUserData {
    return this.#user;
  }

  get passwordControl(): FormControl<string> {
    return <FormControl>this.formGroup.get('password');
  }

  constructor(private fb: FormBuilder, private userService: UserOdataService) {}

  ngOnInit(): void {
    this.user = this.baseModal.data.user as PartialUserData;
    this.initForm();

    this.loading$.next(true);
    this.userService
      .getSecretForAlternate(this.user.id)
      .pipe(
        finalize(() => this.loading$.next(false)),
        untilDestroyed(this)
      )
      .subscribe({
        next: (secret) => {
          this.secret = secret;
          this.twoStepQRURL = `otpauth://totp/MSP360:${this.user.email}?secret=${secret}`;
        },
        error: () => {
          this.formGroup.setErrors({ noSecret: true });
          this.secret = 'Error while getting secret';
        }
      });
  }

  private initForm(): void {
    this.formGroup = this.fb.group({
      responseCode: [null, [Validators.required]],
      password: [null, [Validators.required, MbsValidators.passwordValidator, Validators.maxLength(20)]]
    });
  }

  handleValidate(): void {
    const { password, responseCode } = this.formGroup.value;

    this.validateLoading$.next(true);
    this.userService
      .enableAlternateTwoStep(this.user.id, password, responseCode)
      .pipe(
        finalize(() => this.validateLoading$.next(false)),
        untilDestroyed(this)
      )
      .subscribe({
        next: () => {
          this.baseModal.save(true);
        },
        error: (res: HttpErrorResponse) => {
          if (res.status === 400) {
            const odataError = res.error as ODataError;
            const errorText = odataError && odataError.value ? odataError.value : null;

            this.setPasswordErrorState(errorText);
          }
        }
      });
  }

  private setPasswordErrorState(message: string): void {
    this.passwordControl.setValue('');
    this.passwordControl.setErrors({ password: { message } });
  }

  handleClose(): void {
    this.baseModal.close();
  }

  handleChangePasswordType(): void {
    const isPasswordType: boolean = hasAppendButtonsPasswordType(this.passwordType$.value);

    this.passwordType$.next(APPEND_BUTTON_PASSWORD_STATE[getAppendButtonsState(isPasswordType)].type);
  }
}
