import { DOCUMENT } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NgbDropdown, NgbDropdownMenu } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AdvancedSearchModel } from './advanced-search-model';
import { AdvancedSearchParser } from './advanced-search-parser';
import { Template } from './Template';

export const ADVANCED_SEARCH_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line no-use-before-define
  useExisting: forwardRef(() => AdvancedSearchComponent),
  multi: true
};

@UntilDestroy()
@Component({
  selector: 'mbs-advanced-search',
  templateUrl: './advanced-search.component.html',
  providers: [ADVANCED_SEARCH_VALUE_ACCESSOR]
})
export class AdvancedSearchComponent implements OnDestroy, ControlValueAccessor, AfterContentInit {
  private _mbsSelectOpenedNgOptions: HTMLElement[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    @Inject(DOCUMENT) private doc: Document,
    private cd: ChangeDetectorRef
  ) {
    this.items$.pipe(filter(Boolean), untilDestroyed(this)).subscribe((items) => {
      this.unwrapItems = items;
      this.showDropdown = items.length > 0;
      this.cd.markForCheck();
    });
  }

  @Input() searchFormTemplate: TemplateRef<any>;
  @Input() placeholder = 'Enter Search Request';

  /**
   * Class for span inside of search button
   */
  @Input() searchIcon = 'ico ico-Search';

  set value(value: AdvancedSearchModel) {
    this.myModel = value;
    this.notifyValueChange();
  }

  get value(): AdvancedSearchModel {
    return this.myModel;
  }
  public get searchContent(): string {
    return this.advancedSearch.nativeElement.textContent;
  }
  public set searchContent(v: string) {
    if (v) {
      this.advancedSearch.nativeElement.innerHTML = v.replace(/\s+/g, '&nbsp;');
      this.parseContent(v, this.caret);
    }
  }

  public get searchHasFocus() {
    return this.doc.activeElement === this.advancedSearch.nativeElement;
  }

  public myModel: AdvancedSearchModel;
  public caret = 0;
  public tagInfo: Template<any>;
  /**
   * Current index for selected item in list of autocomplete
   */
  public selectedItemIndex = -1;
  public showDropdown = false;

  public readonly lastWord$ = new BehaviorSubject<string>('');
  public readonly items$ = new BehaviorSubject<string[]>(undefined);
  public unwrapItems: string[] = [];
  public formatter: (val: any) => string;

  @ViewChild('advancedSearch', { static: true }) advancedSearch: ElementRef<HTMLDivElement>;
  @ViewChild('ngbDropdownMenuRef', { static: false, read: NgbDropdownMenu }) ngbDropdownMenu: NgbDropdownMenu;

  /**
   * dropdown height value.
   */
  @Input() dropdownHeight: string | number;

  /**
   * if `true` - search box will NOT include tags that already presented in search field into dropdown.
   * If user write duplicate tag manually it will be ignored;
   */
  @Input() disableMultiTag = true;
  @Input() data: Template<any>[] = [];

  /**
   * `true` - read and edit url query
   * `false` - ignore url query
   */
  @Input() accessUrlQuery = true;

  @Output() search = new EventEmitter<any>();

  onChange: (value) => void;
  onTouched: () => void;
  subscription: Subscription;

  searching: boolean;

  /**
   * Default tags formatter. Example `tagName:`
   * @param {val} val
   * @return {string}
   */
  @Input() tagsFormatter = (val: any): string => String(val) + ':';
  @Input() needClearOnDestroy = false;

  @HostListener('document:click', ['$event'])
  closeDropdown(event: MouseEvent): void {
    if (!this.advancedSearch.nativeElement.contains(event.target as Node) || this.isOpenedNgbDropdownMenu()) {
      this.showDropdown = false;
    }
  }

  @HostListener('document:mousedown', ['$event.target'])
  closeDropdownMenu(target: HTMLElement): void {
    if (this.isClickedOutsideNgbDropdownMenu(target)) {
      const dropdown = this.ngbDropdownMenu.dropdown as NgbDropdown;
      dropdown.close();
    }
  }

  private isClickedOutsideNgbDropdownMenu(target: HTMLElement): boolean {
    const isMbsSelectOptionClicked: boolean = this.isMbsSelectOptionClicked(target);

    const dropdownRef = (this.ngbDropdownMenu.dropdown as NgbDropdown)['_elementRef'] as ElementRef<HTMLElement>;
    return (
      this.isOpenedNgbDropdownMenu() &&
      !dropdownRef.nativeElement.contains(target) &&
      !isMbsSelectOptionClicked &&
      !this.isMbsDatepickerClicked(target) &&
      !this.isMbsTimepickerClicked(target)
    );
  }

  private isOpenedNgbDropdownMenu(): boolean {
    return this.ngbDropdownMenu && (this.ngbDropdownMenu.dropdown as NgbDropdown)._open;
  }

  private isMbsSelectOptionClicked(target: HTMLElement): boolean {
    const ngOptions: HTMLElement[] = this.ngbDropdownMenuNgSelectOptions();
    this._mbsSelectOpenedNgOptions = ngOptions.length > 0 ? ngOptions : this._mbsSelectOpenedNgOptions;
    return this._mbsSelectOpenedNgOptions.some((option) => option.contains(target));
  }

  private ngbDropdownMenuNgSelectOptions(): HTMLElement[] {
    const dropdownRef = (this.ngbDropdownMenu.dropdown as NgbDropdown)['_elementRef'] as ElementRef<HTMLElement>;
    return Array.from(dropdownRef.nativeElement.querySelectorAll('ng-select.ng-select-opened ng-dropdown-panel .ng-option'));
  }

  private isMbsDatepickerClicked(target: HTMLElement): boolean {
    return target.closest('ngb-datepicker') && !target.closest('mbs-datepicker');
  }

  private isMbsTimepickerClicked(target: HTMLElement): boolean {
    return target.closest('ngb-timepicker') && !target.closest('mbs-timepicker');
  }

  ngOnDestroy(): void {
    this.needClearOnDestroy && this.handleClear();
  }

  ngAfterContentInit(): void {
    if (this.accessUrlQuery) {
      this.advancedSearch.nativeElement.textContent = this.route.snapshot.queryParamMap.get('search');
      this.handleParseModel();
    }
  }

  notifyValueChange(): void {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  writeValue(obj: AdvancedSearchModel): void {
    if (!obj) {
      return;
    }
    this.myModel = obj;
    const copy = cloneDeep(obj);
    delete copy.words;

    this.searchContent = Object.keys(copy)
      .map((k) => `${k}: ${copy[k] as string}`)
      .join(' ');
    if (obj.words) {
      this.searchContent = this.searchContent + ' ' + obj.words.join(' ');
    }
  }

  registerOnChange(fn: (value) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  handleParseModel(): void {
    this.value = AdvancedSearchParser.getModel(
      this.searchContent,
      this.data.map((m) => m.tag)
    );
  }

  handleClear(): void {
    this.advancedSearch.nativeElement.innerHTML = '';
    this.handleRoute();
  }

  handlePasteEvent(event: ClipboardEvent): void {
    // cancel paste
    event.preventDefault();

    // get text representation of clipboard
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const text = (((event as any).originalEvent as ClipboardEvent) || event).clipboardData.getData('text/plain');

    // insert text manually
    document.execCommand('insertText', false, text);
  }

  handleDropEvent(event: DragEvent): void {
    event.preventDefault();

    const text = event.dataTransfer.getData('text/plain');
    this.advancedSearch.nativeElement.focus();
    // this.pasteValue(text);
    document.execCommand('insertText', false, text);
  }

  handleKeyboardUpEvent(event: KeyboardEvent): boolean {
    if (event.defaultPrevented) {
      return false;
    }
    switch (event.key) {
      case 'Up': // IE, EDGE
      case 'Down':
      case 'ArrowUp':
      case 'ArrowDown':
        event.preventDefault();
        return false;
    }

    this.getCaretPosition(event);
    return true;
  }

  handleKeyboardDownEvent(event: KeyboardEvent): boolean {
    switch (event.key) {
      case 'Up': // IE, EDGE
      case 'ArrowUp':
        event.preventDefault();
        this.selectedItemIndex = (this.unwrapItems.length + this.selectedItemIndex - 1) % this.unwrapItems.length;
        return false;
      case 'Down': // IE, EDGE
      case 'ArrowDown':
        event.preventDefault();
        this.selectedItemIndex = (this.selectedItemIndex + 1) % this.unwrapItems.length;
        return false;
      case 'Esc': // IE, EDGE
      case 'Escape':
        this.showDropdown = false;
        (event.target as HTMLElement).blur();
        return true;
      case 'Enter':
        return this.handleEnter(event);

      default:
        return true;
    }
  }

  handleEnter(event: KeyboardEvent | MouseEvent): boolean {
    event.preventDefault();

    // if selected some value
    if (this.data.length > 0 && this.selectedItemIndex > -1 && event instanceof KeyboardEvent) {
      this.pasteValue(this.formatter(this.unwrapItems[this.selectedItemIndex]));
      return false;
    }
    this.handleRoute();

    return false;
  }

  handleRoute(): void {
    // write url query
    if (this.accessUrlQuery) {
      let opts: NavigationExtras;
      if (this.searchContent.length > 0) {
        opts = {
          relativeTo: this.route,
          queryParams: {
            search: this.searchContent
          },
          queryParamsHandling: 'merge'
        };
      }

      void this.router.navigate([], opts);
    }
    this.handleParseModel();
    this.search.emit(this.value);
  }

  /**
   * Find caret positions
   * @param {KeyboardEvent | MouseEvent} event source event
   */
  getCaretPosition(event: KeyboardEvent | MouseEvent): void {
    if (this.data.length === 0 || event.ctrlKey || event.shiftKey || event.altKey || window.getSelection().toString().length > 0) {
      return;
    }

    const element = event.target as HTMLElement;

    this.selectedItemIndex = -1;
    if (!element.firstChild) {
      this.caret = 0;
      this.items$.next(this.data.map((d) => d.tag));
      this.formatter = this.tagsFormatter;
      return;
    }

    window.getSelection().collapseToEnd();
    const sel = window.getSelection();
    const range = sel.getRangeAt(0);

    range.setStart(element.firstChild, 0);

    // Can't work for IE/Edge:  window.getSelection().toString();
    const content = range.toString();

    const text = JSON.stringify(content);

    window.getSelection().collapseToEnd();
    this.caret = text.length - 2;

    this.parseContent(this.searchContent, this.caret);
  }

  /**
   * Parse contents and show autocomplete hints
   * @param content search box content
   * @param caret current cursor position
   */

  parseContent(content: string, caret: number): void {
    if (this.data.length === 0) {
      return;
    }
    this.searching = true;
    // unsubscribe from last request
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }

    let result;

    const lastPart = this.getLastWord(content, caret);
    const tagsFilter = this.data
      .filter((t) => {
        // eslint-disable-next-line sonarjs/no-collapsible-if
        if (this.disableMultiTag) {
          if (content.toLowerCase().includes(t.tag.toLowerCase())) {
            return false;
          }
        }
        return t.tag.toLowerCase().includes(lastPart.toLowerCase());
      })
      .map((m) => m.tag)
      .slice(0, 10);

    this.formatter = this.tagsFormatter;

    const reg = AdvancedSearchParser.tagsRegex;

    while ((result = reg.exec(content))) {
      const resultArr = result as RegExpExecArray;
      const startMatchIndex = content.lastIndexOf(resultArr[1]);
      // if caret into match value
      if (startMatchIndex < caret && caret <= startMatchIndex + resultArr[1].length) {
        const matchTag = this.data.find((d) => {
          const tagReg = new RegExp(d.tag.replace('s+', ' '), 'i');
          const matches = tagReg.exec(resultArr[2]);
          return matches && resultArr[2].indexOf(matches[1]) < caret;
        });
        // if caret into value or tag
        if (matchTag && startMatchIndex + resultArr[2].length < caret) {
          this.formatter = matchTag.itemFormatter;
          this.lastWord$.next(lastPart);
          this.items$.next([]);

          this.subscription = matchTag.items(this.lastWord$).subscribe((items: string[]) => {
            this.items$.next(items);
            this.searching = false;
          });
          this.tagInfo = matchTag;
        } else {
          this.formatter = this.tagsFormatter;
          this.items$.next(tagsFilter);
          this.tagInfo = undefined;
        }
        return;
      }
    }

    // else return tags
    this.items$.next(tagsFilter);
    this.tagInfo = undefined;
    this.searching = false;
  }

  /**
   * Get last word before caret
   * @param {string} content search box content
   * @param {number} caret current cursor position
   * @return {string}
   */
  getLastWord(content: string, caret: number): string {
    // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
    const lastWordMatch = content.substring(0, caret).match(/((\S+)|{([\s\S]+)})$/);
    let lastPart = (lastWordMatch && (lastWordMatch[3] || lastWordMatch[1])) || '';
    // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
    const valueMatch = lastPart.match(/:(\S*)$/);
    lastPart = valueMatch ? valueMatch[1] || '' : lastPart;

    return lastPart;
  }

  /**
   * Insert value around caret
   * Call onSelect from dropdown menu
   * @param {string} value source value
   */
  pasteValue(value: string): void {
    this.advancedSearch.nativeElement.focus();
    let selectedLen = 0;
    if (this.advancedSearch.nativeElement.firstChild) {
      selectedLen = this.selectWordBeforeCaret(this.searchContent, this.caret);
    }
    const copyValue = value + ' ';

    const startWordCared = this.caret - selectedLen;
    this.caret += copyValue.length - selectedLen;

    // document.insertText can't work for Firefox and IE
    const copyContent = this.searchContent;
    const leftPart = copyContent.substring(0, startWordCared);
    const rightPart = copyContent.substring(this.caret);
    this.searchContent = leftPart + copyValue + rightPart;

    // move caret to end word after replace content
    this.moveCaretTo(this.caret);
  }

  /**
   * Select one word to caret and return its length.
   * @param {string} content search box content
   * @param {number} caret current cursor position
   * @return {number}
   */
  selectWordBeforeCaret(content: string, caret: number): number {
    content = content.substring(0, this.caret);
    if (this.caret === 0) {
      return 0;
    }
    const lastWord = this.getLastWord(content, caret);
    const offset = content.length - lastWord.length;

    // select left word part and replace
    // don't take right word part if exists
    const sel = window.getSelection();
    const range = sel.getRangeAt(0);
    range.setStart(this.advancedSearch.nativeElement.firstChild, offset);

    return lastWord.length;
  }

  /**
   * Move caret to position
   * @param {number} caret need position
   */
  moveCaretTo(caret: number): void {
    if (!this.advancedSearch.nativeElement.firstChild) {
      return;
    }
    const range = this.doc.createRange();
    range.setStart(this.advancedSearch.nativeElement.firstChild, caret);
    range.setEnd(this.advancedSearch.nativeElement.firstChild, caret);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
  }

  /**
   * Handle mouse click on list of autocomplete value
   * @param {string} item selected value
   */
  handleClickSelectedValue(item: string): void {
    this.selectedItemIndex = -1;
    this.pasteValue(this.formatter(item));
  }
}
