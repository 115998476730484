import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbDatepickerModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { IMaskModule } from 'angular-imask';
import { ButtonModule } from '../../button/button.module';
import { InputBaseModule } from '../input-base/input-base.module';
import { DatepickerComponent } from './datepicker.component';

@NgModule({
  imports: [CommonModule, FormsModule, NgbDatepickerModule, InputBaseModule, IMaskModule, NgbDropdownModule, ButtonModule],
  declarations: [DatepickerComponent],
  exports: [DatepickerComponent],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class DatepickerModule {
  constructor() {
    // empty
  }
}
