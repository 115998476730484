import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from '../button/button.module';
import { CheckboxModule } from '../form/checkbox/checkbox.module';
import { InputModule } from '../form/input/input.module';
import { TableGridModule } from '../table-grid/table-grid.module';
import { AccordionModule } from '../accordion/accordion.module';
import { AdvancedSearchModule } from '../advanced-search/advanced-search.module';
import { ListSelectGroupCheckDirective } from './directives/list-select-group-check.directive';
import { ListSelectGroupHeaderDirective } from './directives/list-select-group-header.directive';
import { ListSelectGroupInnerItemDirective } from './directives/list-select-group-inner-item.directive';
import { ListSelectGroupDirective } from './directives/list-select-group.directive';
import { AppendTopControlsLabel, ListSelectComponent, PrependTopControlsLabel } from './list-select.component';

@NgModule({
  declarations: [
    AppendTopControlsLabel,
    ListSelectComponent,
    ListSelectGroupDirective,
    ListSelectGroupHeaderDirective,
    ListSelectGroupInnerItemDirective,
    ListSelectGroupCheckDirective,
    PrependTopControlsLabel
  ],
  imports: [CommonModule, FormsModule, CheckboxModule, TableGridModule, InputModule, ButtonModule, AccordionModule, AdvancedSearchModule],
  exports: [
    AppendTopControlsLabel,
    ListSelectComponent,
    ListSelectGroupDirective,
    ListSelectGroupHeaderDirective,
    ListSelectGroupInnerItemDirective,
    ListSelectGroupCheckDirective,
    PrependTopControlsLabel,
    TableGridModule
  ],
  providers: []
})
export class ListSelectModule {}
