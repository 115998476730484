import { Injectable, Injector } from '@angular/core';
import { MBS_APP_CONFIG } from '../../../config.loader';

export abstract class ConfigurationAbstractService {
  abstract get(key: string): any;
}

@Injectable({ providedIn: 'root' })
export class ConfigurationService implements ConfigurationAbstractService {
  public loaded = false;
  public configuration: any;

  constructor(private injector: Injector) {
    this.configuration = this.injector.get(MBS_APP_CONFIG);
    this.loaded = true;
  }

  get(key: string): any {
    return this.configuration[key];
  }
}
