import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { map, Observable, of } from 'rxjs';
import {
  ALIAS_REGEX,
  ALIAS_TEXT_REGEX,
  BREAKS_REGEX,
  COMMAS_REGEX,
  DNS_REGEX,
  DOMAIN_REGEX,
  EMAIL_REGEXP,
  getComposedRegex,
  SPACES_REGEX,
  TIME_24_FORMAT_WITHOUT_SECONDS_REGEXP,
  validateIPV4Regex
} from './constants';
import { bytesFrom, Size } from './converter';

/**
 * const errors for other reusing
 */
export const constErrors = {
  TIME_VALIDATION_ERROR: 'Invalid time format'
};

interface PasswordCheckService {
  checkPasswordStrength: (password: string) => Observable<{ result: boolean; message: string; suggestions?: string[] }>;
}

/**
 * Validators with message based angular/Validators (https://angular.io/guide/form-validation)
 */
@Injectable()
export class MbsValidators {
  public static validatorMessages = {
    min: (min: { min: number; actual: number }): string => {
      return `This field value is too small (min ${min.min})`;
    },
    max: (max: { max: number; actual: number }): string => {
      return `This field value is too big (max ${max.max})`;
    },
    email: (): string => {
      return `The email address is not valid`;
    },
    minlength: (minlength: { requiredLength: number; actualLength: number }): string => {
      return `At least ${minlength.requiredLength} symbols required`;
    },
    maxlength: (maxlength: { requiredLength: number; actualLength: number }): string => {
      return `This field is too long (max length ${maxlength.requiredLength})`;
    },
    maxlengthPassword: (maxlength: { requiredLength: number; actualLength: number }): string => {
      return `The password is too long (max length ${maxlength.requiredLength} characters)`;
    },
    minlengthPassword: (minlength: { requiredLength: number; actualLength: number }): string => {
      return `The password is too short (min length ${minlength.requiredLength} characters)`;
    },
    pattern: (): string => {
      return `This field is not valid`;
    },
    time: (): string => {
      return constErrors.TIME_VALIDATION_ERROR;
    },
    mustMatch: (): string => {
      return `Fields don't match`;
    },
    mustNotMatch: (): string => {
      return `Fields cannot be equal`;
    },
    portValidator: (): string => {
      return `Can't be set value more than 65535`;
    },
    dnsMatch: (): string => {
      return `The DNS is not valid`;
    },
    aliasMatch: (): string => {
      return `Only case-sensitive combination of Latin letters and digits is supported`;
    },
    domainNameMatch: (): string => {
      return `The domain name is not valid`;
    }
  };

  public static whitespaceValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value && (/^\s+/.test(control.value as string) || /\s+$/.test(control.value as string))) {
      return { whitespace: { message: 'The field value cannot start and/or end with a space' } };
    }

    return null;
  }

  public static emailValidator(control: AbstractControl): ValidationErrors | null {
    if (new RegExp(EMAIL_REGEXP).test(control.value as string)) {
      return null;
    }

    return { email: { message: MbsValidators.validatorMessages.email() } };
  }

  public static IPAddressValidator(control: AbstractControl): ValidationErrors | null {
    if (new RegExp(validateIPV4Regex).test(control.value as string)) {
      return null;
    }

    return { pattern: { message: MbsValidators.validatorMessages.pattern() } };
  }
  public static fileSizeValidator(maxfileSize: string) {
    return function (control: FormControl): { [key: string]: any } | null {
      const fileList: FileList = control.value as FileList;

      if (fileList && fileList.length) {
        const sizeText = maxfileSize.split(/\d+/).pop() || 'kb';
        const sizeNum = +maxfileSize.split(/\D+/).shift() || 500;
        const file = fileList[0];
        const size = sizeText.toUpperCase() as Size;
        const fileSize = +(file.size / bytesFrom(size)).toFixed(2);

        return fileSize > sizeNum ? { fileSizeValidator: { message: `Max file size ${maxfileSize}.` } } : null;
      }

      return null;
    };
  }

  public static dimensionValidator(settings: { maxWidth: number; maxHeight: number }): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      const fileList: FileList = control.value as FileList;

      if (fileList?.length) {
        const fileReader = new FileReader();

        fileReader.readAsDataURL(fileList[0]);
        fileReader.onload = () => {
          const image = new Image();

          image.src = fileReader.result as string;
          image.onload = () => {
            const testResult = image.width <= settings.maxWidth && image.height <= settings.maxHeight;

            if (testResult) {
              return of({
                dimensionValidator: {
                  message: `The image width and height should not be more than: ${settings.maxWidth} and ${settings.maxHeight}.`
                }
              });
            } else {
              return of(null);
            }
          };
        };
      }

      return of(null);
    };
  }

  public static passwordValidator(control: AbstractControl): ValidationErrors | null {
    const errors = Validators.compose([Validators.required, Validators.minLength(7), Validators.pattern('\\S+')])(control);

    if (!errors) return null;

    if (errors.minlength) {
      return {
        minLength: {
          message: MbsValidators.validatorMessages.minlength(errors.minlength as { requiredLength: number; actualLength: number })
        }
      };
    }

    if (errors.pattern) {
      return { pattern: { message: 'Password should not contain whitespace' } };
    }

    if (errors.required) {
      return { required: { message: '' } };
    }

    return null;
  }

  public static passwordStrengthValidator(passwordCheckService: PasswordCheckService, fakePassword = ''): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      if (!control.value || control.value === fakePassword) {
        return of(null) as Observable<ValidationErrors>;
      }

      return passwordCheckService.checkPasswordStrength(control.value as string).pipe(
        map((data) => {
          const newErrors: ValidationErrors = {};

          if (data.result) {
            const errorKeys = control.errors ? Object.keys(control.errors) : [];
            const filteredKeys = errorKeys.filter((key) => !key.includes('passwordStrengthError'));

            if (errorKeys.length && filteredKeys.length) {
              errorKeys.forEach((key) => {
                if (!key.includes('passwordStrengthError')) newErrors[key] = control.errors[key];
              });

              return newErrors;
            }

            return null;
          }

          if (data?.suggestions?.length) {
            data.suggestions.forEach((err, idx) => {
              newErrors['passwordStrengthError' + idx] = { message: err };
            });
          } else newErrors.passwordStrengthError = { message: data.message };

          return newErrors;
        })
      );
    };
  }

  public static createPasswordValidator(settings: { minlength?: number; maxlength?: number; pattern?: string | RegExp }): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const ValidatorsArray: ValidatorFn[] = [];

      if (!settings) return null;
      if (settings.minlength) ValidatorsArray.push(Validators.minLength(settings.minlength));
      if (settings.maxlength) ValidatorsArray.push(Validators.maxLength(settings.maxlength));
      if (settings.pattern) ValidatorsArray.push(Validators.pattern(settings.pattern));

      const errors: ValidationErrors = Validators.compose(ValidatorsArray)(control);

      if (!errors) return null;

      if (errors.minlength)
        return {
          minLength: {
            message: MbsValidators.validatorMessages.minlengthPassword(errors.minlength as { requiredLength: number; actualLength: number })
          }
        };

      if (errors.maxlength)
        return {
          maxLength: {
            message: MbsValidators.validatorMessages.maxlengthPassword(errors.maxlength as { requiredLength: number; actualLength: number })
          }
        };

      if (errors.pattern) return { pattern: { message: 'Password should not contain whitespace' } };

      if (errors.required) return { required: { message: '' } };

      return null;
    };
  }

  public static triggerValidation(control: AbstractControl) {
    if (control instanceof FormGroup) {
      for (const field in control.controls) {
        if (!Object.prototype.hasOwnProperty.call(control.controls, field)) continue;

        const c = control.controls[field];

        this.triggerValidation(c);
      }
    } else if (control instanceof FormArray) {
      for (const items of control.controls) {
        this.triggerValidation(items);
      }
    }

    if (control.validator) {
      const errors = control.validator(control);

      if (errors && Object.getOwnPropertyNames(errors).length > 0) {
        control.updateValueAndValidity({ onlySelf: false });
        control.markAsDirty();
        control.markAsTouched();
      }
    }
  }

  public static extensionValidator(extensionsArray: string[]): any {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value) {
        let testResult = true;
        let extension = '';
        let file: File;

        for (file of control.value) {
          const splitText: string[] = file.name.split('.');
          extension = splitText[splitText.length - 1];
          const result = extensionsArray.find((t) => extension.toLowerCase() == t.toLowerCase());

          if (!result) {
            testResult = false;
            break;
          }
        }

        if (!testResult) {
          return {
            forbiddenFileType: {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              value: control.value,
              message: `Forbidden file type! Only "${extensionsArray.join(', ')}" available`
            }
          };
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }

  public static allowedFileType(allowedTypes: string[], customMessage?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value) {
        let forbidden = false;

        /* `file` is a native js File. See File Web API (https://developer.mozilla.org/en-US/docs/Web/API/File)
         * so `file.type` is MIME-type of file (https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types)
         *
         * Avoid to use this validator to detect not widespread formats, for example *.psd
         * In this cases file.type will be an empty string
         */
        let file: File;

        for (file of control.value) {
          if (!allowedTypes.map((t) => t.toLowerCase()).includes(file.type)) {
            forbidden = true;
            break;
          }
        }

        return forbidden
          ? {
              forbiddenFileType: {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                value: control.value,
                message: customMessage || `Forbidden file type! Only "${allowedTypes.join(', ')}" available`
              }
            }
          : null;
      }
      return null;
    };
  }

  public static dateValidator(control: AbstractControl): ValidationErrors | null {
    if (control && control.value instanceof Date && control.value.toString() !== 'Invalid Date') {
      return null;
    }

    return { pattern: { message: MbsValidators.validatorMessages.pattern() } };
  }

  public static portValidator(control: AbstractControl): ValidationErrors | null {
    const onlyDigits = /^\d+$/.test(control.value as string);

    if (control.value && !onlyDigits) {
      return { onlyDigits: { message: 'This field should contain only digits' } };
    }

    const port = parseInt(control.value as string);

    if (control.value && !(0 < port && port <= 65535)) {
      return { portValidator: { message: MbsValidators.validatorMessages.portValidator() } };
    }

    return null;
  }

  public static timeFormat24WithoutSecondsValidator(control: AbstractControl): ValidationErrors | null {
    if (new RegExp(TIME_24_FORMAT_WITHOUT_SECONDS_REGEXP).test(control.value as string)) {
      return null;
    }

    return { time: { message: MbsValidators.validatorMessages.time() } };
  }

  public static timeValidatorWithoutText(control: AbstractControl): ValidationErrors | null {
    if (control.disabled || (!control.touched && !control.dirty)) return null;

    return new RegExp(TIME_24_FORMAT_WITHOUT_SECONDS_REGEXP).test(control.value as string) ? null : { timeInvalid: true };
  }

  // custom validator to check that two fields match; FormGroup validator
  public static mustMatch(settings: { controlName: string; matchingControlName: string; inverse?: boolean }) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[settings.controlName];
      const matchingControl = formGroup.controls[settings.matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return null;
      }

      // set error on matchingControl if validation fails
      if (settings.inverse) {
        if (control.enabled && matchingControl.enabled && control.value === matchingControl.value) {
          matchingControl.setErrors({ mustNotMatch: MbsValidators.validatorMessages.mustNotMatch() });
        } else {
          matchingControl.setErrors(null);
        }
      } else {
        if (control.enabled && matchingControl.enabled && control.value !== matchingControl.value) {
          matchingControl.setErrors({ mustMatch: MbsValidators.validatorMessages.mustMatch() });
        } else {
          matchingControl.setErrors(null);
        }
      }

      // return null;
    };
  }

  public static domainValidator(control: AbstractControl): ValidationErrors | null {
    const value: string = control?.value;

    if (value) {
      const pattern = new RegExp(DOMAIN_REGEX);
      const domainNamesRegex = getComposedRegex(BREAKS_REGEX, SPACES_REGEX, COMMAS_REGEX);
      const domainNames = value.split(domainNamesRegex).filter(Boolean);
      const hasError = domainNames.some((name) => !pattern.test(name));

      return hasError ? { domainName: { message: MbsValidators.validatorMessages.domainNameMatch() } } : null;
    }

    return null;
  }

  public static dnsValidator(control: AbstractControl): ValidationErrors | null {
    const pattern = new RegExp(DNS_REGEX);
    const invalidSymbols: string[] = [
      '"',
      '/',
      '\\',
      '[',
      ']',
      ':',
      '|',
      ' ',
      '<',
      '>',
      '+',
      '=',
      ';',
      ',',
      '?',
      '*',
      '%',
      '#',
      '@',
      '{',
      '}',
      '^',
      '`'
    ];

    if (control?.value) {
      const hasError = invalidSymbols.some((s) => control.value.includes(s)) || !pattern.test(control.value);

      return hasError ? { dns: { message: MbsValidators.validatorMessages.dnsMatch() } } : null;
    }

    return null;
  }

  public static aliasValidator(control: AbstractControl): ValidationErrors | null {
    return MbsValidators.aliasCommonValidator(control);
  }

  public static aliasTextValidator(control: AbstractControl): ValidationErrors | null {
    return MbsValidators.aliasCommonValidator(control, true);
  }

  public static aliasCommonValidator(control: AbstractControl, isTextValidator = false): ValidationErrors | null {
    const pattern = new RegExp(isTextValidator ? ALIAS_TEXT_REGEX : ALIAS_REGEX);

    if (control?.value) {
      const hasError = !pattern.test(control.value);

      return hasError ? { alias: { message: MbsValidators.validatorMessages.aliasMatch() } } : null;
    }

    return null;
  }
}
