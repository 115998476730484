import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'smartSearchMaxWidth',
  pure: true
})
export class SmartSearchMaxWidthPipe implements PipeTransform {
  transform(screenX: number): string {
    return screenX > 1400 ? '600px' : 'initial';
  }
}
