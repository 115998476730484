import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AutoFocusDirective } from './auto-focus.directive';
import { FocusDirective } from './focus.directive';
import { FocusBlurHandlerDirective } from './focusBlurHandler.directive';
import { InputAppendDirective } from './input-append.directive';
import { InputErrorsDirective } from './input-errors.directive';
import { InputErrorsComponent } from './input-errors/input-errors.component';
import { InputLabelDirective } from './input-label.directive';
import { InputLabelComponent } from './input-label/input-label.component';
import { InputPreAppendComponent } from './input-pre-append/input-pre-append.component';
import { InputPrependDirective } from './input-prepend.directive';

const baseDeclaration = [
  InputLabelDirective,
  InputErrorsDirective,
  InputLabelComponent,
  InputErrorsComponent,
  InputPreAppendComponent,
  InputPrependDirective,
  InputAppendDirective,
  AutoFocusDirective,
  FocusBlurHandlerDirective,
  FocusDirective
];

@NgModule({
  imports: [CommonModule, NgbTooltipModule],
  declarations: baseDeclaration,
  exports: baseDeclaration
})
export class InputBaseModule {}
