import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { ExportToPST, ExportToPSTServices } from '@app/common/models';
import { AuthService } from '@app/common/services';
import { I18_NAMESPACE_MODULE } from '@app/i18';
import { ModalComponent } from 'mbs-ui-kit';
import { BehaviorSubject } from 'rxjs';

@Component({
  templateUrl: './export-to-pst-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportToPstModalComponent implements OnInit {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  public readonly loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public readonly disabled$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  public readonly moduleAccount = I18_NAMESPACE_MODULE.account;

  private payloadForExportToPST: ExportToPST;

  get userId(): string {
    return this.authService.id;
  }

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.loading$.next(false);
  }

  invalidForms(state: boolean): void {
    this.disabled$.next(!state);
  }

  handleUpdatePayloadForExportToPST(services: ExportToPSTServices[]): void {
    this.payloadForExportToPST = {
      UserIds: [this.userId],
      Services: services
    };
  }

  handleExport(): void {
    this.baseModal.save(this.payloadForExportToPST);
  }

  handleClose(): void {
    this.baseModal.close();
  }
}
