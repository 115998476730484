import { MimeCategory } from '../enums/mime-type.enum';

export interface TeamItem {
  Id: string;
  Name: string;
  ItemType: TeamItemType;
  MimeCategory: MimeCategory;
}

export enum TeamItemType {
  Team = 'Team',
  Channel = 'Channel',
  FilesRoot = 'FilesRoot',
  PostsRoot = 'PostsRoot',
  File = 'File',
  Folder = 'Folder',
  Root = 'Root' // only for manual create root node
}

export interface ChannelItem extends TeamItem {
  ChannelId: string;
}

export interface TeamOdataPost {
  Id: number;
  FromName: string;
  CreatedDate: string;
  // html content
  Content: string;
  ExistReplies: boolean;
}

export class TeamPost {
  public id: number;
  public userName: string;
  public date: Date;
  public content: string;
  public hasReplies: boolean;
  public showReplies = false;

  #repliesSet = false;
  #replies: TeamPost[] = [];

  constructor(post: TeamOdataPost) {
    this.id = post.Id;
    this.userName = post.FromName;
    this.date = new Date(post.CreatedDate);
    this.content = post.Content;
    this.hasReplies = post.ExistReplies;
  }

  get repliesSet(): boolean {
    return this.#repliesSet;
  }

  set replies(value: TeamPost[]) {
    if (this.#repliesSet) return;

    this.#replies = value;
    this.#repliesSet = true;
  }

  get replies(): TeamPost[] {
    return this.#replies;
  }
}

export interface TeamFile extends TeamItem {
  ChannelId: number;
  DbId: string;
  Size: number;
  Type: TeamFileItemType;
}

export enum TeamFileItemType {
  Folder,
  File
}
