import { Inject, Injectable } from '@angular/core';
import { asapScheduler, Observable, of, queueScheduler, scheduled } from 'rxjs';
import { catchError, map, take, zipAll } from 'rxjs/operators';
import { APP_READONLY_MODE } from '../../injection-tokens';
import { ModalService, ModalSettings } from '../../modal/modal.service';
import { modalText } from '../../sidepanel';
import { TabsService } from '../../tabset/tabs.service';
import { EnumHelper } from '../../utils/enum-helper';
import { ConfirmReason } from './ConfirmReason';
import { DataChangeWatcherBase } from './data-change-watcher-base.directive';

@Injectable({
  providedIn: 'root'
})
export class DataChangeWatcherService {
  private components: Array<DataChangeWatcherBase> = [];

  constructor(
    private modalService: ModalService,
    public tabsService: TabsService,
    @Inject(APP_READONLY_MODE) private readonlyMode: boolean
  ) {}

  /**
   * Attach observable component to pool for prevent route change with unsaved changes
   * @param {DataChangeWatcherBase} component DataChangeWatcherBase component instance
   */
  public attachComponent(component: DataChangeWatcherBase): void {
    this.components.push(component);
  }

  /**
   * Remove observable component from pool
   * @param {DataChangeWatcherBase} component DataChangeWatcherBase component instance
   */
  public deattachComponent(component: DataChangeWatcherBase): void {
    const index = this.components.indexOf(component);

    if (index > -1) {
      this.components.splice(index, 1);
    }
  }

  /**
   * Find component in observable pool
   * @param {DataChangeWatcherBase} component DataChangeWatcherBase component instance
   * @return {DataChangeWatcherBase}
   */
  public find(component: DataChangeWatcherBase): DataChangeWatcherBase {
    return this.components.find((c) => c === component);
  }

  /**
   * Return boolean state can change page
   * @return {Observable<boolean> | Promise<boolean>}
   */
  public detectChanges(): Observable<boolean> {
    if (this.components.length > 0 && !this.readonlyMode) {
      const detectChanges$ = this.components.filter(Boolean).map((c) => c.detectChanges$.pipe(take(1)));

      return scheduled(detectChanges$, queueScheduler).pipe(
        zipAll(),
        map((reasons) => !reasons.some((reason) => EnumHelper.HasFlag(reason, ConfirmReason.CANNOT_CLOSE)))
      );
    } else {
      return of(true);
    }
  }

  public showSaveModal(component: DataChangeWatcherBase): Observable<ConfirmReason> {
    if (this.readonlyMode) {
      return of(ConfirmReason.CAN_CLOSE);
    }
    const allValid = component.isValidSidepanel();
    // @TODO refractor. `component` can be a simple page
    const modalText = component && (component as any).genericPanel ? ((component as any).genericPanel.modalText as modalText) : null;

    const modalSettings: ModalSettings = this.getModalSettings(allValid, modalText);
    const modalTitle = allValid
      ? (modalText && modalText.text) || 'All changes will be lost'
      : (modalText && modalText.textInvalid) || 'Not all fields are filled in correctly';

    return scheduled(this.modalService.confirm(modalSettings, modalTitle), asapScheduler).pipe(
      map(() => {
        if (!allValid) {
          this.tabsService.openFirstError();
          return ConfirmReason.BACK;
        }
        return ConfirmReason.SAVE;
      }),
      catchError(() => of(ConfirmReason.DISCARD))
    );
  }

  private getModalSettings(valid: boolean, modalText: modalText): ModalSettings {
    return {
      header: { title: (modalText && modalText.title) || 'Unsaved Changes' },
      footer: {
        okButton: { text: valid ? (modalText && modalText.save) || 'Save' : (modalText && modalText.back) || 'Back' },
        cancelButton: { text: (modalText && modalText.cancel) || 'Discard Changes' }
      }
    };
  }
}
