import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PolicyTypePipe } from '@app/pages/users/pipe/policy-type.pipe';

const pipes = [PolicyTypePipe];

@NgModule({
  imports: [CommonModule],
  declarations: pipes,
  exports: pipes
})
export class UsersPipesModule {}
