<!-- ng-content required single tag for every select="name" -->
<ng-template #ngContentHack>
  <span *ngIf="icon"
        [class.mr-0]="square"
        [ngClass]="[iconClasses]"></span>
  <ng-content></ng-content>
</ng-template>

<ng-container *ngIf="dropdownMenuItemsTemplate">
  <div ngbDropdown
       class="d-inline-flex"
       [ngClass]="customParentClasses"
       [display]="dropdownMenuItemsTemplate.display"
       [placement]="dropdownPlacement"
       [container]="dropdownMenuItemsTemplate.container">
    <button [attr.id]="id"
            type="button"
            (click)="handleClick($event)"
            [container]="tooltipData ? tooltipContainer : null"
            [placement]="tooltipData ? tooltipPlacement : null"
            [triggers]="tooltipData ? tooltipTriggers : null"
            [ngClass]="[prefix, btnTypeClass, btnSizeClass, btnBlockClass, btnLoadingClass, btnActiveClass, btnOverflowClass, customClasses]"
            [class.-no-arrow]="hideDropdownIcon"
            [class.btn-rounded]="rounded"
            [class.btn-square]="square"
            [class.tooltip-always]="tooltipAlways"
            [ngbTooltip]="tooltipData?.length ? tooltipContent : null"
            [tooltipClass]="tooltipClass"
            [disabled]="disabled || loading"
            [attr.aria-disabled]="disabled || loading"
            ngbDropdownToggle>
      <ng-container [ngTemplateOutlet]="ngContentHack"></ng-container>
    </button>
    <div ngbDropdownMenu
         [class.-sm]="dropdownMenuItemsTemplate.size === MbsSize.sm"
         [class.-lg]="dropdownMenuItemsTemplate.size === MbsSize.lg"
         [class.-arrow]="dropdownMenuItemsTemplate.showArrow"
         [attr.aria-labelledby]="id">
      <ng-container [ngTemplateOutlet]="dropdownMenuItemsTemplate.template"></ng-container>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!dropdownMenuItemsTemplate">
  <button [attr.id]="id"
          (click)="handleClick($event)"
          [type]="buttonType"
          [container]="tooltipData ? tooltipContainer : null"
          [placement]="tooltipData ? tooltipPlacement : null"
          [triggers]="tooltipData ? tooltipTriggers : null"
          [ngClass]="[prefix, btnTypeClass, btnSizeClass, btnBlockClass, btnLoadingClass, btnActiveClass, customClasses, btnOverflowClass]"
          [class.btn-rounded]="rounded"
          [class.btn-square]="square"
          [class.tooltip-always]="tooltipAlways"
          [class.text-start]="align === 'left'"
          [disabled]="disabled || loading"
          [ngbTooltip]="tooltipData?.length ? tooltipContent : null"
          [tooltipClass]="tooltipClass"
          [attr.aria-disabled]="disabled || loading">
    <ng-container [ngTemplateOutlet]="ngContentHack"></ng-container>
  </button>
</ng-container>

<ng-template #tooltipContent>
  <div *ngIf="tooltipData?.length" class="text-left">
    <div *ngFor="let row of tooltipData"
         class="mb-1">{{row}}</div>
  </div>
</ng-template>
